import { MutableRefObject, useEffect, useState } from 'react'

type UseOutsideAlerterReturnType = {
    showElement: boolean
    setShowElement: (visible: boolean | ((old: boolean) => boolean)) => void
}

export const useOutsideAlerter = (
    initialIsVisible: boolean,

    ref: MutableRefObject<any>,

    exceptionRefs?: MutableRefObject<any>[],
    focusIn?: boolean,
    onClose?: () => void
): UseOutsideAlerterReturnType => {
    const [isVisible, setIsVisible] = useState<boolean>(initialIsVisible)

    useEffect(() => {
        const handleClickOutside = (event: Event) => {
            const notInExceptions = exceptionRefs?.every(r => !r?.current?.contains(event.target))
            if (ref.current && !ref.current.contains(event.target) && notInExceptions) {
                onClose?.()
                setIsVisible(false)
            }
        }

        // Bind the event listener
        document.addEventListener(focusIn ? 'focusin' : 'mousedown', handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener(focusIn ? 'focusin' : 'mousedown', handleClickOutside)
        }
    }, [ref, exceptionRefs, focusIn, onClose])

    return { showElement: isVisible, setShowElement: setIsVisible }
}
