import { Global } from '~/global'
import { mixpanelIdentify, mixpanelTrack } from '~/utils/MixpanelUtils'
import { NavigateFunction, Outlet, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { ServerApi } from '~/utils/ServerApi'
import { PlaceManager } from '~/utils/PlaceManager'
import React, { useEffect } from 'react'
import ReactModal from 'react-modal'
import { promptMobileBookmarkIfNecessary } from '~/utils/mobileBookmarkPrompt'
import * as ReactI18next from 'react-i18next'
import i18n from 'i18next'
import { OWContextProvider } from '~/utils/OWContext'
import { ApolloClient, from, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { typePolicies } from '~/apollo'
import Cookies from 'js-cookie'
import { Id } from '~/app/types'
import { useLocation } from 'react-router'
import { generatePersistedQueryIdsFromManifest } from '@apollo/persisted-query-lists'
import manifest from '~/generated/persisted-query-manifest.json'
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'

const keepMixpanelUserInfoBooleansSynced = (props: string[]) => {
    const userInfo = JSON.parse(Global.USER_INFO) as Record<string, any>
    if (!userInfo || Global.USER_INFO === '{}') {
        return
    }
    const employeeId = userInfo['Employee Id'] as Id
    if (!employeeId) {
        return
    }

    const mpUserInfoPropKey = (propName: string) => `MP_e${employeeId}_${propName.toLowerCase().replaceAll(' ', '_')}`
    const trackedUserPropChanged = (propName: string) => {
        const boolValFromServer = propName in userInfo && userInfo[propName]
        const boolValFromMp = localStorage.getItem(mpUserInfoPropKey(propName)) === 'true'
        return boolValFromServer !== boolValFromMp
    }

    const atLeastOnePropChanged = props.some(trackedUserPropChanged)
    if (atLeastOnePropChanged) {
        mixpanelIdentify(userInfo)
    }

    // Update LocalStorage
    props.forEach(propName => {
        const boolValFromServer = propName in userInfo && userInfo[propName]
        localStorage.setItem(mpUserInfoPropKey(propName), boolValFromServer)
    })
}
const setupCoverage = (navigate: NavigateFunction) => {
    const coverage = '__coverage__' in globalThis
    console.log(`env: ${process.env.NODE_ENV}, coverage: ${coverage}`)
    if (process.env.NODE_ENV !== 'production' || coverage) {
        console.log('selenium-goto enabled')
        const listener = (event: Event) => navigate((event as CustomEvent).detail)
        document.addEventListener('selenium-goto', listener)
        return () => document.removeEventListener('selenium-goto', listener)
    }
}
const updateTimezone = () => {
    if (Global.CURRENT_EMPLOYEE_PUBLIC_ID) {
        const currentTZOffset = new Date().getTimezoneOffset() * -1
        const currentTZName = moment.tz.guess()
        if (currentTZOffset !== Global.TZ_OFFSET || currentTZName !== Global.TZ_NAME) {
            void ServerApi.updateEmployee(Global.CURRENT_EMPLOYEE_PUBLIC_ID, {
                timezone_offset: currentTZOffset,
                timezone_name: currentTZName,
            }).then(r => console.log(r))
        }
    }
}
type SearchPageData = { query_string: string; employee_count: number }
const trackAbandonedSearch = () => {
    // This tells Mixpanel when our search results page has been abandoned
    if (location.pathname === '/search/') return

    try {
        const searchResultData = JSON.parse(lscache.get('search-page-data')) as SearchPageData
        if (searchResultData) {
            void mixpanelTrack(
                'Search Results - Abandoned',
                {
                    'Query String': searchResultData.query_string,
                    'Employee Hits': searchResultData.employee_count,
                },
                function () {
                    lscache.remove('search-page-data')
                }
            )
        }
    } catch {
        // ignore
    }
}

export const clientFactory = (testing = true) => {
    const errorLink = onError(() => {
        // ignore
    })

    const httpLink = createUploadLink({
        uri: operation => `/graphql?op=${operation.operationName}`,
        credentials: 'include',
    })

    const authLink = setContext((_, { headers }) => {
        const offset = new Date().getTimezoneOffset()
        return {
            headers: {
                ...headers,
                tzOffset: offset,
                tzName: Intl.DateTimeFormat().resolvedOptions().timeZone,
                'X-CSRFToken': Cookies.get('csrftoken') ?? '',
            },
        }
    })
    const persistLink = createPersistedQueryLink({
        ...generatePersistedQueryIdsFromManifest({ loadManifest: () => manifest }),
        useGETForHashedQueries: false,
    })
    const link = testing ? authLink.concat(httpLink) : persistLink.concat(authLink.concat(httpLink))

    return new ApolloClient({
        link: from([errorLink, link]),
        cache: new InMemoryCache({
            typePolicies,
        }),
        credentials: 'include',
        defaultOptions: {
            watchQuery: {
                fetchPolicy: 'cache-first',
                nextFetchPolicy: 'cache-first',
            },
        },
    })
}
export const App = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const placeManager = PlaceManager.getInstance()
    placeManager.setNavigate(navigate)

    useEffect(() => {
        if (Global.RELOAD_PAGE_ON_NAV) {
            globalThis.location.reload()
        }
    }, [location])

    useEffect(() => setupCoverage(navigate), [navigate])
    useEffect(() => {
        // Set up ReactModal to hide the main app from screen readers
        ReactModal.setAppElement('#ow-react-page')
        keepMixpanelUserInfoBooleansSynced(['Name Recording Exists', 'Transfer Portal'])
        updateTimezone()
        promptMobileBookmarkIfNecessary()
        trackAbandonedSearch()
    }, [])

    return (
        <>
            <ReactI18next.I18nextProvider i18n={i18n} defaultNS={'common'}>
                <OWContextProvider>
                    <Outlet />
                </OWContextProvider>
            </ReactI18next.I18nextProvider>
        </>
    )
}
