export const GROUPS_PAGE_TABS = {
    myDLs: 'm',
    customDLs: 'c',
    allDLs: 'a',
    createDL: 'e',
    myCommunities: 'o',
    allCommunities: 'u',
    createCommunity: 't',
    localCommunities: 'l',
}

export const LOCATION_PAGE_TABS = {
    overview: 'o',
    seating: 's',
    members: 'm',
    history: 'a',
    edit: 'e',
}

export const LOCATIONS_PAGE_TABS = {
    all: 'a',
    inactive: 'i',
    create: 'e',
}

export const ADMIN_COMPANY_PAGE_TABS = {
    prelaunch: 'p',
    branding: 'b',
    homepageText: 't',
    identity: 'i',
    access: 'a',
    serviceAccounts: 's',
    configHistory: 'h',
    globalHistory: 'g',
}

export const ADMIN_USERS_PAGE_TABS = {
    all: 'l',
    admins: 'a',
    guestPasses: 'g',
    externalEmails: 'e',
    import: 'i',
    export: 'x',
    inMemoriam: 'm',
    /* TODO: OW-8037
    history: 'h'
     */
}
export const LIST_PAGE_TABS = {
    members: 'm',
    where: 'w',
    history: 'a',
    edit: 'e',
    pendingRequests: 'j',
    import: 'i',
}
export const DEFAULT_LIST_PAGE_TAB = LIST_PAGE_TABS.members

export const COMMUNITY_PAGE_TABS = {
    members: 'm',
    posts: 'p',
    history: 'a',
    edit: 'e',
    overview: 'o',
    pendingRequests: 'j',
}
export const DEFAULT_COMMUNITY_PAGE_TAB = COMMUNITY_PAGE_TABS.overview

export const ADMIN_FEATURES_PAGE_TABS = {
    system: 's',
    profile: 'p',
    email: 'e',
    feed: 'f',
    newHires: 'n',
    roles: 'r',
    products: 'd',
    managerFeedback: 'm',
    /* TODO: OW-8038
    history: 'h'
     */
}
