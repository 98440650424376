import 'jquery-form'
import 'moment'
import 'moment-timezone'
import 'i18next'
import 'tether'
import 'bootstrap-toggle'
import 'bootstrap-paginator'
import 'daterangepicker'
import { Dropzone } from 'dropzone'
import 'jquery.scrollintoview'
import 'select2'

import 'add-to-homescreen'
import 'add-to-homescreen/dist/style/addtohomescreen.css'

import 'unorm'
import 'classnames'
import 'summernote/dist/summernote-lite.min'
import 'summernote/dist/summernote-lite.min.css'
import 'fabric'
import 'jquery-mousewheel'
import 'jquery-jcrop'
import 'react-datetime'
import 'autosize'
import 'linkifyjs'
import 'linkify-jquery'
import 'linkify-react'
import 'jquery-hotkeys'
import 'imagesloaded'
import 'bootstrap/dist/css/bootstrap.css'

import 'clipboard'
import 'react-switch'
import 'handlebars'
import hoistNonReactStatics from 'hoist-non-react-statics'

import { defaultModules } from '@pnotify/core'
import '@pnotify/core/dist/PNotify.css'
import * as PNotifyMobile from '@pnotify/mobile'
import '@pnotify/mobile/dist/PNotifyMobile.css'
import '~/vendor/atwho'
import 'react-datetime/css/react-datetime.css'

import Bloodhound from 'bloodhound-js'

defaultModules.set(PNotifyMobile, {})

declare global {
    interface Window {
        hoistNonReactStatics: typeof hoistNonReactStatics
        homeTypeahead: any
        $clamp: (element: HTMLElement, options: object) => object
        Bloodhound: Bloodhound
    }

    interface JQuery {
        scrollintoview(options?: any)

        bootstrapPaginator(
            param: Partial<{
                bootstrapMajorVersion: number
                containerClass: string
                onPageChanged: (e, oldPage, newPage) => void
                itemContainerClass: (type, page, current) => string
                totalPages: number
                shouldShowPage: (type) => boolean
                currentPage: number
                alignment: string
                itemTexts: (type, page) => any
            }>
        ): void
    }
}

globalThis.hoistNonReactStatics = hoistNonReactStatics

Dropzone.autoDiscover = false
