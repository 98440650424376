import React, { useMemo, useState } from 'react'
import { OWAuthDataModel } from '~/models/OWAuthDataModel'
import * as GroupUtils from '~/utils/GroupUtils'
import { hasFeature } from '~/utils/OWUtils'
import { Features } from '~/utils/Constants'
import { Company, Employee } from '~/app/types'
import { Global } from '~/global'

const CAN_ACCESS_TRANSFER_PORTAL = Global.CAN_ACCESS_TRANSFER_PORTAL
const PROFILE_CELEBRATIONS_ENABLED = Global.PROFILE_CELEBRATIONS_ENABLED
const IS_ACTIVE_ADMIN = Global.IS_ACTIVE_ADMIN
const HIDE_NAVBAR_SEARCH = Global.HIDE_NAVBAR_SEARCH
const HIDE_TOOLS = Global.HIDE_TOOLS
const TAGS_ENABLED = Global.TAGS_ENABLED
const IS_ADMIN = Global.IS_ADMIN
const IS_AUTHENTICATED = Global.IS_AUTHENTICATED
const IS_GUEST = Global.IS_GUEST
const IS_GHOST = Global.IS_GHOST
const ONLY_LOGOUT = Global.ONLY_LOGOUT
const CAN_CREATE_LOCAL_COMMUNITIES = Global.CAN_CREATE_LOCAL_COMMUNITIES
const CAN_ACCESS_SURVEY_TAB = Global.CAN_ACCESS_SURVEY_TAB

type OWContextType = {
    employee?: Employee | null
    company?: Company | null
    timelineModerationDatetime?: string
    setTimelineModerationDatetime: (date: string | undefined) => void
    isAuthenticated: boolean
    isGuest: boolean
    isGhost: boolean
    isActiveAdmin: boolean
    isAdmin: boolean
    hideSearch: boolean
    hideNav: boolean
    tagsEnabled: boolean
    groupsEnabled: boolean
    dlistsEnabled: boolean
    onlyLogout: boolean
    communitiesEnabled: boolean
    configurationModeEnabled: boolean
    dlistsCardEnabled: boolean
    hideProfileStrength: boolean
    teamsDemo: boolean
    transferPortalEnabled: boolean
    profileCelebrationsEnabled: boolean
    canAccessTransferPortal: boolean
    workAnywhereEnabled: boolean
    canCreateLocalCommunities: boolean
}
export const OWContext = React.createContext<OWContextType>({
    setTimelineModerationDatetime: () => {
        return
    },
    isAuthenticated: false,
    isGuest: false,
    isGhost: false,
    isActiveAdmin: false,
    isAdmin: false,
    hideSearch: false,
    hideNav: false,
    tagsEnabled: false,
    groupsEnabled: false,
    dlistsEnabled: false,
    onlyLogout: false,
    communitiesEnabled: false,
    configurationModeEnabled: false,
    dlistsCardEnabled: false,
    hideProfileStrength: false,
    teamsDemo: false,
    transferPortalEnabled: false,
    profileCelebrationsEnabled: false,
    canAccessTransferPortal: false,
    workAnywhereEnabled: false,
    canCreateLocalCommunities: false,
})
export const OWContextProvider = ({ children }) => {
    const [timelineModerationDatetime, setTimelineModerationDatetime] = useState<string | undefined>(
        OWAuthDataModel.getAuthCompany()?.timeline_moderation_datetime ?? undefined
    )
    const context = useMemo(() => {
        return {
            employee: OWAuthDataModel.getAuthEmployee(),
            company: OWAuthDataModel.getAuthCompany(),
            timelineModerationDatetime,
            setTimelineModerationDatetime,
            isAuthenticated: IS_AUTHENTICATED,
            isGuest: IS_GUEST,
            isGhost: IS_GHOST,
            isActiveAdmin: IS_ACTIVE_ADMIN,
            isAdmin: IS_ADMIN,
            hideSearch: HIDE_NAVBAR_SEARCH,
            hideNav: HIDE_TOOLS,
            tagsEnabled: TAGS_ENABLED,
            groupsEnabled: GroupUtils.groupsEnabled(),
            dlistsEnabled: GroupUtils.dlistsEnabled(),
            onlyLogout: ONLY_LOGOUT,
            configurationModeEnabled: hasFeature(Features.PRELAUNCH_MODE),
            dlistsCardEnabled: hasFeature(Features.SHOW_DLISTS_PROFILE_CARD),
            hideProfileStrength: hasFeature(Features.HIDE_PROFILE_STRENGTH),
            teamsDemo: hasFeature(Features.SHOW_TEAMS_DEMO),
            communitiesEnabled: hasFeature(Features.COMMUNITIES_ENABLED),
            transferPortalEnabled: hasFeature(Features.TRANSFER_PORTAL_ENABLED),
            profileCelebrationsEnabled: PROFILE_CELEBRATIONS_ENABLED,
            canAccessTransferPortal: CAN_ACCESS_TRANSFER_PORTAL,
            workAnywhereEnabled: hasFeature(Features.WORK_ANYWHERE_ENABLED),
            canCreateLocalCommunities: CAN_CREATE_LOCAL_COMMUNITIES,
            canAccessSurveyTab: CAN_ACCESS_SURVEY_TAB,
        }
    }, [timelineModerationDatetime])

    return <OWContext.Provider value={context}>{children}</OWContext.Provider>
}

export const useOWContext = () => React.useContext<OWContextType>(OWContext)
