import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import * as Constants from '~/utils/Constants'
import { getAPIBaseURL, getQueryString } from '~/utils/urlUtils'

import { OWAuthDataModel } from '~/models/OWAuthDataModel'
import { EmployeesModel } from '~/models/EmployeesModel'

import { addToFormData } from './FormUtils'
import { escapeForUseInRegex } from './stringUtils'
import { getAPIBaseURLForVersion } from './urlUtils'

import {
    Address,
    AuditTrailEntry,
    AuthPhoto,
    AuthSearchResponse,
    AuthStatus,
    CityLatLongResponse,
    Company,
    CompanyProduct,
    CompanyRole,
    ConferenceRoom,
    CustomSectionData,
    DistributionList,
    DListMemberImportPreview,
    DListMemberImportProgress,
    DListPageData,
    Domain,
    Dossier,
    EditAboutPageData,
    EditResponsibilitiesPageData,
    Employee,
    EmployeeDLists,
    EmployeeLocationOption,
    EmployeePageBody,
    EmployeePageData,
    EmployeePageInfo,
    EmployeeProfileAddResponse,
    ErrorResponse,
    ExportResult,
    FieldPermission,
    FloorPlanCoordinate,
    FloorPlanThumbnail,
    FloorPlanUpdate,
    Group,
    GroupJoinRequest,
    GroupMember,
    GroupMemberCandidate,
    GuestPass,
    Id,
    ImportProgress,
    InMemoriam,
    InMemoriamPageData,
    LocationAddressCheckResponse,
    LocationMergeCandidatesResponse,
    LocationPageData,
    LocationType,
    Maybe,
    NotificationCounts,
    OnboardingResponsibilitiesSection,
    QueryResults,
    ServiceAccount,
    ServiceAccountToken,
    SetTimelineEntryReactionResponse,
    Skill,
    TimelineEntry,
    TimelineEntryComment,
    TimelineEntryViewResponse,
    UploadFloorPlansResponse,
} from '~/app/types'
import { getEmployeeIdsToResolve } from '~/react/utils/TimelineUtils'
import {
    AggregateData,
    EmailsForSearchResponse,
    FilterData,
    LocalCommunity,
    SearchResponse,
} from '~/react/search/SearchTypes'
import { traverse } from '~/utils/traverse'
import Cookies from 'js-cookie'
import { Moment } from 'moment'
import { Global } from '~/global'

export type CreateDListParams = {
    name: string
    email: string
    description?: string
    is_private?: boolean
    invite_only: boolean
    owner_public_id?: string
    who_can_post?: string
}

type UpdateOfficeLocationParams = {
    slug?: string
    name?: string
    displayAddress?: string
    addresses?: Address[]
    is_active?: boolean
    photo?: any
    photoCoords?: string
    photoSize?: string
    dlistSlug?: string
}

type CreateOfficeLocationParams = {
    slug?: string
    name?: string
    displayAddress?: string
    addresses: Address[]
    is_active?: boolean
    photo?: any
    photoCoords?: string
    photoSize?: string
    dlistSlug?: string
}

export const asId = (value: string | number): Id => (typeof value === 'number' ? value : Number.parseInt(value))

export interface AbortablePromise<T> extends Promise<T> {
    abort(): void
}

function makeAP<R, T = R>(req: JQuery.jqXHR<R>, deferred?) {
    const prom = new Promise((resolve, reject) => (deferred ?? req).then(resolve).fail(reject))
    prom['abort'] = () => req.abort()
    return prom as AbortablePromise<T>
}

function normalize<T>(obj): T {
    traverse(obj, (key, value, object) => {
        if (key.toLowerCase().endsWith('id')) {
            object[key] = `${value}`
        }
    })
    return obj
}

type FetchOptions<T> = {
    type?: string
    version?: string
    query?: any
    noApi?: boolean
    skip?: boolean
    contentType?: string
    variables?: Record<string, unknown>
    onComplete?: (data: T) => void
    formData?: FormData
    cacheEnabled?: boolean
    authToken?: string
}
export const useFetch = <T>(url: string, options?: FetchOptions<T>) => {
    const [generation, setGeneration] = useState(0)
    const [error, setError] = useState<ErrorResponse>()
    const resultCache = useRef<Record<string, T | null>>({})
    const {
        onComplete,
        skip = false,
        type = 'GET',
        version = 'v2',
        noApi = false,
        query = {},
        variables,
        formData,
        contentType,
        cacheEnabled = false,
        // allow passing an auth token to set in the headers to accommodate webviews that require auth credentials
        authToken = undefined,
    } = options ?? {}
    const fullUrl = `${noApi ? '' : getAPIBaseURLForVersion(version)}/${url}/${getQueryString(query)}`
    const method = type
    const controller = useRef<AbortController>()
    const body = formData ?? (variables ? JSON.stringify(variables) : undefined)
    const queryKey = JSON.stringify({ fullUrl, variables })
    const shouldLoad = Boolean(fullUrl && !skip)
    const inCache = cacheEnabled && queryKey in resultCache.current
    const [data, setData] = useState(cacheEnabled ? resultCache.current[queryKey] : null)
    const [loading, setLoading] = useState(Boolean(shouldLoad && !inCache))

    const flush = useCallback(
        (options: FetchOptions<T>) => {
            const { query = {}, variables = {} } = options ?? {}
            const fullUrl = `${noApi ? '' : getAPIBaseURLForVersion(version)}/${url}/${getQueryString(query)}`
            const queryKey = JSON.stringify({ fullUrl, variables })
            resultCache.current[queryKey] = null
        },
        [url, version, noApi]
    )
    const reload = useCallback(() => {
        if (controller.current) {
            controller.current.abort()
        }
        controller.current = new AbortController()
        ServerApi.requestCount += 1
        setLoading(true)
        const headers = {
            'Content-Type':
                contentType ??
                (formData ? 'application/x-www-form-urlencoded; charset=utf-8' : 'application/json; charset=utf-8'),
            'X-CSRFToken': Cookies.get('csrftoken') ?? '',
            ...(Boolean(authToken) && { Authorization: authToken }),
        }
        void fetch(fullUrl, { method, body, signal: controller.current.signal, headers })
            .then(response => {
                const serverVersion = response.headers.get('app_version')
                if (serverVersion !== Global.APP_VERSION) {
                    Global.RELOAD_PAGE_ON_NAV = true
                } else if (response.status === 403) {
                    Global.RELOAD_PAGE_ON_NAV = true
                } else if (response.status === 404) {
                    return [response.status, undefined]
                }
                return response.json().then(data => {
                    return [response.status, data]
                })
            })
            .then(([status, data]) => {
                if (status > 299) {
                    if (cacheEnabled) resultCache.current[queryKey] = null
                    setData(null)
                    setError(data)
                } else {
                    if (cacheEnabled) resultCache.current[queryKey] = data
                    setData(data)
                    setError(undefined)
                    onComplete?.(data)
                }
            })
            .finally(() => {
                controller.current = undefined
                ServerApi.requestCount += -1
                setLoading(false)
                // Force an update
                setGeneration(g => g + 1)
            })
    }, [queryKey, fullUrl, setGeneration, method, onComplete, body, formData, contentType, cacheEnabled, authToken])

    useEffect(() => {
        if (!shouldLoad) return
        if (cacheEnabled && queryKey in resultCache.current) {
            setData(resultCache.current[queryKey])
            return
        }
        reload()
        return () => {
            if (controller.current) {
                controller.current.abort()
                controller.current = undefined
            }
        }
    }, [cacheEnabled, shouldLoad, queryKey, reload, setData])

    return { loading, data, reload, generation, error, flush }
}

type EmployeeExportDownloadResponse = { url: string }
export const useEmployeeExportDownload = (activeOnly, fields, options?: { skip?: boolean }) => {
    return useFetch<EmployeeExportDownloadResponse>('export_employees', {
        type: 'POST',
        noApi: true,
        variables: { 'active-only': activeOnly, fields },
        ...options,
    })
}

type GetEmployeePageHeaderResponse = { employee: Employee; more: EmployeePageInfo }
export const useGetEmployeePageHeader = (publicId, fis, options?: { skip: boolean }) => {
    return useFetch<GetEmployeePageHeaderResponse>(`page/employee_profile_header/${publicId}`, {
        query: { ...(fis && { fis: 1 }) },
        ...options,
    })
}

export const useGetSearchResults = (
    query: string,
    filterData: FilterData,
    pageSize: number,
    page: number,
    options?: { skip: boolean }
) => {
    const onComplete = useCallback(r => addEmployeesToCache({ results: r.employees.results.map(e => e.employee) }), [])
    return useFetch<SearchResponse>('search', {
        query: {
            requester: 'ow_search',
            query,
            page_size: pageSize,
            page: +page,
        },
        variables: filterData,
        type: filterData ? 'POST' : 'GET',
        contentType: 'application/json; charset=utf-8',
        onComplete: onComplete,
        ...options,
    })
}

const addEmployeesToCache = ({ results }: { results: Employee[] }) =>
    results.forEach(employee => EmployeesModel.insertEmployee(employee))

type GetEmployeesAdminResponse = { results: Employee[]; count: number }
export const useGetEmployeesAdmin = (
    pageSize: number,
    page: number,
    search: string,
    ordering: string[],
    filterAdmins?: boolean,
    filterActive?: boolean,
    filterExternal?: boolean,
    options?: { skip?: boolean }
) => {
    return useFetch<GetEmployeesAdminResponse>('employees_admin', {
        query: {
            search,
            page_size: pageSize,
            page,
            ordering,
            ...(filterAdmins !== undefined && {
                admins: filterAdmins ? 1 : 0,
            }),
            ...(filterActive !== undefined && {
                active: filterActive ? 1 : 0,
            }),
            ...(filterExternal !== undefined && {
                external: filterExternal ? 1 : 0,
            }),
        },
        onComplete: addEmployeesToCache,
        ...options,
    })
}

export const useGetLocations = (
    sort: string[],
    page_size: number,
    page: number,
    query: string,
    inactive?: boolean,
    options?: { skip: boolean }
) => {
    const args = {
        page_size,
        page,
        q: query,
        ordering: (sort || []).join(','),
    } as Record<string, unknown>
    if (inactive !== undefined) {
        args.is_active = inactive ? '0' : '1'
    }
    return useFetch<QueryResults<LocationType>>('locations', {
        ...options,
        query: args,
    })
}

export const useGetLocationFloorPlans = (slug: string, options?: { skip: boolean }) =>
    useFetch<QueryResults<FloorPlanThumbnail>>(`locations/${slug}/floor_plans`, options)

export const useGetLocationPageData = (slug: string, fis: boolean, options?: { skip: boolean }) =>
    useFetch<LocationPageData>(`page/location_overview/${slug}`, {
        ...options,
        query: { ...(fis && { fis: '1' }) },
    })

const handleGetEmployeePageBodyResponse = ({
    org_chart: { manager, direct_reports, dotted_line_managers, dotted_line_reports },
}: EmployeePageBody) => {
    if (manager) {
        EmployeesModel.insertEmployee(manager)
    }
    if (direct_reports) {
        direct_reports.forEach(e => EmployeesModel.insertEmployee(e))
    }
    if (dotted_line_managers) {
        dotted_line_managers.forEach(e => EmployeesModel.insertEmployee(e))
    }
    if (dotted_line_reports) {
        dotted_line_reports.forEach(e => EmployeesModel.insertEmployee(e))
    }
}

export const useGetLocationFloorPlanCoordinates = (locationSlug, floorPlanSlug, options?: { skip: boolean }) => {
    return useFetch<(FloorPlanCoordinate | Record<string, never>)[]>(
        `locations/${locationSlug}/floor_plans/${floorPlanSlug}/coordinates`,
        options
    )
}

export const useGetEmployeePageBody = (publicId, options?: { skip: boolean }) => {
    return useFetch<EmployeePageBody>(`page/employee_profile_body/${publicId}`, {
        onComplete: handleGetEmployeePageBodyResponse,
        ...options,
    })
}

type GetInMemoriamResponse = { results: InMemoriam[]; count: number }
export const useGetInMemoriams = (query, sort, pageSize, page, options?: { skip: boolean }) => {
    const ordering = useMemo(() => (sort ?? []).join(','), [sort])

    return useFetch<GetInMemoriamResponse>('in_memoriam', {
        query: { search: query, page_size: pageSize, page, ordering },
        ...options,
    })
}

type GetGuestPassesResponse = { results: GuestPass[]; count: number }
export const useGetGuestPasses = (search, sort, pageSize, page, options?: { skip: boolean }) => {
    const ordering = useMemo(() => (sort ?? []).join(','), [sort])
    return useFetch<GetGuestPassesResponse>('guest_passes', {
        query: { search: search, page_size: pageSize, page, ordering },
        ...options,
    })
}

type GetEmployeeLocalCommunitiesResponse = {
    name: string
    slug_name: string
    description: string
    sub_type: string
}[]
export const useGetEmployeeLocalCommunities = (
    employee_lookup_id: string,
    exclude_owned: boolean,
    options?: { skip: boolean }
) => {
    return useFetch<GetEmployeeLocalCommunitiesResponse>(`employees/${employee_lookup_id}/local_communities`, {
        query: {
            exclude_owned: exclude_owned ? '1' : undefined,
        },
        ...options,
    })
}

export const useGetAllLocalCommunities = (options?: { skip: boolean }) => {
    return useFetch<Record<string, LocalCommunity>>('page/communities/local/', options)
}

const updateAuthCompany = company => OWAuthDataModel.getInstance().updateAuthCompany(company)

export const useGetCompany = (guid: string, options?: FetchOptions<Company>) => {
    const onComplete = options?.onComplete
    const update = useCallback(
        company => {
            updateAuthCompany(company)
            onComplete?.(company)
        },
        [onComplete]
    )

    return useFetch<Company>(`company/${guid}`, {
        ...options,
        onComplete: update,
    })
}

type FieldPermissionResponse = { results: FieldPermission[] }
export const useGetFieldPermissions = () => {
    return useFetch<FieldPermissionResponse>('field_permissions')
}

type JoinRequestsResponse = {
    count: number
    next: string | null
    pending_request_count: number
    previous: string | null
    results: GroupJoinRequest[]
}
export const useGetJoinRequests = (slugName: string, pageSize?: number, page?: number, options?: { skip: boolean }) =>
    useFetch<JoinRequestsResponse>(`groups/${slugName}/join_requests`, {
        ...options,
        query: { page_size: pageSize, page },
    })

type JoinRequestResponse = GroupJoinRequest
export const useGetJoinRequest = (slugName: string, publicId: string, options?: { skip: boolean }) =>
    useFetch<JoinRequestResponse>(`groups/${slugName}/join_requests/${publicId}`, options)

type HomePageDataResponse = { root: Employee; reports: Employee[] }
export const useGetHomePageData = (options?: { skip: boolean }) => {
    const onComplete = useCallback(({ root, reports }: HomePageDataResponse) => {
        if (root) {
            EmployeesModel.insertEmployee(root)
        }
        if (reports) {
            reports.forEach(e => EmployeesModel.insertEmployee(e))
        }
    }, [])
    return useFetch<HomePageDataResponse>(`page/homepage`, {
        ...options,
        onComplete,
    })
}

export type DupList = {
    id: Id
    name: string
    dups: number
    lists: string[]
}
export type DupMember = {
    id: Id
    name: string
    dups: number
}
export type GroupMemberDupsResponse = {
    dupMemberCount: number
    dupListCount: number
    lists: DupList[]
    members: DupMember[]
}
export const useGetGroupMemberDups = (slugName: string, subgroupId?: Id, options?: { skip: boolean }) => {
    const query = subgroupId ? { subgroup_id: subgroupId } : {}

    return useFetch<GroupMemberDupsResponse>(`groups/${slugName}/dups`, {
        ...options,
        query,
    })
}

type GroupMembersResponse = {
    count: number
    next: string
    previous: string
    results: GroupMember[]
}
export const useGetGroupMembers = (
    slug_name: string,
    page_size: number,
    page: number,
    flat?: boolean,
    isInactive?: boolean,
    isContractor?: boolean,
    isExternal?: boolean,
    query?: string,
    options?: { skip?: boolean; authToken?: string }
) => {
    return useFetch<GroupMembersResponse>(`groups/${slug_name}/members_list`, {
        ...options,
        query: {
            page_size,
            page,
            flat: flat ? 1 : 0,
            is_inactive: isInactive ? 1 : 0,
            is_contractor: isContractor ? 1 : 0,
            is_external: isExternal ? 1 : 0,
            search: query,
        },
    })
}
/**
 * Gets an audit trail
 */
type AuditTrailResponse = {
    count: number
    results: AuditTrailEntry[]
}
export const useGetAuditTrail = (
    {
        page_size = 0, // The page size to fetch
        page,
        audit_employee,
        show_sick,
        audit_timeoff,
        audit_group,
        audit_location,
        audit_company,
        audit_in_memoriam,
        start_date,
        end_date,
        filter_title_manage_rp,
    }: {
        page: number // The page to fetch
        page_size: number //
        audit_employee?: string | Id // The employee public_id to fetch the audit trail for
        show_sick?: boolean
        audit_timeoff?: Id // The employee to fetch timeoff entries
        audit_group?: Id // The group_id to fetch the audit trail for
        audit_location?: Id // The location_id to fetch the audit trail for
        audit_company?: boolean // The company_id to filter the audit trail to
        audit_in_memoriam?: Id // The in_memoriam_id to filter the audit trail to
        start_date?: string // The formatted start date to filter the audit trail to
        end_date?: string // The formatted end date to filter the audit trail to
        filter_title_manage_rp?: boolean
    },
    options?: { skip: boolean }
) => {
    return useFetch<AuditTrailResponse>('audit', {
        ...options,
        query: {
            page_size,
            page,
            audit_employee,
            show_sick,
            audit_timeoff,
            audit_group,
            audit_locations: audit_location,
            audit_company,
            audit_in_memoriam,
            start_date,
            end_date,
            filter_title_manage_rp,
        },
    })
}

type OnboardingDataResponse = {
    responsibilities: CustomSectionData
    about_me: CustomSectionData
    used_ios: boolean
    used_android: boolean
}

export const useGetOnboardingData = (options?: { skip: boolean }) =>
    useFetch<OnboardingDataResponse>('page/onboarding', options)

export const useGetOnboardingResponbilities = (options?: { skip: boolean }) =>
    useFetch<OnboardingResponsibilitiesSection[]>('page/onboarding_responsibilities', options)

export const useGetOnboardingAboutMe = (options?: { skip: boolean }) =>
    useFetch<OnboardingResponsibilitiesSection[]>('page/onboarding_about_me', options)

export const useGetOnboardingPhotos = (options?: { skip: boolean }) =>
    useFetch<string[]>('page/onboarding_photos', options)

export const useGetEmployee = (public_id: string, options?: { skip: boolean }) =>
    useFetch<Employee>(`employees/${public_id}`, {
        onComplete: useCallback(employee => EmployeesModel.insertEmployee(employee), []),
        ...options,
    })

export const useGetEmployeePronunciationUrl = (employeeId: Id, options?: { skip: boolean }) =>
    useFetch<string>(`employees/${employeeId}/pronunciation`, {
        version: 'v3',
        ...options,
    })

export const useGetLocationMergeCandidates = (locationSlug: string, options?: { skip: boolean }) =>
    useFetch<LocationMergeCandidatesResponse>(`locations/${locationSlug}/merge_candidates`, options)

/**
 * Gets the page data for an in-memoriam page
 */
export const useGetInMemoriamPageData = (publicId: string, options?: { skip: boolean }) =>
    useFetch<InMemoriamPageData>(`page/in_memoriam/${publicId}`, options)
/**
 * Gets the data for an in-memoriam before it is published as a page
 */
export const useGetInMemoriam = (publicId: string, options?: { skip: boolean }) => {
    return useFetch<InMemoriam>(`in_memoriam/${publicId}`, options)
}

/**
 * Gets the list of dlists
 */
export type DListsArgs = {
    sort?: string[]
    subType?: string
    pageSize?: number
    page?: number
    query?: string
    readOnly?: boolean
    officialDlist?: boolean
    slug_name?: string
    public_id?: string
}
export const useGetDlists = (
    { sort, subType, pageSize, page, query, readOnly, officialDlist, slug_name, public_id }: DListsArgs,
    options?: { skip?: boolean; onComplete?: (data: QueryResults<DistributionList>) => void }
) =>
    useFetch<QueryResults<DistributionList>>('groups', {
        query: {
            ordering: (sort || []).join(','),
            type: Constants.GroupTypes.DLIST_TYPE,
            sub_type: subType,
            page_size: pageSize,
            page,
            search: query,
            read_only: readOnly === undefined ? undefined : readOnly ? '1' : '0',
            official_dlist: officialDlist === undefined ? undefined : officialDlist ? '1' : '0',
            member_group_slug: slug_name,
            member_public_id: public_id,
        },
        ...options,
    })

export const useGetGroupPage = (slug: string, options?: { skip?: boolean; authToken?: string }) =>
    useFetch<DListPageData>(`page/group_overview/${slug}`, options)

enum AdminReportType {
    engagement = 'Engagement',
    missingManager = 'MissingManager',
    adoption = 'Adoption',
}

type AdminReportResponse<T> = {
    report_type: AdminReportType
    report: T[]
    date?: string
    type?: string
}

type EngagementReport = {
    date: string
    date_human: string
    post_count: number
    post_view_count: number
    reaction_count: number
    comment_count: number
    tag_count: number
    social_index: number
}

export const getFormattedDate = (d: Date) => {
    return new Date(d.getTime() - d.getTimezoneOffset() * 60000).toISOString().split('T')[0]
}

const pad = value => (value < 10 ? `0${value}` : value)

const getUTCOffset = () => {
    // copied from old jquery, convert timezone into the format the server expects
    const sign = new Date().getTimezoneOffset() > 0 ? '-' : '+'
    const offset = Math.abs(new Date().getTimezoneOffset())
    const hours = pad(Math.floor(offset / 60))
    const minutes = pad(offset % 60)
    return sign + hours + minutes
}

export const useGetEngagementReport = (startDate: Date, endDate: Date, options?: { skip?: boolean }) =>
    useFetch<AdminReportResponse<EngagementReport>>('engagement_report', {
        ...options,
        noApi: true,
        query: {
            report_type: AdminReportType.engagement,
            start_date: getFormattedDate(startDate),
            end_date: getFormattedDate(endDate),
            show_weekends: true,
            timezone_offset: getUTCOffset(),
        },
    })

type MissingManagerReport = {
    name: string
    public_id: string
    email: string
    title: string
}

export const useGetMissingManagers = (options?: { skip: boolean }) =>
    useFetch<AdminReportResponse<MissingManagerReport>>('employee_report', {
        ...options,
        noApi: true,
        query: {
            report_type: AdminReportType.missingManager,
        },
    })

type AdoptionReport = {
    full_name: string
    title: string
    level: number
    people: number
    usage: string
    social: string
}

export const useGetAdoptionReport = (date: Date, type: 'Daily' | 'Weekly', options?: { skip: boolean }) =>
    useFetch<AdminReportResponse<AdoptionReport>>('employee_report', {
        ...options,
        noApi: true,
        query: {
            report_type: AdminReportType.adoption,
            date: getFormattedDate(date),
            type,
        },
    })

export const useGetServiceAccounts = (
    pageSize: number,
    page: Maybe<number> | undefined,
    sort: string[],
    options?: { skip: boolean }
) => {
    const ordering = (sort || []).join(',')
    return useFetch<QueryResults<ServiceAccount>>(`service_accounts`, {
        ...options,
        query: { page_size: pageSize, page, ordering },
    })
}

export const useGetServiceAccountTokens = (
    accountId: string,
    pageSize: number,
    page: Maybe<number> | undefined,
    options?: { skip: boolean }
) => {
    return useFetch<QueryResults<ServiceAccountToken>>(`service_accounts/${accountId}/tokens`, {
        ...options,
        query: { page, page_size: pageSize, ordering: 'created_datetime' },
    })
}

export const API_ERROR_CODES = {
    INDIRECT_MEMBER_FORCE_REQUIRED: 'is_already_indirect_group_member',
    MEMBER_CYCLE: 'cant_add_group_to_itself',
    INVALID_EMAIL: 'email_not_recognized_by_provider',

    LOCATION: 100,
    LOCATION_ADDRESS: 101,

    SKILL: 200,

    VALIDATION_ERROR: 'validation_error',
    INVALID_EMAIL_DOMAIN: 'invalid_email_domain',
    INVALID_IMG_FORMAT: 'invalid_img_format',
    NESTED_GROUP_REQUIRES_FORCE_FLAG: 'nested_group_requires_force_flag',
    REMOVE_LAST_OWNER: 'cant_remove_last_group_owner',
    PROVIDER_RAW_ERROR: 'provider_raw_error',
    PROVIDER_O365_GROUP_RAW_ERROR: 'provider_o365_group_raw_error',
}

type GetNotificationsResponse = {
    results: any[]
    next?: string | null
    unseen_count: number
    unread_count: number
    action_required_count: number
}

export const useGetDomains = (options?: { skip: boolean }) => {
    return useFetch<QueryResults<Domain>>(`domains`, {
        ...options,
    })
}

/**
 * Gets timeline feed entries
 */
export const useGetFeedTimelineEntries = (
    entryTypes: string[],
    pageSize: number,
    page: number,
    tleId?: number,
    groupSlug?: string,
    publicId?: string,
    isPosts?: boolean
) => {
    let extraArgs = {}
    if (!groupSlug && !publicId && page == 1 && !tleId && isPosts) {
        extraArgs = { timelimit: 1 }
    }
    const resource = groupSlug
        ? `groups/${groupSlug}/timeline`
        : publicId
          ? `employees/${publicId}/timeline`
          : 'tle_feed'
    const query =
        groupSlug || publicId
            ? { page_size: pageSize, page }
            : {
                  page_size: pageSize,
                  page,
                  scope: 'feed',
                  entry_id: tleId,
                  entry_types: entryTypes.join(','),
                  ...extraArgs,
              }

    const {
        data,
        loading: loadingTles,
        reload,
        generation,
    } = useFetch<QueryResults<TimelineEntry>>(resource, { query })
    const idsToFetch = useMemo(() => {
        if (data) {
            const employeeIds = new Set<Id>()
            data.results?.forEach(entry => getEmployeeIdsToResolve(entry).forEach(e => employeeIds.add(e)))
            return Array.from(employeeIds).filter(id => !EmployeesModel.getEmployee(id))
        }
        return []
    }, [data])
    const { data: dossier, loading: loadingDossier } = useFetch<Dossier>('dossier', {
        type: 'POST',
        variables: {
            employee_ids: idsToFetch.map(Number),
        },
        skip: idsToFetch.length === 0,
    })
    const filteredData = useMemo(() => {
        // If we need to fetch employee data, don't return anything until it comes back
        // Otherwise, an empty results list can be returned from the filter statement below
        // which causes the timeline to clear before the next page displays
        if (idsToFetch.length > 0 && !dossier?.employee_ids?.includes(idsToFetch.at(0) ?? 0)) {
            return undefined
        }

        if (dossier) {
            const { employee_ids, employee } = dossier
            if (employee) {
                employee_ids?.map(id => EmployeesModel.insertEmployee(employee[String(id)]))
            }
        }

        if (data && idsToFetch.length > 0) {
            return {
                ...data,
                // filter out entries with unresolvable ids
                results: data.results.filter(
                    entry =>
                        Array.from(getEmployeeIdsToResolve(entry)).filter(id => !EmployeesModel.getEmployee(id))
                            .length === 0
                ),
            }
        }
        return data
    }, [data, dossier, idsToFetch])
    return { loading: loadingDossier || loadingTles, data: filteredData, reload, generation }
}

export const useGetRoles = (options?: { skip?: boolean }) => {
    return useFetch<[CompanyRole]>('roles', {
        ...options,
    })
}

export type DListMemberImportError = {
    error_url: string
    invalid: Record<number, string>
    cycles: Record<number, string>
    self: boolean
    total_errors: number
    detail?: string
}

export type DListMemberImportStats = {
    tab_name: string
    column_name: string
    list_adds: number
    list_removes: number
    employee_adds: number
    employee_removes: number
    contractor_adds: number
    contractor_removes: number
    external_address_adds: number
    external_address_removes: number
    internal_address_adds: number
    internal_address_removes: number
    inactive_adds: number
    inactive_removes: number
    total_adds: number
    total_removes: number
    total_rows: number
}

export type DListMemberImportTask = {
    import_id: Id
}

export type DListMemberImportResponse = DListMemberImportError | DListMemberImportStats | DListMemberImportTask

export const ServerApi = {
    requestCount: 0,

    getSubdomain() {
        const domain = location.hostname
        const prefix = String.raw`(.*)\.`
        const regex = new RegExp(`^${prefix}${escapeForUseInRegex(Global.EXTERNAL_NAME)}$`)
        const matches = regex.exec(domain)
        if (matches && matches.length === 2) {
            const subdomain = matches[1]
            return subdomain === 'www' ? undefined : subdomain
        }
        return undefined
    },

    _apiQuery<T>(
        resource: string,
        options?: {
            type?: string
            version?: string
            query?: any
            noApi?: boolean
        } & JQuery.AjaxSettings
    ): JQuery.jqXHR<T> {
        options = options || {}
        options.type = options.type || 'GET'
        options.version = options.version || 'v2'
        ServerApi.requestCount += 1
        return $.ajax(
            `${options.noApi ? '' : getAPIBaseURLForVersion(options.version)}/${resource}/${getQueryString(
                options.query || {}
            )}`,
            options
        ).always((result, outcome, xhr) => {
            ServerApi.requestCount += -1
            if (outcome === 'error') {
                xhr = result as JQuery.jqXHR
            }
            if (outcome !== 'abort' && typeof xhr !== 'string') {
                const serverVersion = xhr.getResponseHeader('app_version')
                if (serverVersion !== Global.APP_VERSION) {
                    Global.RELOAD_PAGE_ON_NAV = true
                } else if (xhr.status === 403) {
                    Global.RELOAD_PAGE_ON_NAV = true
                }
            }
            return result
        })
    },

    getEmployeeDossier(ids: Id[]) {
        const r = ServerApi._apiQuery<Dossier>('dossier', {
            data: JSON.stringify({
                employee_ids: ids.map(i => +i), // Convert to int for request
            }),
            version: 'v3',
            method: 'POST',
            contentType: 'application/json; charset=utf-8',
        })
        const prom = r.then(({ employee_ids, employee }) => {
            if (employee) {
                employee_ids?.map(id => EmployeesModel.insertEmployee(employee['' + id]))
            }
            return employee_ids?.map(id => EmployeesModel.getEmployee(id))
        })
        return makeAP<Dossier, Employee[]>(r, prom)
    },

    getAddressDossier(ids: Id[]) {
        const r = ServerApi._apiQuery<Dossier>('dossier', {
            data: JSON.stringify({
                address_ids: ids.map(i => +i), // Convert to int for request
            }),
            version: 'v3',
            method: 'POST',
            contentType: 'application/json; charset=utf-8',
        })
        const prom = r.then(({ address }) => {
            return address
        })
        return makeAP<Dossier, Address[]>(r, prom)
    },

    /**
     * Get all notifications for the logged-in employee
     */
    getNotifications(pageSize: number, page: Maybe<number>): Promise<GetNotificationsResponse> {
        if (Global.IS_AUTHENTICATED) {
            const req = ServerApi._apiQuery<GetNotificationsResponse>('notifications', {
                dataType: 'json',
                query: { page_size: pageSize, page },
            })
            return makeAP(req)
        } else {
            return Promise.resolve({
                results: [],
                unseen_count: 0,
                unread_count: 0,
                action_required_count: 0,
            })
        }
    },

    /**
     * Gets the notification counts
     */
    getNotificationCounts() {
        if (Global.IS_AUTHENTICATED) {
            const req = ServerApi._apiQuery<NotificationCounts>('notification_counts')
            return makeAP(req)
        } else {
            return Promise.resolve<NotificationCounts>({
                unread_count: 0,
                unseen_count: 0,
                action_required_count: 0,
            })
        }
    },

    /**
     * Clear notifications for the logged-in employee
     */
    clearNotifications(ids: string) {
        return makeAP(
            ServerApi._apiQuery<NotificationCounts>('clear_notifications', {
                type: 'POST',
                data: { notification_ids: ids },
            })
        )
    },

    /**
     * Clears all notifications for the logged-in employee
     */
    clearAllNotifications() {
        return makeAP(ServerApi._apiQuery<NotificationCounts>('clear_all_notifications', { type: 'POST' }))
    },

    /**
     * Mark all notifications as seen
     */
    seeNotifications() {
        return makeAP(ServerApi._apiQuery<void>('see_notifications', { type: 'POST' }))
    },

    /**
     * Gets an okta login url for an email
     */
    loginOkta(email: string) {
        return makeAP(
            ServerApi._apiQuery<AuthSearchResponse>('okta_search', {
                query: { the_email: email },
            })
        )
    },

    /**
     * Gets a pingfed login url for an email
     */
    loginPingfed(email: string) {
        return makeAP(
            ServerApi._apiQuery<AuthSearchResponse>('pingfed_search', {
                query: { the_email: email },
            })
        )
    },

    /**
     * Requests a recovery email listing the domains a user has access to be sent.
     */
    sendRecoveryEmail(email: string) {
        return makeAP(
            ServerApi._apiQuery<void>('domain_memberships_reminder', {
                method: 'POST',
                data: { email },
            })
        )
    },

    /**
     * Gets the url for authing with linked_in
     */
    urlForLinkedInAuth() {
        return '/linkedin/auth_code/'
    },

    /**
     * Gets the url for authing with O365
     */
    urlForO365Auth() {
        return '/o365/auth_code/'
    },

    /**
     * Gets the url for authing with Google
     */
    urlForGoogleAuth() {
        return '/google/auth_code/'
    },

    /**
     * Gets the profile image from an external oauthed source
     */
    getOAuthedPhoto(provider: 'linkedin' | 'google' | 'o365') {
        return makeAP(
            ServerApi._apiQuery<AuthPhoto>(`auth/${provider}/oauth_photo`, {
                noApi: true,
            })
        )
    },

    /**
     * Marks an employee as having skipped the onboarding flow
     */
    skipOnboarding() {
        return makeAP(ServerApi._apiQuery<void>(`skip_onboarding`))
    },

    /**
     * Tracks the last onboarding step an employee has viewed
     */
    viewOnboarding(step: string) {
        return makeAP(ServerApi._apiQuery<void>(`view_onboarding`, { query: { step } }))
    },

    /**
     * Enables admin mode
     */
    enableAdminMode(publicId: string) {
        return makeAP(
            ServerApi._apiQuery<{ success: 'True' | 'False' }>(`enable_admin_mode/${publicId}`, {
                method: 'POST',
            })
        )
    },

    /**
     * Disabled admin mode
     */
    disableAdminMode(publicId: string) {
        return makeAP(
            ServerApi._apiQuery<{ success: 'True' | 'False' }>(`disable_admin_mode/${publicId}`, {
                method: 'POST',
            })
        )
    },

    /**
     * Gets the page data for the edit responsibilities page
     */
    getEditResponsibilitiesPageData(public_id: string) {
        return makeAP(ServerApi._apiQuery<EditResponsibilitiesPageData>(`page/responsibilities_edit/${public_id}`))
    },

    updateResponsibilitesDate(public_id: string) {
        return makeAP(
            ServerApi._apiQuery(`ping/responsibilities/${public_id}`, {
                method: 'POST',
            })
        )
    },

    /**
     * Gets the page data for the edit about me page
     */
    getEditAboutPageData(public_id: string) {
        return makeAP(ServerApi._apiQuery<EditAboutPageData>(`page/about_edit/${public_id}`))
    },

    /**
     * Gets an employee by public id
     */
    getEmployee(public_id: string) {
        const req = ServerApi._apiQuery<Employee>(`employees/${public_id}`)
        const prom = req.then(employee => {
            EmployeesModel.insertEmployee(employee)
            return employee
        })
        return makeAP(req, prom)
    },

    /**
     * Gets an employee by public id
     */
    getManagerGroups(employeeId: Id) {
        return makeAP(ServerApi._apiQuery<EmployeeDLists>(`employees/${employeeId}/dlists`))
    },

    deleteManagerGroup(employeeId: Id, dlist: string) {
        return makeAP(
            ServerApi._apiQuery<void>(`employees/${employeeId}/dlists/${dlist}`, {
                method: 'DELETE',
            })
        )
    },

    /**
     * Create employee dlists
     */
    createManagerGroups(employeeId: Id, dlists: string[]) {
        return makeAP(
            ServerApi._apiQuery<EmployeeDLists>(`employees/${employeeId}/dlists`, {
                data: JSON.stringify({
                    dlists,
                }),
                method: 'POST',
                contentType: 'application/json; charset=utf-8',
            })
        )
    },

    /**
     * Gets the page data for the employee add page
     */
    getEmployeeAddPage() {
        return makeAP(ServerApi._apiQuery<EmployeeProfileAddResponse>(`page/employee_edit`))
    },

    /**
     * Gets the page data for the employee edit page
     */
    getEmployeeEditPage(public_id: string) {
        const req = ServerApi._apiQuery<EmployeePageData>(`page/employee_edit/${public_id}`)
        const prom = req.then(({ employee }) => {
            EmployeesModel.insertEmployee(employee)
            return req
        })
        return makeAP<EmployeePageData>(req, prom)
    },

    /**
     * Updates an employee
     */
    updateEmployee(public_id: string, fields: object, photo_blob?: Blob, photo_input?: File) {
        const formData = new FormData()
        const data = { ...fields }
        addToFormData(formData, data, undefined)
        if (photo_blob) {
            formData.append('profile_photo', photo_blob, 'blob')
        } else if (photo_input) {
            formData.append('profile_photo', photo_input, photo_input.name)
        }
        const req = ServerApi._apiQuery<Employee>(`employees/${public_id}`, {
            method: 'PATCH',
            data: formData,
            processData: false,
            contentType: false,
            cache: false,
        })
        const prom = req.then(employee => {
            OWAuthDataModel.maybeUpdateAuthEmployee(employee)
            EmployeesModel.insertEmployee(employee)
            return employee
        })
        return makeAP(req, prom)
    },

    /**
     * Updates an employee's reports
     */
    updateEmployeeReports(publicId: string, directReports?: string[], dottedLineReports?: string[]) {
        return makeAP(
            ServerApi._apiQuery<Employee>(`employees/${publicId}`, {
                method: 'PATCH',
                data: JSON.stringify({
                    ...(directReports !== undefined && {
                        direct_reports: directReports || [],
                    }),
                    ...(dottedLineReports !== undefined && {
                        dotted_line_reports: dottedLineReports || [],
                    }),
                }),
                contentType: 'application/json; charset=utf-8',
            })
        )
    },

    /**
     * Updates an employee's managers
     */
    updateEmployeeManagers(publicId: string, managerPublicId?: string | null, dottedLineManagers?: string[]) {
        return makeAP(
            ServerApi._apiQuery<Employee>(`employees/${publicId}`, {
                method: 'PATCH',
                data: JSON.stringify({
                    ...(managerPublicId !== undefined && {
                        manager: managerPublicId,
                    }),
                    ...(dottedLineManagers !== undefined && {
                        dotted_line_managers: dottedLineManagers || [],
                    }),
                }),
                contentType: 'application/json; charset=utf-8',
            })
        )
    },

    /**
     * Updates an employee's skills
     */
    updateEmployeeSkills(publicId: string, skills: object[]) {
        return makeAP(
            ServerApi._apiQuery<Skill[]>(`employees/${publicId}/skills`, {
                method: 'POST',
                data: {
                    skills: JSON.stringify(skills),
                },
            })
        )
    },

    /**
     * Updates the active status of an employee's custom section datas
     */
    updateEmployeeCustomSectionStatuses(publicId: string, customSectionStatuses: object) {
        return makeAP(
            ServerApi._apiQuery<void>(`employees/${publicId}/custom_sections`, {
                method: 'PATCH',
                data: {
                    custom_section_statuses: JSON.stringify(customSectionStatuses),
                },
            })
        )
    },

    /**
     * Creates an employee
     */
    createEmployee(fields: object, photo_blob?: Maybe<Blob>, photo_input?: Maybe<File>) {
        const formData = new FormData()
        const data = { ...fields }
        addToFormData(formData, data, undefined)
        if (photo_blob) {
            formData.append('profile_photo', photo_blob, 'blob')
        } else if (photo_input) {
            formData.append('profile_photo', photo_input, photo_input.name)
        }
        const req = ServerApi._apiQuery<Employee>(`employees`, {
            method: 'POST',
            data: formData,
            processData: false,
            contentType: false,
            cache: false,
        })
        const prom = req.then(employee => {
            EmployeesModel.insertEmployee(employee)
            return employee
        })
        return makeAP(req, prom)
    },

    /**
     * Deletes an already inactive employee
     */
    deleteEmployee(public_id: string) {
        return makeAP(
            ServerApi._apiQuery<void>(`employees/${public_id}`, {
                method: 'DELETE',
            })
        )
    },

    /**
     * Updates an employee mobile phone, and sends a mobile donwload link by sms or email
     */
    onboardEmployeeMobile(mobile_phone?: string) {
        return makeAP(
            ServerApi._apiQuery<{ sms?: string }>(`mymobile`, {
                method: 'PATCH',
                data: { mobile_phone },
            })
        )
    },

    /**
     * Prefetches all the employees for a company
     */
    prefetchEmployees() {
        const req = ServerApi._apiQuery<Dossier>('client_cache', {
            query: { objTypeList: 'employee' },
        })
        const prom = req.then(({ employee_ids }) => {
            const maxlen = 500
            employee_ids = employee_ids ?? []
            let prom: Promise<any> = ServerApi.getEmployeeDossier(employee_ids.splice(0, maxlen))
            while (employee_ids.length > 0) {
                const eids = employee_ids.splice(0, maxlen)
                prom = prom.then(employees => ServerApi.getEmployeeDossier(eids).then(e => e.concat(employees)))
            }
            return prom
        })
        return makeAP(req, prom)
    },

    /**
     * Searches employees
     */
    searchEmployees(page_size: number, page: Maybe<number> | undefined, query: string) {
        const req = ServerApi._apiQuery<Employee[]>('search_employees', {
            query: { search: query, page_size, page },
        })
        const prom = req.then(results => {
            results.forEach(employee => EmployeesModel.insertEmployee(employee))
            return req
        })
        return makeAP(req, prom)
    },

    /**
     * Gets a paginated list of employees
     */
    getEmployees(
        pageSize?: number,
        page?: number,
        search?: string,
        ordering?: string[],
        filterAdmins?: boolean,
        filterActive?: boolean,
        filterExternal?: boolean
    ) {
        const flatOrdering = (ordering || []).join(',')
        const req = ServerApi._apiQuery<QueryResults<Employee>>('employees', {
            query: {
                search,
                page_size: pageSize,
                page,
                ordering: flatOrdering,
                ...(filterAdmins !== undefined && {
                    admins: filterAdmins ? 1 : 0,
                }),
                ...(filterActive !== undefined && {
                    active: filterActive ? 1 : 0,
                }),
                ...(filterExternal !== undefined && {
                    external: filterExternal ? 1 : 0,
                }),
            },
        })
        const prom = req.then(({ results }) => {
            results.forEach(employee => EmployeesModel.insertEmployee(employee))
            return req
        })
        return makeAP(req, prom)
    },

    /**
     * Gets the page data for a Group
     */
    getGroupPage(slugName: string, fis: boolean) {
        const req = ServerApi._apiQuery<DListPageData>(`page/group_overview/${slugName}`, {
            query: { ...(fis && { fis: '1' }) },
        })
        const prom = req.then(response => {
            response = normalize(response)
            response.group.top_members?.forEach(e => EmployeesModel.insertEmployee(e))
            return req
        })
        return makeAP<DListPageData>(req, prom)
    },

    /**
     * Creates a dlist
     */
    createDList({
        name,
        email,
        description,
        is_private,
        invite_only,
        owner_public_id,
        who_can_post,
    }: CreateDListParams) {
        const req = ServerApi._apiQuery<DistributionList>(`groups`, {
            method: 'POST',
            data: {
                name,
                email,
                description,
                is_private,
                invite_only,
                who_can_post,
                owners: owner_public_id,
                type: Constants.GroupTypes.DLIST_TYPE,
                sub_type: Constants.GroupSubTypes.CUSTOM_DLIST_TYPE,
            },
        })
        return makeAP(req)
    },

    /**
     * Creates a community
     */
    createCommunity({
        name,
        sub_type,
        description,
        invite_only,
        overview_enabled,
        members_only_social,
        hide_email_members,
        photo,
        photoCoords,
        photoSize,
        owner_public_id,
        radius,
        radiusUnits,
        fields,
    }: {
        name: string
        description: string
        invite_only: boolean
        overview_enabled: boolean
        photo?: File
        photoCoords: string
        photoSize: string
        members_only_social: boolean
        hide_email_members: boolean
        owner_public_id: string
        sub_type?: unknown
        radius?: unknown
        radiusUnits?: unknown
        fields?: unknown
    }) {
        const formData = new FormData()
        const data = {
            name,
            description,
            invite_only,
            overview_enabled,
            members_only_social,
            hide_email_members,
            owners: owner_public_id,
            type: Constants.GroupTypes.COMMUNITY_TYPE,
            sub_type,
            photo_coords: photoCoords,
            photo_preview_size: photoSize,
            radius,
            radius_units: radiusUnits,
        }
        Object.keys(data).forEach(k => {
            formData.append(k, data[k])
        })
        addToFormData(formData, fields, undefined)
        if (photo) {
            formData.append('photo', photo)
        }
        const req = ServerApi._apiQuery<Group>(`groups`, {
            method: 'POST',
            data: formData,
            processData: false,
            contentType: false,
            cache: false,
        })
        return makeAP(req)
    },

    /**
     * Updates a dlist
     */
    updateDList({
        name,
        email,
        description,
        is_private,
        invite_only,
        slug_name,
        who_can_post,
    }: {
        name: string
        email: string
        description: string
        is_private: boolean
        invite_only: boolean
        slug_name: string
        who_can_post: string
    }) {
        return makeAP(
            ServerApi._apiQuery<DistributionList>(`groups/${slug_name}`, {
                method: 'PATCH',
                data: {
                    name,
                    email,
                    description,
                    is_private,
                    invite_only,
                    who_can_post,
                },
            })
        )
    },

    /**
     * Updates a community
     */
    updateCommunity({
        name,
        sub_type,
        description,
        invite_only,
        overview_enabled,
        slug_name,
        photo,
        photoCoords,
        photoSize,
        members_only_social,
        hide_email_members,
        radius,
        radiusUnits,
        city_id,
        fields,
    }: {
        name: string
        description: string
        invite_only: boolean
        overview_enabled: boolean
        slug_name: string
        photo?: File
        photoCoords: string
        photoSize: string
        members_only_social: boolean
        hide_email_members: boolean
        sub_type?: unknown
        radius?: unknown
        radiusUnits?: unknown
        city_id?: unknown
        fields?: unknown
    }) {
        const formData = new FormData()
        const data = {
            name,
            description,
            invite_only,
            overview_enabled,
            members_only_social,
            hide_email_members,
            sub_type,
            photo_coords: photoCoords,
            photo_preview_size: photoSize,
            radius,
            radius_units: radiusUnits,
            city_id,
        }
        Object.keys(data).forEach(k => {
            if (data[k] !== undefined && data[k] !== null) {
                formData.append(k, data[k])
            }
        })
        addToFormData(formData, fields, undefined)
        if (photo) {
            formData.append('photo', photo, photo.name)
        }
        return makeAP(
            ServerApi._apiQuery<Group>(`groups/${slug_name}`, {
                method: 'PATCH',
                data: formData,
                processData: false,
                contentType: false,
                cache: false,
            })
        )
    },

    /**
     * Delete a Group
     */
    deleteGroup({ slug_name }: { slug_name: string }, force: boolean) {
        return makeAP(
            ServerApi._apiQuery<void>(`groups/${slug_name}`, {
                method: 'DELETE',
                query: { f: force ? '1' : undefined },
            })
        )
    },

    /**
     * Updates the overview text for a community
     */
    updateCommunityOverview(slug_name: string, overviewText: string) {
        return makeAP(
            ServerApi._apiQuery<object>(`groups/${slug_name}`, {
                method: 'PATCH',
                data: { overview_text: overviewText },
            })
        )
    },

    getCommunityEmails(slug_name: string) {
        return makeAP(
            ServerApi._apiQuery<{ emails_as_string: string; email_count: number }>(`emails`, {
                query: { group: slug_name },
                async: false,
            })
        )
    },

    /**
     * Add a member to a Group
     */
    addMemberToGroup(
        { slug_name }: { slug_name: string },
        {
            employee_public_id,
            group_slug_name,
            raw_email,
        }: { employee_public_id?: string; group_slug_name?: string; raw_email?: string },
        force = false
    ) {
        const req = ServerApi._apiQuery<DListPageData>(`groups/${slug_name}/members`, {
            method: 'POST',
            data: {
                person: employee_public_id,
                nested_group: group_slug_name,
                raw_email,
                force,
            },
        })
        const prom = req.then(({ group, more }) => {
            return Object.assign(normalize<DistributionList>(group), more)
        })
        return makeAP<DListPageData, DistributionList>(req, prom)
    },

    /**
     * Request owner to add to a Group
     */
    askToJoinGroup({ slug_name }: { slug_name: string }) {
        return makeAP(
            ServerApi._apiQuery<void>(`groups/${slug_name}/membership_request`, {
                method: 'POST',
            })
        )
    },

    /**
     * Cancel a group join request
     */
    cancelJoinRequest({ slug_name }: { slug_name: string }) {
        return makeAP(
            ServerApi._apiQuery<void>(`groups/${slug_name}/membership_request`, {
                method: 'DELETE',
            })
        )
    },

    /**
     * Accepts requests to Join
     */
    acceptJoinRequests(slug_name: string, publicIds: string[], asOwner: boolean) {
        return makeAP(
            ServerApi._apiQuery<{ group; more }>(`groups/${slug_name}/resolve_requests`, {
                method: 'POST',
                contentType: 'application/json; charset=utf-8',
                data: JSON.stringify({
                    action: asOwner ? 'accept-owner' : 'accept',
                    public_ids: publicIds,
                }),
            })
        )
    },

    /**
     * Declines requests to Join
     */
    declineJoinRequests(slug_name: string, publicIds: string[], message: string) {
        return makeAP(
            ServerApi._apiQuery<{ group; more }>(`groups/${slug_name}/resolve_requests`, {
                method: 'POST',
                contentType: 'application/json; charset=utf-8',
                data: JSON.stringify({
                    action: 'decline',
                    public_ids: publicIds,
                    message,
                }),
            })
        )
    },

    /**
     * Checks if a dlist with a given email exists
     */
    dlistEmailExists(email: string) {
        return makeAP(ServerApi._apiQuery<{ result: boolean }>('group_email_exists', { query: { email } }))
    },

    /**
     * Remove a member from a Group
     */
    removeMemberFromGroup(
        { slug_name }: { slug_name: string }, // the dlist to operate on
        {
            employee_public_id,
            group_slug_name,
            raw_email,
        }: {
            employee_public_id?: string // The employee to remove
            group_slug_name?: string // the nested group to remove
            raw_email?: string // the external email to remove
        }
    ) {
        const req = ServerApi._apiQuery<DListPageData>(
            `groups/${slug_name}/members/${employee_public_id ? 'persons' : group_slug_name ? 'groups' : 'email'}/${
                employee_public_id || group_slug_name || raw_email
            }`,
            { method: 'DELETE' }
        )
        const prom = req.then(({ group, more }) => {
            return Object.assign(normalize<DistributionList>(group), more)
        })
        return makeAP<DListPageData, DistributionList>(req, prom)
    },

    /**
     * Add an owner to a Group
     */
    addOwnerToGroup(
        { slug_name }: DistributionList, // The slug_name of the group to operate on
        { public_id }: { public_id: string } // The public id of the employee to add
    ) {
        const req = ServerApi._apiQuery<DListPageData>(`groups/${slug_name}/owners`, {
            method: 'POST',
            data: { person: public_id },
        })
        const prom = req.then(({ group, more }) => {
            return Object.assign(group, more)
        })
        return makeAP<DListPageData, DistributionList>(req, prom)
    },

    /**
     * Remove an owner from a Group
     */
    removeOwnerFromGroup(
        { slug_name }: DistributionList, // The slug_name of the group to operate on
        { public_id }: { public_id: string } // The public id of employee to remove
    ) {
        const req = ServerApi._apiQuery<DListPageData>(`groups/${slug_name}/owners/${public_id}`, {
            method: 'DELETE',
        })
        const prom = req.then(({ group, more }) => {
            return Object.assign(group, more)
        })
        return makeAP<DListPageData, DistributionList>(req, prom)
    },

    /**
     * Gets the list of dlists
     */
    getDLists(
        sort?: string[],
        subType?: string,
        pageSize?: number,
        page?: number,
        query?: string,
        readOnly?: boolean,
        officialDlist?: boolean
    ) {
        const queryParams = {
            ordering: (sort || []).join(','),
            type: Constants.GroupTypes.DLIST_TYPE,
            sub_type: subType,
            page_size: pageSize,
            page,
            search: query,
        }

        if (readOnly !== undefined) queryParams['read_only'] = readOnly ? '1' : '0'
        if (officialDlist !== undefined) queryParams['official_dlist'] = officialDlist ? '1' : '0'
        const req = ServerApi._apiQuery<QueryResults<DistributionList>>(`groups`, {
            query: queryParams,
        })
        return makeAP(req)
    },

    /**
     * Gets the list of Communities
     */
    getCommunities(
        ordering: string[],
        page_size: number,
        page: number,
        search: Maybe<string> | undefined,
        member_groups: boolean,
        local_groups?: boolean
    ) {
        return makeAP(
            ServerApi._apiQuery<QueryResults<DistributionList>>(`page/communities`, {
                query: {
                    ordering: (ordering || []).join(','),
                    page_size,
                    page,
                    search,
                    my: member_groups ? '1' : undefined,
                    local: local_groups ? '1' : undefined,
                    type: Constants.GroupTypes.COMMUNITY_TYPE,
                },
            })
        )
    },

    /**
     * Gets the list of Groups
     */
    getGroups(sort: string[], page_size: number, page: number | undefined, query: string) {
        return makeAP(
            ServerApi._apiQuery<QueryResults<Group>>('groups', {
                query: {
                    ordering: (sort || []).join(','),
                    page_size,
                    page,
                    search: query,
                },
            })
        )
    },

    /**
     * Gets a list of company roles
     */
    getRoles(this: void, query: Maybe<string>) {
        return makeAP(
            ServerApi._apiQuery<CompanyRole[]>('roles', {
                query: {
                    search: query,
                },
            })
        )
    },

    /**
     * Records a role instant search selection
     */
    recordRoleView(name: string) {
        return makeAP(
            ServerApi._apiQuery<CompanyRole>(`roles/${encodeURI(name)}/selected`, {
                method: 'POST',
            })
        )
    },

    /**
     * Gets a list of company products
     */
    getProducts(this: void, query: Maybe<string>) {
        return makeAP(
            ServerApi._apiQuery<CompanyProduct[]>('products', {
                query: {
                    search: query,
                },
            })
        )
    },

    /**
     * Records a product instant search selection
     */
    recordProductView(name: string) {
        return makeAP(
            ServerApi._apiQuery<CompanyProduct>(`products/${encodeURI(name)}/selected`, {
                method: 'POST',
            })
        )
    },

    /**
     * Gets the list of locations
     */
    getLocations(sort: string[], page_size: number, page: number, query: string, inactive?: boolean) {
        const args = {
            page_size,
            page,
            q: query,
            ordering: (sort || []).join(','),
        } as any
        if (inactive !== undefined) {
            args.is_active = inactive ? '0' : '1'
        }
        return makeAP(ServerApi._apiQuery<QueryResults<LocationType>>('locations', { query: args }))
    },

    /**
     * Update the Transfer Portal field(s) for an Employee
     */
    updateTransferDescription(publicId: string, transferDescription: string | null | undefined, pending?: boolean) {
        return makeAP(
            ServerApi._apiQuery<void>(`page/transfer_portal_edit/${publicId}`, {
                method: 'POST',
                contentType: 'application/json',
                data: JSON.stringify({ transfer_description: transferDescription, transfer_entry_pending: pending }),
            })
        )
    },

    /**
     * Gets options for an employee office
     */
    getEmployeeLocationOptions(sort: string[], pageSize: number, page: Maybe<number> | undefined, query: string) {
        const args = {
            page_size: pageSize,
            q: query,
            ordering: (sort || []).join(','),
            page,
        }
        return makeAP(ServerApi._apiQuery<QueryResults<EmployeeLocationOption>>('location_options', { query: args }))
    },

    /**
     * Gets options for a Home City
     */
    getEmployeeCitySuggestions(sort: string[], pageSize: number, page: Maybe<number> | undefined, query: string) {
        const args = {
            page_size: pageSize,
            q: query,
            ordering: (sort || ['state']).join(','),
            page,
        }
        return makeAP(ServerApi._apiQuery<QueryResults<EmployeeLocationOption>>('city_suggestions', { query: args }))
    },

    getCityLatLong(singleLine, magicKey) {
        const args = { single_line: singleLine, magic_key: magicKey }
        return makeAP(ServerApi._apiQuery<CityLatLongResponse>('city_latlong', { query: args, version: 'v3' }))
    },

    /**
     * Creates a location
     */
    createOfficeLocation({
        name,
        displayAddress,
        addresses,
        is_active,
        photo,
        photoCoords,
        photoSize,
        dlistSlug,
    }: CreateOfficeLocationParams) {
        const formData = new FormData()
        const data = {
            name,
            is_active: is_active ? '1' : '0',
            addresses: addresses ? JSON.stringify(addresses) : undefined,
            photo_coords: photoCoords,
            display_address: displayAddress,
            photo_preview_size: photoSize,
            official_distribution_list: dlistSlug,
            type: Constants.LocationTypes.WORKPLACE_OFFICE,
        }
        Object.keys(data).forEach(k => {
            if (data[k] !== undefined && data[k] !== null) {
                formData.append(k, data[k])
            }
        })
        if (photo) {
            formData.append('photo', photo, photo.name)
        }
        return makeAP(
            ServerApi._apiQuery<Location>(`locations`, {
                method: 'POST',
                data: formData,
                processData: false,
                contentType: false,
                cache: false,
            })
        )
    },

    /**
     * Updates a location
     */
    updateOfficeLocation({
        slug,
        name,
        displayAddress,
        addresses,
        is_active,
        photo,
        photoCoords,
        photoSize,
        dlistSlug,
    }: UpdateOfficeLocationParams) {
        const data = {
            name,
            addresses: addresses ? JSON.stringify(addresses) : undefined,
            photo_coords: photoCoords,
            display_address: displayAddress,
            photo_preview_size: photoSize,
            official_distribution_list: dlistSlug,
        }

        if (is_active === true || is_active === false) {
            data['is_active'] = is_active ? '1' : '0'
        }
        const formData = new FormData()
        Object.keys(data).forEach(k => {
            if (data[k] !== undefined && data[k] !== null) {
                formData.append(k, data[k])
            }
        })
        if (photo) {
            formData.append('photo', photo, photo.name)
        }
        return makeAP(
            ServerApi._apiQuery<Location>(`locations/${slug}`, {
                method: 'PATCH',
                data: formData,
                processData: false,
                contentType: false,
                cache: false,
            })
        )
    },

    /**
     * Deletes an office location
     */
    deleteOfficeLocation(slug?: string) {
        if (slug) {
            return makeAP(
                ServerApi._apiQuery<void>(`locations/${slug}`, {
                    method: 'DELETE',
                })
            )
        } else {
            return Promise.resolve()
        }
    },

    /**
     * Updates the FloorPlans for a location
     */
    updateOfficeFloorPlans(slug, floorPlans: FloorPlanUpdate[]) {
        const formData = new FormData()
        floorPlans = floorPlans.map(o => Object.assign({}, o))
        const payload = floorPlans.map((fp, index) => {
            const { image, ...rest } = fp
            const result = { index, ...rest }
            if (image) {
                formData.append(String(index), image, image.name)
                Object.assign(result, { image_key: String(index) })
            }
            return result
        })
        formData.append('floorplans', JSON.stringify(payload))
        const req = ServerApi._apiQuery<UploadFloorPlansResponse>(`locations/${slug}/upload_floor_plans`, {
            method: 'POST',
            data: formData,
            processData: false,
            contentType: false,
            cache: false,
        })
        return makeAP(req)
    },

    /**
     * Adds an employee floor plan coordinate
     */
    addEmployeeFloorPlanCoordinate(locationSlug: string, publicId: string, data: FloorPlanCoordinate) {
        return makeAP(
            ServerApi._apiQuery<FloorPlanCoordinate>(`locations/${locationSlug}/employee_coordinates/${publicId}`, {
                method: 'PATCH',
                data: JSON.stringify({ coordinate: data }),
                contentType: 'application/json; charset=utf-8',
            })
        )
    },

    /**
     * Deletes an employee floor plan coordinate
     */
    deleteEmployeeFloorPlanCoordinate(locationSlug: string, publicId: string) {
        return makeAP(
            ServerApi._apiQuery<void>(`locations/${locationSlug}/employee_coordinates/${publicId}`, {
                method: 'PATCH',
                data: JSON.stringify({ coordinate: null }),
                contentType: 'application/json; charset=utf-8',
            })
        )
    },

    /**
     * Adds a conference room floor plan coordinate
     */
    addConferenceRoomFloorPlanCoordinate(locationSlug: string, slug: string, data: FloorPlanCoordinate) {
        return makeAP(
            ServerApi._apiQuery<FloorPlanCoordinate>(`locations/${locationSlug}/conference_rooms/${slug}`, {
                method: 'PATCH',
                data: JSON.stringify({ coordinates: [data] }),
                contentType: 'application/json; charset=utf-8',
            })
        )
    },

    /**
     * Deletes an employee floor plan coordinate
     */
    deleteConferenceRoomFloorPlanCoordinate(locationSlug: string, slug: string) {
        return makeAP(
            ServerApi._apiQuery<void>(`locations/${locationSlug}/conference_rooms/${slug}`, {
                method: 'PATCH',
                data: JSON.stringify({ coordinates: [] }),
                contentType: 'application/json; charset=utf-8',
            })
        )
    },

    /**
     * Merges two locations
     */
    mergeLocation(sourceSlug: string, destSlug: string) {
        return makeAP(
            ServerApi._apiQuery<LocationType>(`locations/${sourceSlug}/merge/${destSlug}`, {
                method: 'PATCH',
            })
        )
    },

    /**
     * Gets the members of a Location
     */
    getLocationMembers(
        slug: string,
        page_size: number,
        page: Maybe<number> | undefined,
        query: string,
        ordering?: string[],
        secondary?: boolean
    ) {
        return makeAP(
            ServerApi._apiQuery<QueryResults<Employee>>(`locations/${slug}/people`, {
                query: {
                    page_size,
                    page,
                    search: query,
                    ordering,
                    ...(secondary && { secondary: 1 }),
                },
            })
        )
    },

    /**
     * Checks if a location address already exists, and gets its normalized form
     */
    checkLocationAddress(
        street_address: string,
        city: string,
        state: string,
        country: string,
        address_id: Maybe<Id>,
        skip_address_normalization: boolean
    ) {
        return makeAP(
            ServerApi._apiQuery<LocationAddressCheckResponse>(`location_addresses`, {
                method: 'POST',
                data: {
                    street_address,
                    city,
                    state,
                    country,
                    ...(address_id && { id: address_id }),
                    skip_address_normalization,
                },
            })
        )
    },

    /**
     * Updates the overview text for a location
     */
    updateLocationOverview(slug: string, overviewText: string) {
        return makeAP(
            ServerApi._apiQuery<LocationType>(`locations/${slug}/wiki`, {
                method: 'PATCH',
                data: { overview_text: overviewText },
            })
        )
    },

    /**
     * Updates the photo for a location.  This hits a different endpoint than the standard location endpoint, which
     *  allows for non-admins to edit when wiki edits are enabled
     */
    updateLocationPhoto(slug?: string, photo?: File, photoCoords?: string, photoSize?: string) {
        const data = {
            photo_coords: photoCoords,
            photo_preview_size: photoSize,
        }
        const formData = new FormData()
        Object.keys(data).forEach(k => {
            if (data[k] !== undefined && data[k] !== null) {
                formData.append(k, data[k])
            }
        })
        if (photo) {
            formData.append('photo', photo, photo.name)
        }
        return makeAP(
            ServerApi._apiQuery<Location>(`locations/${slug}/wiki`, {
                method: 'PATCH',
                data: formData,
                processData: false,
                contentType: false,
                cache: false,
            })
        )
    },

    /**
     * Gets a conference room
     */
    getConferenceRoom(slug: string, conferenceRoomSlug: string, fis: boolean) {
        return makeAP(
            ServerApi._apiQuery<ConferenceRoom>(`locations/${slug}/conference_rooms/${conferenceRoomSlug}`, {
                query: { ...(fis && { fis: 1 }) },
            })
        )
    },

    /**
     * Gets conference rooms
     */
    getConferenceRooms(
        sort: Maybe<string[]> | undefined,
        pageSize: Maybe<number> | undefined,
        page: Maybe<number> | undefined,
        search: string,
        slug?: string
    ) {
        const props = {
            ordering: (sort || []).join(','),
            page_size: pageSize,
            page,
            search,
        }
        const url = slug ? `locations/${slug}/conference_rooms` : `conference_rooms`
        return makeAP(ServerApi._apiQuery<QueryResults<ConferenceRoom>>(url, { query: props }))
    },

    /**
     * Creates a conference room
     */
    createConferenceRoom(locationSlug: string, props: ConferenceRoom) {
        return makeAP(
            ServerApi._apiQuery<ConferenceRoom>(`locations/${locationSlug}/conference_rooms`, {
                method: 'POST',
                data: JSON.stringify(props),
                contentType: 'application/json; charset=utf-8',
            })
        )
    },

    /**
     * Updates a conference room
     */
    updateConferenceRoom(locationSlug: string, slug: string, props: ConferenceRoom) {
        return makeAP(
            ServerApi._apiQuery<ConferenceRoom>(`locations/${locationSlug}/conference_rooms/${slug}`, {
                method: 'PATCH',
                data: JSON.stringify(props),
                contentType: 'application/json; charset=utf-8',
            })
        )
    },

    /**
     * Deletes a conference room
     */
    deleteConferenceRoom(locationSlug: string, slug: string) {
        return makeAP(
            ServerApi._apiQuery<void>(`locations/${locationSlug}/conference_rooms/${slug}`, {
                method: 'DELETE',
            })
        )
    },

    /**
     * Pings the responsibilities page to update the last_updated ts to now.
     */
    pingResponsibilities(public_id: string) {
        return makeAP(
            ServerApi._apiQuery<EditResponsibilitiesPageData>(`ping/responsibilities/${public_id}`, { type: 'POST' })
        )
    },

    /**
     * Gets the emails of all employees that match a given search
     */
    getEmailsForSearch(query: string, count: number, filterData?: object) {
        return makeAP(
            ServerApi._apiQuery<EmailsForSearchResponse>(`emails_from_search`, {
                query: {
                    requester: 'ow_search',
                    query,
                    page_size: count,
                },
                data: filterData ? JSON.stringify(filterData) : undefined,
                async: false,
                method: filterData ? 'POST' : 'GET',
                contentType: 'application/json; charset=utf-8',
            })
        )
    },

    /**
     * Gets a page of skills
     */
    getSkills(sort: string[], query: string, pageSize?: number, page?: number, noSynonyms = false, noEmpty = false) {
        const ne = noEmpty ? '1' : undefined
        return makeAP(
            ServerApi._apiQuery<QueryResults<Skill>>('skills', {
                query: {
                    ordering: (sort || []).join(','),
                    page_size: pageSize,
                    page,
                    q: query,
                    synonyms: !noSynonyms,
                    ne,
                },
            })
        )
    },

    /**
     * Records a skill instant search selection
     */
    recordSkillView(slug_name: string) {
        return makeAP(
            ServerApi._apiQuery<Skill>(`skills/${slug_name}/selected`, {
                method: 'POST',
            })
        )
    },

    /**
     * Gets aggregates for a search
     */
    _getSearchAggregates(facet, filterLookup, mainQuery, subQuery, filterData, pageSize?: number) {
        // Clone filterData and remove facet
        const filter = structuredClone(filterData)
        filter.filter.include[facet] = { keywords: [], text: [] }
        return makeAP(
            ServerApi._apiQuery<AggregateData[]>('search', {
                query: {
                    requester: 'ow_search',
                    query: mainQuery,
                    filter_lookup: filterLookup,
                    filter_query: subQuery,
                    page_size: pageSize,
                },
                data: JSON.stringify(filter),
                method: filter ? 'POST' : 'GET',
                contentType: 'application/json; charset=utf-8',
            })
        )
    },

    /**
     * Gets the group aggregates for a search
     */
    getGroupAggregates(mainQuery: string, subQuery: string, filterData: any, pageSize?: number) {
        return ServerApi._getSearchAggregates('groups', 'groups', mainQuery, subQuery, filterData, pageSize)
    },

    /**
     * Gets the skill aggregates for a search
     */
    getSkillAggregates(mainQuery: string, subQuery: string, filterData: FilterData, pageSize?: number) {
        return ServerApi._getSearchAggregates('skills', 'skill_sets', mainQuery, subQuery, filterData, pageSize)
    },

    /**
     * Gets the manager aggregates for a search
     */
    getManagerAggregates(mainQuery: string, subQuery: string, filterData: FilterData, pageSize: number) {
        return ServerApi._getSearchAggregates('managers', 'managers', mainQuery, subQuery, filterData, pageSize)
    },

    /**
     * Gets the aggregates for the direct reports of a manager
     */
    getManagerReportAggregates(
        mainQuery: string,
        filterData: FilterData,
        publicId?: string,
        pageSize?: number,
        page?: number
    ) {
        // Clone filterData and remove managers
        const filter = structuredClone(filterData)
        filter.filter.include.managers = { keywords: [], text: [] }
        return makeAP(
            ServerApi._apiQuery<object[]>('search', {
                query: {
                    requester: 'ow_search',
                    query: mainQuery,
                    filter_lookup: 'manager_browser',
                    page_size: pageSize,
                    page,
                    manager_public_id: publicId,
                },
                data: JSON.stringify(filter),
                method: filter ? 'POST' : 'GET',
                contentType: 'application/json; charset=utf-8',
            })
        )
    },

    /**
     * Gets the aggregates for locations
     */
    getLocationAggregates(mainQuery: string, subQuery: string, filterData: FilterData, pageSize?: number) {
        return ServerApi._getSearchAggregates('location', 'location', mainQuery, subQuery, filterData, pageSize)
    },

    /**
     * Gets the aggregates for Title
     */
    getTitleAggregates(mainQuery: string, filterData: FilterData, subQuery: string) {
        return ServerApi._getSearchAggregates('title', 'title', mainQuery, subQuery, filterData)
    },

    /**
     * Deletes a timeline entry
     */
    deleteTimelineEntry(timelineEntryId: Id) {
        return makeAP(
            ServerApi._apiQuery<void>(`timeline_entry/${timelineEntryId}`, {
                method: 'DELETE',
            })
        )
    },

    /**
     * Update a post
     */
    updatePost(
        tleId: Id,
        postText: string,
        postMentions: string,
        storyText: string,
        storyMentions: string,
        isJobPost: boolean
    ) {
        return makeAP(
            ServerApi._apiQuery<void>(`edit_post/${tleId}`, {
                method: 'PATCH',
                data: {
                    post_text: postText,
                    post_text_mentions: postMentions,
                    story_text: storyText,
                    story_text_mentions: storyMentions,
                    is_job_post: isJobPost,
                },
            })
        )
    },

    /**
     * Sets a reaction on a timeline entry
     */
    setTimelineEntryReaction(timelineEntryId: Id, shouldLike: boolean) {
        return makeAP(
            ServerApi._apiQuery<SetTimelineEntryReactionResponse>(`reaction_for_tle`, {
                method: 'POST',
                data: { the_tle_id: timelineEntryId, should_like: shouldLike },
            })
        )
    },

    /**
     * Marks a timeline entry as viewed
     */
    addTimelimeEntryView(timelineEntryId: Id) {
        return makeAP(
            ServerApi._apiQuery<TimelineEntryViewResponse>('create_view_for_tle', {
                method: 'POST',
                data: { the_timeline_entry: timelineEntryId },
            })
        )
    },

    /**
     * Creates a new comment
     */
    createComment(timelineEntryId: Id, text: string, mentions: string) {
        return makeAP(
            ServerApi._apiQuery<TimelineEntryComment>('tl/comments', {
                method: 'POST',
                data: {
                    timeline_entry: timelineEntryId,
                    text,
                    text_mentions: mentions,
                },
            })
        )
    },

    /**
     * Creates a new comment
     */
    updateComment(timelineEntryId: Id, commentId: Id, text: string, mentions: string) {
        return makeAP<TimelineEntryComment>(
            ServerApi._apiQuery(`tl/comments/${commentId}`, {
                method: 'PATCH',
                data: {
                    timeline_entry: timelineEntryId,
                    text,
                    text_mentions: mentions,
                },
            })
        )
    },

    /**
     * Deletes a comment
     */
    deleteComment(commentId: Id) {
        return makeAP(
            ServerApi._apiQuery<void>(`tl/comments/${commentId}`, {
                method: 'DELETE',
            })
        )
    },

    /**
     * Sets the read-only flag for a DList
     */
    setDListReadOnly(slug_name: string, read_only: boolean) {
        return makeAP(
            ServerApi._apiQuery<void>(`admin/dlist_read_only/${slug_name}`, {
                method: read_only ? 'POST' : 'DELETE',
            })
        )
    },

    /**
     * Deletes a domain
     */
    deleteDomain(domainId: Id) {
        return makeAP(ServerApi._apiQuery<void>(`domains/${domainId}`, { method: 'DELETE' }))
    },

    /**
     * Creates a domain
     */
    createDomain(name: string) {
        return makeAP(
            ServerApi._apiQuery<Domain>('domains', {
                method: 'POST',
                data: { name },
            })
        )
    },

    /**
     * Gets the admin new hire email config
     */
    getNewHireEmailConfig() {
        return makeAP(ServerApi._apiQuery<object>('admin_new_hire'))
    },

    /**
     * Updates the new hire email config
     */
    updateNewHireEmailConfig({ enabled = false, day = 5, hour = 9, includeContractors = true }) {
        return makeAP(
            ServerApi._apiQuery<void>('admin_new_hire', {
                method: 'POST',
                data: {
                    is_enabled: enabled ? '1' : '0',
                    email_send_day: (day + 6) % 7,
                    email_send_hour: hour,
                    include_contractors: includeContractors ? '1' : '0',
                },
            })
        )
    },

    /**
     * Gets the current provider auth state for a company
     */
    checkCompanyAuth(guid: string) {
        return makeAP(ServerApi._apiQuery<AuthStatus>(`company/${guid}/checkAuth`))
    },

    /**
     * Updates company email integration settings
     */
    updateEmailIntegrationSettings(
        guid: string,
        {
            dlistsEnabled,
            provider,
            adminEmail,
            domain,
            adminOnly,
            showWhoCanPost,
            customOnly,
            outOfOfficeEnabled,
            employeeOnlyEnabled,
        }: {
            dlistsEnabled?: boolean
            provider?: string
            adminEmail?: string
            domain?: string
            adminOnly?: boolean
            showWhoCanPost?: boolean
            customOnly?: boolean
            outOfOfficeEnabled?: boolean
            employeeOnlyEnabled?: boolean
        }
    ) {
        const data = {
            dlist_provider: provider,
            dist_lists_domain: domain,
            dist_lists_admin_email: adminEmail,
            sync_vacation: outOfOfficeEnabled,
            dist_lists_custom_only: customOnly,
            dist_lists_enabled: dlistsEnabled,
            dist_lists_admin_only: adminOnly,
            show_who_can_post_to_owners: showWhoCanPost,
            employee_only_lists_enabled: employeeOnlyEnabled,
        }
        const req = ServerApi._apiQuery<Company>(`company/${guid}`, {
            method: 'PATCH',
            data,
        })
        const prom = req.then(company => {
            OWAuthDataModel.getInstance().updateAuthCompany(company)
            return req
        })
        return makeAP<Company, DistributionList>(req, prom)
    },

    /**
     * Resets a company's email integration settings
     */
    resetEmailIntegration(guid: string) {
        const req = ServerApi._apiQuery<void>(`company/${guid}/disable_email_integration`, {
            method: 'POST',
        })
        const prom = req.then(company => {
            OWAuthDataModel.getInstance().updateAuthCompany(company)
            return req
        })
        return makeAP(req, prom)
    },

    /**
     * Updates info about a company
     */
    updateCompany({
        configuration_mode_enabled,
        company_posts_enabled,
        timeline_moderation_datetime,
        hide_company_feed,
        wiki_office_edits_disabled,
        social_groups_enabled,
        guest_passes_enabled,
        wiki_profile_edits_enabled,
        dotted_lines_enabled,
        tags_enabled,
        profile_celebrations_enabled,
        name,
        technical_contact,
        saml_metadata_url,
        saml_metadata_file,
        identity_providers,
        company_config,
    }: {
        configuration_mode_enabled?: boolean
        company_posts_enabled?: boolean
        timeline_moderation_datetime?: any
        hide_company_feed?: boolean
        wiki_office_edits_disabled?: boolean
        social_groups_enabled?: boolean
        guest_passes_enabled?: boolean
        wiki_profile_edits_enabled?: boolean
        dotted_lines_enabled?: boolean
        tags_enabled?: boolean
        profile_celebrations_enabled?: boolean
        name?: string
        technical_contact?: any
        saml_metadata_url?: string
        saml_metadata_file?: File
        identity_providers?: string[]
        company_config?: any
    }) {
        if (timeline_moderation_datetime) {
            timeline_moderation_datetime = timeline_moderation_datetime.toISOString()
        }
        const technical_contact_public_id =
            technical_contact === undefined ? undefined : technical_contact ? technical_contact.public_id : null
        const data = {
            configuration_mode_enabled,
            company_posts_enabled,
            timeline_moderation_datetime,
            hide_company_feed,
            wiki_office_edits_disabled,
            social_groups_enabled,
            guest_passes_enabled,
            wiki_profile_edits_enabled,
            dotted_lines_enabled,
            tags_enabled,
            profile_celebrations_enabled,
            name,
            technical_contact_public_id,
            saml_metadata_url,
            identity_providers,
            company_config,
        }
        const sanitizedData = Object.fromEntries(
            Object.keys(data)
                .filter(k => data[k] !== undefined)
                .map(k => [k, data[k]])
        )
        const formData = new FormData()
        addToFormData(formData, sanitizedData, undefined)
        if (saml_metadata_file !== undefined) {
            addToFormData(formData, saml_metadata_file, 'saml_metadata_file')
        }
        const req = ServerApi._apiQuery<Company>(`company/${Global.COMPANY_GUID}`, {
            method: 'PATCH',
            data: formData,
            processData: false,
            contentType: false,
            cache: false,
        })
        const prom = req.then(company => {
            OWAuthDataModel.getInstance().updateAuthCompany(company)
            return req
        })
        return makeAP<Company>(req, prom)
    },

    /**
     * Creates a new service account
     */
    createServiceAccount({ name, description, type }: { name: string; description: string; type: string }) {
        return makeAP(
            ServerApi._apiQuery<ServiceAccount>(`service_accounts`, {
                method: 'POST',
                data: { name, description, type },
            })
        )
    },

    /**
     * Updates a service account
     */
    updateServiceAccount(
        accountId: string,
        {
            name,
            description,
            active,
            archived,
        }: { name: string; description: string; active: boolean; archived: boolean }
    ) {
        return makeAP(
            ServerApi._apiQuery<ServiceAccount>(`service_accounts/${accountId}`, {
                method: 'PATCH',
                data: {
                    ...(name !== undefined && { name }),
                    ...(description !== undefined && { description }),
                    ...(active !== undefined && { is_active: active }),
                    ...(archived !== undefined && { is_archived: archived }),
                },
            })
        )
    },

    /**
     * Creates a new token for a service account
     */
    createServiceAccountToken(accountId: string) {
        return makeAP(
            ServerApi._apiQuery<ServiceAccountToken>(`service_accounts/${accountId}/tokens`, {
                method: 'POST',
            })
        )
    },

    /**
     * Updates a service account token
     */
    updateServiceAccountToken(accountId: string, tokenId: string, { active }: { active: boolean }) {
        return makeAP(
            ServerApi._apiQuery<ServiceAccountToken>(`service_accounts/${accountId}/tokens/${tokenId}`, {
                method: 'PATCH',
                data: { is_active: active },
            })
        )
    },

    /**
     * Deletes a service account token

     */
    deleteServiceAccountToken(accountId: string, tokenId: string) {
        return makeAP(
            ServerApi._apiQuery<void>(`service_accounts/${accountId}/tokens/${tokenId}`, {
                method: 'DELETE',
            })
        )
    },

    /**
     * Create a guest pass
     */
    createGuestPass(
        firstName: string,
        lastName: string,
        companyName: string,
        email: string,
        expiration: Moment,
        authorizer?: Employee
    ) {
        const expirationDate = expiration ? expiration.format('YYYY-MM-DD') : ''
        const authorizerEmail = authorizer ? authorizer.email : ''
        return makeAP(
            ServerApi._apiQuery<GuestPass>('create_guest_pass', {
                method: 'POST',
                data: {
                    guest_first_name: firstName,
                    guest_last_name: lastName,
                    guest_company_name: companyName,
                    guest_email: email,
                    expiration_date: expirationDate,
                    authorizer_email: authorizerEmail,
                },
            })
        )
    },

    /**
     * Revokes a guest pass
     */
    revokeGuestPass(guestId: string) {
        return makeAP(
            ServerApi._apiQuery<void>(`revoke_guest_pass/${guestId}`, {
                method: 'POST',
            })
        )
    },

    /**
     * Renews a guest pass

     */
    renewGuestPass(guestId: Id, expiration: Moment) {
        const expirationDate = expiration ? expiration.format('YYYY-MM-DD') : ''
        return makeAP(
            ServerApi._apiQuery<GuestPass>(`renew_guest_pass/${guestId}`, {
                method: 'POST',
                data: { expiration_date: expirationDate },
            })
        )
    },

    updateFieldPermission(fieldName: string, type: string) {
        const req = ServerApi._apiQuery<FieldPermission>(`field_permissions/${fieldName}`, {
            method: 'PATCH',
            data: { type },
        })
        return makeAP(req)
    },

    /**
     * Gets candidates for group membership
     */
    getGroupMemberCandidates(
        slugName: string,
        memberType: 'PERSON' | 'GROUP' | 'EXTERNAL',
        groupType: string | undefined,
        groupSubType: string | undefined,
        pageSize: number,
        search: string
    ) {
        return makeAP(
            ServerApi._apiQuery<QueryResults<GroupMemberCandidate>>(`groups/${slugName}/candidates`, {
                query: {
                    search,
                    page_size: pageSize,
                    member_type: memberType,
                    group_type: groupType,
                    group_sub_type: groupSubType,
                },
            })
        )
    },

    /**
     * Dedup group
     */
    purgeGroupMemberDups(slugName: string, members: Id[] | null, lists: Id[] | null) {
        const data = { op: 'prune' } as any
        if (members === null) {
            data.has_member_ids = false
        } else {
            data.has_member_ids = true
            data.members = members
        }
        if (lists === null) {
            data.has_list_ids = false
        } else {
            data.has_list_ids = true
            data.lists = lists
        }
        return makeAP(
            ServerApi._apiQuery<{ group: any }>(`groups/${slugName}/dups`, {
                method: 'POST',
                data: data,
            })
        )
    },

    /**
     * Starts a spreadsheet import
     */
    startSpreadsheetImport(formData: FormData, skipBlanks: boolean) {
        return makeAP(
            ServerApi._apiQuery<ImportProgress>(`import_file`, {
                method: 'POST',
                data: formData,
                processData: false,
                contentType: false,
                cache: false,
                query: { skip_blanks: skipBlanks ? 1 : 0 },
            })
        )
    },

    /**
     * Gets the current status of a spreadsheet upload
     */
    getSpreadsheetImportStatus(progressId: string) {
        return makeAP(ServerApi._apiQuery<ImportProgress>(`import_status/${progressId}`))
    },

    /**
     * Gets a dlist member import preview
     */
    previewDListMemberImport(slugName: string, formData: FormData) {
        return makeAP(
            ServerApi._apiQuery<DListMemberImportPreview>(`groups/${slugName}/import_preview`, {
                method: 'POST',
                data: formData,
                processData: false,
                contentType: false,
                cache: false,
            })
        )
    },

    /**
     * Starts a dlist member import
     */
    startDListMemberImport(slugName: string, formData: FormData) {
        return makeAP(
            ServerApi._apiQuery<DListMemberImportResponse>(`groups/${slugName}/import_members`, {
                method: 'POST',
                data: formData,
                processData: false,
                contentType: false,
                cache: false,
            })
        )
    },

    /**
     * Gets the progress of a dlist member export
     */
    getDListMemberExportStatus(importId: Id) {
        return makeAP(ServerApi._apiQuery<DListMemberImportProgress>(`dlist_member_import_progress/${importId}`))
    },

    /**
     * Gets a download url for a dlist member export
     */
    getDListMemberExportDownload(slugName: string, fields: string[], nonMembersTab: boolean) {
        return makeAP(
            ServerApi._apiQuery<ExportResult>(`groups/${slugName}/export_members`, {
                method: 'POST',
                data: JSON.stringify({
                    ...(nonMembersTab && { non_members_tab: 1 }),
                    ...(fields && { fields }),
                }),
                contentType: 'application/json; charset=utf-8',
            })
        )
    },

    /**
     * Gets a list of in-memoriam objects
     */
    getInMemoriams(query: string, sort?: string[], pageSize?: number, page?: Maybe<number>) {
        const ordering = (sort || []).join(',')
        return makeAP(
            ServerApi._apiQuery<QueryResults<InMemoriam>>('in_memoriam', {
                query: { search: query, page_size: pageSize, page, ordering },
            })
        )
    },

    /**
     * Creates an In-Memoriam object
     */
    createInMemoriam(employee: Employee, fields: any, photoBlob?: Blob, photoInput?: File) {
        const data = { ...fields, employee: employee.public_id }
        const formData = new FormData()
        addToFormData(formData, data, undefined)
        if (photoBlob) {
            formData.append('profile_photo', photoBlob, 'blob')
        } else if (photoInput) {
            formData.append('profile_photo', photoInput, photoInput.name)
        }
        const req = ServerApi._apiQuery<InMemoriam>('in_memoriam', {
            method: 'POST',
            data: formData,
            processData: false,
            contentType: false,
            cache: false,
        })
        return makeAP(req)
    },

    /**
     * Updates an In-Memoriam object
     */
    updateInMemoriam(publicId: string, fields: any, photoBlob?: Blob, photoInput?: File) {
        const data = { ...fields }
        const formData = new FormData()
        addToFormData(formData, data, undefined)
        if (photoBlob) {
            formData.append('profile_photo', photoBlob, 'blob')
        } else if (photoInput) {
            formData.append('profile_photo', photoInput, photoInput.name)
        }
        const req = ServerApi._apiQuery<InMemoriam>(`in_memoriam/${publicId}`, {
            method: 'PATCH',
            data: formData,
            processData: false,
            contentType: false,
            cache: false,
        })
        return makeAP(req)
    },

    /**
     * Publishes an In-Memoriam page
     */
    publishInMemoriam(publicId: string) {
        return ServerApi.updateInMemoriam(publicId, { is_active: true }, undefined, undefined)
    },

    /**
     * Deletes an In-Memoriam page
     */
    deleteInMemoriam(publicId: string) {
        return makeAP(
            ServerApi._apiQuery<void>(`in_memoriam/${publicId}`, {
                method: 'DELETE',
            })
        )
    },

    /**
     * Update the display language for the logged-in user
     */
    updateDisplayLanguage(lang: string) {
        return makeAP(
            ServerApi._apiQuery<void>('owuser_language_tag', {
                method: 'PATCH',
                data: { language_tag: lang },
            })
        )
    },

    /**
     * Gets the url to generate a team pptx
     */
    urlForTeamPPTX({ public_id }: { public_id: string }) {
        return `${getAPIBaseURL()}/employees/${public_id}/teampptx/`
    },

    /**
     * Gets the url for a spreadsheet import result
     */
    urlForImportResult(importId: string) {
        return `/export_import_results/${importId}`
    },

    /**
     * Gets the url for dlist member import result
     */
    urlForDListMemberImportResult(importId: Id) {
        return `/dlist_member_import_results/${importId}/`
    },
}
