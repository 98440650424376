// prettier-ignore
export const Translations = {
    "en": {
        "common": {
            "navbar.feed_sm": "Feed",
            "navbar.feed": "News Feed",
            "navbar.people": "People",
            "navbar.groups": "Groups",
            "navbar.offices": "Offices",
            "navbar.menu": "Menu",
            "navbar.admin": "Admin",
            "navbar.my_profile": "My Profile",
            "navbar.company_config": "Configure Company",
            "navbar.manage_employees": "Manage Employees",
            "navbar.manage_guest_passes": "Manage Guest Passes",
            "navbar.reports": "Reports & Analytics",
            "navbar.admin_enter": "Enable Admin Mode",
            "navbar.transfer_portal": "Transfer Portal",
            "navbar.admin_exit": "Disable Admin Mode",
            "navbar.admin_page": "Admin Console",
            "navbar.admin_page_leave": "Exit Admin Console",
            "navbar.admin_users": "Users",
            "navbar.admin_features": "Features",
            "navbar.admin_reports": "Reports",
            "navbar.admin_company": "Settings",
            "navbar.logout": "Logout",
            "navbar.restart_tour": "Restart Tour",

            "admin.history": "History",
            "admin.users_title": "Manage Users",
            "admin.users_all": "All Users",
            "admin.users_add": "+ User",
            "admin.users_add_admin": "+ Admin",
            "admin.users_add_in_memoriam": "+ In Memoriam Page",
            "admin.users_admin_modal_header": "New Administrator",
            "admin.users_admin_modal_search": "Search for an existing user to give admin privileges:",
            "admin.users_status_active": "Active",
            "admin.users_status_inactive": "Inactive",
            "admin.users_admins": "Admins",
            "admin.users_guest": "Guest Passes",
            "admin.users_name": "Name",
            "admin.users_role": "Role",
            "admin.users_created": "Created",
            "admin.users_status": "Status",
            "admin.users_last_updated": "Last updated",
            "admin.users_search": "Search by name or email",
            "admin.users_search_none": "No users match this search",
            "admin.users_in_memoriam_search_none": "No In Memoriam pages match this search",
            "admin.users_contractor": "Contractor",
            "admin.users_employee": "Employee",
            "admin.users_admin": "Admin",
            "admin.users_external": "External Email Profiles",
            "admin.users_import": "Import Users",
            "admin.users_export": "Export Users",
            "admin.users_in_memoriam": "In Memoriam",
            "admin.users_memoriam_date": "Memoriam Date",
            "admin.guest_revoked": "Revoked",
            "admin.guest_expired": "Expired",
            "admin.guest_active": "Active",
            "admin.guest_created": "Created",
            "admin.guest_expires": "Expires",
            "admin.guest_status": "Status",
            "admin.guest_revoke": "Revoke",
            "admin.guest_revoke_confirm": "Are you sure you want to revoke the guest pass: {{name, text}}?",
            "admin.guest_renew": "Renew",
            "admin.guest_add_title": "Add Guest Pass",
            "admin.guest_renew_title": "Renew Guest Pass",
            "admin.guest_email_address": "Email address",
            "admin.guest_company": "Company",
            "admin.guest_expiration_date": "Expiration Date",
            "admin.guest_authorizer": "Authorizer",
            "admin.guest_created_by": "{{created, date}} by <2>{{full_name, name}}</2>",
            "admin.guest_authorized_by": "Authorized by <1>{{name, name}}</1>",
            "admin.users_add_guest": "+ Guest Pass",
            "admun.users_import_results_header": "Import Results:",
            "admin.users_import_inserted": "{{count, number}} profile was created",
            "admin.users_import_inserted_other": "{{count, number}} profiles were created",
            "admin.users_import_updated": "{{count, number}} profile was updated",
            "admin.users_import_updated_other": "{{count, number}} profiles were updated",
            "admin.users_import_inserted_error": "{{count, number}} profile was created with errors",
            "admin.users_import_inserted_error_other": "{{count, number}} profiles were created with errors",
            "admin.users_import_updated_error": "{{count, number}} profile was updated with errors",
            "admin.users_import_updated_error_other": "{{count, number}} profiles were updated with errors",
            "admin.users_import_failed": "{{count, number}} row failed with errors",
            "admin.users_import_failed_other": "{{count, number}} rows failed with errors",
            "admin.users_import_no_rows": "0 profiles updated",
            "admin.users_import_column_errors": "{{count, number}} column not processed",
            "admin.users_import_column_errors_other": "{{count, number}} columns not processed",
            "admin.users_import_download_results": "Download Results Excel File",
            "admin.users_import_start_new": "Start a New Import",
            "admin.users_import_select_file": "Select your populated Excel file:",
            "admin.users_import_upload_file": "Upload File",
            "admin.users_import_existing_records":
                "If the employee already exists in OrgWiki (matched by External Id or Email Address) the existing record will be updated.",
            "admin.users_import_empty_cells": "What do you want to do with empty cells?",
            "admin.users_import_do_nothing": "Don't make any changes",
            "admin.users_import_clear": "Clear the data in the field",
            "admin.users_import_start": "Start Import",
            "admin.users_import_in_progres": "Import in Progress",
            "admin.users_import_progress": "{{progress, number}}% processed",
            "admin.users_import_time_p1": "The import may take some time, and will continue if you leave this page.",
            "admin.users_import_time_p2": "You will receive an email when the import is complete.",
            "admin.users_import_description":
                "OrgWiki supports bulk loading or updating of employee data using an Excel spreadsheet.",
            "admin.users_import_template": "Download the Excel Template",
            "admin.users_import_provisioning":
                "OrgWiki also supports automatically provisioning users from Okta. Download the <1>instruction manual (PDF)</1> for more details.",
            "admin.features_title": "Features",
            "admin.features_system": "System Features",
            "admin.features_profile": "Profile Features",
            "admin.features_email": "Email Integration",
            "admin.features_feed": "Feed Suspension",
            "admin.features_new_hires": "New Hires Email",
            "admin.features_roles": "Roles",
            "admin.features_products": "Products",
            "admin.features_manager_feedback": "Manager Feedback",
            "admin.features_add_survey": "+ Survey",
            "admin.features_survey_none": "No surveys",
            "admin.features_survey_title": "Title",
            "admin.features_survey_start_date": "Start Date",
            "admin.features_survey_end_date": "End Date",
            "admin.features_survey_eligible": "Eligible",
            "admin.features_survey_received": "Received",
            "admin.features_survey_results": "Full Results",
            "admin.features_survey_actions": "Actions",
            "admin.features_survey_download": "Download",
            "admin.features_survey_publish_first": "Publish 1st Line",
            "admin.features_survey_publish_second": "Publish Roll-Ups",
            "admin.features_survey_cancel": "Cancel",
            "admin.features_survey_publish_modal_header": "Generate and Publish Results",
            "admin.features_survey_publish_first_modal_body": "Are you ready to generate and publish First-Line Manager reports?",
            "admin.features_survey_publish_second_modal_body": "Are you ready to generate and publish the Roll-Up Reports?",
            "admin.features_survey_cancel_modal_body": "Are you sure you want to cancel this survey?",
            "admin.features_survey_modal_publish": "Publish",
            "admin.features_company_feed": "Company Feed",
            "admin.features_company_feed_description_p1":
                "The Company Feed gives everyone a clear picture of updates within the company. It shows new hires, title changes, manager changes, and departures. If Social Posts or Communities are enabled, posts will also appear in the feed. It's a great way to make sure everyone has visibility into org changes and employee updates.",
            "admin.features_company_feed_description_p2":
                "<0>Need to temporarily pause the feed?</0> Use <2>Feed Suspension</2>.",
            "admin.features_company_feed_confirm_on":
                "Are you sure you want to enable the feed? This will make it visible to all users.",
            "admin.features_company_feed_confirm_on_title": "Enable Company Feed",
            "admin.features_company_feed_confirm_off":
                "Are you sure you want to disable the feed? It will be hidden from all non-admin users, but no data will be lost.",
            "admin.features_company_feed_confirm_off_title": "Disable Company Feed",
            "admin.features_social_posts": "Social Posts",
            "admin.features_social_posts_description":
                "Posts allow users to share short updates and photos with the company. Posts will appear both on employee profile pages, as well as the Company Feed. Other employees can 'Like' and Comment on posts, so they're great for community building and getting to know coworkers.",
            "admin.features_social_posts_confirm_on":
                "Are you sure you want to enable social posts? Any posts which were previously created will be visible again.",
            "admin.features_social_posts_confirm_on_title": "Enable Social Posts",
            "admin.features_social_posts_confirm_off":
                "Are you sure you want to disable social posts? Social posts will be removed from the company feed and profile pages, but not deleted (they will appear again if re-enabled). This will not effect Community Posts.",
            "admin.features_social_posts_confirm_off_title": "Disable Social Posts",
            "admin.features_wiki_office": "Wiki Office Editing",
            "admin.features_wiki_office_description_p1":
                "Wiki Office Editing allows non-admin users to make basic changes to Offices. When enabled, all users can make changes to: office floor plans, conference rooms, photos, and descriptions.",
            "admin.features_wiki_office_description_p2":
                "Admin privileges are still required to create new offices, create new floor plans, edit names, statuses, addresses, and distribution list settings.",
            "admin.features_wiki_office_confirm_on": "Are you sure you want to enable wiki office editing?",
            "admin.features_wiki_office_confirm_on_title": "Enable Wiki Office Edits",
            "admin.features_wiki_office_confirm_off":
                "Are you sure you want to disable wiki office editing? Non-admins will no longer be able to make updates to Offices.",
            "admin.features_wiki_office_confirm_off_title": "Disable Wiki Office Edits",
            "admin.features_communities": "Communities",
            "admin.features_communities_description":
                "When Communities are enabled, employees are able to create and join Community Groups, where they can set a topic and have discussions with others in the group. These are often used for special interests like Book Clubs and Hiking Clubs, but also as an alternative to email distribution lists. Community Groups appear under the Groups tab in the main navigation, and employee profile pages will display the groups they have joined.",
            "admin.features_communities_confirm_on": "Are you sure you want to enable communities?",
            "admin.features_communities_confirm_on_title": "Enable Communities",
            "admin.features_communities_confirm_off":
                "Are you sure you want to disable communities? All existing communities will be removed, and will not come back. We don't recommend this.",
            "admin.features_communities_confirm_off_title": "Disable Communities",
            "admin.features_guest_passes": "Guest Passes",
            "admin.features_guest_passes_description_p1":
                "Enable Guest Passes if you would like the option of granting temporary access to OrgWiki to users outside of your company or domain. When enabled, you can add Guest Pass users in the Admin Console, under the Users tab.",
            "admin.features_guest_passes_description_p2":
                "When adding a guest pass user, the Admin can set an expiration date for access, and the user will receive an email with a special link granting them access to a read-only account. Guest Pass users can view content in OrgWiki, but do not have profiles, and cannot make changes.",
            "admin.features_guest_passes_confirm_on":
                "Are you sure you want to enable Guest Passes? The Guest Pass feature can be configured in the Admin Console under Users.",
            "admin.features_guest_passes_confirm_on_title": "Enable Guest Passes",
            "admin.features_guest_passes_confirm_off":
                "Are you sure you want to disable guest passes? Any active guest pass accounts will be disabled, and the feature will be removed from the Admin Console. Guest pass data will not be deleted, and will return if re-enabled.",
            "admin.features_guest_passes_confirm_off_title": "Disable Guest Passes",
            "admin.features_wiki_profile": "Wiki Profile Edits",
            "admin.features_wiki_profile_description_p1":
                "When enabled, all users can edit the profiles of all other users. This is helpful in making sure that profiles are always up to date. This is especially useful for managers, HR, IT, and facilities teams, and when OrgWiki is considered the source-of-truth for your organization. You can turn on Wiki Profile Edits and mark some fields as read-only (see below). This is helpful for fields that are maintained by another system that OrgWiki should not change.",
            "admin.features_wiki_profile_description_p2":
                "Note that users are notified when someone else updates their profile, and a history of changes is maintained for each profile.",
            "admin.features_wiki_profile_confirm_on": "Are you sure you want to enable wiki profile edits?",
            "admin.features_wiki_profile_confirm_on_title": "Enable Wiki Profile Edits",
            "admin.features_wiki_profile_confirm_off":
                "Are you sure you want to disable wiki profile edits? Profiles will only be editable by Admins.",
            "admin.features_wiki_profile_confirm_off_title": "Disable Wiki Profile Edits",
            "admin.features_dotted_lines": "Dotted-Line Managers",
            "admin.features_dotted_lines_description":
                "Dotted-Line managers (and reports) are useful in organizatons where an employee may have responsibilities to a secondary manager. For the purposes of the Org Chart, dotted line reports do not count toward a managers report count, but the dotted line relationship is shown on profile pages.",
            "admin.features_dotted_lines_confirm_on": "Are you sure you want to turn dotted line managers on?",
            "admin.features_dotted_lines_confirm_on_title": "Enable Dotted Line Managers",
            "admin.features_dotted_lines_confirm_off": "Are you sure you want to turn dotted line managers off?",
            "admin.features_dotted_lines_confirm_off_title": "Disable Dotted Line Managers",
            "admin.features_skills_confirm_on": "Are you sure you want to turn $t(skill.label_other) on?",
            "admin.features_skills_description":
                "When enabled, employees can add Skill 'tags' to their profile to display their proficiencies. Each skill also has a corresponding level indicator (1-4) to represent the skill level. Clicking on or searching for a skill will then display all other users with that skill.",
            "admin.features_skills_confirm_on_title": "Enable $t(skill.label_other)",
            "admin.features_skills_confirm_off": "Are you sure you want to turn $t(skill.label_other) off?",
            "admin.features_skills_confirm_off_title": "Disable $t(skill.label_other)",
            "admin.features_profile_celebrations": "Anniversary Celebrations",
            "admin.features_profile_celebrations_description":
                "This feature is just for fun! On the anniversary of an employee's start date, their profile page will display a special celebration.",
            "admin.features_profile_celebrations_confirm_on": "Are you sure you want to turn profile celebrations on?",
            "admin.features_profile_celebrations_confirm_on_title": "Enable Profile Celebrations",
            "admin.features_profile_celebrations_confirm_off":
                "Are you sure you want to turn profile celebrations off?",
            "admin.features_profile_celebrations_confirm_off_title": "Disable Profile Celebrations",
            "admin.features_read_only_fields": "Read-Only Profile Fields",
            "admin.features_read_only_description":
                "Marked fields will not be editable within OrgWiki, but can only be changed through the API.",
            "admin.features_profile_manager": "Manager",
            "admin.features_location_read_only":
                "Only applies to the primary location. Secondary locations are always editable.",
            "admin.features_survey_form_add_survey": "Add Survey",
            "admin.features_survey_form_short_title": "Short Title",
            "admin.features_survey_form_short_title_hint": "Example: Jan. 2025",
            "admin.features_survey_form_entire_company": "Send to Entire Company?",
            "admin.features_survey_form_managers": "Managers to Include",
            "admin.features_survey_form_managers_first_hint": "Send manager feedback survey for the following managers",
            "admin.features_survey_form_managers_second_hint": "Paste manager email addresses on separate lines",
            "admin.features_survey_form_start_date": "Survey Start Date",
            "admin.features_survey_form_end_date": "Survey End Date",
            "admin.features_survey_form_first_reminder_date": "First Reminder Date",
            "admin.features_survey_form_second_reminder_date": "Second Reminder Date",
            "admin.features_survey_form_local_time": "Times above are displayed in your local time zone",
            "admin.company_title": "Settings",
            "admin.company_identity": "Identity & Security",
            "admin.company_ip_access": "IP Access Control",
            "admin.restict_access_label": "Restrict web and app access to specified addresses",
            "admin.allowed_ip_addresses": "Allowed IP Addresses",
            "admin.enter_allowed_ip": "Enter allowed IP addresses in CIDR range format on separate lines",
            "admin.edit_config": "Edit Configuration",
            "admin.company_service_accounts": "Service Accounts",
            "admin.company_service_accounts_tokens": "{{count, number}} active token",
            "admin.company_service_accounts_tokens_other": "{{count, number}} active tokens",
            "admin.company_prelaunch_mode": "Launch Status",
            "admin.company_branding": "Custom Branding",
            "admin.company_history": "Settings History",
            "admin.company_history_header": "Company Settings History",
            "admin.company_global_history": "Global Audit Trail",
            "admin.company_global_history_header": "Global History Audit Trail",
            "admin.company_domain_remove": "Remove",
            "admin.company_domain_remove_title": "Remove Domain",
            "admin.company_domains": "Internal Domains",
            "admin.company_domain_add": "+ Domain",
            "admin.company_domain_add_title": "Add Internal Domain",
            "admin.company_homepage_text": "Homepage Text",
            "admin.identity_header": "Identity",
            "admin.identity_company_name": "Company Name",
            "admin.identity_company_contact": "Technical Contact",
            "admin.security_header": "Security",
            "admin.security_sso_header": "Single Sign-On Configuration",
            "admin.security_sso_description":
                "Select the authentication services you wish to allow users to log in with.",
            "admin.security_saml_providers": "SAML Provider:",
            "admin.security_provider_okta": "Okta",
            "admin.security_provider_pingfed": "PingFed",
            "admin.security_oauth_providers": "OAuth Provider(s):",
            "admin.security_provider_google": "Google",
            "admin.security_provider_microsoft": "Microsoft",
            "admin.security_configure_header_google": "Configure Google Sign-On",
            "admin.security_configure_header_microsoft": "Configure Microsoft Sign-On",
            "admin.security_configure_header_okta": "Configure SAML for Okta",
            "admin.secruity_configure_header_pingfed": "Configure SAML for PingFed",
            "admin.security_disable_google":
                "If you disable this provider, then you will need to add every external user that uses Google to login to your other providers.",
            "admin.security_disable_microsoft":
                "If you disable this provider, then you will need to add every external user that uses Microsoft to login to your other providers.",
            "admin.security_disable_okta":
                "If you disable this provider, then you will need to add every external user that uses Okta to login to your other providers.",
            "admin.security_disable_pingfed":
                "If you disable this provider, then you will need to add every external user that uses PingFed to login to your other providers.",
            "admin.security_enable_google": "Are you sure you want to allow authentication through Google?",
            "admin.security_enable_microsoft": "Are you sure you want to allow authentication through Microsoft?",
            "admin.security_multi_saml":
                "You may only have one SAML provider configured at a time. Please disable your other SAML provider before trying to configure a new one.",
            "admin.security_update_saml": "(Update Config)",
            "admin.security_saml": "SAML Configuration",
            "admin.security_saml_url": "SAML Metadata URL",
            "admin.security_saml_file": "SAML Metadata File",
            "admin.security_saml_url_label": "SAML URL:",
            "admin.security_saml_file_label": "Current SAML Metadata File:",
            "admin.security_saml_no_file": "No SAML Metadata file selected",
            "admin.security_saml_upload_file": "Upload File",
            "admin.security_domains_description":
                "Only users with email addresses in the following domains can access OrgWiki.",
            "admin.security_domain_name": "Domain Name",
            "admin.security_session_timeout": "Session Timeout",
            "admin.security_session_timeout_desc": "Set the maximum session time in minutes",
            "admin.branding_header": "Customize the look of OrgWiki to match your company's brand.",
            "admin.branding_sub_tab_header": "Subpage Navigation",
            "admin.branding_active_tab": "Active Tab",
            "admin.branding_inactive_tab": "Inactive Tab",
            "admin.branding_active_sub_tab": "Active sub-tab",
            "admin.branding_inactive_sub_tab": "Inactive sub-tab",
            "admin.branding_primary_button": "Primary Button",
            "admin.branding_primary_color": "Top Navigation Bar",
            "admin.branding_background_color": "Page Background",
            "admin.branding_highlight_color": "Highlights",
            "admin.branding_highlight_description": "Always on white background",
            "admin.branding_primary_button_color": "Primary Buttons",
            "admin.branding_primary_button_color_description": "Always has white text",
            "admin.branding_light_navbar": "Top Navigation Tabs",
            "admin.branding_light_navbar_on": "White",
            "admin.branding_light_navbar_off": "Dark Grey",
            "admin.branding_light_background": "Sub Tabs",
            "admin.branding_light_background_on": "White",
            "admin.branding_light_background_off": "Dark Grey",
            "admin.branding_company_logo": "Homepage Logo",
            "admin.branding_navbar_logo": "Navigation Bar Logo",
            "admin.branding_upload_new": "Upload New",
            "admin.branding_remove": "Remove",
            "admin.feed_header": "Company Feed Suspension",
            "admin.feed_description_header": "How does this work?",
            "admin.feed_description_p1":
                "Enable Company Feed Suspension to <1>temporarily hide org changes from the company feed</1>. Any changes to the org (New/Terminated Employees, Manager and Title) <3>will take effect</3>, but will be hidden on the company feed from non-admin employees. All org change notifications will also be suspended.",
            "admin.feed_description_p2":
                "This feature is useful when large or sensitive org changes are being made, to allow time for company communication before changes appear to all employees in the feed.",
            "admin.feed_description_p3":
                "Suspended feed items can be removed by admins while this feature is enabled. When the feature is disabled, any remaining feed items will be visible to all users, and notifications will be sent.",
            "admin.feed_description_p4":
                "<0>Note: </0>The feed can be retroactively suspended by scheduling a feed suspension to a date and time in the past. Any feed updates after that time will be hidden to non-admins.",
            "admin.feed_suspend_now": "Suspend Company Feed Now",
            "admin.feed_suspend_schedule_header": "Schedule Company Feed Suspension",
            "admin.feed_suspend_schedule": "Schedule Suspension",
            "admin.feed_suspend_scheduled_date":
                "<0>Company Feed is currently SCHEDULED to suspend</0> on {{date, date}})}",
            "admin.feed_suspend_cancel": "Cancel Scheduled Suspension",
            "admin.feed_suspended": "Company Feed is currently SUSPENDED",
            "admin.feed_resume_description":
                "All feed posts marked 'suspended' will be visible to all employees when resuming the Feed.",
            "admin.feed_resume": "Resume Company Feed",
            "admin.roles_header": "Roles",
            "admin.products_header": "Products",
            "admin.manager_feedback_header": "All Surveys",
            "notifications.count+": "{{count, number}}+",
            "notifications.count": "{{count, number}}",
            "notifications.read_error": "There was a problem with marking all notifications as read.",
            "notifications.empty": "Nothing new at this time.",
            "notifications.action_required": "Action Required",
            "notifications.notifications": "Notifications",
            "notifications.moment_year": "{{date, MMMM D, YYYY}}",
            "notifications.moment": "{{date, MMMM D}}",
            "notifications.moment_from": "{{date, fromNow}}",
            "notifications.read_all": "Mark All As Read",

            "instant_search.office_count": "{{count, number}} person in this office",
            "instant_search.office_count_other": "{{count, number}} people in this office",
            "instant_search.room_capacity": "{{count, number}} person capacity",
            "instant_search.shared_desk": "Shared Desk",
            "instant_search.conference_room": "Conference Room",
            "instant_search.members_everyone": "Everyone",
            "instant_search.members_more": "{{count, number}}+ members",
            "instant_search.members": "{{count, number}} member",
            "instant_search.members_other": "{{count, number}} members",
            "instant_search.skill_count": "{{count, number}} person with this $t(skill.label)",
            "instant_search.skill_count_other": "{{count, number}} people with this $t(skill.label)",
            "instant_search.skill_alias": "(Also Known as <1>{{match, text}}</1>)",
            "instant_search.all_groups": "View All Groups",
            "instant_search.all_skills": "View All $t(skill.label_other)",
            "instant_search.advanced_search": "Advanced People Search",
            "instant_search.placeholder": "Search OrgWiki",
            "instant_search.role_count": "{{count, number}} person in this role",
            "instant_search.role_count_other": "{{count, number}} people in this role",
            "instant_search.product_count": "{{count, number}} person works on this product",
            "instant_search.product_count_other": "{{count, number}} people work on this product",

            "loading...": "Loading...",
            "loading": "Loading",
            "cancel": "Cancel",
            "save": "Save",
            "save_changes": "Save Changes",
            "yes": "Yes",
            "confirm": "Confirm",
            "no": "No",
            "ok": "Ok",
            "saving...": "Saving...",
            "edit": "Edit",
            "update": "Update",
            "create": "Create",
            "delete": "Delete",
            "deleting...": "Deleting...",
            "disabled": "Disabled",
            "count": "{{count, number}}",

            "role.label": "Role",
            "role.label_other": "Start typing to select a role",
            "product.label": "Product",
            "product.label_other": "Products",
            "product.placeholder": "Start typing to select a product",
            "product.sublabel": "What product are you assigned to cover?",
            "skill.label": "Skill",
            "skill.label_other": "Skills",
            "skill.level1": "Beginner",
            "skill.level2": "Intermediate",
            "skill.level3": "Advanced",
            "skill.level4": "Expert",

            "profile_page.manager": "Manager",
            "profile_page.managers": "Managers",
            "profile_page.change_manager": "Change Manager",
            "profile_page.one_minute_ago": "1 minute ago",
            "profile_page.count_minutes_ago": "{{minuteDisplay, number}} minutes ago",
            "profile_page.an_hour_ago": "an hour ago",
            "profile_page.count_hours_ago": "{{hourDisplay, number}} hours ago",
            "profile_page.a_day_ago": "a day ago",
            "profile_page.count_days_ago": "{{dayDisplay, number}} days ago",
            "profile_page.count_weeks_ago": "{{weekDisplay, number}} weeks ago",
            "profile_page.count_months_ago": "{{monthDisplay, number}} months ago",
            "profile_page.count_years_ago": "{{yearDisplay, number}} years ago",
            "profile_page.updated_one_minute_ago": "Last updated 1 minute ago",
            "profile_page.updated_count_minutes_ago": "Last updated {{minuteDisplay, number}} minutes ago",
            "profile_page.updated_an_hour_ago": "Last updated an hour ago",
            "profile_page.updated_count_hours_ago": "Last updated {{hourDisplay, number}} hours ago",
            "profile_page.updated_a_day_ago": "Last updated a day ago",
            "profile_page.updated_count_days_ago": "Last updated {{dayDisplay, number}} days ago",
            "profile_page.updated_count_weeks_ago": "Last updated {{weekDisplay, number}} weeks ago",
            "profile_page.updated_count_months_ago": "Last updated {{monthDisplay, number}} months ago",
            "profile_page.updated_count_years_ago": "Last updated {{yearDisplay, number}} years ago",
            "profile_page.team": "Team",
            "profile_page.teams": "Teams",
            "profile_page.community": "Community",
            "profile_page.communities": "Communities",
            "profile_page.posts": "Posts",
            "profile_page.count_members": "{{count, number}} Members",
            "profile_page.direct_and_total_reports_count":
                "{{directReportsCount, number}} direct reports · {{descendantsCount, number}} total reports",
            "profile_page.direct_reports_count": "{{directReportsCount, number}} direct reports",
            "profile_page.reports": "Reports",
            "profile_page.profile_photo_title": "{{employeeFirstName, text}}'s profile photo",
            "profile_page.desk_location": "Desk Location",
            "profile_page.dlists_count": "Distribution Lists ({{dlistsCount, number}})",
            "profile_page.service_meeting": "{{serviceName, text}} Meeting",
            "profile_page.web_conference": "Web Conference",
            "profile_page.add_manager": "Add Manager",
            "profile_page.edit_manager": "Edit Manager",
            "profile_page.add_reports": "Add Reports",
            "profile_page.edit_reports": "Edit Reports",
            "profile_page.no_manager_or_reports": "No manager or reports",
            "profile_page.organization": "Organization",
            "profile_page.dotted_line_reports": "Dotted Line Reports",
            "profile_page.add_direct_report": "Add Direct Report",
            "profile_page.add_dotted_line_report": "Add Dotted-Line Report",
            "profile_page.direct_reports": "Direct Reports",
            "profile_page.copy_the_teams_email_addresses": "Copy the team's email addresses",
            "profile_page.name_plus_reports": "{{name, text}} + Direct Reports",
            "profile_page.name_plus_team": "{{name, text}} + Team",
            "profile_page.name_plus_managers": "{{name, text}} + Managers",
            "profile_page.name_plus_reports_employees": "{{name, text}} + Direct Reports Employees Only",
            "profile_page.name_plus_team_employees": "{{name, text}} + Team Employees Only",
            "profile_page.create_name_plus_reports_employees": "+ Create {{name, text}} + Reports Employees Only",
            "profile_page.create_name_plus_team_employees": "+ Create {{name, text}} + Team Employees Only",
            "profile_page.create_lists": "Create List",
            "profile_page.create_lists_other": "Create Lists",
            "profile_page.create_eo_lists": "+ Create Employee Only Lists for {{name, text}}",
            "profile_page.count_people": "{{count, number}} people",
            "profile_page.download_team_powerpoint": "Download Team Powerpoint",
            "profile_page.employee_will_be_notified": "{{name, text}} will be notified when changes are saved.",
            "profile_page.your_team_slides": "Your Team Slides download should begin in a moment.",
            "profile_page.preparing_team_slides": "Preparing Team Slides",
            "profile_page.copied_to_clipboard": "{{prefix, text}} for {{listText, text}} copied to clipboard",
            "profile_page.distribution_list": "Distribution list",
            "profile_page.count_email": "{{count, number}} email",
            "profile_page.count_email_other": "{{count, number}} emails",
            "profile_page.add_direct_manager": "Add Direct Manager",
            "profile_page.direct_manager": "Direct Manager",
            "profile_page.dotted_line_managers": "Dotted-Line Managers",
            "profile_page.employee_profile_only_percent_complete":
                "{{name, text}}'s profile is only {{percent, number}}% complete.",
            "profile_page.employee_profile_percent_complete":
                "{{name, text}}'s profile is {{percent, number}}% complete",
            "profile_page.your_profile_only_percent_complete": "Your profile is only {{percent, number}}% complete.",
            "profile_page.your_profile_percent_complete": "Your profile is {{percent, number}}% complete.",
            "profile_page.this_profile_only_percent_complete": "This profile is only {{percent, number}}% complete.",
            "profile_page.this_profile_percent_complete": "This profile is {{percent, number}}% complete.",
            "profile_page.hide_details": "Hide Details",
            "profile_page.show_details": "Show Details",
            "profile_page.add": "Add",
            "profile_page.phone_number": "Phone Number",
            "profile_page.profile_photo": "Profile Photo",
            "profile_page.upload_your_photo": "Upload your photo",
            "profile_page.add_a_number": "Add a mobile or office number",
            "profile_page.include_the_city": "Include the city where you work",
            "profile_page.location": "Location",
            "profile_page.fill_in_responsibilities": "Fill in your responsibilities",
            "profile_page.responsibilities": "Responsibilities",
            "profile_page.share_about_yourself": "Share a little about yourself",
            "profile_page.about": "About",
            "profile_page.you_have_more_things":
                "You have {{count, number}} more thing to do to complete your profile.",
            "profile_page.you_have_more_things_other":
                "You have {{count, number}} more things to do to complete your profile.",
            "profile_page.email_team": "Email Team",
            "profile_page.team_email_lists": "Team Email Lists",
            "profile_page.key": "Key:",
            "profile_page.create_new_skill": "Create new $t(skill.label): {{name, text}}",
            "profile_page.already_have_skill": "(You already have this $t(skill.label))",
            "profile_page.search_skills": "Search $t(skill.label_other)",
            "profile_page.add_new_skill": "Add a new $t(skill.label)",
            "profile_page.select_a_level": "Select a level:",
            "profile_page.add_skill": "Add $t(skill.label_other)",
            "profile_page.add_a_skill": "Add a $t(skill.label)",
            "profile_page.problem_saving_skills":
                "There was a problem saving your changes related to $t(skill.label_other)",
            "profile_page.no_skills": "{{name, text}} has not added any $t(skill.label_other)",
            "profile_page.remove_skill": "Remove $t(skill.label)",
            "profile_page.skill_max_size": "$t(skill.label_other) cannot be longer than 100 characters.",
            "edit_transfer_portal.managers_warning": "Managers will see your profile in the Transfer Portal",
            "edit_transfer_portal.removal_warning": "You will be removed from the Transfer Portal",

            "audit_trail.filter_start_date": "Start Date",
            "audit_trail.filter_end_date": "End Date",
            "audit_trail.export_date_limit": "You can only export up to three months of history at a time.",
            "audit_trail.export": "Export to CSV",
            "audit_trail.filter_title": "Audit Filter",
            "audit_trail.filter_all": "All Actions",
            "audit_trial.filter_profile": "Profile Updates",
            "audit_trail.hide_history": "Hide History",
            "audit_trail.view_history": "View History",
            "audit_trail.profile_history": "Profile History",
            "audit_trail.in_memoriam_history": "In Memoriam History",
            "audit_trail.object": "Object",
            "audit_trail.entry": "Entry",
            "audit_trail.name": "Name",
            "audit_trail.modified_date": "Modified Date",
            "audit_trail.modified_by": "Modified By",
            "audit_trail.action": "Action",
            "audit_trail.field": "Field",
            "audit_trail.field_name": "Field Name",
            "audit_trail.old_value": "Old Value",
            "audit_trail.new_value": "New Value",
            "audit_trail.date_value": "{{date, MM/DD/YYYY}}",
            "audit_trail.datetime_value": "{{date, MM/DD/YYYY hh:mm:ss A}}",
            "audit_trail.date": "{{date, MM/DD/YYYY hh:mm:ss A}}",
            "audit_trail.restricted_membership": "Restricted Membership",
            "audit_trail.is_active": "Is Active",
            "audit_trail.person": "Person",
            "audit_trail.title": "Title",
            "audit_trail.manager": "Manager",
            "audit_trail.email": "Email",
            "audit_trail.city_description": "City Description",
            "audit_trail.first_name": "First Name",
            "audit_trail.last_name": "Last Name",
            "audit_trail.external_id": "External ID",
            "audit_trail.start_date": "Start Date",
            "audit_trail.mobile_phone": "Mobile Phone",
            "audit_trail.text": "Text",
            "audit_trail.skill_level": "Skill Level",
            "audit_trail.office_phone": "Office Phone",
            "audit_trail.city": "City",
            "audit_trail.country": "Country",
            "audit_trail.office_type": "Office Type",
            "audit_trail.state": "State",
            "audit_trail.responsibilities": "Responsibilities",
            "audit_trail.profile_photo": "Profile Photo",
            "audit_trail.location_address": "Location Address",
            "audit_trail.location_type": "Location Type",
            "audit_trail.about": "About",
            "audit_trail.post_text": "Post Text",
            "audit_trail.is_contractor": "Is Contractor",
            "audit_trail.is_admin": "Is Admin",
            "audit_trail.can_create_company_mentions": "Can Create Company Mentions",
            "audit_trail.balloons": "Balloons",
            "audit_trail.photo": "Photo",
            "audit_trail.synonym_of": "Synonym Of",
            "audit_trail.external_email": "External Email",
            "audit_trail.story_text": "Story Text",
            "audit_trail.out_of_office_message": "Out Of Office Message",
            "audit_trail.out_of_office": "Out of Office",
            "audit_trail.ip_address": "IP Address",
            "audit_trail.role": "Role",
            "audit_trail.description": "Description",
            "audit_trail.about_me": "About Me",
            "audit_trail.nested_group": "Nested Group",
            "audit_trail.invite_only": "Invite Only",
            "audit_trail.what_am_i_doing_this_quarter": "What am I doing this Quarter",
            "audit_trail.my_responsibilities": "My Responsibilities",
            "audit_trail.office_location": "Office Location",
            "audit_trail.is_private": "Is Private",
            "audit_trail.linkedin_url": "LinkedIn URL",
            "audit_trail.is_locked": "Is Locked",
            "audit_trail.projects_past_and_present": "Projects - Past and Present",
            "audit_trail.is_read_only": "Is Read Only",
            "audit_trail.what_i_am_responsible_for": "What I am Responsible For",
            "audit_trail.nick_name": "Nick Name",
            "audit_trail.primary_location": "Primary Location",
            "audit_trail.area": "Area",
            "audit_trail.zoom_url": "Zoom URL",
            "audit_trail.created_on_mobile": "Created On Mobile",
            "audit_trail.who_can_post": "Who Can Post",
            "audit_trail.slack_name": "Slack Name",
            "audit_trail.mattermost_username": "Mattermost Username",
            "audit_trail.mattermost_team": "Mattermost Team",
            "audit_trail.slack_team": "Slack Team",
            "audit_trail.dotted_line_manager": "Dotted Line Manager",
            "audit_trail.owners": "Owners",
            "audit_trail.products": "Products",
            "audit_trail.additional_contact_methods": "Additional Contact Methods",
            "audit_trail.skype_name": "Skype Name",
            "audit_trail.key_accounts": "Key Accounts",
            "audit_trail.what_am_i_responsible_for": "What I am Responsible For",
            "audit_trail.primary_location_floor_plan": "Primary Location - Desk Location",
            "audit_trail.is_owner": "Is Owner",
            "audit_trail.secondary_location_address": "Secondary Location Address",
            "audit_trail.desk_location": "Desk Location",
            "audit_trail.display_address": "Display Address",
            "audit_trail.official_office_distribution_list": "Official Office Distribution List",
            "audit_trail.prior_start_date": "Prior Start Date",
            "audit_trail.overview_text": "Overview Text",
            "audit_trail.conference_room": "Conference Room",
            "audit_trail.expiration_date": "Expiration Date",
            "audit_trail.type": "Type",
            "audit_trail.conference_room_floor_plan": "Conference Room Floor Plan",
            "audit_trail.official_distribution_list": "Official Distribution List",
            "audit_trail.company_logo": "Company Logo",
            "audit_trail.self_signup_enabled": "Self Signup Enabled",
            "audit_trail.conference_room_capacity": "Conference Room Capacity",
            "audit_trail.alternate_address": "Alternate Address",
            "audit_trail.configuration_mode_enabled": "Pre-launch Mode",
            "audit_trail.webex": "WebEx",
            "audit_trail.secondary_location": "Secondary Location",
            "audit_trail.primary_address": "Primary Address",
            "audit_trail.prior_company_name": "Prior Company Name",
            "audit_trail.membership": "Membership",
            "audit_trail.authorizer_email": "Authorizer Email",
            "audit_trail.guest_email": "Guest Email",
            "audit_trail.guest_last_name": "Guest Last Name",
            "audit_trail.guest_first_name": "Guest First Name",
            "audit_trail.guest_company_name": "Guest Company Name",
            "audit_trail.tags_enabled": "Tags Enabled",
            "audit_trail.company_posts_enabled": "Company Posts Enabled",
            "audit_trail.social_groups_enabled": "Social Posts Enabled",
            "audit_trail.is_early_adopter": "Is Early Adopter",
            "audit_trail.wiki_profile_edits_enabled": "Wiki Profile Edits Enabled",
            "audit_trail.conference_room_description": "Conference Room Description",
            "audit_trail.technical_contact": "Technical Contact",
            "audit_trail.navbar_logo": "Navbar Logo",
            "audit_trail.dist_lists_admin_only": "Distribution Lists Admin Only",
            "audit_trail.seating_chart": "Floor Plan",
            "audit_trail.primary_color": "Primary Color",
            "audit_trail.dotted_lines_enabled": "Dotted Lines Enabled",
            "audit_trail.active_by_default": "Active By Default",
            "audit_trail.section_type": "Section Type",
            "audit_trail.intended_for": "Intended For",
            "audit_trail.sort_order": "Sort Order",
            "audit_trail.update_interval": "Update Interval",
            "audit_trail.revoked": "Revoked",
            "audit_trail.company": "Company",
            "audit_trail.okta_metadata_url": "Okta Metadata URL",
            "audit_trail.profile_celebrations_enabled": "Profile Celebrations Enabled",
            "audit_trail.highlight_color": "Highlight Color",
            "audit_trail.background_color": "Background Color",
            "audit_trail.secondary_location_floor_plan": "Secondary Location - Desk Location",
            "audit_trail.primary_button_color": "Primary Button Color",
            "audit_trail.company_logo_title": "Company Logo Title",
            "audit_trail.guest_passes_enabled": "Guest Passes Enabled",
            "audit_trail.timeline_moderation_datetime": "Timeline Moderation Datetime",
            "audit_trail.light_navbar": "Light Navbar",
            "audit_trail.champion_contact": "Champion Contact",
            "audit_trail.dist_lists_enabled": "Distribution Lists Enabled",
            "audit_trail.saml_metadata_url": "SAML Metadata URL",
            "audit_trail.company_logo_url": "Company Logo URL",
            "audit_trail.website": "Website",
            "audit_trail.dist_lists_admin_email": "Distribution Lists Admin Email",
            "audit_trail.dist_lists_domain": "Distribution Lists Domain",
            "audit_trail.billing_contact": "Billing Contract",
            "audit_trail.welcome_email_enabled": "Welcome Email Enabled",
            "audit_trail.sponsor_contact": "Sponsor Contact",
            "audit_trail.birthday_today": "Birthday Today",
            "audit_trail.secondary_contact": "Secondary Contact",
            "audit_trail.light_background": "Light Background",
            "audit_trail.primary_contact": "Primary Contact",
            "audit_trail.saml_metadata_file": "SAML Metadata File",
            "audit_trail.contest_winner": "Contest Winner",
            "audit_trail.alternate_email": "Alternate Email",
            "audit_trail.employees_manager": "<0>{{name, text}}'s</0> Manager",
            "audit_trail.employees_dotted_line_manager": "<0>{{name, text}}'s</0> Dotted Line Manager",

            "profile_edit_page.first_name": "First Name",
            "profile_edit_page.nickname": "Nickname",
            "profile_edit_page.last_name": "Last Name",
            "profile_edit_page.title": "Title",
            "profile_edit_page.contact_information": "Contact Information",
            "profile_edit_page.email": "Email",
            "profile_edit_page.office_phone": "Office Phone",
            "profile_edit_page.mobile_phone": "Mobile Phone",
            "profile_edit_page.messaging": "Messaging",
            "profile_edit_page.slack_team": "Slack Team",
            "profile_edit_page.slack_username": "Slack Username",
            "profile_edit_page.mattermost_team": "Mattermost Team",
            "profile_edit_page.mattermost_username": "Mattermost Username",
            "profile_edit_page.conferencing": "Conferencing",
            "profile_edit_page.location": "Location",
            "profile_edit_page.primary_work_location": "Primary Work Location",
            "profile_edit_page.home": "Home",
            "profile_edit_page.office": "Office",
            "profile_edit_page.unknown": "Unknown",
            "profile_edit_page.where_do_you_work": "Where do you normally work when not traveling?",
            "profile_edit_page.edit_desk_location": "Edit Desk Location",
            "profile_edit_page.secondary_work_location": "Secondary Work Location",
            "profile_edit_page.social": "Social",
            "profile_edit_page.profile_url": "Profile URL",
            "profile_edit_page.locate_mine": "Locate Mine",
            "profile_edit_page.other_contact_info": "Other Contact Info",
            "profile_edit_page.other_contact_info_description":
                "Preferred method of communication? Other communication channels? Office/cubicle location?",
            "profile_edit_page.admin_fields": "Admin Fields",
            "profile_edit_page.active_employee": "Active Employee",
            "profile_edit_page.start_date": "Start Date",
            "profile_edit_page.prior_company_name": "Prior Company name",
            "profile_edit_page.prior_start_date": "Prior Start Date",
            "profile_edit_page.prior_start_date_hover":
                "If the employee is from an acquired company, this is the date they started at that company.",
            "profile_edit_page.admin": "Admin",
            "profile_edit_page.contractor": "Contractor",
            "profile_edit_page.external_id": "External ID",
            "profile_edit_page.announcements": "Announcements",
            "profile_edit_page.balloons": "Balloons",
            "profile_edit_page.show_sensitive_profile_contact_info": "Show Contact Information on Profile",
            "profile_edit_page.is_board_of_directors": "Board of Directors",
            "profile_edit_page.last_modified_date": "Last Modified Date",
            "profile_edit_page.last_modified_by": "Last Modified By",
            "profile_edit_page.profile_photo": "Profile photo",
            "profile_edit_page.customize_custom_sections": "Customize Custom Sections",
            "profile_edit_page.meeting_url": "Meeting URL",
            "profile_edit_page.name": "Name",
            "profile_edit_page.personal_room_url": "Personal Room URL",
            "profile_edit_page.address": "Address",
            "profile_edit_page.set_desk_location": "Set Desk Location",
            "profile_edit_page.early_adopter": "Early Adopter",
            "profile_edit_page.deleting...": "Deleting...",
            "profile_edit_page.delete_profile": "Delete Profile",
            "profile_edit_page.saving...": "Saving...",
            "profile_edit_page.save_and_add_another": "Save and Add Another",
            "profile_edit_page.active?": "Active?",
            "profile_edit_page.intended_for": "Intended For",
            "profile_edit_page.no_sections": "No sections have been created yet",
            "profile_edit_page.select_custom_sections":
                "Select the sections everyone will see for <1>{{fullName, text}}</1>",
            "profile_edit_page.mark_inactive_warning":
                "Marking an employee as inactive will reassign any direct reports to their manager and then clear their manager assignment.",
            "profile_edit_page.mark_active_warning":
                "Reactivating an employee will restore prior community and distribution list membership.",
            "profile_edit_page.mark_active_note": "Note - you will need to set their manager.",
            "profile_edit_page.early_adopter_warning":
                "This setting only applies while Orgwiki is in pre-launch mode. Early adopters will receive OrgWiki product emails and in-app notifications",
            "profile_edit_page.nickname_prompt": "Would you like to set your nickname to {{suggestedNickName, text}}?",
            "profile_edit_page.delete_profile_warning":
                "Are you sure you want to delete this profile? Deleting a profile also deletes any posts or comments made by the user. Any actions the user took will still be shown in the audit trail.",
            "profile_edit_page.processing_error":
                "There was a problem processing your request. Code: {{status, number}}, {{statusText, text}}",
            "profile_edit_page.inactive": "Inactive",
            "profile_edit_page.edit_profile_sections": "Edit Profile Sections",
            "profile_edit_page.save_changes": "Save Changes",
            "profile_edit_page.save_location_and_continue_prompt":
                "You must save your location before you can set your desk location. Would you like to save your profile edits and continue?",
            "profile_edit_page.employee_not_active_warning":
                "Warning: this employee is not currently active. Non-admin users will not be able to view this employee record.",
            "profile_edit_page.select_one": "Select One",
            "profile_edit_page.remote": "Remote",
            "profile_edit_page.none": "None",
            "profile_edit_page.enter_a_city": "Enter a city",
            "profile_edit_page.edit_employee_profile": "Edit {{firstName, text}}, {{lastName, text}}'s Profile",
            "profile_edit_page.add_manager": "Add Manager",
            "profile_edit_page.mattermost_team_tooltip":
                "Must be the fully qualified domain e.g. companyname.mattermost.com",
            "profile_edit_page.mattermost_username_tooltip": "Mattermost's @mention name is case sensitive e.g. JoeSmith",
            "profile_edit_page.edit_profile_header": "Edit <1>{{firstName, text}} {{lastName, text}}'s</1> Profile",
            "profile_edit_page.upload_photo": "Upload Photo",
            "profile_edit_page.use_linkedin_photo": "Use LinkedIn Photo",
            "profile_edit_page.use_office_365_photo": "Use Office 365 Photo",
            "profile_edit_page.use_gsuite_photo": "Use G Suite Photo",
            "profile_edit_page.users_will_be_notified": "Users will be notified of any changes made to their profile.",
            "profile_edit_page.profile_sections_updated": "Profile sections updated",
            "profile_edit_page.problem_processing_request":
                "There was a problem processing your request. Code: {{status, number}} {{statusText, text}}, error: {{errorText, text}}",
            "profile_edit_page.employee_created": "Employee {{name, text}} created",
            "profile_edit_page.error": "Error: {{errorText, text}}",
            "profile_edit_page.city": "City",
            "profile_edit_page.primary_location": "Primary Location",
            "profile_edit_page.search_offices": "Search Offices...",
            "profile_edit_page.search_cities": "Enter a city...",
            "profile_edit_page.add_employee": "Add Employee",
            "locations.all_offices": "All Offices",
            "locations.inactive_offices": "Inactive Offices",
            "locations.navigate_to": "Navigate to:",
            "locations.new_office": "New Office",
            "locations.offices_caps": "OFFICES",
            "locations.search_offices": "Search Offices",
            "locations.no_results_found": "No results found.",
            "locations.no_offices": "No Offices",
            "locations.office": "Office",
            "locations.offices": "Offices",
            "locations.location": "Location",
            "locations.people": "People",
            "locations.actions": "Actions",
            "locations.people_count": "{{count, number}} <1>Person</1>",
            "locations.people_count_other": "{{count, number}} <1>People</1>",
            "locations.done": "Done",
            "locations.office_overview": "Office Overview",
            "locations.floor_plans": "Floor Plans",
            "locations.conference_rooms": "Conference Rooms",
            "locations.history": "History",
            "locations.edit_office": "Edit Office",
            "locations.office_people_count": "<0>{{count, number}} person</0> in this office",
            "locations.office_people_count_other": "<0>{{count, number}} people</0> in this office",
            "locations.secondary_office_people_count": "{{count, number}} person has this office as a secondary location",
            "locations.secondary_office_people_count_other":
                "{{count, number}} people have this office as a secondary location",
            "locations.workanywhere_office_people_count": "{{count, number}} person works primarily from this office",
            "locations.workanywhere_office_people_count_other":
                "{{count, number}} people work primarily from this office",
            "locations.home_office_people_count":
                "{{count, number}} person works primarily from home and has this as their office",
            "locations.home_office_people_count_other":
                "{{count, number}} people work primarily from home and have this as their office",
            "locations.office_is_inactive": "Office is Inactive",
            "locations.office_inactive_couple_options":
                "This office is inactive and only visible to Admins. There are a couple of options:",
            "locations.make_office_visible":
                "This will make the office visible to everyone and make it available for users to join",
            "locations.duplicate_office_merge": "If this office is a duplicate of an existing office, merge them!",
            "locations.merge_this_office": "Merge this office",
            "locations.set_office_to_active": "Set office to Active",
            "locations.edit_information": "Edit Information",
            "locations.office_information_not_provided": "Office information has not been provided.",
            "locations.consider_adding_information":
                "Consider adding helpful information here, such as office contacts, Wi-Fi passwords, special instructions, and anything else that might be helpful to someone visiting this office.",
            "locations.information": "Information",
            "locations.new_community": "New {{communitySynonym, text}}",
            "locations.new_room": "New Room",
            "locations.no_conference_rooms": "There are no conference rooms",
            "locations.search_rooms": "Search Rooms",
            "locations.name": "Name",
            "locations.capacity": "Capacity",
            "locations.this_office_empty": "This office is empty.",
            "locations.distribution_list": "Distribution List",
            "locations.include_secondary_office_members": "Include Secondary Office Members",
            "locations.title": "Title",
            "locations.type": "Type",
            "locations.primary_location": "Primary Location",
            "locations.secondary": "Secondary",
            "locations.primary": "Primary",
            "locations.search_members": "Search Members",
            "locations.something_went_wrong_dlist": "Something went wrong when creating the Official Distribution List",
            "locations.primary__required": "Primary address is a required field",
            "locations.unexpected_update_error": "We encountered an unexpected error when updating your office.",
            "locations.unexpected_create_error": "We encountered an unexpected error when creating your office.",
            "locations.unexpected_floor_plan_error": "An error occured while saving the floor plans. Please try again.",
            "locations.cant_delete_location":
                "You can't delete {{name, text}} while there are still people associated with it.",
            "locations.are_you_sure_delete_location": "Are you sure you want to delete {{name, text}}?",
            "locations.add_floor_plan": "Add Floor Plan",
            "locations.edit_floor_plan": "Edit Floor Plan",
            "locations.edit_address": "Edit Address",
            "locations.add_address": "Add Address",
            "locations.move_up": "Move Up",
            "locations.office_name": "Office Name",
            "locations.status": "Status",
            "locations.active": "Active",
            "locations.inactive_hidden": "Inactive (Hidden)",
            "locations.office_photo": "Office Photo",
            "locations.official_distribution_list": "Official Distribution List",
            "locations.official_distribution_list_explanation":
                "Orgwiki displays the official list prominently so users know they should use it to communicate with people in the office.",
            "locations.change_official_distribution_list": "Change Official Distribution List",
            "locations.set_official_distribution_list": "Set Official Distribution List",
            "locations.remove": "Remove",
            "locations.no_official_distribution_list_set": "No official distribution list set",
            "locations.primary_address": "Primary Address",
            "locations.primary_address_explanation": "The primary address is used for searching people.",
            "locations.merge_with_another_office": "Merge with another office...",
            "locations.add_primary_address": "Add primary address",
            "locations.no_primary_address_set": "No primary address set",
            "locations.display_address": "Display Address",
            "locations.display_address_explanation": "This is how offices address will be displayed within OrgWiki.",
            "locations.address_variations": "Address Variations",
            "locations.address_variations_explanation":
                "OrgWiki uses these addresses to match people to this office (so they will match the right office even if there is typo, or is incomplete, etc.).",
            "locations.add_address_variation": "Add address variation",
            "locations.no_address_variations": "No Address variations",
            "locations.no_floor_plans": "No floor plans",
            "locations.delete_office": "Delete Office",
            "locations.floor_plan_name": "Floor Plan Name",
            "locations.name_is_required": "Name is required",
            "locations.image_format": "Image (.jpg or .png format)",
            "locations.select_image": "Select image...",
            "locations.image_is_required": "Image is required",
            "locations.loc_merged_into_dest": "{{location, text}} merged into {{destination, text}}",
            "locations.merge_warning":
                "Merging will reassign all people in the source office to the destination office, add the source office address as an alternative address on the destination, and delete the source office. Floor plans and conference rooms in the source office will not be preserved.",
            "locations.dlist_member_warning":
                "Distribution lists that include the source office as a member will be updated to include the destination office as a member.",
            "locations.merge": "Merge",
            "locations.address_search": "Address Search",
            "locations.street_address": "Street Address",
            "locations.city": "City",
            "locations.state_province": "State/Province",
            "locations.country": "Country",
            "locations.save_previous_address": "Save previous address as an alternate",
            "locations.found_matching_address": "We've found a matching address to what you entered.",
            "locations.recommend_this_version": "We recommend using this version:",
            "locations.recommended_address": "Recommended address:",
            "locations.use_recommended_address": "Use recommended address",
            "locations.what_you_entered": "What you entered:",
            "locations.use_this_address": "Use this address",
            "locations.go_back": "Go Back",
            "locations.address_entered_exists": "Address entered already exists!",
            "locations.existing_address": "Existing Address:",
            "locations.similar_address_found": "<0>We found a similar address to what you entered.</0> Do you want to proceed?",
            "locations.address_you_entered": "Address You Entered:",
            "locations.similar_existing_address": "Similar Existing Address:",
            "locations.add_address_anyways": "Add this address anyways",
            "locations.select_an_existing_list": "Please select an existing list.",
            "locations.enter_an_email_address": "Please enter an email address.",
            "locations.enter_a_name": "Please enter a name.",
            "locations.specify_an_owner": "Please specify an owner.",
            "locations.list_already_exists": "A list with this email already exists.",
            "locations.remove_user_from_group_list": "Remove <1>{{count, number}} user</1> from <4>{{group, text}}</4> since they are also a member of nested lists",
            "locations.remove_user_from_group_list_other":
                "Remove <1>{{count, number}} users</1> from <4>{{group, text}}</4> since they are also members of nested lists",
            "locations.remove_user_from_group_office":
                "Remove <1>{{count, number}} user</1> from <4>{{group, text}}</4> since they are also a member of the office",
            "locations.remove_user_from_group_office_other":
                "Remove <1>{{count, number}} users</1> from <4>{{group, text}}</4> since they are also members of the office",
            "locations.remove_list_from_group":
                "Remove <1>{{count, number}} list</1> from <4>{{group, text}}</4> since it is also a member of nested lists",
            "locations.remove_list_from_group_other":
                "Remove <1>{{count, number}} lists</1> from <4>{{group, text}}</4> since they are also members of nested lists",
            "locations.remove_nested_list_from_group":
                "Remove <1>{{count, number}} nested list</1> from <4>{{group, text}}</4> since it is also a member of nested list <6>{{name, text}}</6>",
            "locations.remove_nested_list_from_group_other":
                "Remove <1>{{count, number}} nested lists</1> from <4>{{group, text}}</4> since they are also members of nested list <6>{{name, text}}</6>",
            "locations.create_new_distribution_list": "Create new distribution list",
            "locations.use_existing_distribution_list": "Use existing distribution list",
            "locations.distribution_list_name": "Distribution List Name",
            "locations.email_address": "Email Address",
            "locations.email": "Email",
            "locations.who_can_join": "Who can join (other than Office Members)?",
            "locations.new_members_must_be_approved": "New members must be approved",
            "locations.anyone_can_join": "Anyone can join",
            "locations.selected_owner": "Selected Owner",
            "locations.select_a_different_owner": "Select a different owner",
            "locations.select_an_owner": "Select an Owner",
            "locations.search_people": "Search people",
            "locations.select_distribution_list": "Select Distribution List",
            "locations.search_existing_dlists": "Search existing distribution lists",
            "locations.select_a_different_list": "Select a different list",
            "locations.duplicates_found": "<0>Duplicates Found!</0> List cleanup suggestions:",
            "locations.removing_duplicates_membership":
                "Removing duplicates does not change the membership of {{name, text}}.",
            "locations.eliminating_redundancies":
                "Eliminating redundancies will help maintain <2>{{name, text}}</2> manual membership changes and errors.",
            "locations.create_official_distribution_list": "Create Official Distribution List",
            "locations.ie_first_floor": "ie 'First Floor'",
            "locations.select_local_community": "Select your local community on the <1>local community page</1>.",

            "floor_plans.select_a_floor_plan": "Select a floor plan:",
            "floor_plans.no_floor_plans": "No floor plans.",
            "floor_plans.edit_floor_plan": "Edit floor plan",
            "floor_plans.loading_floor_plan": "Loading floor plan",
            "floor_plans.create_new_room": "Create new room {{placeholder, text}}",
            "floor_plans.add_person": "Add person",
            "floor_plans.add_person_colon": "Add person:",
            "floor_plans.add_room": "Add room or desk",
            "floor_plans.add_conference_room": "Add conference room or shared desk",
            "floor_plans.create_shared_desk_note": "Note: To create a shared desk, include \"desk\" in the name above.",
            "floor_plans.employee_not_in_this_city":
                "Employee is not in this city, are you sure you want to add them to this seating chart?",
            "floor_plans.search_people": "Search people...",
            "floor_plans.select_room": "Select or create a room (or desk):",
            "floor_plans.search_rooms": "Search rooms...",
            "floor_plans.optional": "Optional",
            "floor_plans.description": "Description",
            "floor_plans.capacity": "Capacity",
            "floor_plans.room_name": "Room Name",

            "dlists.distribution_lists_caps": "DISTRIBUTION LISTS",
            "dlists.my_distribution_lists": "My Distribution Lists",
            "dlists.all_distribution_lists": "All Distribution Lists",
            "dlists.distribution_lists": "Distribution Lists",
            "dlists.custom_distribution_lists": "Custom Distribution Lists",

            "feed.tab_all_updates": "All <1>Updates</1>",
            "feed.tab_posts": "Posts",
            "feed.tab_jobs": "Jobs",
            "feed.tab_manager_changes": "Manager <1>Changes</1>",
            "feed.tab_title_changes": "Title <1>Changes</1>",
            "feed.tab_manager_or_title_changes": "Manager & Title <1>Changes</1>",
            "feed.tab_new_employees": "New <1>Employees</1>",
            "feed.show_more": "Show more activity",
            "feed.no_posts": "There are no posts yet. Share something today.",
            "feed.no_jobs": "No job posts to display",
            "feed.no_manager_changes": "No manager changes to display.",
            "feed.no_manager_or_title_changes": "No manager or title changes to display.",
            "feed.no_title_changes": "No title changes to display.",
            "feed.no_new_hires": "No new hires to display.",
            "feed.no_tles": "No timeline entries to display.",

            "tle.delete_tle_error": "There was a problem deleting this timeline entry",
            "tle.like_post": "Like this Post",
            "tle.unlike_post": "Unlike this Post",
            "tle.share_post": "Share this post",
            "tle.copy_paste_link": "Copy and paste the link above to share this post.",
            "tle.copy_link": "Copy Link",
            "tle.link_copied": "Link copied.",
            "tle.copy": "Copy",
            "tle.comment_placeholder": "Write a comment...",
            "tle.delete_confirm": "Are you sure you want to delete this entry?  Any comments will also be deleted.",
            "tle.delete_comment_confirm": "Are you sure you want to delete this comment?",
            "tle.delete_comment_error": "There was a problem deleting this comment",
            "tle.back_to_feed": "Back to Feed",
            "tle.now_reports_to": "Now reports to <1></1>",
            "tle.now_dotted_line_reports_to": "Now dotted-line reports to <1></1>",
            "tle.title_is_now": "Title is now {{title, text}}",
            "tle.joins_as": "Joins as {{title, text}}",
            "tle.has_left": "Has left the company - was {{title, text}}",
            "tle.one_liker": "<0></0> liked this",
            "tle.two_likers": "<0></0>, <2></2> liked this",
            "tle.three_likers": "<0></0>, <2></2>, <4></4> liked this",
            "tle.many_likers": "<0></0>, <2></2>, <4></4> and <6>{{otherLikersStr, text}}</6> liked this",
            "tle.other_likers_count": "{{count, number}} other",
            "tle.other_likers_count_other": "{{count, number}} others",
            "tle.you": "You",

            "tle.view_count": "{{count, number}} View",
            "tle.view_count_other": "{{count, number}} Views",
            "tle.like": "Like",
            "tle.like_other": "Likes",
            "tle.like_count": "{{count}} Like",
            "tle.like_count_other": "{{count}} Likes",
            "tle.comment": "Comment",
            "tle.comment_other": "Comments",
            "tle.comment_count": "{{count}} Comment",
            "tle.comment_count_other": "{{count}} Comments",
            "tle.commenter_title_inactive_annotation": "(Inactive) ",

            "edit_tle.title": "Edit post text",
            "edit_tle.job_post": "Job Post",
            "edit_tle.optional_post_text_placeholder": "Please write a brief caption before you post.",
            "edit_tle.required_post_text_placeholder": "Write a caption (required)",
            "edit_tle.optional_story_text_placeholder": "Add a story (optional)",
            "edit_tle.losing_photo_for_job_post":
                "The photo in this post will be removed if it is updated to be a Job post",
            "edit_tle.losing_video_for_job_post":
                "The video in this post will be removed if it is updated to be a Job post",

            "new_post.post_placeholder": "Share an update with the company",
            "new_post.story_placeholder": "Add a story",
            "new_post.drag_photo_video": "Drag photo or video here to upload.",
            "new_post.upload_photo_video": "Add photo or video",
            "new_post.drag_photo": "Drag photo here to upload.",
            "new_post.upload_photo": "Add photo",
            "new_post.remove_photo": "Remove Photo",
            "new_post.remove_video": "Remove Video",
            "new_post.posting...": "Posting...",
            "new_post.post": "Post",
            "new_post.job_post": "Job Post",
            "new_post.job_post_placeholder": "Add a story for this job",

            "advanced_search.refine_results": "Refine Results",
            "advanced_search.clear_filters": "Clear Filters",
            "advanced_search.title": "Title",
            "advanced_search.primary_location": "Primary Location",
            "advanced_search.title_other": "Titles",
            "advanced_search.remove_title_filter": "Remove Title Filter",
            "advanced_search.has_title": "Has Title",
            "advanced_search.remove_var_filter": "Remove {{tag, text}} filter",
            "advanced_search.office": "Office",
            "advanced_search.remove_location_filter": "Remove Location Filter",
            "advanced_search.home": "Home",
            "advanced_search.map_view": "Map View",
            "advanced_search.location": "Location",
            "advanced_search.located_in_office": "Located in office",
            "advanced_search.organization": "Organization",
            "advanced_search.team": "Team",
            "advanced_search.team_filter": "{{teamSynonym, text}} Filter",
            "advanced_search.remove_manager_filter": "Remove manager filter",
            "advanced_search.remove_city_filters": "Remove all city filters",
            "advanced_search.select_a_manager": "Select a manager",
            "advanced_search.browse_all_managers": "Browse All Managers",
            "advanced_search.group": "Group",
            "advanced_search.remove_group_filter": "Remove Group Filter",
            "advanced_search.has_group": "Has group",
            "advanced_search.less_than_ninety_days": "First 90 days",
            "advanced_search.less_than_one_year": "< 1 year",
            "advanced_search.between_one_and_two_years": "1-2 years",
            "advanced_search.between_two_and_three_years": "2-3 years",
            "advanced_search.between_three_and_four_years": "3-4 years",
            "advanced_search.between_four_and_five_years": "4-5 years",
            "advanced_search.greater_than_five_years": "> 5 years",
            "advanced_search.tenure": "Start Date",
            "advanced_search.show_matches_on": "Show Matches On",
            "advanced_search.in_facet": "In {{tag, text}}",
            "advanced_search.has_facet": "Has {{tag, text}}",
            "advanced_search.responsibilities": "Responsibilities",
            "advanced_search.about": "About",
            "advanced_search.groups": "Groups",
            "advanced_search.this_quarter": "This Quarter",
            "advanced_search.projects": "Projects",
            "advanced_search.key_account": "Key Account",
            "advanced_search.email": "Email",
            "advanced_search.mobile_phone": "Mobile Phone",
            "advanced_search.office_phone": "Office Phone",
            "advanced_search.secondary_location": "Secondary Location",
            "advanced_search.email_all": "Email All",
            "advanced_search.searching": "Searching...",
            "advanced_search.copy_email_addresses": "Copy email addresses of matching profiles",
            "advanced_search.search_error": "Sorry, there was an error searching for <i>{{searchText, text}}</i>",
            "advanced_search.search_results_count": "{{count, number}} matching profile",
            "advanced_search.search_results_count_other": "{{count, number}} matching profiles",
            "advanced_search.copied_to_clipboard": "{{count, number}} email copied to clipboard",
            "advanced_search.copied_to_clipboard_other": "{{count, number}} emails copied to clipboard",
            "advanced_search.count_parens": "({{count, number}})",
            "advanced_search.generic_match": "{{tag, text}} that match: {{query, text}}",
            "advanced_search.transfer_portal_match": "Transfer Portal: {{count, number}} matching profiles",
            "advanced_search.group_match": "Groups that contain: {{query, text}}",
            "advanced_search.transfer_description": "Interested In",
            "advanced_search.transfer_portal": "Transfer Portal",

            "stars_and_moons.tenure": "Employee for {{duration, text}}",
            "stars_and_moons.prior_tenure": "{{priorCompanyName, text}} employee for {{duration, text}}",

            "mentions.group_notify": "Notify everyone in {{name, text}}",
            "mentions.company_notify": "Notify everyone at {{name, text}}",

            "onboarding_welcome.highlights_title": "Welcome to OrgWiki, {{firstName, text}}!",
            "onboarding_welcome.highlights_subtitle":
                "<0>OrgWiki connects you with everyone else at {{companyName, text}}.</0> There's a lot to it, but here are some highlights:",
            "onboarding_welcome.highlight_directory":
                "<0>Company Directory</0> with profiles for everyone in the company",
            "onboarding_welcome.highlight_profiles":
                "<0>Detailed Profiles</0> of coworkers help you get to know them better.",
            "onboarding_welcome.highlight_dlists":
                "<0>Distribution Lists</0> built in, so you can see and manage all your lists.",
            "onboarding_welcome.highlight_offices": "<0>Office Information</0> with interactive floor plans.",
            "onboarding_welcome.highlight_search":
                "<0>Advanced Search</0> makes it easy to find coworkers based on name, title, location, interests, and more.",
            "onboarding_welcome.highlight_feed":
                "<0>Company Feed</0> lets you see recent updates to coworkers titles, new hires, and shared posts.",
            "onboarding_welcome.highlight_feed_non_social":
                "<0>Company Feed</0> lets you see recent updates to coworkers titles and new hires.",
            "onboarding_welcome.bottom_para_1":
                "<0>Everyone at {{companyName, text}} has their own profile</0> with their <2>Contact Information</2>, a <4>Photo</4>, details about their <6>Responsibilities</6>, a personal <8>About Me</8> description, and more.",
            "onboarding_welcome.bottom_para_2":
                "<0>Let's take just a few minutes to complete your profile. </0>We've already done most of the work, so this will be easy!",
            "onboarding_welcome.get_started": "Get Started!",
            "onboarding_welcome.remind_me_tom": "Remind me tomorrow",
            "onboarding_photo.add_photo": "Add a Photo",
            "onboarding_photo.photo_desc": "A good photo helps your coworkers recognize you and learn your name.",
            "onboarding_photo.remind_me_later": "Remind me later",
            "onboarding_photo.upload_photo": "Upload Photo",
            "onboarding_photo.uploading_photo": "Uploading Photo",
            "onboarding_photo.import_photo": "Already have a photo elsewhere?",
            "onboarding_photo.import_linkedin": "Use LinkedIn Photo",
            "onboarding_photo.import_gsuite": "Use G Suite Photo",
            "onboarding_photo.import_office365": "Use Office 365 Photo",
            "onboarding_responsibilities.save_changes": "Save My Changes",
            "onboarding_responsibilities.still_accurate": "This is still accurate",
            "onboarding_responsibilities.what_are_they": "What are Your Responsibilities?",
            "onboarding_responsibilities.update_them": "Update Your Responsibilities",
            "onboarding_responsibilities.still_your_role":
                "Does this still describe your current role and responsibilities?",
            "onboarding_responsibilities.describe_wyd": "Describe what you do at {{companyName, text}}",
            "onboarding_responsibilities.what_do_you_even_do":
                "What is your role? What do you specialize in? What accounts do you manage?",
            "onboarding.save_and_continue": "Save & Continue",
            "onboarding.remind_me_later": "Remind me later",
            "onboarding_responsibilities.others_responsibility": "{{name, text}}'s Responsibilities",
            "onboarding.need_inspiration": "Need some Inspiration?",
            "onboarding_about.title": "Tell Us About Yourself",
            "onboarding_about.help_coworkers": "Help your coworkers get to know you better.",
            "onboarding_about.ideas":
                "Where are you from? Where did you work before you joined {{companyName, text}}? What do you do on weekends?",
            "onboarding_about.others_about": "About {{name, text}}",
            "onboarding_mobile.title": "Thanks for keeping your profile updated!",
            "onboarding_mobile.in_your_pocket": "All of {{companyName, text}} in your pocket!",
            "onboarding_mobile.while_ur_here":
                "While you're here, would you like to install the OrgWiki mobile app on your phone?",
            "onboarding_mobile.get_mobile_app": "Get the Mobile App",
            "onboarding_mobile.must_have":
                "<0>The OrgWiki mobile app for iOS and Android is a must-have, </0>with great features such as:",
            "onboarding_mobile.contact_book": "<0>Contact book</0> with profiles for everyone in the company",
            "onboarding_mobile.name_game": "<0>Name Games</0> to help learn names and faces of coworkers (iOS).",
            "onboarding_mobile.callerid": "<0>Enhanced CallerID</0> lets you know when coworkers are calling.",
            "onboarding_mobile.floor_plans":
                "<0>Floor plans</0> of offices so you can find coworkers and conference rooms.",
            "onboarding_mobile.enter_your_number":
                "<0>Enter your mobile number</0> and we'll text you a download link:",
            "onboarding_mobile.send_link": "Send Download Link",
            "onboarding_mobile.continue": "Continue",
            "onboarding_mobile.finish_profile_setup": "Finish my profile setup",
            "onboarding_mobile.download_the_app": "Download the App:",
            "onboarding_profile_overlay.title": "Welcome to your profile!",
            "onboarding_profile_overlay.subtitle":
                "<0>You’re off to a good start!</0> From here we encourage you to review your profile and make sure everything is correct. You can edit this at any time.",
            "onboarding_profile_overlay.close_guide": "Close Welcome Guide",
            "onboarding_profile_overlay.things_you_can_do": "Here are some of the things you can do with OrgWiki:",
            "onboarding_profile_overlay.visit_feed_posts_enabled":
                "Visit the feed to see posts from your coworkers, new hires, manager changes, and more!",
            "onboarding_profile_overlay.visit_feed_posts_disabled":
                "Visit the feed to see new hires, manager changes, and more!",
            "onboarding_profile_overlay.search_coworkers":
                "Search for coworkers by name, title or any keyword on their profile",
            "onboarding_profile_overlay.join_communities": "Join some communities of common interests",
            "onboarding_profile_overlay.add_tag": "Add $t(skill.label_other) to your profile",
            "onboarding_profile_overlay.view_offices": "View information about {{companyName, text}} offices",
            "onboarding_profile_overlay.join_dlists": "Browse and join distribution lists",

            "language_picker.header": "Language Preference",

            "employee_badge.out_of_office": "Out of Office",

            "banners.get_started": "Get Started",
            "banners.claim_30_day_trial": "Like what you see? Claim your 30-day trial!",
            "banners.welcome_to_orgwiki": "Welcome to OrgWiki! Please remember to <1>complete your profile.</1>",
            "banners.launching_orgwiki_will_email":
                "<0>Launching OrgWiki</0> will clear news feed entries and send a welcome email to all provisioned users.",
            "banners.not_yet": "Not Yet",
            "banners.orgwiki_prelaunch_mode": "OrgWiki is in pre-launch mode.",
            "banners.launch_orgwiki": "Update <1>Launch Status</1> when you are ready to deploy it to all employees.",
            "banners.orgwiki_not_rolled_out": "OrgWiki has not yet been rolled out to all employees.",
            "banners.feed_suspended_on": "Org Chart Feed was suspended on {{date, MM/DD/YYYY hh:mm:ss A}}",
            "banners.feed_suspended_started":
                "Org Chart Feed will be suspended starting {{date, MM/DD/YYYY hh:mm:ss A}}",

            "footer.copyright": "Copyright ©2013—{{date, YYYY}} <2>Veeva</2>. All rights reserved.",
            "footer.about": "About OrgWiki",
            "footer.legal": "Legal",
            "footer.contact": "Contact Us",
            "field_value_blank": "This field may not be blank.",
            "invalid_email": "Enter a valid email.",
            "invalid_email_domain": "Email domain is not associated with this company.",
            "email_auth_mismatch": "Email does not match value used for authentication.",
            "invalid_img_format": "Invalid image format. Must be GIF, JPG, or PNG.",
            "adding_inactive_manager": "Cannot add inactive manager.",
            "circular_mgr_change":
                "Invalid Manager. {{manager, text}} cannot report to {{report, text}} because {{report, text}} reports to {{manager, text}}.",
            "adding_mgr_to_inactive_employees": "Cannot add manager to inactive employees.",
            "deleting_active_employees": "Only inactive employees can be deleted.",
            "invalid_json": "Value must be valid JSON.",
            "invalid_date_format": "Date has wrong format. Use one of these formats instead: YYYY-MM-DD.",
            "prior_start_date_must_precede_start_date": "Prior start date must be before current start date.",
            "invalid_choice": "'{{value, text}}' is not a valid choice.",
            "object_does_not_exist": "Object with {{slugName, text}}={{value, text}} does not exist.",
            "inactive_report": "Invalid Report. Reports must be active.",
            "inactive_manager": "Invalid Manager. Managers must be active.",
            "invalid_zoom_url": "Invalid zoom url.  Typically https://COMPANY.zoom.us/my/USERNAME",
            "invalid_webex_url": "Invalid webex url.  Typically https://COMPANY.webex.com/meet/USERNAME",
            "mgr_cannot_report_to_employee": "Invalid Report. Manager cannot report to employee.",
            "adding_dotted_reports_to_inactive_employee": "Cannot add dotted-line reports to inactive employees.",
            "adding_dotted_mgrs_to_inactive_employee": "Cannot add dotted-line managers to inactive employees.",
            "adding_reports_to_inactive_employee": "Cannot add reports to inactive employees.",
            "cant_add_this_dotted_report": "You cannot add {{full_name, text}} as a dotted line report.",
            "cant_add_this_dotted_mgr": "You cannot add %s as a dotted line manager.",
            "conf_room_name_conflict": "A conference room with this name already exists at this location.",
            "read_only_field_for_non_admins": "{{field_name, text}} is a read-only field for non-admins.",
            "read_only_field_once_set": "Read-only field once set.",
            "field_cannot_be_whitespace": "Field value cannot be entirely whitespace.",
            "coord_must_have_employee_or_conf_room": "Floor plan coordinate must have an employee or conference room.",
            "only_one_host_owuser_allowed": "There can only be one OWUser with type 'Host'.",
            "not_a_valid_dlist_member": "Not a valid member.",
            "email_required_for_lookup": "An email address is required for the lookup.",
            "group_member_requires_person_email_or_nested_group":
                "Exactly one of external_email, person, or nested_group must be set.",
            "mobile_disabled_for_company": "OrgWiki for Mobile is not currently enabled for {{company_name, text}}.",
            "inactive_admin_deleting_employees": "Only an active admin can delete employees.",
            "field_cannot_be_set_if_loc_type_is_office":
                "{{field_display, text}} cannot be set if location type is office.",
            "primary_and_secondary_locations_are_identical":
                "Primary location address and Seconday location address cannot be the same.",
            "permission_denied": "You do not have permission to perform this action.",
            "data_unavailable_so_back_off": "Data unavailable. Back off.",
            "multiple_employees_found_with_external_id":
                "Multiple employees found with {{field, text}} {{value, text}}.",
            "location_requires_an_address_param": "Location requires an 'addresses' parameter when being created.",
            "cannot_delete_address_with_person":
                "Cannot delete Address {{address, text}} when 1 person is still associated with it.",
            "cannot_delete_address_with_people":
                "Cannot delete Address {{address, text}} when {{count, number}} people are still associated with it.",
            "primary_address_is_required": "Primary address is a required field.",
            "this_location_contains_a_duplicate_address":
                "'{{location, text}}' contains duplicate address: {{address, text}}.",
            "location_has_too_many_primary_addresses":
                "'{{location, text}}' must have exactly 1 primary Address, but you set {{count, number}}.",
            "official_dlist_not_found": "Official Distribution list not found.",
            "name_already_taken": "This name is already taken.",
            "address_already_in_use_by_office":
                "The address \"{{address, text}}\" is already in use by Office \"{{office, text}}\".",
            "loc_cannot_use_address_from_another_loc":
                "'{{location1, text}}' cannot use the Office '{{location2, text}}' address: {{address, text}}.",
            "cant_find_address_for_this_location": "Cannot find Address related to: {{location_slug, text}}.",
            "address_must_have_at_least_one_field_set": "Address must have at least one field set.",
            "location_slug_required_in_patch_url": "Location slug name in URL required for PATCH.",
            "location_not_found_for_patch_request": "Location object required by PATCH not found: {{slug, text}}.",
            "location_slug_required_in_url_for_delete": "Location slug name in URL required for DELETE.",
            "location_query_cannot_have_slug_in_url": "Location query does not permit slug in URL: {{slug, text}}.",
            "cant_delete_loc_with_person":
                "Cannot delete {{location, text}} when 1 person is still associated with it.",
            "cant_delete_loc_with_people":
                "Cannot delete {{location, text}} when {{count, number}} people are still associated with it.",
            "cant_delete_non_office_location": "Cannot delete a non-office Location.",
            "location_not_found": "Location object not found: {{slug, text}}.",
            "unsupported_ordering_field": "Unsupported ordering field : {{field, text}}.",
            "couldnt_parse_input_data": "Could not parse input data:  {{error, text}}.",
            "floorplan_needs_image_file": "Received floorplan that did not have a corresponding image file.",
            "floorplan_not_found": "Could not find floorplan with {{lookup_field, text}} '{{lookup_value, text}}'.",
            "office_not_found_for_slug": "Office with slug {{slug, text}} not found.",
            "conf_room_not_found_for_slug": "Conference Room with slug {{slug, text}} not found.",
            "something_went_wrong": "Something went wrong.",
            "cant_pass_both_member_group_slug_and_public_id":
                "Cannot pass both \"member_group_slug\" and \"member_public_id\".",
            "group_not_found": "Group not found.",
            "group_not_found_for_slug": "Group not found for slug name : {{slug, text}}.",
            "employee_not_found_for_public_id": "Employee not found for public id : {{public_id, text}}.",
            "employee_not_found_for_lookup": "Employee not found for {{lookup_text, text}} : {{lookup_value, text}}.",
            "employee_not_found_for_email_and_company_id":
                "Can't find employee with email {{email, text}} and company id {{company_id, number}}.",
            "employee_not_found_for_public_ids": "Employees not found for public ids : {{public_ids, text}}.",
            "cant_delete_auto_dlists": "Auto Distribution Lists may not be deleted.",
            "cant_delete_group_unless_owner_or_admin": "Must be an owner or active admin to delete a Group.",
            "nested_group_requires_force_flag": "This group is nested, must use force flag.",
            "must_use_force_flag_to_delete_group": "Must use force flag to delete a group.",
            "must_specify_group_member_type": "Must specify a member type.",
            "group_slug_name_required": "Group slug name required.",
            "cant_modify_read_only_groups_in_ow":
                "Read Only groups may not have membership/ownership modified in OrgWiki.",
            "cant_modify_locked_groups_in_ow": "Locked groups may not have membership/ownership modified in OrgWiki.",
            "cant_modify_auto_dlists_in_ow":
                "Automatic distribution lists may not have membership/ownership modified in OrgWiki.",
            "email_or_group_exists_with_email": "Employee or Group already exists with that email.",
            "email_to_address_is_throttled":
                "We have already received three requests for that email address.  It may take a while for the email to arrive.  Please check your spam folder and check that {{email, text}} is correct.  If you still haven't received the email, contact your IT administrator.",
            "email_not_recognized_by_provider": "Email not recognized by provider.",
            "unknown_group_member_type": "Unknown group member type.",
            "identity_associated_with_request_unknown": "Identity associated with request unknown.",
            "dlist_member_not_found": "DList member not found.",
            "employee_lookup_id_required": "Employee lookup Id required.",
            "cant_add_group_to_itself": "Cannot add group to itself directly or indirectly.",
            "is_already_indirect_group_member": "Already an indirect member.",
            "post_to_add_group_owner_needs_public_id_url_param":
                "POST payload to add a group owner must include person=<public_id>.",
            "owner_must_already_be_group_member": "Owner must already be group member.",
            "must_be_owner_or_admin_to_add_owner": "Must be owner or admin to add owner.",
            "unknown_group_operation":
                "Unknown group operation: must add/remove members or owners {{operation, text}}.",
            "unknown_action": "Unknown action.",
            "registration_id_conflict": "This registration_id is already registered.",
            "must_have_permission_to_add_self_to_group": "Must be group owner, active admin, or permitted to add self.",
            "group_container_is_invite_only": "Group container is invite only.",
            "cant_nest_communities": "Cannot nest communities.",
            "cant_add_raw_emails_to_communities": "Cannot add raw emails to communities.",
            "cant_remove_last_group_owner": "Removing the last owner of a group is not allowed.",
            "post_to_add_group_member_must_have_only_one_related_id":
                "POST payload must contain exacly on of person=<public_id> or nested_group=<slug_name> or raw_email=<email>.",
            "product_not_found": "Product {{product, text}} not found.",
            "error_serializing_contentobjectrelatedfield":
                "Error serializing ContentObjectRelatedField: {{error, text}}.",
            "missing_param_for_user_notification_testing":
                "Missing 'public_id' or 'notification_type' or 'days_since_last_active'.",
            "notification_doesnt_exist_for_user": "Notification doesn't exist for user.",
            "cant_find_company_for_email_domain":
                "Sorry, we could not find a company associated with that email domain.",
            "company_not_configured_for_pingfed":
                "Sorry, it looks like your company, {{company_name, text}}, is not configured for PingFed authentication. If you believe this is a mistake, please contact your system administrator.",
            "company_not_configured_for_okta":
                "Sorry, it looks like your company, {{company, text}}, is not configured for Okta authentication. If you believe this is a mistake, please contact your system administrator.",
            "missing_request_data_param": "Missing request data parameter: {{param, text}.",
            "company_must_always_have_an_admin": "There must always be at least one admin in a company.",
            "cant_find_company_okta_cpc_error":
                "Sorry, we could not find a company for that email address.\n\nPlease click on the OrgWiki chiclet on your Okta home page and sign up for OrgWiki before you enable provisioning.",
            "cant_find_company_okta_error":
                "Sorry, we could not find a company associated with that email domain. If you are the first employee at your company to use OrgWiki and you wish to authenticate with Okta, please add OrgWiki to your Okta home page.",
            "group_doesnt_have_permissions_for_request":
                "Group {{slug, text}} does not have the right permissions for this request.",
            "cant_delete_last_domain_for_company":
                "Your company must always have at least 1 domain associated with it, please add another before removing this one.",
            "cant_delete_domain_because_dlists_are_using_it":
                "This domain is currently being used for distribution lists and cannot be removed.",
            "mentioned_object_not_found": "Mentioned object not found.",
            "user_not_allowed_to_create_mention":
                "User does not have permissions to create or edit mention object: {{mention_name, text}}.",
            "user_not_allowed_to_edit_or_create_mention":
                "User is not able to edit or create mention object: {{mention_name, text}} mention source.",
            "mention_data_incorrectly_formatted": "Mention data is incorrectly formatted.",
            "mention_placeholders_doesnt_match_data":
                "The number of mention placeholders in {{mentionable_field, text}} must match the number of mentions sent in {{mentionable_field_data_name, text}}.",
            "mention_object_must_be_a_django_model":
                "{{mention_supporting_object, text}} must be a django model object to implement MentionSupportingObject.",
            "destination_location_for_merge_not_found":
                "Destination Location object for merge not found: {{location, text}}.",
            "source_location_for_merge_not_found": "Source Location object for merge not found: {{location, text}}.",
            "cant_merge_active_office_into_inactive_office": "An active office cannot be merged into an inactive one.",
            "cant_modify_archived_service_account": "A Service Account becomes immutable once archived.",
            "cant_add_token_to_inactive_service_account": "Can't add token to inactive Service Account.",
            "only_office_locations_can_be_merged": "Currently, only Location objects of type OFFICE can be merged.",
            "must_be_active_owner_to_merge_locations": "Must be active admin to merge Location objects.",
            "invalid_ordering": "Invalid ordering.",
            "can_only_add_floorplans_to_office_locations": "Can only add floor plans to Locations of type OFFICE.",
            "max_floorplans_exceeded": "Max of {{count, number}} floorplans exceeded.",
            "conf_room_may_only_have_one_coordinate": "A conference room may only have one coordinate.",
            "dlist_config_operation_already_in_progress":
                "A distribution list configuration operation is already in progress.",
            "cant_edit_other_profiles": "You are not allowed to edit other profiles.",
            "only_admins_and_owners_can_import_dlist_members":
                "Only admins and owners can import distribution list members.",
            "must_include_file_with_xlsx_import": "Must include \"file\" parameter with import xlsx.",
            "import_removes_all_list_owners":
                "This import would remove all owners from this list. Please ensure at least 1 owner will remain in the import. If you wish to replace the owner with a new one, add them as an owner prior to the import.",
            "dlist_not_found": "DList not found.",
            "only_admins_can_export_nonmembers": "Only admins can export non-members.",
            "only_owners_and_admins_can_export_dlist": "Only owners and admins can export dlist membership.",
            "only_dlists_support_membership_import": "Only distribution lists support membership import.",
            "auto_dlists_dont_support_membership_import":
                "Automatic distribution lists do not support membership import.",
            "readonly_dlists_dont_support_membership_import":
                "Read-only distribution lists do not support membership import.",
            "dlists_for_entire_domain_dont_support_membership_import":
                "Distribution lists that contain everyone in the domain do not support membership import.",
            "list_is_already_being_imported":
                "An import for this list is already in progress.  Please wait for it to complete before trying another import.",
            "invalid_xlsx_file": "Please upload a valid Excel (.xlsx) file.",
            "unable_to_load_xlsx": "Unable to load Excel file.",
            "first_spreadsheet_row_must_contain_column_headers":
                "The first row in the spreadsheet must contain column headers.",
            "too_many_email_columns": "Please include only one email column.",
            "email_column_required":
                "Email Address column required. The spreadsheet must have one column with \"Email\" in the first row.",
            "email_column_empty":
                "The Email column is empty. You cannot import an empty list. (If you wish to delete the list, please do so by clicking Edit on the list's page, then clicking the Delete button.).",
            "skill_object_not_found_for_patch": "'Skill object for PATCH not found: {{slug_name, text}}'.",
            "skill_name_empty": "A non-empty 'name' parameter is required when creating a Skill.",
            "skills_cant_both_have_and_be_synonyms":
                "Skills cannot both have synonyms and be a synonym_of another skill.",
            "skill_synonym_does_not_exist": "Skill synonym_of slug_name does not exist: {{synonym, text}}.",
            "skill_max_length": "Skill cannot be longer than 100 characters.",
            "skill_name_conflict": "Conflict with existing Skill named: {{name, text}}.",
            "skill_cant_be_own_synonym": "Skill cannot have itself as a synonym: {{slug_name, text}}.", // looks like skill_cant_be_own_synonym (uses name instead of slug)
            "skill_cant_be_synonym_for_itself": "Skill '{{skill_name, text}}' canot be a synonym for itself.", // looks like skill_cant_be_own_synonym (uses name instead of slug)
            "synonym_already_in_use":
                "Skill '{{skill_name, text}}' specified synonym '{{syn_str, text}}', which is already a synonym for '{{syn_name, text}}'.",
            "skill_slug_required_for_delete": "Skill slug name in URL required for DELETE.",
            "skill_slug_required_for_patch": "Skill slug name in URL required for PATCH.",
            "only_admins_can_create_skills": "Only admins can create Skills.",
            "only_local_communities_team_can_create_local_communities":
                "Only members of the Local Communities Team may create local communities.",
            "cant_merge_skill_into_itelf": "Cannot merge a Skill into itself.",
            "skill_not_found": "Skill object not found: {{slug_name, text}}.",
            "service_account_description_too_short":
                "Please enter a longer description of the purpose of this service account.",
            "service_account_cant_be_unarchived": "Service Account can't be un-archived.",
            "service_account_not_found": "Service Account not found.",
            "service_account_type_must_be_token": "Service account type must be 'TOKEN'.",
            "service_account_max_token_limit_reached":
                "Service accounts can have a maximum of {{max_allowed, text}} tokens. Please delete an existing token before generating a new one.",
            "service_account_token_cant_be_reactivated": "Service account token can't be reactivated.",
            "invalid_int_for_recent_posts": "Please pass a valid integer for recent_posts.",
            "missing_required_url_param": "Missing required url parameter: {{param, text}}.",
            "tle_not_found": "Record not found for {{tle_id, text}}.",
            "invalid_sync_since_format": "Invalid datetime format for {{sync_since, text}}.",
            "must_be_owner_or_admin_to_update_group": "Must be an owner or admin to update a group",
            "cant_update_auto_dlist_groups": "Cannot update AUTO_DLIST Groups",
            "cant_update_readonly_groups": "Cannot update readonly groups",
            "cant_update_locked_groups": "Cannot update locked groups",
            "cant_update_transfer_portal_fields": "Cannot update Transfer Portal fields",
            "cant_update_transfer_entry_pending": "Cannot update Transfer Portal entry pending status",
            "transfer_portal_not_enabled": "Transfer Portal is not enabled",
            "max_length_exceeded":
                "Ensure this value has at most {{max_length, number}} character (it has {{given_length, number}}).",
            "only_one_community_owner_allowed": "Communities may only have one owner",
            "owner_not_found": "Owner not found",
            "must_specify_group_name": "You must specify a name.",
            "dlist_cant_have_a_photo": "Distribution Lists cannot have a photo",
            "dlists_cant_have_an_overview": "Distributions Lists cannot have an overview",
            "communities_cant_have_an_email": "Communities cannot have an email",
            "please_enter_email_for_dlist": "Please enter an email for this dlist",
            "editable_email_portion_too_long": "The editable portion of the email can be no longer than 64 characters.",
            "list_with_this_email_exists": "A list with this email already exists.",
            "group_with_this_name_exists": "A group with this name already exists.",
            "group_exists_in_provider": "A group with this email already exists in {{provider, text}}",
            "issue_contacting_provider": "There was an issue contacting {{provider, text}}; please try again later.",
            "communities_cant_be_private": "Communities may not be private",
            "must_specify_group_owner": "Please specify an owner",
            "dlists_arent_enabled": "Distribution Lists are not enabled",
            "only_admins_can_create_dlists": "Only admins may create Distribution Lists",
            "cant_change_group_type": "Cannot change group type",
            "cant_change_group_subtype": "Cannot change group subtype",
            "dlists_must_have_custom_dlist_subtype":
                "Distributions Lists can only be created with CUSTOM_DLIST sub type",
            "communities_must_have_social_subtype": "Communities can only be created with SOCIAL sub type",
            "communities_not_enabled": "Communities are not enabled",
            "required": "This field is required.",
            "min_value_not_met": "Must be at least {{min_value, number}}.",
            "company_posts_disabled": "Company Posts are currently disabled for {{company, text}}",
            "social_group_posts_disabled": "Social Group posts are currently disabled for {{company, text}}",
            "invalid_num_of_photos": "Invalid # of photos",
            "cant_create_post_without_text": "Cannot create a post without text",
            "cant_create_post_without_owuser": "Cannot create a post without a owuser",
            "cant_create_post_without_company": "Cannot create a post without a company",
            "employee_with_email_already_exists": "An employee with this email already exists.",
            "manager_must_be_readonly_if_wiki_is_off":
                "Manager field must be read-only if Wiki Profile Edits are disabled",
            "cant_reenable_config_mode": "Configuration Mode can not be re-enabled",
            "unknown_dlist_provider_type": "Unknown provider type: {{provider_type, text}}",
            "provider_type_not_specified": "Provider type not specified",
            "domain_blank": "Domain must not be blank",
            "out_of_office_only_supported_by_gsuite": "Out of office is only currently supported for GSuite.",
            "admin_email_doesnt_match_selected_domain":
                "The domain of the Administrator Email does not match the selected Domain.",
            "current_user_isnt_using_google_auth": "The current user is not authenticated with Google",
            "invalid_xml_file_url": "Please enter a URL for a valid xml file.",
            "invalid_xml_file_upload": "Please upload a valid xml file.",
            "guest_pass_authorizer_required": "Please enter authorizer email.",
            "cant_find_active_employee_for_email": "Email does not exist for a current active employee",
            "invalid_value": "Invalid value",
            "multiple_employees_found_with_key": "Multiple employees found with {{field, text}}: {{value, text}}",
            "required_field": "This is a required field",
            "error_creating_post": "There was an error creating the post.",
            "max_length_vs_given_length":
                "Ensure this value has at most {{max_length, number}} characters (it has {{given_length, number}}).",
            "localized_text_count_invalid": "Invalid number of plural forms.",
            "malformed_json": "Please provide valid JSON.",
            "company_not_found": "Could not recognize company.",
            "employees_not_found_for_public_ids": "Employees not found: {{missing_public_ids_str, text}}.",
            "owuser_not_found": "OWUser not found.",
            "language_tag_invalid": "Invalid language tag: {{language_tag, text}}.",
            "unrecognized_error": "Unrecognized error.",
            "invalid_datetime_format": "Invalid datetime format.",
            "dlist_member_import_file_not_found": "Dlist member import file not found.",
            "not_found": "Not found.",
            "model_with_field_already_exists":
                "This {{field_name, text}} is already in use by another {{model_name, text}}.",
            "field_cannot_be_null": "This field cannot be null",
            "video_format_not_suupported": "This video format is not supported.",
            "malformed_video_url": "Video not found",
            "invalid_linkedin_url": "Invalid or malformed LinkedIn url",
            "unable_to_generate_wistia_token": "Cannot upload video at this time.",
            "invalid_url": "Enter a valid URL.",
            "cant_disable_email_integration_dlists":
                "Email integration cannot be turned off while distribution lists are enabled. Please turn distribution lists off first.",
            "cant_disable_email_integration_ooo":
                "Email integration cannot be turned off while out-of-office sync is enabled. Please turn out-of-office sync off first.",
            "provider_raw_error": "{{message, text}}",
            "provider_o365_group_raw_error":
                "The new service account was not able to create and edit groups. Please check that it has the correct privileges and that the username and password are correct.\n\n Microsoft Error: {{message, text}}",
            "not_within_calendar_day_range": "Not within calendar day range of 1 through 6",
            "not_within_calendar_hr_range": "Not within calendar hour range of 0 through 23",
            "cant_change_provider_type_while_enabled":
                "The provider type cannot be changed while Distribution Lists or Out of Office Sync are enabled.",
            "cant_change_domain_while_enabled":
                "The domain cannot be changed while Distribution Lists or Out of Office Sync are enabled",
            "subdomain_invalid": "Please enter only letters (a-z), numbers (0-9), and hyphens (-).",
            "subdomain_reserved": "{{subdomain, text}} is a reserved subdomain.",
            "subdomain_unique": "{{subdomain, text}} is already in use.",
            "identity_provider_empty": "There must be at least one configured identity provider.",
            "saml_metadata_without_provider": "You cannot configure SAML without specifying a SAML provider.",
            "saml_multi_providers": "You may only have one SAML provider configured.",
            "saml_provider_without_metadata": "You must provide SAML metadata when adding a SAML provider.",
            "dlist_domain_unique": "This email domain is already being managed by another OrgWiki company.",
            "email_address_malformed": "Please provide a valid email address.",
            "community_only_field": "{{field, text}} is only applicable to communities.",
            "in_memoriam_deactivate": "Published In Memoriam pages cannot be deactivated.",
            "in_memoriam_update_employee": "The Memorialized employee cannot be changed.",
            "in_memoriam_unique": "This employee already has an In-Memoriam page",
            "local_community_requires_work_anywhere": "Local Communities requires Work Anywhere",
            "radius_must_be_greater_than_zero": "Radius must be greate than zero",
            "communities_must_have_social_or_local_subtype": "Community must have either Social or Local subtype",
            "local_community_must_show_overview": "Local Communities must have overview enabled",
            "local_community_must_show_email_button": "Local Communities must have email button enabled",
            "local_community_must_allow_posts": "Local Communities must allow posts",
            "local_community_must_be_public": "Local Communities must be public",
            "only_local_communities_have_radius": "Non-Local Communities must not have a radius",
            "local_community_must_have_radius": "Local Communities must have a radius",
            "only_one_local_community_allowed_per_city": "There is already a local community in this city.",
            "time_off_requires_holiday_calendar": "Time Off requires a Holiday Calendar to be specified"
        }
    },
    "de": {
        "common": {
            "navbar.feed_sm": "Feed",
            "navbar.feed": "News Feed",
            "navbar.people": "Personen",
            "navbar.groups": "Gruppen",
            "navbar.offices": "Büros",
            "navbar.menu": "Menü",
            "navbar.admin": "Admin",
            "navbar.my_profile": "Mein Profil",
            "navbar.company_config": "Unternehmen konfigurieren",
            "navbar.manage_employees": "Mitarbeiter verwalten",
            "navbar.manage_guest_passes": "Gastpässe verwalten",
            "navbar.reports": "Berichte und Analysen",
            "navbar.admin_enter": "Administratormodus starten",
            "navbar.admin_exit": "Administratormodus beenden",
            "navbar.logout": "Abmelden",
            "navbar.restart_tour": "Tour neu starten",
            "notifications.count+": "{{count, number}}+",
            "notifications.count": "{{count, number}}",
            "notifications.read_error":
                "Beim Markieren aller Benachrichtigungen als \"Gelesen\" ist ein Problem aufgetreten.",
            "notifications.empty": "Es gibt derzeit keine Neuigkeiten.",
            "notifications.action_required": "Aktion erforderlich",
            "notifications.notifications": "Benachrichtigungen",
            "notifications.moment_year": "{{date, MMMM D, YYYY}}",
            "notifications.moment": "{{date, MMMM D}}",
            "notifications.moment_from": "{{date, fromNow}}",
            "notifications.read_all": "Alle als \"Gelesen\" markieren",
            "instant_search.office_count": "{{count, number}} Person in diesem Büro",
            "instant_search.office_count_other": "{{count, number}} Personen in diesem Büro",
            "instant_search.room_capacity": "Kapazität: {{count, number}} Personen",
            "instant_search.conference_room": "Konferenzraum",
            "instant_search.members_everyone": "Jeder",
            "instant_search.members_more": "{{count, number}}+ Mitglieder",
            "instant_search.members": "{{count, number}} Mitglied",
            "instant_search.members_other": "{{count, number}} Mitglieder",
            "instant_search.skill_count": "{{count, number}} Person mit {{label, text}}",
            "instant_search.skill_count_other": "{{count, number}} Personen mit {{label, text}}",
            "instant_search.skill_alias": "(Auch bezeichnet als <1>{{match, text}}</1>)",
            "instant_search.all_groups": "Alle Gruppen anzeigen",
            "instant_search.all_skills": "Alle {{label, text}} anzeigen",
            "instant_search.advanced_search": "Erweiterte Personensuche",
            "instant_search.placeholder": "OrgWiki durchsuchen",
            "loading...": "Laden...",
            "loading": "Laden",
            "cancel": "Abbrechen",
            "save": "Speichern",
            "yes": "Ja",
            "no": "Nein",
            "ok": "OK",
            "saving...": "Speichern...",
            "edit": "Bearbeiten",
            "update": "Aktualisieren",
            "create": "Erstellen",
            "delete": "Löschen",
            "deleting...": "Löschen...",
            "count": "{{count, number}}",
            "area.label": "Bereich",
            "area.label_other": "Bereiche",
            "product.label": "Produkt",
            "product.label_other": "Produkte",
            "skill.label": "Skill",
            "skill.label_other": "Skills",
            "skill.level1": "Anfänger",
            "skill.level2": "Fortgeschrittener",
            "skill.level3": "Experte",
            "skill.level4": "Profi",
            "profile_page.manager": "Vorgesetzter",
            "profile_page.managers": "Vorgesetzte",
            "profile_page.change_manager": "Vorgesetzten ändern",
            "profile_page.one_minute_ago": "vor einer Minute",
            "profile_page.count_minutes_ago": "vor {{minuteDisplay, number}} Minuten",
            "profile_page.an_hour_ago": "vor einer Stunde",
            "profile_page.count_hours_ago": "vor {{hourDisplay, number}} Stunden",
            "profile_page.a_day_ago": "vor einem Tag",
            "profile_page.count_days_ago": "vor {{dayDisplay, number}} Tagen",
            "profile_page.count_weeks_ago": "vor {{weekDisplay, number}} Wochen",
            "profile_page.count_months_ago": "vor {{monthDisplay, number}} Monaten",
            "profile_page.count_years_ago": "vor {{yearDisplay, number}} Jahren",
            "profile_page.updated_one_minute_ago": "Letzte Aktualisierung vor einer Minute",
            "profile_page.updated_count_minutes_ago": "Letzte Aktualisierung vor {{minuteDisplay, number}} Minuten",
            "profile_page.updated_an_hour_ago": "Letzte Aktualisierung vor einer Stunde",
            "profile_page.updated_count_hours_ago": "Letzte Aktualisierung vor {{hourDisplay, number}} Stunden",
            "profile_page.updated_a_day_ago": "Letzte Aktualisierung vor einem Tag",
            "profile_page.updated_count_days_ago": "Letzte Aktualisierung vor {{dayDisplay, number}} Tagen",
            "profile_page.updated_count_weeks_ago": "Letzte Aktualisierung vor {{weekDisplay, number}} Wochen",
            "profile_page.updated_count_months_ago": "Letzte Aktualisierung vor {{monthDisplay, number}} Monaten",
            "profile_page.updated_count_years_ago": "Letzte Aktualisierung vor {{yearDisplay, number}} Jahren",
            "profile_page.team": "Team",
            "profile_page.teams": "Teams",
            "profile_page.community": "Community",
            "profile_page.communities": "Communitys",
            "profile_page.posts": "Beiträge",
            "profile_page.count_members": "{{count, number}} Mitglieder",
            "profile_page.direct_and_total_reports_count":
                "{{directReportsCount, number}} direkt unterstellte Mitarbeiter · {{descendantsCount, number}} unterstellte Mitarbeiter insgesamt",
            "profile_page.direct_reports_count": "{{directReportsCount, number}} direkt unterstellte Mitarbeiter",
            "profile_page.reports": "Unterstellte Mitarbeiter",
            "profile_page.profile_photo_title": "Profilfoto von {{employeeFirstName, text}}",
            "profile_page.desk_location": "Arbeitsplatz",
            "profile_page.dlists_count": "Verteilerlisten ({{dlistsCount, number}})",
            "profile_page.service_meeting": "{{serviceName, text}} Meeting",
            "profile_page.web_conference": "Webkonferenz",
            "profile_page.add_manager": "Vorgesetzten hinzufügen",
            "profile_page.edit_manager": "Vorgesetzten bearbeiten",
            "profile_page.add_reports": "Unterstellte Mitarbeiter hinzufügen",
            "profile_page.edit_reports": "Unterstellte Mitarbeiter bearbeiten",
            "profile_page.no_manager_or_reports": "Kein Vorgesetzter, kein unterstellter Mitarbeiter",
            "profile_page.organization": "Organisation",
            "profile_page.dotted_line_reports": "Fachlich unterstellte Mitarbeiter",
            "profile_page.add_direct_report": "Direkt unterstellten Mitarbeiter hinzufügen",
            "profile_page.add_dotted_line_report": "Fachlich unterstellten Mitarbeiter hinzufügen",
            "profile_page.direct_reports": "Direkt unterstellte Mitarbeiter",
            "profile_page.copy_the_teams_email_addresses": "E-Mail-Adressen des Teams kopieren",
            "profile_page.name_plus_reports": "{{name, text}} + unterstellte Mitarbeiter",
            "profile_page.name_plus_team": "{{name, text}} + Team",
            "profile_page.count_people": "{{count, number}} Personen",
            "profile_page.download_team_powerpoint": "Team-Powerpoint herunterladen",
            "profile_page.employee_will_be_notified":
                "{{name, text}} wird beim Speichern von Änderungen benachrichtigt.",
            "profile_page.your_team_slides": "Der Download Ihrer Team-Folien sollte demnächst beginnen.",
            "profile_page.preparing_team_slides": "Team-Folien werden vorbereitet.",
            "profile_page.copied_to_clipboard": "{{prefix, text}} für {{listText, text}} in Zwischenablage kopiert",
            "profile_page.distribution_list": "Verteilerliste",
            "profile_page.count_email": "{{count, number}} E-Mail",
            "profile_page.count_email_other": "{{count, number}} E-Mails",
            "profile_page.add_direct_manager": "Direkten Vorgesetzten hinzufügen",
            "profile_page.direct_manager": "Direkter Vorgesetzter",
            "profile_page.dotted_line_managers": "Fachlicher Vorgesetzter",
            "profile_page.employee_profile_only_percent_complete":
                "Das Profil von {{name, text}} ist nur zu {{percent, number}} % abgeschlossen.",
            "profile_page.employee_profile_percent_complete":
                "Das Profil von {{name, text}} ist zu {{percent, number}} % abgeschlossen.",
            "profile_page.your_profile_only_percent_complete":
                "Ihr Profil ist nur zu {{percent, number}} % abgeschlossen.",
            "profile_page.your_profile_percent_complete": "Ihr Profil ist zu {{percent, number}} % abgeschlossen.",
            "profile_page.this_profile_only_percent_complete":
                "Dieses Profil ist nur zu {{percent, number}} % abgeschlossen.",
            "profile_page.this_profile_percent_complete": "Dieses Profil ist zu {{percent, number}} % abgeschlossen.",
            "profile_page.hide_details": "Details verbergen",
            "profile_page.show_details": "Details anzeigen",
            "profile_page.add": "Hinzufügen",
            "profile_page.phone_number": "Telefonnummer",
            "profile_page.profile_photo": "Profilfoto",
            "profile_page.upload_your_photo": "Laden Sie Ihr Foto hoch.",
            "profile_page.add_a_number": "Fügen Sie eine Büro- oder Mobiltelefonnummer hinzu.",
            "profile_page.include_the_city": "Geben Sie an, an welchem Ort Sie arbeiten.",
            "profile_page.location": "Ort",
            "profile_page.fill_in_responsibilities": "Tragen Sie Ihre Zuständigkeiten ein.",
            "profile_page.responsibilities": "Zuständigkeiten",
            "profile_page.share_about_yourself": "Erzählen Sie ein wenig über sich selbst.",
            "profile_page.about": "Info",
            "profile_page.you_have_more_things":
                "{{count, number}} weiterer Schritt ist noch zu erledigen, um Ihr Profil abzuschließen.",
            "profile_page.you_have_more_things_other":
                "{{count, number}} weitere Schritte sind noch zu erledigen, um Ihr Profil abzuschließen.",
            "profile_page.email_team": "E-Mail an Team",
            "profile_page.key": "Schlüssel:",
            "profile_page.create_new_skill": "{{tag, text}} neu erstellen: {{name, text}}",
            "profile_page.already_have_skill": "(Sie haben {{tag, text}} bereits)",
            "profile_page.search_skills": "{{tag, text}} suchen",
            "profile_page.add_new_skill": "{{tag, text}} neu hinzufügen",
            "profile_page.select_a_level": "Level auswählen:",
            "profile_page.add_skill": "{{tag, text}} hinzufügen",
            "profile_page.add_a_skill": "{{tag, text}} hinzufügen",
            "profile_page.problem_saving_skills":
                "Beim Speichern Ihrer Änderungen zu {{tag, text}} ist ein Problem aufgetreten.",
            "profile_page.no_skills": "{{name, text}} sind keine {{tag, text}} hinzugefügt",
            "profile_page.remove_skill": "{{tag, text}} entfernen",
            "profile_page.skill_max_size": "{{tag, text}} darf nicht länger als 100 Zeichen sein.",
            "audit_trail.hide_history": "Verlauf verbergen",
            "audit_trail.view_history": "Verlauf anzeigen",
            "audit_trail.profile_history": "Profilverlauf",
            "audit_trail.object": "Objekt",
            "audit_trail.name": "Name",
            "audit_trail.modified_date": "Geändert am",
            "audit_trail.modified_by": "Geändert von",
            "audit_trail.action": "Aktion",
            "audit_trail.field": "Feld",
            "audit_trail.field_name": "Feldname",
            "audit_trail.old_value": "Alter Wert",
            "audit_trail.new_value": "Neuer Wert",
            "audit_trail.date": "{{date, MM/DD/YYYY hh:mm:ss A}}",
            "audit_trail.restricted_membership": "Eingeschränkte Mitgliedschaft",
            "audit_trail.is_active": "Ist aktiv",
            "audit_trail.person": "Person",
            "audit_trail.title": "Titel",
            "audit_trail.manager": "Vorgesetzter",
            "audit_trail.email": "E-Mail",
            "audit_trail.city_description": "Ort – Beschreibung",
            "audit_trail.first_name": "Vorname",
            "audit_trail.last_name": "Nachname",
            "audit_trail.external_id": "Externe ID",
            "audit_trail.start_date": "Einstellungsdatum",
            "audit_trail.mobile_phone": "Mobilnummer",
            "audit_trail.text": "Text",
            "audit_trail.skill_level": "Qualifikationsstufe",
            "audit_trail.office_phone": "Büronummer",
            "audit_trail.city": "Ort",
            "audit_trail.country": "Land",
            "audit_trail.office_type": "Bürotyp",
            "audit_trail.state": "Bundesland",
            "audit_trail.responsibilities": "Zuständigkeiten",
            "audit_trail.profile_photo": "Profilfoto",
            "audit_trail.location_address": "Standortadresse",
            "audit_trail.location_type": "Standorttyp",
            "audit_trail.about": "Info",
            "audit_trail.post_text": "Beitragstext",
            "audit_trail.is_contractor": "Ist Auftragnehmer",
            "audit_trail.is_admin": "Ist Administrator",
            "audit_trail.can_create_company_mentions": "Kann Unternehmenserwähnungen erstellen",
            "audit_trail.balloons": "Balloons",
            "audit_trail.photo": "Foto",
            "audit_trail.synonym_of": "Synonym für",
            "audit_trail.external_email": "Externe E-Mail",
            "audit_trail.story_text": "Text der Geschichte",
            "audit_trail.out_of_office_message": "Abwesenheitsnachricht",
            "audit_trail.out_of_office": "Abwesend",
            "audit_trail.ip_address": "IP-Adresse",
            "audit_trail.role": "Rolle",
            "audit_trail.description": "Beschreibung",
            "audit_trail.about_me": "Über mich",
            "audit_trail.nested_group": "Verschachtelte Gruppe",
            "audit_trail.invite_only": "Nur einladen",
            "audit_trail.what_am_i_doing_this_quarter": "Das mache ich in diesem Quartal",
            "audit_trail.my_responsibilities": "Meine Zuständigkeiten",
            "audit_trail.office_location": "Bürostandort",
            "audit_trail.is_private": "Ist privat",
            "audit_trail.linkedin_url": "LinkedIn-URL",
            "audit_trail.is_locked": "Ist gesperrt",
            "audit_trail.projects_past_and_present": "Projekte – früher und heute",
            "audit_trail.is_read_only": "Ist schreibgeschützt",
            "audit_trail.what_i_am_responsible_for": "Dafür bin ich verantwortlich",
            "audit_trail.nick_name": "Spitzname",
            "audit_trail.primary_location": "Primärer Ort",
            "audit_trail.area": "Bereich",
            "audit_trail.zoom_url": "Zoom-URL",
            "audit_trail.created_on_mobile": "Auf Mobilgerät erstellt",
            "audit_trail.who_can_post": "Wer darf posten",
            "audit_trail.slack_name": "Slack-Name",
            "audit_trail.mattermost_username": "Mattermost-Handle",
            "audit_trail.mattermost_team": "Mattermost-Domäne",
            "audit_trail.slack_team": "Slack-Team",
            "audit_trail.dotted_line_manager": "Fachlicher Vorgesetzter",
            "audit_trail.owners": "Eigner",
            "audit_trail.products": "Produkte",
            "audit_trail.additional_contact_methods": "Weitere Kontaktmethoden",
            "audit_trail.skype_name": "Skype-Name",
            "audit_trail.key_accounts": "Key Accounts",
            "audit_trail.what_am_i_responsible_for": "Dafür bin ich verantwortlich",
            "audit_trail.primary_location_floor_plan": "Primärer Ort – Arbeitsplatz",
            "audit_trail.is_owner": "Ist Eigner",
            "audit_trail.secondary_location_address": "Sekundäre Standortadresse",
            "audit_trail.desk_location": "Arbeitsplatz",
            "audit_trail.display_address": "Anzeigeadresse",
            "audit_trail.official_office_distribution_list": "Offizielle Büroverteilerliste",
            "audit_trail.prior_start_date": "Früheres Einstellungsdatum",
            "audit_trail.overview_text": "Übersichtstext",
            "audit_trail.conference_room": "Konferenzraum",
            "audit_trail.expiration_date": "Ablaufdatum",
            "audit_trail.type": "Typ",
            "audit_trail.conference_room_floor_plan": "Konferenzraum – Grundriss",
            "audit_trail.official_distribution_list": "Offizielle Verteilerliste",
            "audit_trail.company_logo": "Firmenlogo",
            "audit_trail.self_signup_enabled": "Selbstsignierung aktiviert",
            "audit_trail.conference_room_capacity": "Konferenzraum – Kapazität",
            "audit_trail.alternate_address": "Alternative Adresse",
            "audit_trail.configuration_mode_enabled": "Pre-Launch-Modus",
            "audit_trail.webex": "WebEx",
            "audit_trail.secondary_location": "Sekundärer Ort",
            "audit_trail.primary_address": "Primäre Adresse",
            "audit_trail.prior_company_name": "Früherer Unternehmensname",
            "audit_trail.membership": "Mitgliedschaft",
            "audit_trail.authorizer_email": "Bevollmächtigter – E-Mail",
            "audit_trail.guest_email": "Gast – E-Mail",
            "audit_trail.guest_last_name": "Gast – Nachname",
            "audit_trail.guest_first_name": "Gast – Vorname",
            "audit_trail.guest_company_name": "Gast – Unternehmensname",
            "audit_trail.tags_enabled": "Tags aktiviert",
            "audit_trail.company_posts_enabled": "Unternehmensbeiträge aktiviert",
            "audit_trail.social_groups_enabled": "Beiträge in sozialen Medien aktiviert",
            "audit_trail.is_early_adopter": "Ist Early Bird",
            "audit_trail.wiki_profile_edits_enabled": "Wiki-Profilbearbeitungen aktiviert",
            "audit_trail.conference_room_description": "Konferenzraum – Beschreibung",
            "audit_trail.technical_contact": "Technischer Kontakt",
            "audit_trail.navbar_logo": "Navigationsleistenlogo",
            "audit_trail.dist_lists_admin_only": "Nur Verteilerlistenadministrator",
            "audit_trail.seating_chart": "Grundriss",
            "audit_trail.primary_color": "Primäre Farbe",
            "audit_trail.dotted_lines_enabled": "Fachliche Unterstellung aktiviert",
            "audit_trail.active_by_default": "Standardmäßig aktiv",
            "audit_trail.section_type": "Abschnittstyp",
            "audit_trail.intended_for": "Vorgesehen für",
            "audit_trail.sort_order": "Sortierreihenfolge",
            "audit_trail.update_interval": "Aktualisierungsintervall",
            "audit_trail.revoked": "Zurückgerufen",
            "audit_trail.company": "Unternehmen",
            "audit_trail.okta_metadata_url": "Okta-Metadaten-URL",
            "audit_trail.profile_celebrations_enabled": "Profiljubiläen aktiviert",
            "audit_trail.highlight_color": "Hervorhebungsfarbe",
            "audit_trail.background_color": "Hintergrundfarbe",
            "audit_trail.secondary_location_floor_plan": "Sekundärer Ort – Arbeitsplatz",
            "audit_trail.primary_button_color": "Primärtastenfarbe",
            "audit_trail.company_logo_title": "Firmenlogotitel",
            "audit_trail.guest_passes_enabled": "Gastpässe aktiviert",
            "audit_trail.timeline_moderation_datetime": "Datum/Uhrzeit der Timeline-Moderation",
            "audit_trail.light_navbar": "Helle Navigationsleiste",
            "audit_trail.champion_contact": "Hauptkontakt",
            "audit_trail.dist_lists_enabled": "Verteilerlisten aktiviert",
            "audit_trail.saml_metadata_url": "SAML-Metadaten-URL",
            "audit_trail.company_logo_url": "Firmenlogo-URL",
            "audit_trail.website": "Website",
            "audit_trail.dist_lists_admin_email": "Verteilerlistenadministrator – E-Mail",
            "audit_trail.dist_lists_domain": "Verteilerlistendomäne",
            "audit_trail.billing_contact": "Abrechnungsvertrag",
            "audit_trail.welcome_email_enabled": "Begrüßungs-E-Mail aktiviert",
            "audit_trail.sponsor_contact": "Sponsorkontakt",
            "audit_trail.birthday_today": "Geburtstag heute",
            "audit_trail.secondary_contact": "Sekundärer Kontakt",
            "audit_trail.light_background": "Heller Hintergrund",
            "audit_trail.primary_contact": "Primärer Kontakt",
            "audit_trail.saml_metadata_file": "SAML-Metadaten-Datei",
            "audit_trail.contest_winner": "Wettbewerbssieger",
            "audit_trail.alternate_email": "Alternative E-Mail",
            "audit_trail.employees_manager": "Vorgesetzter von <0>{{name, text}}</0>",
            "audit_trail.employees_dotted_line_manager": "Fachlicher Vorgesetzter von <0>{{name, text}}</0>",
            "profile_edit_page.first_name": "Vorname",
            "profile_edit_page.nickname": "Spitzname",
            "profile_edit_page.last_name": "Nachname",
            "profile_edit_page.title": "Titel",
            "profile_edit_page.contact_information": "Kontaktdaten",
            "profile_edit_page.email": "E-Mail",
            "profile_edit_page.office_phone": "Büronummer",
            "profile_edit_page.mobile_phone": "Mobilnummer",
            "profile_edit_page.messaging": "Messaging",
            "profile_edit_page.slack_team": "Slack-Team",
            "profile_edit_page.slack_username": "Slack-Benutzername",
            "profile_edit_page.mattermost_team": "Mattermost-Domäne",
            "profile_edit_page.mattermost_username": "Mattermost-Handle",
            "profile_edit_page.conferencing": "Konferenzen",
            "profile_edit_page.location": "Ort",
            "profile_edit_page.primary_work_location": "Primärer Arbeitsort",
            "profile_edit_page.office": "Büro",
            "profile_edit_page.edit_desk_location": "Arbeitsplatz bearbeiten",
            "profile_edit_page.secondary_work_location": "Sekundärer Arbeitsort",
            "profile_edit_page.social": "Soziale Netzwerke",
            "profile_edit_page.profile_url": "Profil-URL",
            "profile_edit_page.locate_mine": "Meine suchen",
            "profile_edit_page.other_contact_info": "Weitere Kontaktdaten",
            "profile_edit_page.other_contact_info_description":
                "Bevorzugte Kommunikationsmethode? Andere Kommunikationskanäle? Standort des Büros/Arbeitsplatzes?",
            "profile_edit_page.admin_fields": "Felder für Administration",
            "profile_edit_page.active_employee": "Aktiver Mitarbeiter",
            "profile_edit_page.start_date": "Einstellungsdatum",
            "profile_edit_page.prior_company_name": "Früherer Unternehmensname",
            "profile_edit_page.prior_start_date": "Früheres Einstellungsdatum",
            "profile_edit_page.prior_start_date_hover":
                "Falls der Mitarbeiter von einem erworbenen Unternehmen übernommen wurde, ist dies das Einstellungsdatum bei diesem Unternehmen.",
            "profile_edit_page.admin": "Admin",
            "profile_edit_page.contractor": "Auftragnehmer",
            "profile_edit_page.external_id": "Externe ID",
            "profile_edit_page.announcements": "Ankündigungen",
            "profile_edit_page.balloons": "Balloons",
            "profile_edit_page.last_modified_date": "Zuletzt geändert am",
            "profile_edit_page.last_modified_by": "Zuletzt geändert von",
            "profile_edit_page.profile_photo": "Profilfoto",
            "profile_edit_page.customize_custom_sections": "Benutzerdefinierte Abschnitte anpassen",
            "profile_edit_page.meeting_url": "Meeting-URL",
            "profile_edit_page.name": "Name",
            "profile_edit_page.personal_room_url": "URL des persönlichen Raums",
            "profile_edit_page.address": "Adresse",
            "profile_edit_page.set_desk_location": "Arbeitsplatz festlegen",
            "profile_edit_page.early_adopter": "Early Bird",
            "profile_edit_page.deleting...": "Löschen...",
            "profile_edit_page.delete_profile": "Profil löschen",
            "profile_edit_page.saving...": "Speichern...",
            "profile_edit_page.save_and_add_another": "Speichern und weiteren hinzufügen",
            "profile_edit_page.active?": "Aktiv?",
            "profile_edit_page.intended_for": "Vorgesehen für",
            "profile_edit_page.no_sections": "Es wurden noch keine Abschnitte erstellt.",
            "profile_edit_page.select_custom_sections":
                "Wählen Sie die Abschnitte von <1>{{fullName, text}}</1> aus, die jeder sehen kann.",
            "profile_edit_page.mark_inactive_warning":
                "Durch die Kennzeichnung eines Mitarbeiters als \"Inaktiv\" werden alle ihm direkt unterstellten Mitarbeiter seinem Vorgesetzten zugewiesen und die Zuordnung zwischen dem Mitarbeiter und seinem Vorgesetzten wird dann gelöscht.",
            "profile_edit_page.mark_active_warning":
                "Durch die Reaktivierung eines Mitarbeiters wird seine frühere Zugehörigkeit zu Communitys und Verteilerlisten wiederhergestellt.",
            "profile_edit_page.mark_active_note": "Hinweis: Seinen Vorgesetzten müssen Sie neu festlegen.",
            "profile_edit_page.early_adopter_warning":
                "Diese Einstellung gilt nur für den Pre-Launch-Modus von OrgWiki. Early Birds erhalten Produkt-E-Mails und In-App-Benachrichtigungen zu OrgWiki.",
            "profile_edit_page.nickname_prompt":
                "Möchten Sie {{suggestedNickName, text}} als Ihren Spitznamen verwenden?",
            "profile_edit_page.delete_profile_warning":
                "Möchten Sie dieses Profil wirklich löschen? Durch das Löschen eines Profils werden auch die Beiträge und Kommentare des Benutzers gelöscht. Die vom Benutzer durchgeführten Aktionen sind im Prüfprotokoll weiterhin enthalten.",
            "profile_edit_page.processing_error":
                "Bei der Verarbeitung Ihrer Anforderung ist ein Problem aufgetreten. Code: {{status, number}}, {{statusText, text}}",
            "profile_edit_page.inactive": "Inaktiv",
            "profile_edit_page.edit_profile_sections": "Profilabschnitte bearbeiten",
            "profile_edit_page.save_changes": "Änderungen speichern",
            "profile_edit_page.save_location_and_continue_prompt":
                "Vor der Einstellung Ihres Arbeitsplatzes müssen Sie den Arbeitsort speichern. Möchten Sie Ihre Profiländerungen speichern und fortfahren?",
            "profile_edit_page.employee_not_active_warning":
                "Warnung: Dieser Mitarbeiter ist derzeit nicht aktiv. Benutzer ohne Administratorrechte können den Datensatz dieses Mitarbeiters nicht anzeigen.",
            "profile_edit_page.select_one": "Eine Option auswählen",
            "profile_edit_page.remote": "Remote",
            "profile_edit_page.none": "Keine",
            "profile_edit_page.enter_a_city": "Ort eingeben",
            "profile_edit_page.edit_employee_profile": "Profil von {{firstName, text}}, {{lastName, text}} bearbeiten",
            "profile_edit_page.add_manager": "Vorgesetzten hinzufügen",
            "profile_edit_page.mattermost_team_tooltip":
                "Muss die vollständig qualifizierte Domäne sein, z. B. unternehmensname.mattermost.com",
            "profile_edit_page.mattermost_username_tooltip":
                "Bei @mention-Namen von Mattermost wird die Groß-/Kleinschreibung beachtet, z. B. JoeSchmidt.",
            "profile_edit_page.edit_profile_header":
                "Profil von <1>{{firstName, text}} {{lastName, text}}</1> bearbeiten",
            "profile_edit_page.upload_photo": "Foto hochladen",
            "profile_edit_page.use_linkedin_photo": "LinkedIn-Foto verwenden",
            "profile_edit_page.use_office_365_photo": "Office 365-Foto verwenden",
            "profile_edit_page.use_gsuite_photo": "G Suite-Foto verwenden",
            "profile_edit_page.users_will_be_notified":
                "Die Benutzer werden bei Änderungen an ihren Profilen informiert.",
            "profile_edit_page.profile_sections_updated": "Profilabschnitte wurden aktualisiert",
            "profile_edit_page.problem_processing_request":
                "Bei der Verarbeitung Ihrer Anforderung ist ein Problem aufgetreten. Code: {{status, number}} {{statusText, text}}, Fehler: {{errorText, text}}",
            "profile_edit_page.employee_created": "Mitarbeiter {{name, text}} wurde erstellt",
            "profile_edit_page.error": "Fehler: {{errorText, text}}",
            "profile_edit_page.city": "Ort",
            "profile_edit_page.primary_location": "Primärer Ort",
            "profile_edit_page.search_offices": "Büros suchen...",
            "locations.all_offices": "Alle Büros",
            "locations.inactive_offices": "Inaktive Büros",
            "locations.navigate_to": "Navigieren zu:",
            "locations.new_office": "Neues Büro",
            "locations.offices_caps": "BÜROS",
            "locations.search_offices": "Büros suchen",
            "locations.no_results_found": "Keine Ergebnisse gefunden.",
            "locations.no_offices": "Keine Büros",
            "locations.office": "Büro",
            "locations.offices": "Büros",
            "locations.location": "Ort",
            "locations.people": "Personen",
            "locations.actions": "Aktionen",
            "locations.people_count": "{{count, number}} <1>Person</1>",
            "locations.people_count_other": "{{count, number}} <1>Personen</1>",
            "locations.done": "Fertig",
            "locations.office_overview": "Büroübersicht",
            "locations.floor_plans": "Grundrisse",
            "locations.conference_rooms": "Konferenzräume",
            "locations.history": "Verlauf",
            "locations.edit_office": "Büro bearbeiten",
            "locations.office_people_count": "<0>{{count, number}} Person</0> in diesem Büro",
            "locations.office_people_count_other": "<0>{{count, number}} Personen</0> in diesem Büro",
            "locations.secondary_office_people_count":
                "{{count, number}} Person gibt dieses Büro als sekundären Arbeitsort an",
            "locations.secondary_office_people_count_other":
                "{{count, number}} Personen geben dieses Büro als sekundären Arbeitsort an",
            "locations.office_is_inactive": "Büro ist inaktiv",
            "locations.office_inactive_couple_options":
                "Dieses Büro ist inaktiv und nur für Administratoren sichtbar. Sie haben verschiedene Optionen:",
            "locations.make_office_visible":
                "Dadurch wird das Büro für jeden sichtbar und steht Benutzern zur Eintragung zur Verfügung.",
            "locations.duplicate_office_merge":
                "Falls dieses Büro ein doppelter Eintrag zu einem vorhandenen Büro ist, sollten Sie die Einträge zusammenfassen!",
            "locations.merge_this_office": "Büro zusammenfassen",
            "locations.set_office_to_active": "Büro aktivieren",
            "locations.edit_information": "Informationen bearbeiten",
            "locations.office_information_not_provided": "Zu diesem Büro wurden keine Informationen eingegeben.",
            "locations.consider_adding_information":
                "Es empfiehlt sich, hier nützliche Informationen wie Ansprechpartner für das Büro, WLAN-Kennwörter, spezielle Hinweise und sonstige Informationen einzugeben, die für einen Besucher des Büros hilfreich sein können.",
            "locations.information": "Informationen",
            "locations.new_community": "Neue {{communitySynonym, text}}",
            "locations.new_room": "Neuer Raum",
            "locations.no_conference_rooms": "Keine Konferenzräume vorhanden",
            "locations.search_rooms": "Räume suchen",
            "locations.name": "Name",
            "locations.capacity": "Kapazität",
            "locations.this_office_empty": "Dieses Büro ist leer.",
            "locations.distribution_list": "Verteilerliste",
            "locations.include_secondary_office_members": "Sekundäre Büromitglieder einschließen",
            "locations.title": "Titel",
            "locations.secondary": "Sekundär",
            "locations.primary": "Primär",
            "locations.search_members": "Mitglieder suchen",
            "locations.something_went_wrong_dlist": "Die Erstellung der offiziellen Verteilerliste ist fehlgeschlagen.",
            "locations.primary__required": "Die primäre Adresse ist ein erforderliches Feld.",
            "locations.unexpected_update_error":
                "Bei der Aktualisierung Ihres Büros ist ein unerwarteter Fehler aufgetreten.",
            "locations.unexpected_create_error":
                "Bei der Erstellung Ihres Büros ist ein unerwarteter Fehler aufgetreten.",
            "locations.unexpected_floor_plan_error":
                "Beim Speichern der Grundrisse ist ein Fehler aufgetreten. Versuchen Sie es erneut.",
            "locations.cant_delete_location":
                "{{name, text}} kann nicht gelöscht werden, solange noch Personen zugewiesen sind.",
            "locations.are_you_sure_delete_location": "Möchten Sie {{name, text}} wirklich löschen?",
            "locations.add_floor_plan": "Grundriss hinzufügen",
            "locations.edit_floor_plan": "Grundriss bearbeiten",
            "locations.edit_address": "Adresse bearbeiten",
            "locations.add_address": "Adresse hinzufügen",
            "locations.move_up": "Nach oben",
            "locations.office_name": "Büroname",
            "locations.status": "Status",
            "locations.active": "Aktiv",
            "locations.inactive_hidden": "Inaktiv (verborgen)",
            "locations.office_photo": "Foto des Büros",
            "locations.official_distribution_list": "Offizielle Verteilerliste",
            "locations.official_distribution_list_explanation":
                "OrgWiki zeigt die offizielle Verteilerliste an prominenter Stelle an, um deutlich hervorzuheben, dass dieser Verteiler zur Kommunikation mit den Personen im Büro verwendet werden soll.",
            "locations.change_official_distribution_list": "Offizielle Verteilerliste ändern",
            "locations.set_official_distribution_list": "Offizielle Verteilerliste festlegen",
            "locations.remove": "Entfernen",
            "locations.no_official_distribution_list_set": "Keine offizielle Verteilerliste festgelegt",
            "locations.primary_address": "Primäre Adresse",
            "locations.primary_address_explanation": "Die primäre Adresse wird für die Suche nach Personen verwendet.",
            "locations.merge_with_another_office": "Mit anderem Büro zusammenführen...",
            "locations.add_primary_address": "Primäre Adresse hinzufügen",
            "locations.no_primary_address_set": "Keine primäre Adresse festgelegt",
            "locations.display_address": "Anzeigeadresse",
            "locations.display_address_explanation": "So wird die Büroadresse in OrgWiki angezeigt.",
            "locations.address_variations": "Adressvarianten",
            "locations.address_variations_explanation":
                "OrgWiki verwendet diese Adressvarianten zum Abgleich der Personen in diesem Büro, damit das richtige Büro selbst bei Tippfehlern oder Unvollständigkeiten gefunden wird.",
            "locations.add_address_variation": "Adressvariante hinzufügen",
            "locations.no_address_variations": "Keine Adressvarianten",
            "locations.no_floor_plans": "Keine Grundrisse",
            "locations.delete_office": "Büro löschen",
            "locations.floor_plan_name": "Name des Grundrisses",
            "locations.name_is_required": "Name ist erforderlich",
            "locations.image_format": "Bild (JPG-oder PNG-Format)",
            "locations.select_image": "Bild auswählen...",
            "locations.image_is_required": "Bild ist erforderlich",
            "locations.loc_merged_into_dest": "{{location, text}} zusammengeführt in {{destination, text}}",
            "locations.merge_warning":
                "Durch Zusammenführung werden alle Personen des Quellbüros dem Zielbüro zugewiesen, die Adresse des Quellbüros wird dem Zielbüro als alternative Adresse hinzugefügt und das Quellbüro wird gelöscht. Die Grundrisse und Konferenzräume des Quellbüros bleiben nicht erhalten.",
            "locations.dlist_member_warning":
                "Verteilerlisten, die das Quellbüro enthalten, wird das Zielbüro hinzugefügt.",
            "locations.merge": "Zusammenführen",
            "locations.address_search": "Adresssuche",
            "locations.street_address": "Hausanschrift",
            "locations.city": "Ort",
            "locations.state_province": "Bundesland",
            "locations.country": "Land",
            "locations.save_previous_address": "Vorherige Adresse als Alternative speichern",
            "locations.found_matching_address": "Eine mit Ihrem Eintrag übereinstimmende Adresse wurde gefunden.",
            "locations.recommend_this_version": "Wir empfehlen die Verwendung dieser Version:",
            "locations.recommended_address": "Empfohlene Adresse:",
            "locations.use_recommended_address": "Empfohlene Adresse verwenden",
            "locations.what_you_entered": "Ihr Eintrag:",
            "locations.use_this_address": "Diese Adresse verwenden",
            "locations.go_back": "Zurück",
            "locations.address_entered_exists": "Die eingegebene Adresse ist bereits vorhanden!",
            "locations.existing_address": "Vorhandene Adresse:",
            "locations.similar_address_found":
                "<0>Eine Ihrem Eintrag ähnliche Adresse wurde gefunden.</0> Möchten Sie fortfahren?",
            "locations.address_you_entered": "Eingegebene Adresse:",
            "locations.similar_existing_address": "Ähnliche vorhandene Adresse:",
            "locations.add_address_anyways": "Diese Adresse trotzdem hinzufügen",
            "locations.select_an_existing_list": "Wählen Sie eine vorhandene Liste aus.",
            "locations.enter_an_email_address": "Geben Sie eine E-Mail-Adresse ein.",
            "locations.specify_an_owner": "Geben Sie den Eigner an.",
            "locations.list_already_exists": "Eine Liste mit dieser E-Mail-Adresse ist bereits vorhanden.",
            "locations.remove_user_from_group_list":
                "<1>{{count, number}} Benutzer</1> aus <4>{{group, text}}</4> entfernen, da er auch in verschachtelten Listen enthalten ist",
            "locations.remove_user_from_group_list_other":
                "<1>{{count, number}} Benutzer</1> aus <4>{{group, text}}</4> entfernen, da sie auch in verschachtelten Listen enthalten sind",
            "locations.remove_user_from_group_office":
                "<1>{{count, number}} Benutzer</1> aus <4>{{group, text}}</4> entfernen, da er dem Büro bereits zugewiesen ist",
            "locations.remove_user_from_group_office_other":
                "<1>{{count, number}} Benutzer</1> aus <4>{{group, text}}</4> entfernen, da sie dem Büro bereits zugewiesen sind",
            "locations.remove_list_from_group":
                "<1>{{count, number}} Liste</1> aus <4>{{group, text}}</4> entfernen, da sie auch in verschachtelten Listen enthalten ist",
            "locations.remove_list_from_group_other":
                "<1>{{count, number}} Listen</1> aus <4>{{group, text}}</4> entfernen, da sie auch in verschachtelten Listen enthalten sind",
            "locations.remove_nested_list_from_group":
                "<1>{{count, number}} verschachtelte Liste</1> aus <4>{{group, text}}</4> entfernen, da sie auch in der verschachtelten Liste <6>{{name, text}}</6> enthalten ist",
            "locations.remove_nested_list_from_group_other":
                "<1>{{count, number}} verschachtelte Listen</1> aus <4>{{group, text}}</4> entfernen, da sie auch in der verschachtelten Liste <6>{{name, text}}</6> enthalten sind",
            "locations.create_new_distribution_list": "Neue Verteilerliste erstellen",
            "locations.use_existing_distribution_list": "Vorhandene Verteilerliste verwenden",
            "locations.distribution_list_name": "Name der Verteilerliste",
            "locations.email_address": "E-Mail-Adresse",
            "locations.email": "E-Mail",
            "locations.who_can_join": "Wer kann beitreten (außer Büromitglieder)?",
            "locations.new_members_must_be_approved": "Neue Mitglieder müssen genehmigt werden.",
            "locations.anyone_can_join": "Jeder kann beitreten.",
            "locations.selected_owner": "Ausgewählter Eigner",
            "locations.select_a_different_owner": "Anderen Eigner auswählen",
            "locations.select_an_owner": "Eigner auswählen",
            "locations.search_people": "Personen suchen",
            "locations.select_distribution_list": "Verteilerliste auswählen",
            "locations.search_existing_dlists": "Vorhandene Verteilerlisten suchen",
            "locations.select_a_different_list": "Andere Liste auswählen",
            "locations.duplicates_found": "<0>Doppelte Einträge gefunden!</0> Vorschläge zur Bereinigung der Liste:",
            "locations.removing_duplicates_membership":
                "Durch das Entfernen doppelter Einträge wird die Zugehörigkeit von {{name, text}} nicht geändert.",
            "locations.eliminating_redundancies":
                "Das Entfernen von Redundanzen hilft bei der Verwaltung manueller Mitgliederänderungen und der Vermeidung von Fehlern für <2>{{name, text}}</2>.",
            "locations.create_official_distribution_list": "Offizielle Verteilerliste erstellen",
            "locations.ie_first_floor": "d. h. \"Erdgeschoss\"",
            "floor_plans.select_a_floor_plan": "Grundriss auswählen:",
            "floor_plans.no_floor_plans": "Keine Grundrisse.",
            "floor_plans.edit_floor_plan": "Grundriss bearbeiten",
            "floor_plans.loading_floor_plan": "Grundriss wird geladen.",
            "floor_plans.create_new_room": "Neuen Raum erstellen: {{placeholder, text}}",
            "floor_plans.add_person": "Person hinzufügen",
            "floor_plans.add_person_colon": "Person hinzufügen:",
            "floor_plans.add_room": "Raum hinzufügen",
            "floor_plans.add_conference_room": "Konferenzraum hinzufügen",
            "floor_plans.employee_not_in_this_city":
                "Der Mitarbeiter arbeitet nicht an diesem Ort. Möchten Sie ihn wirklich dieser Sitzordnung hinzufügen?",
            "floor_plans.search_people": "Personen suchen...",
            "floor_plans.select_room": "Raum auswählen:",
            "floor_plans.search_rooms": "Räume suchen...",
            "floor_plans.optional": "Optional",
            "floor_plans.description": "Beschreibung",
            "floor_plans.capacity": "Kapazität",
            "floor_plans.room_name": "Raumname",
            "dlists.distribution_lists_caps": "VERTEILERLISTEN",
            "dlists.my_distribution_lists": "Meine Verteilerlisten",
            "dlists.all_distribution_lists": "Alle Verteilerlisten",
            "dlists.distribution_lists": "Verteilerlisten",
            "dlists.custom_distribution_lists": "Benutzerdefinierte Verteilerlisten",
            "feed.tab_all_updates": "Alle <1>Aktualisierungen</1>",
            "feed.tab_posts": "Beiträge",
            "feed.tab_manager_changes": "Vorgesetzter – <1>Änderungen</1>",
            "feed.tab_title_changes": "Titel – <1>Änderungen</1>",
            "feed.tab_new_employees": "Neue <1>Mitarbeiter</1>",
            "feed.show_more": "Weitere Aktivitäten anzeigen",
            "feed.no_posts": "Es gibt noch keine Beiträge. Heute etwas teilen",
            "feed.no_manager_changes": "Es gibt keine anzeigbaren Änderungen am Vorgesetzten.",
            "feed.no_title_changes": "Es gibt keine anzeigbaren Titeländerungen.",
            "feed.no_new_hires": "Es gibt keine anzeigbaren Neueinstellungen.",
            "feed.no_tles": "Es gibt keine anzeigbaren Timeline-Einträge.",
            "tle.delete_tle_error": "Beim Löschen dieses Timeline-Eintrags ist ein Problem aufgetreten.",
            "tle.like_post": "Diesen Beitrag mit \"Gefällt mir\" markieren",
            "tle.unlike_post": "Diesen Beitrag mit \"Gefällt mir nicht\" markieren",
            "tle.share_post": "Diesen Beitrag teilen",
            "tle.copy_paste_link": "Kopieren und fügen Sie den Link oben ein, um diesen Beitrag zu teilen.",
            "tle.copy_link": "Link kopieren",
            "tle.link_copied": "Link wurde kopiert.",
            "tle.copy": "Kopieren",
            "tle.comment_placeholder": "Kommentar schreiben...",
            "tle.delete_confirm":
                "Möchten Sie diesen Eintrag wirklich löschen? Dabei werden auch die zugehörigen Kommentare gelöscht.",
            "tle.delete_comment_confirm": "Möchten Sie diesen Kommentar wirklich löschen?",
            "tle.delete_comment_error": "Beim Löschen dieses Kommentars ist ein Problem aufgetreten.",
            "tle.back_to_feed": "Zurück zum Feed",
            "tle.now_reports_to": "Nun <1></1> unterstellt",
            "tle.now_dotted_line_reports_to": "Nun <1></1> fachlich unterstellt",
            "tle.title_is_now": "Titel lautet nun {{title, text}}",
            "tle.joins_as": "Beitritt als {{title, text}}",
            "tle.has_left": "Hat das Unternehmen als {{title, text}} verlassen",
            "tle.one_liker": "<0></0> gefällt dieser Beitrag",
            "tle.two_likers": "<0></0>, <2></2> gefällt dieser Beitrag",
            "tle.three_likers": "<0></0>, <2></2>, <4></4> gefällt dieser Beitrag",
            "tle.many_likers": "<0></0>, <2></2>, <4></4> und <6>{{otherLikersStr, text}}</6> gefällt dieser Beitrag",
            "tle.other_likers_count": "{{count, number}} weitere Person",
            "tle.other_likers_count_other": "{{count, number}} weitere Personen",
            "tle.you": "Sie",
            "tle.view_count": "{{count, number}} Aufruf",
            "tle.view_count_other": "{{count, number}} Aufrufe",
            "tle.like": "Gefällt mir",
            "tle.like_other": "Gefällt mir",
            "tle.like_count": "{{count}} Gefällt mir",
            "tle.like_count_other": "{{count}} Gefällt mir",
            "tle.comment": "Kommentar",
            "tle.comment_other": "Kommentare",
            "tle.comment_count": "{{count}} Kommentar",
            "tle.comment_count_other": "{{count}} Kommentare",
            "tle.commenter_title_inactive_annotation": "(Inaktiv) ",
            "edit_tle.title": "Beitrag bearbeiten",
            "edit_tle.optional_post_text_placeholder": "Geben Sie zu Ihrem Beitrag eine kurze Überschrift ein.",
            "edit_tle.required_post_text_placeholder": "Überschrift eingeben (erforderlich)",
            "edit_tle.optional_story_text_placeholder": "Geschichte hinzufügen (optional)",
            "new_post.post_placeholder": "Aktualisierung mit Unternehmen teilen",
            "new_post.story_placeholder": "Geschichte hinzufügen",
            "new_post.drag_photo": "Ziehen Sie ein Foto zum Hochladen hierhin.",
            "new_post.upload_photo": "Foto hochladen",
            "new_post.posting...": "Beitrag wird gepostet...",
            "new_post.post": "Beitrag",
            "advanced_search.refine_results": "Ergebnisse verfeinern",
            "advanced_search.clear_filters": "Filter löschen",
            "advanced_search.title": "Titel",
            "advanced_search.remove_title_filter": "Titelfilter entfernen",
            "advanced_search.has_title": "Hat Titel",
            "advanced_search.remove_var_filter": "{{tag, text}}filter entfernen",
            "advanced_search.office": "Büro",
            "advanced_search.remove_location_filter": "Bürofilter entfernen",
            "advanced_search.location": "Ort",
            "advanced_search.located_in_office": "In Büro",
            "advanced_search.organization": "Organisation",
            "advanced_search.team": "Team",
            "advanced_search.team_filter": "{{teamSynonym, text}}filter",
            "advanced_search.remove_manager_filter": "Vorgesetztenfilter entfernen",
            "advanced_search.select_a_manager": "Vorgesetzten auswählen",
            "advanced_search.browse_all_managers": "Alle Vorgesetzten durchsuchen",
            "advanced_search.group": "Gruppe",
            "advanced_search.remove_group_filter": "Gruppenfilter entfernen",
            "advanced_search.has_group": "Hat Gruppe",
            "advanced_search.less_than_one_year": "< 1 Jahr",
            "advanced_search.between_one_and_two_years": "1–2 Jahre",
            "advanced_search.between_two_and_three_years": "2–3 Jahre",
            "advanced_search.between_three_and_four_years": "3–4 Jahre",
            "advanced_search.between_four_and_five_years": "4–5 Jahre",
            "advanced_search.greater_than_five_years": "> 5 Jahre",
            "advanced_search.tenure": "Beschäftigungsdauer",
            "advanced_search.show_matches_on": "Übereinstimmungen anzeigen zu",
            "advanced_search.in_facet": "In {{tag, text}}",
            "advanced_search.has_facet": "Hat {{tag, text}}",
            "advanced_search.responsibilities": "Zuständigkeiten",
            "advanced_search.about": "Info",
            "advanced_search.groups": "Gruppen",
            "advanced_search.this_quarter": "Dieses Quartal",
            "advanced_search.projects": "Projekte",
            "advanced_search.key_account": "Key Account",
            "advanced_search.email": "E-Mail",
            "advanced_search.mobile_phone": "Mobilnummer",
            "advanced_search.office_phone": "Büronummer",
            "advanced_search.secondary_location": "Sekundärer Ort",
            "advanced_search.email_all": "E-Mail an alle",
            "advanced_search.searching": "Suchen...",
            "advanced_search.copy_email_addresses": "E-Mail-Adressen übereinstimmender Profile kopieren",
            "advanced_search.search_error":
                "Bei der Suche nach <1>{{searchText, text}}</1> ist ein Fehler aufgetreten.",
            "advanced_search.search_results_count": "{{count, number}} übereinstimmendes Profil",
            "advanced_search.search_results_count_other": "{{count, number}} übereinstimmende Profile",
            "advanced_search.copied_to_clipboard": "{{count, number}} E-Mail in Zwischenablage kopiert",
            "advanced_search.copied_to_clipboard_other": "{{count, number}} E-Mails in Zwischenablage kopiert",
            "advanced_search.count_parens": "({{count, number}})",
            "advanced_search.generic_match": "Übereinstimmende {{tag, text}}: {{query, text}}",
            "stars_and_moons.tenure": "Mitarbeiter seit {{duration, text}}",
            "mentions.group_notify": "Jeden in {{name, text}} benachrichtigen",
            "mentions.company_notify": "Jeden in {{name, text}} benachrichtigen",
            "onboarding_welcome.highlights_title": "Willkommen bei OrgWiki, {{firstName, text}}!",
            "onboarding_welcome.highlights_subtitle":
                "<0>OrgWiki verbindet Sie mit jedem Mitarbeiter bei {{companyName, text}}.</0> Das Portal hat eine Menge zu bieten, hier einige Highlights:",
            "onboarding_welcome.highlight_directory":
                "<0>Unternehmensverzeichnis</0> mit Profilen für jeden Mitarbeiter des Unternehmens",
            "onboarding_welcome.highlight_profiles":
                "<0>Ausführliche Profile</0>, durch die Sie Ihre Kollegen besser kennenlernen",
            "onboarding_welcome.highlight_dlists":
                "<0>Verteilerlisten</0>, in denen Sie alle Ihre Listen sehen und verwalten können",
            "onboarding_welcome.highlight_offices": "<0>Büroinformationen</0> mit interaktiven Grundrissen",
            "onboarding_welcome.highlight_search":
                "<0>Erweiterte Suche</0>, mit der Sie Kollegen nach Name, Titel, Ort, Interessen und vielem mehr suchen können",
            "onboarding_welcome.highlight_feed":
                "<0>Unternehmensfeed</0>, in dem Sie sich über Höherstufungen und Neueinstellungen informieren und geteilte Beiträge lesen können",
            "onboarding_welcome.highlight_feed_non_social":
                "<0>Unternehmensfeed</0>, in dem Sie sich über Höherstufungen und Neueinstellungen informieren können",
            "onboarding_welcome.bottom_para_1":
                "<0>Jeder Mitarbeiter von {{companyName, text}} hat sein eigenes Profil</0> mit seinen <2>Kontaktdaten</2>, einem <4>Foto</4>, Details zu seinen <6>Zuständigkeiten</6>, einem persönlichen <8>Info</8>-Bereich und mehr.",
            "onboarding_welcome.bottom_para_2":
                "<0>Nehmen Sie sich nur wenige Minuten Zeit, um Ihr Profil fertigzustellen. </0>Das meiste haben wir bereits eingetragen – die Fertigstellung ist für Sie ein Kinderspiel!",
            "onboarding_welcome.get_started": "Los geht's!",
            "onboarding_welcome.remind_me_tom": "Morgen erinnern",
            "onboarding_photo.add_photo": "Foto hinzufügen",
            "onboarding_photo.photo_desc":
                "Durch ein gutes Foto erleichtern Sie Ihren Kollegen, Sie zu erkennen und sich Ihren Namen zu merken.",
            "onboarding_photo.remind_me_later": "Später erinnern",
            "onboarding_photo.upload_photo": "Foto hochladen",
            "onboarding_photo.uploading_photo": "Foto wird hochgeladen",
            "onboarding_photo.import_photo": "Haben Sie bereits ein Foto in einem anderen Portal?",
            "onboarding_photo.import_linkedin": "LinkedIn-Foto verwenden",
            "onboarding_photo.import_gsuite": "G Suite-Foto verwenden",
            "onboarding_photo.import_office365": "Office 365-Foto verwenden",
            "onboarding_responsibilities.save_changes": "Meine Änderungen speichern",
            "onboarding_responsibilities.still_accurate": "Dies trifft noch zu",
            "onboarding_responsibilities.what_are_they": "Was sind Ihre Zuständigkeiten?",
            "onboarding_responsibilities.update_them": "Zuständigkeiten aktualisieren",
            "onboarding_responsibilities.still_your_role":
                "Trifft diese Beschreibung noch auf Ihre derzeitige Funktion und Zuständigkeiten zu?",
            "onboarding_responsibilities.describe_wyd": "Beschreiben Sie Ihre Aufgaben bei {{companyName, text}}.",
            "onboarding_responsibilities.what_do_you_even_do":
                "In welcher Funktion sind Sie eingestellt? Was sind Ihre Fachbereiche? Welche Accounts verwalten Sie?",
            "onboarding.save_and_continue": "Speichern und fortfahren",
            "onboarding.remind_me_later": "Später erinnern",
            "onboarding_responsibilities.others_responsibility": "Zuständigkeiten von {{name, text}}",
            "onboarding.need_inspiration": "Wollen Sie sich inspirieren lassen?",
            "onboarding_about.title": "Erzählen Sie uns von sich selbst.",
            "onboarding_about.help_coworkers": "Helfen Sie Ihren Kollegen, Sie besser kennenzulernen.",
            "onboarding_about.ideas":
                "Wo wurden Sie geboren? Wo haben Sie vor Ihrer Einstellung bei {{companyName, text}} gearbeitet? Was machen Sie an Wochenenden?",
            "onboarding_about.others_about": "Info zu {{name, text}}",
            "onboarding_mobile.title": "Danke für die Aktualisierung Ihres Profils!",
            "onboarding_mobile.in_your_pocket": "{{companyName, text}} in Ihrer Jackentasche!",
            "onboarding_mobile.while_ur_here":
                "Möchten Sie die Gelegenheit nutzen, die mobile App von OrgWiki gleich jetzt auf Ihrem Smartphone zu installieren?",
            "onboarding_mobile.get_mobile_app": "Mobile App erhalten",
            "onboarding_mobile.must_have":
                "<0>Die mobile App von OrgWiki für iOS und Android ist ein Must-Have</0> mit praktischen Funktionen wie:",
            "onboarding_mobile.contact_book": "<0>Kontaktliste</0> mit Profilen für jeden Mitarbeiter des Unternehmens",
            "onboarding_mobile.name_game":
                "<0>Namensspiele</0> zum Kennenlernen der Namen und Gesichter Ihrer Kollegen (iOS)",
            "onboarding_mobile.callerid": "<0>Erweiterte Anruferkennung</0> – Sie sehen sofort, ob ein Kollege anruft.",
            "onboarding_mobile.floor_plans":
                "<0>Grundrisse</0> der Büros – Damit finden Sie Kollegen und Konferenzräume sofort.",
            "onboarding_mobile.enter_your_number":
                "<0>Geben Sie Ihre Mobiltelefonnummer ein</0>, damit wir Ihnen Ihren Download-Link zusenden können:",
            "onboarding_mobile.send_link": "Download-Link senden",
            "onboarding_mobile.continue": "Weiter",
            "onboarding_mobile.finish_profile_setup": "Mein Profil fertigstellen",
            "onboarding_mobile.download_the_app": "App herunterladen:",
            "onboarding_profile_overlay.title": "Willkommen bei Ihrem Profil!",
            "onboarding_profile_overlay.subtitle":
                "<0>Der Anfang ist gemacht!</0> Wir empfehlen Ihnen nun, Ihr Profil zu überprüfen und sicherzustellen, dass alles richtig ist. Sie können Ihr Profil jederzeit bearbeiten.",
            "onboarding_profile_overlay.close_guide": "Einführung schließen",
            "onboarding_profile_overlay.things_you_can_do": "OrgWiki bietet Ihnen u. a. die folgenden Möglichkeiten:",
            "onboarding_profile_overlay.visit_feed_posts_enabled":
                "Besuch des Feeds mit Beiträgen Ihrer Kollegen und Informationen zu Neueinstellungen, Änderungen in der Führungsebene und mehr!",
            "onboarding_profile_overlay.visit_feed_posts_disabled":
                "Besuch des Feeds mit Informationen zu Neueinstellungen, Änderungen in der Führungsebene und mehr!",
            "onboarding_profile_overlay.search_coworkers":
                "Suche nach Kollegen nach Name, Titel und sonstigen Schlüsselwörtern in deren Profilen",
            "onboarding_profile_overlay.join_communities": "Beitritt zu Communitys mit gemeinsamen Interessen",
            "onboarding_profile_overlay.add_tag": "{{tagLabel, true}} zu Ihrem Profil hinzufügen",
            "onboarding_profile_overlay.view_offices": "Informationen zu Büros bei {{companyName, text}} anzeigen",
            "onboarding_profile_overlay.join_dlists": "Verteilerlisten durchsuchen und beitreten",
            "language_picker.header": "Bevorzugte Sprache",
            "employee_badge.out_of_office": "Abwesend",
            "banners.get_started": "Erste Schritte",
            "banners.claim_30_day_trial": "Gefällt Ihnen, was Sie sehen? Holen Sie sich Ihren 30-Tage-Test!",
            "banners.welcome_to_orgwiki":
                "Willkommen bei OrgWiki! Bitte denken Sie daran, <1>Ihr Profil fertigzustellen.</1>",
            "banners.launching_orgwiki_will_email":
                "Durch den <0>Start von OrgWiki</0> werden die News-Feed-Einträge gelöscht und alle bereitgestellten Benutzer erhalten eine Begrüßungs-E-Mail.",
            "banners.not_yet": "Noch nicht",
            "banners.orgwiki_prelaunch_mode": "OrgWiki befindet sich noch im Pre-Launch-Modus.",
            "banners.launch_orgwiki":
                "<0>Starten Sie OrgWiki</0>, wenn Sie für die Verfügbarkeit für alle Mitarbeiter bereit sind.",
            "banners.orgwiki_not_rolled_out": "OrgWiki wurde noch nicht für alle Mitarbeiter eingerichtet.",
            "banners.feed_suspended_on": "Organigramm-Feed wurde angehalten am {{date, MM/DD/YYYY hh:mm:ss A}}",
            "banners.feed_suspended_started": "Organigramm-Feed wird angehalten am {{date, MM/DD/YYYY hh:mm:ss A}}",
            "footer.copyright": "Copyright © 2013– {{date, YYYY}} <2>Veeva</2>. Alle Rechte vorbehalten.",
            "footer.about": "Über OrgWiki",
            "footer.legal": "Rechtliche Hinweise",
            "footer.contact": "Kontakt",
            "field_value_blank": "Dieses Feld darf nicht leer sein",
            "invalid_email_domain": "Die E-Mail-Domäne gehört nicht zu diesem Unternehmen.",
            "invalid_img_format": "Ungültiges Bildformat. Das Format muss GIF, JPG oder PNG sein.",
            "adding_inactive_manager": "Ein inaktiver Vorgesetzter kann nicht hinzugefügt werden.",
            "circular_mgr_change":
                "Ungültiger Vorgesetzter. {{report, text}} kann {{manager, text}} nicht unterstellt sein, weil {{manager, text}} {{report, text}} unterstellt ist.",
            "adding_mgr_to_inactive_employees": "Vorgesetzter kann keinen inaktiven Mitarbeitern zugeordnet werden.",
            "deleting_active_employees": "Nur inaktive Mitarbeiter können gelöscht werden."
        }
    },
    "nl": {
        "common": {
            "navbar.feed_sm": "Feed",
            "navbar.feed": "Nieuwsfeed",
            "navbar.people": "Personen",
            "navbar.groups": "Groepen",
            "navbar.offices": "Kantoren",
            "navbar.menu": "Menu",
            "navbar.admin": "Admin",
            "navbar.my_profile": "Mijn profiel",
            "navbar.company_config": "Bedrijf configureren",
            "navbar.manage_employees": "Medewerkers beheren",
            "navbar.manage_guest_passes": "Gastpassen beheren",
            "navbar.reports": "Rapporten en analyses",
            "navbar.admin_enter": "Naar Admin-modus",
            "navbar.admin_exit": "Admin-modus verlaten",
            "navbar.logout": "Afmelden",
            "navbar.restart_tour": "Rondleiding opnieuw starten",
            "notifications.count+": "{{count, number}}+",
            "notifications.count": "{{count, number}}",
            "notifications.read_error": "Er is een probleem met het als gelezen markeren van alle meldingen.",
            "notifications.empty": "Niets nieuws op dit moment.",
            "notifications.action_required": "Actie vereist",
            "notifications.notifications": "Meldingen",
            "notifications.moment_year": "{{date, MMMM D, YYYY}}",
            "notifications.moment": "{{date, MMMM D}}",
            "notifications.moment_from": "{{date, fromNow}}",
            "notifications.read_all": "Alles als gelezen markeren",
            "instant_search.office_count": "{{count, number}} persoon in dit kantoor",
            "instant_search.office_count_other": "{{count, number}} personen in dit kantoor",
            "instant_search.room_capacity": "Capaciteit van {{count, number}} personen",
            "instant_search.conference_room": "Vergaderzaal",
            "instant_search.members_everyone": "Iedereen",
            "instant_search.members_more": "{{count, number}}+ leden",
            "instant_search.members": "{{count, number}} lid",
            "instant_search.members_other": "{{count, number}} leden",
            "instant_search.skill_count": "{{count, number}} persoon met {{label, text}}",
            "instant_search.skill_count_other": "{{count, number}} personen met {{label, text}}",
            "instant_search.skill_alias": "(ook wel bekend als <1>{{match, text}}</1>)",
            "instant_search.all_groups": "Alle groepen weergeven",
            "instant_search.all_skills": "Alle {{label, text}} weergeven",
            "instant_search.advanced_search": "Geavanceerd personen zoeken",
            "instant_search.placeholder": "Zoeken op OrgWiki",
            "loading...": "Laden.....",
            "loading": "Laden",
            "cancel": "Annuleren",
            "save": "Opslaan",
            "yes": "Ja",
            "no": "Nee",
            "ok": "OK",
            "saving...": "Opslaan...",
            "edit": "Bewerken",
            "update": "Bijwerken",
            "create": "Maken",
            "delete": "Verwijderen",
            "deleting...": "Verwijderen.....",
            "count": "{{count, number}}",
            "area.label": "Gebied",
            "area.label_other": "Gebieden",
            "product.label": "Product",
            "product.label_other": "Producten",
            "skill.label": "Vaardigheid",
            "skill.label_other": "Vaardigheden",
            "skill.level1": "Beginner",
            "skill.level2": "Gevorderde",
            "skill.level3": "Geavanceerd",
            "skill.level4": "Expert",
            "profile_page.manager": "Manager",
            "profile_page.managers": "Managers",
            "profile_page.change_manager": "Veranderingsmanager",
            "profile_page.one_minute_ago": "1 minuut geleden",
            "profile_page.count_minutes_ago": "{{minuteDisplay, number}} minuten geleden",
            "profile_page.an_hour_ago": "een uur geleden",
            "profile_page.count_hours_ago": "{{hourDisplay, number}} uur geleden",
            "profile_page.a_day_ago": "een dag geleden",
            "profile_page.count_days_ago": "{{dayDisplay, number}} dagen geleden",
            "profile_page.count_weeks_ago": "{{weekDisplay, number}} weken geleden",
            "profile_page.count_months_ago": "{{monthDisplay, number}} maanden geleden",
            "profile_page.count_years_ago": "{{yearDisplay, number}} jaar geleden",
            "profile_page.updated_one_minute_ago": "Laatst bijgewerkt: 1 minuut geleden",
            "profile_page.updated_count_minutes_ago": "Laatst bijgewerkt: {{minuteDisplay, number}} minuten geleden",
            "profile_page.updated_an_hour_ago": "Laatst bijgewerkt: een uur geleden",
            "profile_page.updated_count_hours_ago": "Laatst bijgewerkt: {{minuteDisplay, number}} uur geleden",
            "profile_page.updated_a_day_ago": "Laatst bijgewerkt: een dag geleden",
            "profile_page.updated_count_days_ago": "Laatst bijgewerkt: {{minuteDisplay, number}} dagen geleden",
            "profile_page.updated_count_weeks_ago": "Laatst bijgewerkt: {{minuteDisplay, number}} weken geleden",
            "profile_page.updated_count_months_ago": "Laatst bijgewerkt: {{minuteDisplay, number}} maanden geleden",
            "profile_page.updated_count_years_ago": "Laatst bijgewerkt: {{minuteDisplay, number}} jaar geleden",
            "profile_page.team": "Team",
            "profile_page.teams": "Teams",
            "profile_page.community": "Community",
            "profile_page.communities": "Community's",
            "profile_page.posts": "Berichten",
            "profile_page.count_members": "{{count, number}} leden",
            "profile_page.direct_and_total_reports_count":
                "{{directReportsCount, number}} direct ondergeschikten · {{descendantsCount, number}} totaal aantal ondergeschikten",
            "profile_page.direct_reports_count": "{{directReportsCount, number}} direct ondergeschikten",
            "profile_page.reports": "Ondergeschikten",
            "profile_page.profile_photo_title": "Profielfoto van {{employeeFirstName, text}}",
            "profile_page.desk_location": "Bureaulocatie",
            "profile_page.dlists_count": "Distributielijsten ({{dlistsCount, number}})",
            "profile_page.service_meeting": "{{serviceName, text}} vergadering",
            "profile_page.web_conference": "Webconferentie",
            "profile_page.add_manager": "Manager toevoegen",
            "profile_page.edit_manager": "Manager bewerken",
            "profile_page.add_reports": "Ondergeschikten toevoegen",
            "profile_page.edit_reports": "Ondergeschikten bewerken",
            "profile_page.no_manager_or_reports": "Geen manager of ondergeschikten",
            "profile_page.organization": "Organisatie",
            "profile_page.dotted_line_reports": "Ondergeschikte met stippellijn",
            "profile_page.add_direct_report": "Direct ondergeschikte toevoegen",
            "profile_page.add_dotted_line_report": "Ondergeschikte met stippellijn toevoegen",
            "profile_page.direct_reports": "Direct ondergeschikten",
            "profile_page.copy_the_teams_email_addresses": "Kopieer de e-mailadressen van het team",
            "profile_page.name_plus_reports": "{{name, text}} + ondergeschikten",
            "profile_page.name_plus_team": "{{name, text}} + team",
            "profile_page.count_people": "{{count, number}} personen",
            "profile_page.download_team_powerpoint": "Team Powerpoint downloaden",
            "profile_page.employee_will_be_notified":
                "{{name, text}} wordt gemeld wanneer er wijzigingen worden opgeslagen.",
            "profile_page.your_team_slides": "Het downloaden van Team Slides begint dadelijk.",
            "profile_page.preparing_team_slides": "Team Slides wordt voorbereid",
            "profile_page.copied_to_clipboard": "{{prefix, text}} voor {{listText, text}} naar Klembord gekopieerd",
            "profile_page.distribution_list": "Distributielijst",
            "profile_page.count_email": "{{count, number}} e-mail",
            "profile_page.count_email_other": "{{count, number}} e-mails",
            "profile_page.add_direct_manager": "Directe manager toevoegen",
            "profile_page.direct_manager": "Directe manager",
            "profile_page.dotted_line_managers": "Managers met stippellijn",
            "profile_page.employee_profile_only_percent_complete":
                "Het profiel van {{name, text}} is slechts {{percent, number}}% compleet.",
            "profile_page.employee_profile_percent_complete":
                "Het profiel van {{name, text}} is {{percent, number}}% compleet",
            "profile_page.your_profile_only_percent_complete": "Uw profiel is slechts {{percent, number}}% compleet.",
            "profile_page.your_profile_percent_complete": "Uw profiel is {{percent, number}}% compleet.",
            "profile_page.this_profile_only_percent_complete": "Dit profiel is slechts {{percent, number}}% compleet.",
            "profile_page.this_profile_percent_complete": "Dit profiel is {{percentage, number}}% compleet.",
            "profile_page.hide_details": "Details verbergen",
            "profile_page.show_details": "Details weergeven",
            "profile_page.add": "Toevoegen",
            "profile_page.phone_number": "Telefoonnummer",
            "profile_page.profile_photo": "Profielfoto",
            "profile_page.upload_your_photo": "Uw foto uploaden",
            "profile_page.add_a_number": "Een mobiel of kantoornummer toevoegen",
            "profile_page.include_the_city": "Vermeld ook de plaats waar u werkt",
            "profile_page.location": "Locatie",
            "profile_page.fill_in_responsibilities": "Vul uw verantwoordelijkheden in",
            "profile_page.responsibilities": "Verantwoordelijkheden",
            "profile_page.share_about_yourself": "Vertel een en ander over uzelf",
            "profile_page.about": "Info",
            "profile_page.you_have_more_things":
                "U hebt nog {{count, number}} ding te doen om uw profiel compleet te maken.",
            "profile_page.you_have_more_things_other":
                "U hebt nog {{count, number}} dingen te doen om uw profiel compleet te maken.",
            "profile_page.email_team": "E-mail team",
            "profile_page.key": "Sleutel:",
            "profile_page.create_new_skill": "Nieuw {{tag, text}} maken: {{name, text}}",
            "profile_page.already_have_skill": "(U hebt dit al: {{tag, text}})",
            "profile_page.search_skills": "{{tag, text}} zoeken",
            "profile_page.add_new_skill": "Een nieuw {{tag, text}} toevoegen",
            "profile_page.select_a_level": "Selecteer een niveau:",
            "profile_page.add_skill": "{{tag, text}} toevoegen",
            "profile_page.add_a_skill": "Voeg een {{tag, text}} toe",
            "profile_page.problem_saving_skills":
                "Er is een probleem met het opslaan van uw wijzigingen die gerelateerd zijn aan {{tag, text}}",
            "profile_page.no_skills": "{{name, text}} heeft geen {{tag, text}} toegevoegd",
            "profile_page.remove_skill": "{{tag, text}} verwijderen",
            "profile_page.skill_max_size": "{{tag, text}} mag niet langer zijn dan 100 tekens.",
            "audit_trail.hide_history": "Geschiedenis verbergen",
            "audit_trail.view_history": "Geschiedenis weergeven",
            "audit_trail.profile_history": "Profielgeschiedenis",
            "audit_trail.object": "Object",
            "audit_trail.name": "Naam",
            "audit_trail.modified_date": "Wijzigingsdatum",
            "audit_trail.modified_by": "Gewijzigd door",
            "audit_trail.action": "Actie",
            "audit_trail.field": "Veld",
            "audit_trail.field_name": "Veldnaam",
            "audit_trail.old_value": "Oude waarde",
            "audit_trail.new_value": "Nieuwe waarde",
            "audit_trail.date": "{{date, MM/DD/YYYY hh:mm:ss A}}",
            "audit_trail.restricted_membership": "Beperkt lidmaatschap",
            "audit_trail.is_active": "Is actief",
            "audit_trail.person": "Persoon",
            "audit_trail.title": "Functie",
            "audit_trail.manager": "Manager",
            "audit_trail.email": "E-mail",
            "audit_trail.city_description": "Beschrijving plaats",
            "audit_trail.first_name": "Voornaam",
            "audit_trail.last_name": "Achternaam",
            "audit_trail.external_id": "Externe ID",
            "audit_trail.start_date": "Datum in dienst",
            "audit_trail.mobile_phone": "Mobiele telefoon",
            "audit_trail.text": "Bericht",
            "audit_trail.skill_level": "Vaardigheidsniveau",
            "audit_trail.office_phone": "Telefoon op kantoor",
            "audit_trail.city": "Plaats",
            "audit_trail.country": "Land",
            "audit_trail.office_type": "Type kantoor",
            "audit_trail.state": "Staat",
            "audit_trail.responsibilities": "Verantwoordelijkheden",
            "audit_trail.profile_photo": "Profielfoto",
            "audit_trail.location_address": "Adres locatie",
            "audit_trail.location_type": "Type locatie",
            "audit_trail.about": "Info",
            "audit_trail.post_text": "Berichttekst",
            "audit_trail.is_contractor": "Is contractant",
            "audit_trail.is_admin": "Is admin",
            "audit_trail.can_create_company_mentions": "Kan bedrijfs-mentions maken",
            "audit_trail.balloons": "Ballonnen",
            "audit_trail.photo": "Foto",
            "audit_trail.synonym_of": "Synoniem van",
            "audit_trail.external_email": "Externe e-mail",
            "audit_trail.story_text": "Verhaaltekst",
            "audit_trail.out_of_office_message": "Afwezigheidsbericht",
            "audit_trail.out_of_office": "Afwezig",
            "audit_trail.ip_address": "IP-adres",
            "audit_trail.role": "Rol",
            "audit_trail.description": "Beschrijving",
            "audit_trail.about_me": "Info over mij",
            "audit_trail.nested_group": "Geneste groep",
            "audit_trail.invite_only": "Alleen op uitnodiging",
            "audit_trail.what_am_i_doing_this_quarter": "Waar ben ik dit kwartaal mee bezig",
            "audit_trail.my_responsibilities": "Mijn verantwoordelijkheden",
            "audit_trail.office_location": "Locatie van kantoor",
            "audit_trail.is_private": "Is privé",
            "audit_trail.linkedin_url": "LinkedIn-URL",
            "audit_trail.is_locked": "Is vergrendeld",
            "audit_trail.projects_past_and_present": "Projecten - huidige en vorige projecten",
            "audit_trail.is_read_only": "Is alleen-lezen",
            "audit_trail.what_i_am_responsible_for": "Waar ben ik verantwoordelijk voor",
            "audit_trail.nick_name": "Bijnaam",
            "audit_trail.primary_location": "Hoofdlocatie",
            "audit_trail.area": "Gebied",
            "audit_trail.zoom_url": "Zoom-URL",
            "audit_trail.created_on_mobile": "Gemaakt op mobiel",
            "audit_trail.who_can_post": "Wie kan publiceren",
            "audit_trail.slack_name": "Slack-naam",
            "audit_trail.mattermost_username": "Mattermost-handle",
            "audit_trail.mattermost_team": "Mattermost-domein",
            "audit_trail.slack_team": "Slack-team",
            "audit_trail.dotted_line_manager": "Manager met stippellijn",
            "audit_trail.owners": "Eigenaren",
            "audit_trail.products": "Producten",
            "audit_trail.additional_contact_methods": "Extra contactmethoden",
            "audit_trail.skype_name": "Skypenaam",
            "audit_trail.key_accounts": "Belangrijke accounts",
            "audit_trail.what_am_i_responsible_for": "Waar ben ik verantwoordelijk voor",
            "audit_trail.primary_location_floor_plan": "Hoofdlocatie - Bureaulocatie",
            "audit_trail.is_owner": "Is eigenaar",
            "audit_trail.secondary_location_address": "Adres van tweede locatie",
            "audit_trail.desk_location": "Bureaulocatie",
            "audit_trail.display_address": "Adres weergeven",
            "audit_trail.official_office_distribution_list": "Officiële distributielijst kantoor",
            "audit_trail.prior_start_date": "Vorige datum in dienst",
            "audit_trail.overview_text": "Overzichttekst",
            "audit_trail.conference_room": "Vergaderzaal",
            "audit_trail.expiration_date": "Vervaldatum",
            "audit_trail.type": "Type",
            "audit_trail.conference_room_floor_plan": "Plattegrond vergaderzaal",
            "audit_trail.official_distribution_list": "Officiële distributielijst",
            "audit_trail.company_logo": "Bedrijfslogo",
            "audit_trail.self_signup_enabled": "Zelfinschrijving ingeschakeld",
            "audit_trail.conference_room_capacity": "Capaciteit van vergaderzaal",
            "audit_trail.alternate_address": "Alternatief adres",
            "audit_trail.configuration_mode_enabled": "Modus Pre-launch",
            "audit_trail.webex": "WebEx",
            "audit_trail.secondary_location": "Tweede locatie",
            "audit_trail.primary_address": "Hoofdadres",
            "audit_trail.prior_company_name": "Vorige bedrijfsnaam",
            "audit_trail.membership": "Lidmaatschap",
            "audit_trail.authorizer_email": "E-mail toestemminggever",
            "audit_trail.guest_email": "E-mail gast",
            "audit_trail.guest_last_name": "Achternaam gast",
            "audit_trail.guest_first_name": "Voornaam gast",
            "audit_trail.guest_company_name": "Bedrijfsnaam gast",
            "audit_trail.tags_enabled": "Tags ingeschakeld",
            "audit_trail.company_posts_enabled": "Bedrijfsberichten ingeschakeld",
            "audit_trail.social_groups_enabled": "Sociale berichten ingeschakeld",
            "audit_trail.is_early_adopter": "Is early adopter",
            "audit_trail.wiki_profile_edits_enabled": "Wiki-profielbewerkingen ingeschakeld",
            "audit_trail.conference_room_description": "Beschrijving van vergaderzaal",
            "audit_trail.technical_contact": "Technisch contact",
            "audit_trail.navbar_logo": "Navbar-logo",
            "audit_trail.dist_lists_admin_only": "Distributielijsten Alleen admin",
            "audit_trail.seating_chart": "Plattegrond",
            "audit_trail.primary_color": "Primaire kleur",
            "audit_trail.dotted_lines_enabled": "Stippellijnen ingeschakeld",
            "audit_trail.active_by_default": "Standaard actief",
            "audit_trail.section_type": "Type sectie",
            "audit_trail.intended_for": "Bestemd voor",
            "audit_trail.sort_order": "Sorteervolgorde",
            "audit_trail.update_interval": "Bijwerkinterval",
            "audit_trail.revoked": "Ingetrokken",
            "audit_trail.company": "Bedrijf",
            "audit_trail.okta_metadata_url": "URL Okta-metadata",
            "audit_trail.profile_celebrations_enabled": "Profielvieringen ingeschakeld",
            "audit_trail.highlight_color": "Markeerkleur",
            "audit_trail.background_color": "Achtergrondkleur",
            "audit_trail.secondary_location_floor_plan": "Tweede locatie - Bureaulocatie",
            "audit_trail.primary_button_color": "Primaire knopkleur",
            "audit_trail.company_logo_title": "Titel bedrijfslogo",
            "audit_trail.guest_passes_enabled": "Gastpassen ingeschakeld",
            "audit_trail.timeline_moderation_datetime": "Datum/tijd toezicht tijdlijn",
            "audit_trail.light_navbar": "Navbar licht",
            "audit_trail.champion_contact": "Contactgegevens Champion",
            "audit_trail.dist_lists_enabled": "Distributielijsten ingeschakeld",
            "audit_trail.saml_metadata_url": "URL SAML-metadata",
            "audit_trail.company_logo_url": "URL bedrijfslogo",
            "audit_trail.website": "Website",
            "audit_trail.dist_lists_admin_email": "E-mail admin distributielijsten",
            "audit_trail.dist_lists_domain": "Domein distributielijsten",
            "audit_trail.billing_contact": "Factureringscontract",
            "audit_trail.welcome_email_enabled": "Welkomst-e-mail ingeschakeld",
            "audit_trail.sponsor_contact": "Contactgegevens sponsor",
            "audit_trail.birthday_today": "Vandaag jarig",
            "audit_trail.secondary_contact": "Tweede contactpersoon",
            "audit_trail.light_background": "Lichte achtergrond",
            "audit_trail.primary_contact": "Eerste contactpersoon",
            "audit_trail.saml_metadata_file": "Bestand met SAML-metadata",
            "audit_trail.contest_winner": "Winnaar van wedstrijd",
            "audit_trail.alternate_email": "Alternatief e-mailadres",
            "audit_trail.employees_manager": "Manager van <0>{{name, text}}</0>",
            "audit_trail.employees_dotted_line_manager": "Manager met stippellijn van <0>{{name, text}}</0>",
            "profile_edit_page.first_name": "Voornaam",
            "profile_edit_page.nickname": "Bijnaam",
            "profile_edit_page.last_name": "Achternaam",
            "profile_edit_page.title": "Functie",
            "profile_edit_page.contact_information": "Contactgegevens",
            "profile_edit_page.email": "E-mail",
            "profile_edit_page.office_phone": "Telefoon op kantoor",
            "profile_edit_page.mobile_phone": "Mobiele telefoon",
            "profile_edit_page.messaging": "Berichten",
            "profile_edit_page.slack_team": "Slack-team",
            "profile_edit_page.slack_username": "Slack-gebruikersnaam",
            "profile_edit_page.mattermost_team": "Mattermost-domein",
            "profile_edit_page.mattermost_username": "Mattermost-handle",
            "profile_edit_page.conferencing": "Vergaderen",
            "profile_edit_page.location": "Locatie",
            "profile_edit_page.primary_work_location": "Hoofdlocatie werk",
            "profile_edit_page.office": "Kantoor",
            "profile_edit_page.edit_desk_location": "Bureaulocatie bewerken",
            "profile_edit_page.secondary_work_location": "Tweede werklocatie",
            "profile_edit_page.social": "Sociale media",
            "profile_edit_page.profile_url": "Webadres van profiel",
            "profile_edit_page.locate_mine": "Die van mij vinden",
            "profile_edit_page.other_contact_info": "Andere contactgegevens",
            "profile_edit_page.other_contact_info_description":
                "Voorkeursmethode voor communicatie? Andere communicatiekanalen? Locatie van kantoor/werkplek?",
            "profile_edit_page.admin_fields": "Admin-velden",
            "profile_edit_page.active_employee": "Actieve werknemer",
            "profile_edit_page.start_date": "Datum in dienst",
            "profile_edit_page.prior_company_name": "Naam vorig bedrijf",
            "profile_edit_page.prior_start_date": "Vorige datum in dienst",
            "profile_edit_page.prior_start_date_hover":
                "Als de werknemer van een overgenomen bedrijf afkomstig is, is dit de datum waarop hij/zij bij dat bedrijf is begonnen.",
            "profile_edit_page.admin": "Admin",
            "profile_edit_page.contractor": "Contractant",
            "profile_edit_page.external_id": "Externe id",
            "profile_edit_page.announcements": "Aankondigingen",
            "profile_edit_page.balloons": "Ballonnen",
            "profile_edit_page.last_modified_date": "Datum laatste wijziging",
            "profile_edit_page.last_modified_by": "Laatst gewijzigd door",
            "profile_edit_page.profile_photo": "Profielfoto",
            "profile_edit_page.customize_custom_sections": "Aangepaste secties aanpassen",
            "profile_edit_page.meeting_url": "Webadres voor vergadering",
            "profile_edit_page.name": "Naam",
            "profile_edit_page.personal_room_url": "Webadres voor persoonlijke kamer",
            "profile_edit_page.address": "Adres",
            "profile_edit_page.set_desk_location": "Bureaulocatie instellen",
            "profile_edit_page.early_adopter": "Early adopter",
            "profile_edit_page.deleting...": "Verwijderen.....",
            "profile_edit_page.delete_profile": "Profiel verwijderen",
            "profile_edit_page.saving...": "Opslaan...",
            "profile_edit_page.save_and_add_another": "Opslaan en nieuwe toevoegen",
            "profile_edit_page.active?": "Actief?",
            "profile_edit_page.intended_for": "Bestemd voor",
            "profile_edit_page.no_sections": "Er zijn nog geen secties gemaakt",
            "profile_edit_page.select_custom_sections":
                "Selecteer de secties die iedereen ziet voor <1>{{fullName, text}}</1>",
            "profile_edit_page.mark_inactive_warning":
                "Door een werknemer als inactief te markeren, worden alle direct ondergeschikten aan hun manager toegewezen en wordt hun managertoewijzing beëindigd.",
            "profile_edit_page.mark_active_warning":
                "Het opnieuw activeren van een medewerker resulteert in herstel van het lidmaatschap van de eerdere community en distributielijst.",
            "profile_edit_page.mark_active_note": "Opmerking - u moet hun manager instellen.",
            "profile_edit_page.early_adopter_warning":
                "Deze instelling is alleen van toepassing wanneer OrgWiki zich in de modus Pre-launch bevindt. Early adopters ontvangen e-mails over OrgWiki-producten en in-app meldingen",
            "profile_edit_page.nickname_prompt": "Wilt u uw bijnaam instellen op {{suggestedNickName, text}}?",
            "profile_edit_page.delete_profile_warning":
                "Weet u zeker dat u dit profiel wilt verwijderen? Met het verwijderen van een profiel worden ook alle berichten of opmerkingen van de gebruiker verwijderd. Alle acties die de gebruiker heeft ondernomen, worden nog steeds in de audittrail weergegeven.",
            "profile_edit_page.processing_error":
                "Er is een probleem met de verwerking van uw aanvraag. Code: {{status, number}}, {{statusText, text}}",
            "profile_edit_page.inactive": "Inactief",
            "profile_edit_page.edit_profile_sections": "Profielsecties bewerken",
            "profile_edit_page.save_changes": "Wijzigingen opslaan",
            "profile_edit_page.save_location_and_continue_prompt":
                "U moet uw locatie opslaan voordat u uw bureaulocatie kunt instellen. Wilt u uw profielbewerkingen opslaan en doorgaan?",
            "profile_edit_page.employee_not_active_warning":
                "Waarschuwing: deze medewerker is momenteel niet actief. Gebruikers die geen admin zijn, kunnen dit werknemersdossier niet bekijken.",
            "profile_edit_page.select_one": "Maak een keuze",
            "profile_edit_page.remote": "Extern",
            "profile_edit_page.none": "Geen",
            "profile_edit_page.enter_a_city": "Voer een plaats in",
            "profile_edit_page.edit_employee_profile": "Profiel van {{firstName, text}} {{lastName, text}} bewerken",
            "profile_edit_page.add_manager": "Manager toevoegen",
            "profile_edit_page.mattermost_team_tooltip":
                "Moet het volledig gekwalificeerde domein zijn, bijv. companyname.mattermost.com",
            "profile_edit_page.mattermost_username_tooltip":
                "De @mention-naam van Mattermost is hoofdlettergevoelig, bijv. JoeSmith",
            "profile_edit_page.edit_profile_header":
                "Profiel van <1>{{firstName, text}} {{lastName, text}}</1> bewerken",
            "profile_edit_page.upload_photo": "Foto uploaden",
            "profile_edit_page.use_linkedin_photo": "LinkedIn-foto gebruiken",
            "profile_edit_page.use_office_365_photo": "Office 365-foto gebruiken",
            "profile_edit_page.use_gsuite_photo": "G Suite-foto gebruiken",
            "profile_edit_page.users_will_be_notified":
                "Gebruikers worden op de hoogte gebracht van alle wijzigingen in hun profiel.",
            "profile_edit_page.profile_sections_updated": "Profielsecties bijgewerkt",
            "profile_edit_page.problem_processing_request":
                "Er is een probleem met de verwerking van uw aanvraag. Code: {{status, number}} {{statusText, text}}, fout: {{errorText, text}}",
            "profile_edit_page.employee_created": "Medewerker {{name, text}} gemaakt",
            "profile_edit_page.error": "Fout: {{errorText, text}}",
            "profile_edit_page.city": "Plaats",
            "profile_edit_page.primary_location": "Hoofdlocatie",
            "profile_edit_page.search_offices": "Kantoren zoeken...",
            "locations.all_offices": "Alle kantoren",
            "locations.inactive_offices": "Inactieve kantoren",
            "locations.navigate_to": "Navigeer naar:",
            "locations.new_office": "Nieuw kantoor",
            "locations.offices_caps": "KANTOREN",
            "locations.search_offices": "Kantoren zoeken",
            "locations.no_results_found": "Geen resultaten gevonden.",
            "locations.no_offices": "Geen kantoren",
            "locations.office": "Kantoor",
            "locations.offices": "Kantoren",
            "locations.location": "Locatie",
            "locations.people": "Personen",
            "locations.actions": "Acties",
            "locations.people_count": "{{count, number}} <1>persoon</1>",
            "locations.people_count_other": "{{count, number}} <1>personen</1>",
            "locations.done": "Gereed",
            "locations.office_overview": "Kantooroverzicht",
            "locations.floor_plans": "Plattegronden",
            "locations.conference_rooms": "Vergaderzalen",
            "locations.history": "Geschiedenis",
            "locations.edit_office": "Kantoor bewerken",
            "locations.office_people_count": "<0>{{count, number}} persoon</0> in dit kantoor",
            "locations.office_people_count_other": "<0>{{count, number}} personen</0> in dit kantoor",
            "locations.secondary_office_people_count": "{{count, number}} persoon heeft dit kantoor als tweede locatie",
            "locations.secondary_office_people_count_other":
                "{{count, number}} personen hebben dit kantoor als tweede locatie",
            "locations.office_is_inactive": "Kantoor is inactief",
            "locations.office_inactive_couple_options":
                "Dit kantoor is inactief en alleen zichtbaar voor admins. Er zijn enkele opties:",
            "locations.make_office_visible":
                "Dit maakt het kantoor zichtbaar voor iedereen en maakt het beschikbaar voor gebruikers",
            "locations.duplicate_office_merge":
                "Als dit kantoor een duplicaat is van een bestaand kantoor, voeg ze dan samen!",
            "locations.merge_this_office": "Dit kantoor samenvoegen",
            "locations.set_office_to_active": "Kantoor instellen op Actief",
            "locations.edit_information": "Informatie bewerken",
            "locations.office_information_not_provided": "Er is geen kantoorinformatie verstrekt.",
            "locations.consider_adding_information":
                "Hier kunt u nuttige informatie toevoegen, zoals kantoorcontacten, Wi-Fi-wachtwoorden, speciale instructies en alles wat nuttig kan zijn voor iemand die dit kantoor bezoekt.",
            "locations.information": "Informatie",
            "locations.new_community": "Nieuwe {{communitySynonym, text}}",
            "locations.new_room": "Nieuwe kamer",
            "locations.no_conference_rooms": "Er zijn geen vergaderzalen",
            "locations.search_rooms": "Kamers zoeken",
            "locations.name": "Naam",
            "locations.capacity": "Capaciteit",
            "locations.this_office_empty": "Dit kantoor is leeg.",
            "locations.distribution_list": "Distributielijst",
            "locations.include_secondary_office_members": "Ook leden met een tweede werkplek",
            "locations.title": "Functie",
            "locations.secondary": "Tweede werkplek",
            "locations.primary": "Hoofdwerkplek",
            "locations.search_members": "Leden zoeken",
            "locations.something_went_wrong_dlist":
                "Er is iets misgegaan bij het maken van de officiële distributielijst",
            "locations.primary__required": "Primair adres is een verplicht veld",
            "locations.unexpected_update_error": "Bij het bijwerken van uw kantoor is een onverwachte fout opgetreden.",
            "locations.unexpected_create_error": "Bij het maken van uw kantoor is een onverwachte fout opgetreden.",
            "locations.unexpected_floor_plan_error":
                "Er is een fout opgetreden tijdens het opslaan van de plattegronden. Probeer het opnieuw.",
            "locations.cant_delete_location":
                "U kunt {{name, text}} niet verwijderen terwijl er nog mensen aan zijn gekoppeld.",
            "locations.are_you_sure_delete_location": "Weet u zeker dat u {{name, text}} wilt verwijderen?",
            "locations.add_floor_plan": "Plattegrond toevoegen",
            "locations.edit_floor_plan": "Plattegrond bewerken",
            "locations.edit_address": "Adres bewerken",
            "locations.add_address": "Adres toevoegen",
            "locations.move_up": "Omhoog",
            "locations.office_name": "Kantoornaam",
            "locations.status": "Status",
            "locations.active": "Actief",
            "locations.inactive_hidden": "Inactief (verborgen)",
            "locations.office_photo": "Kantoorfoto",
            "locations.official_distribution_list": "Officiële distributielijst",
            "locations.official_distribution_list_explanation":
                "OrgWiki geeft de officiële lijst prominent weer, zodat gebruikers weten dat ze deze moeten gebruiken om met mensen op kantoor te communiceren.",
            "locations.change_official_distribution_list": "Officiële distributielijst wijzigen",
            "locations.set_official_distribution_list": "Officiële distributielijst instellen",
            "locations.remove": "Verwijderen",
            "locations.no_official_distribution_list_set": "Geen officiële distributielijst ingesteld",
            "locations.primary_address": "Hoofdadres",
            "locations.primary_address_explanation": "Het hoofdadres wordt gebruikt om mensen te zoeken.",
            "locations.merge_with_another_office": "Samenvoegen met een ander kantoor...",
            "locations.add_primary_address": "Hoofdadres toevoegen",
            "locations.no_primary_address_set": "Geen hoofdadres ingesteld",
            "locations.display_address": "Adres weergeven",
            "locations.display_address_explanation": "Dit is hoe het kantooradres wordt weergegeven in OrgWiki.",
            "locations.address_variations": "Adresvariaties",
            "locations.address_variations_explanation":
                "OrgWiki gebruikt deze adressen om mensen te koppelen aan dit kantoor (zodat ze bij het juiste kantoor horen, zelfs als er sprake is van een typfout, of een adres onvolledig is, etc.).",
            "locations.add_address_variation": "Adresvariaties toevoegen",
            "locations.no_address_variations": "Geen adresvariaties",
            "locations.no_floor_plans": "Geen plattegronden",
            "locations.delete_office": "Kantoor verwijderen",
            "locations.floor_plan_name": "Naam van plattegrond",
            "locations.name_is_required": "Naam is verplicht",
            "locations.image_format": "Afbeelding (in de indeling .jpg of .png)",
            "locations.select_image": "Selecteer afbeelding...",
            "locations.image_is_required": "Afbeelding is vereist",
            "locations.loc_merged_into_dest": "{{location, text}} samengevoegd tot {{destination, text}}",
            "locations.merge_warning":
                "Door samenvoeging worden alle personen in het bronkantoor opnieuw toegewezen aan het doelkantoor, wordt het adres van het bronkantoor als alternatief adres aan het doelkantoor toegevoegd en wordt het bronkantoor verwijderd. Plattegronden en vergaderzalen in het bronkantoor blijven niet behouden.",
            "locations.dlist_member_warning":
                "Distributielijsten waarop het bronkantoor als lid staat vermeld, worden bijgewerkt zodat het doelkantoor als lid wordt vermeld.",
            "locations.merge": "Samenvoegen",
            "locations.address_search": "Adres zoeken",
            "locations.street_address": "Adres",
            "locations.city": "Plaats",
            "locations.state_province": "Staat/provincie",
            "locations.country": "Land",
            "locations.save_previous_address": "Vorig adres als alternatief opslaan",
            "locations.found_matching_address": "Er is een adres gevonden dat past bij wat u hebt ingevoerd.",
            "locations.recommend_this_version": "Wij raden het gebruik van deze versie aan:",
            "locations.recommended_address": "Aanbevolen adres:",
            "locations.use_recommended_address": "Aanbevolen adres gebruiken",
            "locations.what_you_entered": "Wat u hebt ingevoerd:",
            "locations.use_this_address": "Dit adres gebruiken",
            "locations.go_back": "Ga terug",
            "locations.address_entered_exists": "Opgegeven adres bestaat al!",
            "locations.existing_address": "Bestaand adres:",
            "locations.similar_address_found":
                "<0>Er is een vergelijkbaar adres gevonden als wat u hebt ingevoerd.</0> Wilt u doorgaan?",
            "locations.address_you_entered": "Adres dat u hebt ingevoerd:",
            "locations.similar_existing_address": "Soortgelijk bestaand adres:",
            "locations.add_address_anyways": "Dit adres toch toevoegen",
            "locations.select_an_existing_list": "Selecteer een bestaande lijst.",
            "locations.enter_an_email_address": "Voer een e-mailadres in.",
            "locations.specify_an_owner": "Voer een eigenaar in.",
            "locations.list_already_exists": "Er bestaat al een lijst met dit e-mailadres.",
            "locations.remove_user_from_group_list":
                "Verwijder <1>{{count, number}} gebruiker</1> uit <4>{{group, text}}</4> aangezien deze ook lid is van geneste lijsten",
            "locations.remove_user_from_group_list_other":
                "Verwijder <1>{{count, number}} gebruikers</1> uit <4>{{group, text}}</4> aangezien deze ook lid zijn van geneste lijsten",
            "locations.remove_user_from_group_office":
                "Verwijder <1>{{count, number}} gebruiker</1> uit <4>{{group, text}}</4> aangezien deze ook lid is van het kantoor",
            "locations.remove_user_from_group_office_other":
                "Verwijder <1>{{count, number}} gebruikers</1> uit <4>{{group, text}}</4> aangezien deze ook lid zijn van het kantoor",
            "locations.remove_list_from_group":
                "Verwijder <1>{{count, number}} lijst</1> uit <4>{{group, text}}</4> aangezien deze ook lid is van geneste lijsten",
            "locations.remove_list_from_group_other":
                "Verwijder <1>{{count, number}} lijsten</1> uit <4>{{group, text}}</4> aangezien deze ook lid zijn van geneste lijsten",
            "locations.remove_nested_list_from_group":
                "Verwijder <1>{{count, number}} geneste lijst</1> uit <4>{{group, text}}</4> aangezien deze ook lid is van de geneste lijst <6>{{name, text}}</6>",
            "locations.remove_nested_list_from_group_other":
                "Verwijder <1>{{count, number}} geneste lijsten</1> uit <4>{{group, text}}</4> aangezien deze ook lid zijn van de geneste lijst <6>{{name, text}}</6>",
            "locations.create_new_distribution_list": "Nieuwe distributielijst maken",
            "locations.use_existing_distribution_list": "Bestaande distributielijst maken",
            "locations.distribution_list_name": "Naam van distributielijst",
            "locations.email_address": "E-mailadres",
            "locations.email": "E-mail",
            "locations.who_can_join": "Wie kunnen er lid worden (als behalve kantoorleden)?",
            "locations.new_members_must_be_approved": "Nieuwe leden moeten worden goedgekeurd",
            "locations.anyone_can_join": "Iedereen kan lid worden",
            "locations.selected_owner": "Geselecteerde eigenaar",
            "locations.select_a_different_owner": "Selecteer een andere eigenaar",
            "locations.select_an_owner": "Selecteer een eigenaar",
            "locations.search_people": "Personen zoeken",
            "locations.select_distribution_list": "Selecteer distributielijst",
            "locations.search_existing_dlists": "Bestaande distributielijsten zoeken",
            "locations.select_a_different_list": "Selecteer een andere lijst",
            "locations.duplicates_found": "<0>Duplicaten gevonden!</0> Suggesties voor opschonen van lijst:",
            "locations.removing_duplicates_membership":
                "Het verwijderen van duplicaten verandert niets aan het lidmaatschap van {{name, text}}.",
            "locations.eliminating_redundancies":
                "Het verwijderen van duplicaten helpt bij het onderhoud van handmatige lidmaatschapswijzigingen en fouten in <2>{{name, text}}</2>.",
            "locations.create_official_distribution_list": "Officiële distributielijst maken",
            "locations.ie_first_floor": "bijv. 'Begane grond'",
            "floor_plans.select_a_floor_plan": "Selecteer een plattegrond:",
            "floor_plans.no_floor_plans": "Geen plattegronden.",
            "floor_plans.edit_floor_plan": "Plattegrond bewerken",
            "floor_plans.loading_floor_plan": "Plattegrond wordt geladen",
            "floor_plans.create_new_room": "Een nieuwe kamer maken {{placeholder, text}}",
            "floor_plans.add_person": "Persoon toevoegen",
            "floor_plans.add_person_colon": "Persoon toevoegen:",
            "floor_plans.add_room": "Kamer toevoegen",
            "floor_plans.add_conference_room": "Vergaderzaal toevoegen",
            "floor_plans.employee_not_in_this_city":
                "Medewerker is niet in deze plaats. Weet u zeker dat u deze wilt toevoegen aan deze plaatsingsdiagram?",
            "floor_plans.search_people": "Personen zoeken...",
            "floor_plans.select_room": "Selecteer kamer:",
            "floor_plans.search_rooms": "Kamers zoeken...",
            "floor_plans.optional": "Optioneel",
            "floor_plans.description": "Beschrijving",
            "floor_plans.capacity": "Capaciteit",
            "floor_plans.room_name": "Naam van kamer",
            "dlists.distribution_lists_caps": "DISTRIBUTIELIJSTEN",
            "dlists.my_distribution_lists": "Mijn distributielijsten",
            "dlists.all_distribution_lists": "Alle distributielijsten",
            "dlists.distribution_lists": "Distributielijsten",
            "dlists.custom_distribution_lists": "Aangepaste distributielijsten",
            "feed.tab_all_updates": "Alle <1>updates</1>",
            "feed.tab_posts": "Berichten",
            "feed.tab_manager_changes": "Manager<1>wijzigingen</1>",
            "feed.tab_title_changes": "Functie<1>wijzigingen</1>",
            "feed.tab_new_employees": "Nieuwe <1>medewerkers</1>",
            "feed.show_more": "Meer activiteit weergeven",
            "feed.no_posts": "Er zijn nog geen berichten. Deel vandaag nog iets.",
            "feed.no_manager_changes": "Geen managerwijzigingen om weer te geven.",
            "feed.no_title_changes": "Geen functiewijzigingen om weer te geven.",
            "feed.no_new_hires": "Geen nieuwe medewerkers om weer te geven.",
            "feed.no_tles": "Geen tijdlijnvermeldingen om weer te geven.",
            "tle.delete_tle_error": "Er is een probleem met het verwijderen van deze tijdlijnvermelding",
            "tle.like_post": "Dit bericht leuk vinden",
            "tle.unlike_post": "Dit bericht niet meer leuk vinden",
            "tle.share_post": "Dit bericht delen",
            "tle.copy_paste_link": "Kopieer en plak de bovenstaande koppeling om dit bericht te delen.",
            "tle.copy_link": "Koppeling kopiëren",
            "tle.link_copied": "Koppeling gekopieerd.",
            "tle.copy": "Kopiëren",
            "tle.comment_placeholder": "Schrijf een opmerking...",
            "tle.delete_confirm":
                "Weet u zeker dat u deze vermelding wilt verwijderen?  Eventuele opmerkingen worden ook verwijderd.",
            "tle.delete_comment_confirm": "Weet u zeker dat u deze opmerking wilt verwijderen?",
            "tle.delete_comment_error": "Er is een probleem met het verwijderen van deze opmerking",
            "tle.back_to_feed": "Terug naar feed",
            "tle.now_reports_to": "Rapporteert nu aan <1></1>",
            "tle.now_dotted_line_reports_to": "Rapporteert nu mede aan <1></1>",
            "tle.title_is_now": "Functie is nu {{title, text}}",
            "tle.joins_as": "Doet mee als {{title, text}}",
            "tle.has_left": "Heeft het bedrijf verlaten - was {{title, text}}",
            "tle.one_liker": "<0></0> vindt dit leuk",
            "tle.two_likers": "<0></0>, <2></2> vinden dit leuk",
            "tle.three_likers": "<0></0>, <2></2>, <4></4> vinden dit leuk",
            "tle.many_likers": "<0></0>, <2></2>, <4></4> en <6>{{otherLikersStr, text}}</6> vinden dit leuk",
            "tle.other_likers_count": "{{count, number}} iemand anders",
            "tle.other_likers_count_other": "{{count, number}} anderen",
            "tle.you": "U",
            "tle.view_count": "{{count, number}} keer bekeken",
            "tle.view_count_other": "{{count, number}} keer bekeken",
            "tle.like": "Vind-ik-leuk",
            "tle.like_other": "Vind-ik-leuks",
            "tle.like_count": "{{count}} vind-ik-leuk",
            "tle.like_count_other": "{{count}} vind-ik-leuks",
            "tle.comment": "Opmerking",
            "tle.comment_other": "Opmerkingen",
            "tle.comment_count": "{{count}} opmerking",
            "tle.comment_count_other": "{{count}} opmerkingen",
            "tle.commenter_title_inactive_annotation": "(Inactief) ",
            "edit_tle.title": "Berichttekst bewerken",
            "edit_tle.optional_post_text_placeholder": "Schrijf een kort bijschrift voordat u het bericht plaatst.",
            "edit_tle.required_post_text_placeholder": "Schrijf een bijschrift (verplicht)",
            "edit_tle.optional_story_text_placeholder": "Verhaal toevoegen (optioneel)",
            "new_post.post_placeholder": "Deel een update met het bedrijf",
            "new_post.story_placeholder": "Een verhaal toevoegen",
            "new_post.drag_photo": "Sleep foto hiernaartoe om te uploaden.",
            "new_post.upload_photo": "Foto uploaden",
            "new_post.posting...": "Publiceren.....",
            "new_post.post": "Publiceren",
            "advanced_search.refine_results": "Resultaten verfijnen",
            "advanced_search.clear_filters": "Filters wissen",
            "advanced_search.title": "Functie",
            "advanced_search.remove_title_filter": "Functiefilter verwijderen",
            "advanced_search.has_title": "Heeft functie",
            "advanced_search.remove_var_filter": "{{tag, text}} filter verwijderen",
            "advanced_search.office": "Kantoor",
            "advanced_search.remove_location_filter": "Locatiefilter verwijderen",
            "advanced_search.location": "Locatie",
            "advanced_search.located_in_office": "Locatie in kantoor",
            "advanced_search.organization": "Organisatie",
            "advanced_search.team": "Team",
            "advanced_search.team_filter": "{{teamSynonym, text}} filter",
            "advanced_search.remove_manager_filter": "Managerfilter verwijderen",
            "advanced_search.select_a_manager": "Selecteer een manager",
            "advanced_search.browse_all_managers": "Door alle managers bladeren",
            "advanced_search.group": "Groep",
            "advanced_search.remove_group_filter": "Groepsfilter verwijderen",
            "advanced_search.has_group": "Heeft groep",
            "advanced_search.less_than_one_year": "< 1 jaar",
            "advanced_search.between_one_and_two_years": "1-2 jaar",
            "advanced_search.between_two_and_three_years": "2-3 jaar",
            "advanced_search.between_three_and_four_years": "3-4 jaar",
            "advanced_search.between_four_and_five_years": "4-5 jaar",
            "advanced_search.greater_than_five_years": "> 5 jaar",
            "advanced_search.tenure": "Periode",
            "advanced_search.show_matches_on": "Overeenkomsten weergeven op",
            "advanced_search.in_facet": "In {{tag, text}}",
            "advanced_search.has_facet": "Heeft {{tag, text}}",
            "advanced_search.responsibilities": "Verantwoordelijkheden",
            "advanced_search.about": "Info",
            "advanced_search.groups": "Groepen",
            "advanced_search.this_quarter": "Dit kwartaal",
            "advanced_search.projects": "Projecten",
            "advanced_search.key_account": "Belangrijk account",
            "advanced_search.email": "E-mail",
            "advanced_search.mobile_phone": "Mobiele telefoon",
            "advanced_search.office_phone": "Telefoon op kantoor",
            "advanced_search.secondary_location": "Tweede locatie",
            "advanced_search.email_all": "Iedereen e-mailen",
            "advanced_search.searching": "Zoeken...",
            "advanced_search.copy_email_addresses": "E-mailadressen van overeenkomende profielen kopiëren",
            "advanced_search.search_error": "Er is een fout opgetreden bij het zoeken naar <1>{{searchText, text}}</1>",
            "advanced_search.search_results_count": "{{count, number}} overeenkomend profiel",
            "advanced_search.search_results_count_other": "{{count, number}} overeenkomende profielen",
            "advanced_search.copied_to_clipboard": "{{count, number}} e-mail gekopieerd naar Klembord",
            "advanced_search.copied_to_clipboard_other": "{{count, number}} e-mails gekopieerd naar Klembord",
            "advanced_search.count_parens": "({{count, number}})",
            "advanced_search.generic_match": "{{tag, text}} die overeenkomst: {{query, text}}",
            "stars_and_moons.tenure": "Medewerker gedurende {{duration, text}}",
            "mentions.group_notify": "Iedereen op de hoogte brengen in {{name, text}}",
            "mentions.company_notify": "Iedereen op de hoogte brengen bij {{name, text}}",
            "onboarding_welcome.highlights_title": "Welkom bij OrgWiki, {{firstName, text}}!",
            "onboarding_welcome.highlights_subtitle":
                "<0>OrgWiki verbindt u met iedereen bij {{companyName, text}}.</0> Veel nuttige info, maar dit zijn enkele hoogtepunten:",
            "onboarding_welcome.highlight_directory": "<0>Bedrijvengids</0> met profielen van iedereen in het bedrijf",
            "onboarding_welcome.highlight_profiles":
                "<0>Gedetailleerde profielen</0> van collega's zodat u elkaar beter leert kennen.",
            "onboarding_welcome.highlight_dlists":
                "Ingebouwde <0>distributielijsten</0>, zodat u al uw lijsten kunt zien en beheren.",
            "onboarding_welcome.highlight_offices": "<0>Kantoorinformatie</0> met interactieve plattegronden.",
            "onboarding_welcome.highlight_search":
                "<0>Geavanceerd zoeken</0> zodat u collega's gemakkelijk kunt vinden op naam, functie, locatie, interesses en meer.",
            "onboarding_welcome.highlight_feed":
                "Met <0>Bedrijfsfeed</0> kunt u recente wijzigingen zien in de functies van collega's, nieuwe medewerkers en gedeelde berichten.",
            "onboarding_welcome.highlight_feed_non_social":
                "Met <0>Bedrijfsfeed</0> kunt u recente wijzigingen zien in de functies van collega's en nieuwe medewerkers.",
            "onboarding_welcome.bottom_para_1":
                "<0>Iedereen bij {{companyName, text}} heeft zijn eigen profiel</0> met zijn of haar <2>contactgegevens</2>, een <4>foto</4>, info over zijn of haar <6>verantwoordelijkheden</6>, een persoonlijke beschrijving genaamd <8>Info over</8> en meer.",
            "onboarding_welcome.bottom_para_2":
                "<0>Neem een paar minuten de tijd om uw profiel compleet te maken. </0>Wij hebben het meeste werk al gedaan, dus u hebt gemakkelijk werk!",
            "onboarding_welcome.get_started": "Aan de slag!",
            "onboarding_welcome.remind_me_tom": "Help mij morgen herinneren",
            "onboarding_photo.add_photo": "Een foto toevoegen",
            "onboarding_photo.photo_desc":
                "Met een goede foto kunnen uw collega's u herkennen en uw naam leren kennen.",
            "onboarding_photo.remind_me_later": "Later",
            "onboarding_photo.upload_photo": "Foto uploaden",
            "onboarding_photo.uploading_photo": "Foto uploaden...",
            "onboarding_photo.import_photo": "Hebt u elders al een foto?",
            "onboarding_photo.import_linkedin": "LinkedIn-foto gebruiken",
            "onboarding_photo.import_gsuite": "G Suite-foto gebruiken",
            "onboarding_photo.import_office365": "Office 365-foto gebruiken",
            "onboarding_responsibilities.save_changes": "Mijn wijzigingen opslaan",
            "onboarding_responsibilities.still_accurate": "Dit is nog steeds juist",
            "onboarding_responsibilities.what_are_they": "Wat zijn uw verantwoordelijkheden?",
            "onboarding_responsibilities.update_them": "Uw verantwoordelijkheden bijwerken",
            "onboarding_responsibilities.still_your_role":
                "Is dit nog steeds uw huidige rol en zijn dit uw verantwoordelijkheden?",
            "onboarding_responsibilities.describe_wyd": "Beschrijf wat u doet bij {{companyName, text}}",
            "onboarding_responsibilities.what_do_you_even_do":
                "Wat is uw rol? Waarin bent u gespecialiseerd? Welke accounts beheert u?",
            "onboarding.save_and_continue": "Opslaan en doorgaan",
            "onboarding.remind_me_later": "Later",
            "onboarding_responsibilities.others_responsibility": "Verantwoordelijkheden van {{name, text}}",
            "onboarding.need_inspiration": "Inspiratie nodig?",
            "onboarding_about.title": "Vertel ons over uzelf",
            "onboarding_about.help_coworkers": "Help uw collega's u beter te leren kennen.",
            "onboarding_about.ideas":
                "Waar komt u vandaan? Waar werkte u voordat u bij {{companyName, text}} kwam werken? Wat doet u in het weekend?",
            "onboarding_about.others_about": "Info over {{name, text}}",
            "onboarding_mobile.title": "Bedankt voor het up-to-date houden van uw profiel!",
            "onboarding_mobile.in_your_pocket": "Alle {{companyName, text}} bij de hand!",
            "onboarding_mobile.while_ur_here":
                "Nu u hier toch bent - wilt u de OrgWiki-app voor mobiele apparaten op uw telefoon installeren?",
            "onboarding_mobile.get_mobile_app": "De app voor mobiele apparaten downloaden",
            "onboarding_mobile.must_have":
                "<0>De OrgWiki-app voor iOS en Android is onmisbaar </0>en heeft geweldige functies zoals:",
            "onboarding_mobile.contact_book":
                "<0>Boek met contactpersonen</0> met daarin profielen van iedereen in het bedrijf",
            "onboarding_mobile.name_game":
                "<0>Naamspelletjes</0> om de namen en gezichten van collega's te leren kennen (iOS).",
            "onboarding_mobile.callerid":
                "<0>Uitgebreide beller-ID</0> om u te laten weten wanneer collega's u bellen.",
            "onboarding_mobile.floor_plans":
                "<0>Plattegronden</0> van kantoren, zodat u collega's en vergaderzalen kunt vinden.",
            "onboarding_mobile.enter_your_number":
                "<0>Voer uw mobiele nummer in</0> en wij sms'en u een koppeling om de app te downloaden:",
            "onboarding_mobile.send_link": "Downloadkoppeling verzenden",
            "onboarding_mobile.continue": "Doorgaan",
            "onboarding_mobile.finish_profile_setup": "Mijn profielinstelling voltooien",
            "onboarding_mobile.download_the_app": "De app downloaden:",
            "onboarding_profile_overlay.title": "Welkom bij uw profiel!",
            "onboarding_profile_overlay.subtitle":
                "<0>U hebt een goede start gemaakt!</0> Wij raden u aan uw profiel te controleren en ervoor te zorgen dat alles correct is. U kunt deze gegevens op elk moment bewerken.",
            "onboarding_profile_overlay.close_guide": "Welkomstgids sluiten",
            "onboarding_profile_overlay.things_you_can_do":
                "Dit zijn enkele van de dingen die u met OrgWiki kunt doen:",
            "onboarding_profile_overlay.visit_feed_posts_enabled":
                "Bezoek de feed om berichten te zien van uw collega's, nieuwe medewerkers, managerswisselingen en nog veel meer!",
            "onboarding_profile_overlay.visit_feed_posts_disabled":
                "Bezoek de feed om nieuwe medewerkers, managerswisselingen en meer te zien!",
            "onboarding_profile_overlay.search_coworkers":
                "Zoek collega's op naam, functie of een willekeurig trefwoord in hun profiel",
            "onboarding_profile_overlay.join_communities":
                "Neem deel aan community's met gemeenschappelijke interesses",
            "onboarding_profile_overlay.add_tag": "Voeg {{tagLabel, true}} toe aan uw profiel",
            "onboarding_profile_overlay.view_offices": "Informatie over kantoren van {{companyName, text}} bekijken",
            "onboarding_profile_overlay.join_dlists": "Bladeren in en deelnemen aan distributielijsten",
            "language_picker.header": "Taalvoorkeur",
            "employee_badge.out_of_office": "Afwezig",
            "banners.get_started": "Aan de slag",
            "banners.claim_30_day_trial": "Spreekt dit u aan? Profiteer van een proefperiode van 30 dagen!",
            "banners.welcome_to_orgwiki": "Welkom bij OrgWiki! Vergeet niet <1>uw profiel in te vullen.</1>",
            "banners.launching_orgwiki_will_email":
                "<0>Met het lanceren van OrgWiki</0> worden alle berichten in nieuwsfeeds gewist en wordt er naar alle ingerichte gebruikers een welkomstmail gestuurd.",
            "banners.not_yet": "Nog niet",
            "banners.orgwiki_prelaunch_mode": "OrgWiki staat in de modus Pre-launch.",
            "banners.launch_orgwiki":
                "<0>Lanceer OrgWiki</0> zodra u klaar bent om dit bij alle medewerkers te implementeren.",
            "banners.orgwiki_not_rolled_out": "OrgWiki is nog niet uitgerold naar alle medewerkers.",
            "banners.feed_suspended_on": "Organigram-feed is tijdelijk geblokkeerd op {{date, MM/DD/YYYY hh:mm:ss A}}",
            "banners.feed_suspended_started":
                "Organigram-feed wordt tijdelijk geblokkeerd vanaf {{date, MM/DD/YYYY hh:mm:ss A}}",
            "footer.copyright": "Copyright ©2013—{{date, YYYY}} <2>Veeva</2>. Alle rechten voorbehouden.",
            "footer.about": "Info over OrgWiki",
            "footer.legal": "Juridisch",
            "footer.contact": "Contact opnemen",
            "field_value_blank": "Dit veld moet een waarde bevatten",
            "invalid_email_domain": "E-maildomein is niet gekoppeld aan dit bedrijf.",
            "invalid_img_format": "Ongeldige beeldindeling. Moet gif, jpg of png zijn.",
            "adding_inactive_manager": "Inactieve manager kan niet worden toegevoegd.",
            "circular_mgr_change":
                "Ongeldige manager. {{report, text}} kan niet rapporteren aan {{manager, text}} omdat {{manager, text}} aan {{report, text}} rapporteert.",
            "adding_mgr_to_inactive_employees": "Manager kan niet aan inactieve medewerkers worden toegevoegd.",
            "deleting_active_employees": "Alleen inactieve werknemers kunnen worden verwijderd."
        }
    },
    "fr": {
        "common": {
            "navbar.feed_sm": "Flux",
            "navbar.feed": "Flux d'actualité",
            "navbar.people": "Personnes",
            "navbar.groups": "Groupes",
            "navbar.offices": "Bureaux",
            "navbar.menu": "Menu",
            "navbar.admin": "Administrateur",
            "navbar.my_profile": "Mon profil",
            "navbar.company_config": "Configurer l'entreprise",
            "navbar.manage_employees": "Gérer les employés",
            "navbar.manage_guest_passes": "Gérer les invitations",
            "navbar.reports": "Rapports et analyse",
            "navbar.admin_enter": "Entrer en mode Admin",
            "navbar.admin_exit": "Quitter le mode Admin",
            "navbar.logout": "Déconnexion",
            "navbar.restart_tour": "Redémarrer la visite",
            "notifications.count+": "{{count, number}}+",
            "notifications.count": "{{count, number}}",
            "notifications.read_error":
                "Un problème est survenu lors du marquage de toutes les notifications comme lues.",
            "notifications.empty": "Aucune actualité à afficher.",
            "notifications.action_required": "Action requise",
            "notifications.notifications": "Notifications",
            "notifications.moment_year": "{{date, MMMM D, YYYY}}",
            "notifications.moment": "{{date, MMMM D}}",
            "notifications.moment_from": "{{date, fromNow}}",
            "notifications.read_all": "Tout marquer comme lu",
            "instant_search.office_count": "{{count, number}} personne dans ce bureau",
            "instant_search.office_count_other": "{{count, number}} personnes dans ce bureau",
            "instant_search.room_capacity": "Capacité de {{count, number}} personne",
            "instant_search.conference_room": "Salle de conférence",
            "instant_search.members_everyone": "Tout le monde",
            "instant_search.members_more": "{{count, number}}+ membres",
            "instant_search.members": "{{count, number}} membre",
            "instant_search.members_other": "{{count, number}} membres",
            "instant_search.skill_count": "{{count, number}} personne avec cette étiquette {{label, text}}",
            "instant_search.skill_count_other": "{{count, number}} personnes avec cette étiquette {{label, text}}",
            "instant_search.skill_alias": "(Autre nom : <1>{{match, text}}</1>)",
            "instant_search.all_groups": "Afficher tous les groupes",
            "instant_search.all_skills": "Afficher toutes les étiquettes {{label, text}}",
            "instant_search.advanced_search": "Recherche avancée de personnes",
            "instant_search.placeholder": "Rechercher dans OrgWiki",
            "loading...": "Chargement en cours...",
            "loading": "Chargement en cours",
            "cancel": "Annuler",
            "save": "Enregistrer",
            "yes": "Oui",
            "no": "Non",
            "ok": "OK",
            "saving...": "Enregistrement en cours...",
            "edit": "Modifier",
            "update": "Mettre à jour",
            "create": "Créer",
            "delete": "Supprimer",
            "deleting...": "Suppression en cours...",
            "count": "{{count, number}}",
            "area.label": "Zone",
            "area.label_other": "Zones",
            "product.label": "Produit",
            "product.label_other": "Produits",
            "skill.label": "Compétence",
            "skill.label_other": "Compétences",
            "skill.level1": "Débutant",
            "skill.level2": "Intermédiaire",
            "skill.level3": "Avancé",
            "skill.level4": "Expert",
            "profile_page.manager": "Responsable",
            "profile_page.managers": "Responsables",
            "profile_page.change_manager": "Changer le responsable",
            "profile_page.one_minute_ago": "Il y a 1 minute",
            "profile_page.count_minutes_ago": "Il y a {{minuteDisplay, number}} minutes",
            "profile_page.an_hour_ago": "il y a une heure",
            "profile_page.count_hours_ago": "Il y a {{hourDisplay, number}} heures",
            "profile_page.a_day_ago": "Il y a un jour",
            "profile_page.count_days_ago": "Il y a {{dayDisplay, number}} jours",
            "profile_page.count_weeks_ago": "Il y a {{weekDisplay, number}} semaines",
            "profile_page.count_months_ago": "Il y a {{monthDisplay, number}} mois",
            "profile_page.count_years_ago": "Il y a {{yearDisplay, number}} ans",
            "profile_page.updated_one_minute_ago": "Dernière mise à jour : il y a 1 minute",
            "profile_page.updated_count_minutes_ago": "Dernière mise à jour : il y a {{minuteDisplay, number}} minutes",
            "profile_page.updated_an_hour_ago": "Dernière mise à jour : il y a une heure",
            "profile_page.updated_count_hours_ago": "Dernière mise à jour : il y a {{hourDisplay, number}} heures",
            "profile_page.updated_a_day_ago": "Dernière mise à jour : il y a un jour",
            "profile_page.updated_count_days_ago": "Dernière mise à jour : il y a {{dayDisplay, number}} jours",
            "profile_page.updated_count_weeks_ago": "Dernière mise à jour : il y a {{weekDisplay, number}} semaines",
            "profile_page.updated_count_months_ago": "Dernière mise à jour : il y a {{monthDisplay, number}} mois",
            "profile_page.updated_count_years_ago": "Dernière mise à jour : il y a {{yearDisplay, number}} années",
            "profile_page.team": "Équipe",
            "profile_page.teams": "Équipes",
            "profile_page.community": "Communauté",
            "profile_page.communities": "Communautés",
            "profile_page.posts": "Publications",
            "profile_page.count_members": "{{count, number}} membres",
            "profile_page.direct_and_total_reports_count":
                "{{directReportsCount, number}} subordonnés directs · {{descendantsCount, number}} nombre total de subordonnés",
            "profile_page.direct_reports_count": "{{directReportsCount, number}} subordonnés directs",
            "profile_page.reports": "Subordonnés",
            "profile_page.profile_photo_title": "Photo de profil de {{employeeFirstName, text}}",
            "profile_page.desk_location": "Emplacement du support technique",
            "profile_page.dlists_count": "Listes de distribution ({{dlistsCount, number}})",
            "profile_page.service_meeting": "Réunion {{serviceName, text}}",
            "profile_page.web_conference": "Conférence Web",
            "profile_page.add_manager": "Ajouter un responsable",
            "profile_page.edit_manager": "Modifier le responsable",
            "profile_page.add_reports": "Ajouter des subordonnés",
            "profile_page.edit_reports": "Modifier des subordonnés",
            "profile_page.no_manager_or_reports": "Aucun responsable ou subordonné",
            "profile_page.organization": "Organisation",
            "profile_page.dotted_line_reports": "Subordonnés indirects",
            "profile_page.add_direct_report": "Ajouter un subordonné direct",
            "profile_page.add_dotted_line_report": "Ajouter un subordonné indirect",
            "profile_page.direct_reports": "Subordonnés directs",
            "profile_page.copy_the_teams_email_addresses": "Copier les adresses e-mail de l'équipe",
            "profile_page.name_plus_reports": "{{name, text}} + Subordonnés",
            "profile_page.name_plus_team": "{{name, text}} + Équipe",
            "profile_page.count_people": "{{count, number}} personnes",
            "profile_page.download_team_powerpoint": "Télécharger la présentation Powerpoint de l'équipe",
            "profile_page.employee_will_be_notified":
                "{{name, text}} sera informé en cas d'enregistrement de modifications.",
            "profile_page.your_team_slides":
                "Le téléchargement de vos diapositives d'équipe devrait commencer dans un instant.",
            "profile_page.preparing_team_slides": "Préparation des diapositives d'équipe",
            "profile_page.copied_to_clipboard":
                "{{prefix, text}} pour {{listText, text}} a été copié dans le presse-papiers",
            "profile_page.distribution_list": "Liste de distribution",
            "profile_page.count_email": "{{count, number}} e-mail",
            "profile_page.count_email_other": "{{count, number}} e-mails",
            "profile_page.add_direct_manager": "Ajouter un responsable direct",
            "profile_page.direct_manager": "Responsable direct",
            "profile_page.dotted_line_managers": "Responsables indirects",
            "profile_page.employee_profile_only_percent_complete":
                "Le profil de {{name, text}} est complet à {{percent, number}} % seulement.",
            "profile_page.employee_profile_percent_complete":
                "Le profil de {{name, text}} est complet à {{percent, number}} %.",
            "profile_page.your_profile_only_percent_complete":
                "Votre profil est complet à {{percent, number}} % seulement.",
            "profile_page.your_profile_percent_complete": "Votre profil est complet à {{percent, number}} %.",
            "profile_page.this_profile_only_percent_complete":
                "Ce profil est complet à {{percent, number}} % seulement.",
            "profile_page.this_profile_percent_complete": "Ce profil est complet à {{percent, number}} %.",
            "profile_page.hide_details": "Masquer les détails",
            "profile_page.show_details": "Afficher les détails",
            "profile_page.add": "Ajouter",
            "profile_page.phone_number": "Numéro de téléphone",
            "profile_page.profile_photo": "Photo de profil",
            "profile_page.upload_your_photo": "Charger votre photo",
            "profile_page.add_a_number": "Ajouter un numéro de téléphone portable ou fixe",
            "profile_page.include_the_city": "Inclure la ville où vous travaillez",
            "profile_page.location": "Lieu",
            "profile_page.fill_in_responsibilities": "Renseigner vos responsabilités",
            "profile_page.responsibilities": "Responsabilités",
            "profile_page.share_about_yourself": "Parlez-nous un peu de vous",
            "profile_page.about": "À propos",
            "profile_page.you_have_more_things":
                "Il vous reste {{count, number}} élément à indiquer pour compléter votre profil.",
            "profile_page.you_have_more_things_other":
                "Il vous reste {{count, number}} éléments à indiquer pour compléter votre profil.",
            "profile_page.email_team": "Envoyer un e-mail à l'équipe",
            "profile_page.key": "Clé :",
            "profile_page.create_new_skill": "Créer une nouvelle {{tag, text}} : {{name, text}}",
            "profile_page.already_have_skill": "(Vous avez déjà cette {{tag, text}})",
            "profile_page.search_skills": "Rechercher {{tag, text}}",
            "profile_page.add_new_skill": "Ajouter une nouvelle {{tag, text}}",
            "profile_page.select_a_level": "Sélectionner un niveau :",
            "profile_page.add_skill": "Ajouter {{tag, text}}",
            "profile_page.add_a_skill": "Ajouter une {{tag, text}}",
            "profile_page.problem_saving_skills":
                "Un problème est survenu lors de l'enregistrement de vos modifications concernant {{tag, text}}",
            "profile_page.no_skills": "{{name, text}} n'a pas ajouté de {{tag, text}}",
            "profile_page.remove_skill": "Supprimer {{tag, text}}",
            "profile_page.skill_max_size": "{{tag, text}} ne doit pas dépasser 100 caractères.",
            "audit_trail.hide_history": "Masquer l'historique",
            "audit_trail.view_history": "Afficher l'historique",
            "audit_trail.profile_history": "Historique du profil",
            "audit_trail.object": "Objet",
            "audit_trail.name": "Nom",
            "audit_trail.modified_date": "Date de modification",
            "audit_trail.modified_by": "Modifié par",
            "audit_trail.action": "Action",
            "audit_trail.field": "Champ",
            "audit_trail.field_name": "Nom du champ",
            "audit_trail.old_value": "Ancienne valeur",
            "audit_trail.new_value": "Nouvelle valeur",
            "audit_trail.date": "{{date, MM/DD/YYYY hh:mm:ss A}}",
            "audit_trail.restricted_membership": "Appartenance limitée",
            "audit_trail.is_active": "Est actif",
            "audit_trail.person": "Personne",
            "audit_trail.title": "Fonction",
            "audit_trail.manager": "Responsable",
            "audit_trail.email": "E-mail",
            "audit_trail.city_description": "Description de la ville",
            "audit_trail.first_name": "Prénom",
            "audit_trail.last_name": "Nom",
            "audit_trail.external_id": "ID externe",
            "audit_trail.start_date": "Date de début",
            "audit_trail.mobile_phone": "Téléphone portable",
            "audit_trail.text": "Texte",
            "audit_trail.skill_level": "Niveau de compétences",
            "audit_trail.office_phone": "Téléphone professionnel",
            "audit_trail.city": "Ville",
            "audit_trail.country": "Pays",
            "audit_trail.office_type": "Type de bureau",
            "audit_trail.state": "État",
            "audit_trail.responsibilities": "Responsabilités",
            "audit_trail.profile_photo": "Photo de profil",
            "audit_trail.location_address": "Adresse du lieu",
            "audit_trail.location_type": "Type d'emplacement",
            "audit_trail.about": "À propos de",
            "audit_trail.post_text": "Texte de la publication",
            "audit_trail.is_contractor": "Est sous-traitant",
            "audit_trail.is_admin": "Est administrateur",
            "audit_trail.can_create_company_mentions": "Peut créer des mentions de l'entreprise",
            "audit_trail.balloons": "Bulles",
            "audit_trail.photo": "Photo",
            "audit_trail.synonym_of": "Synonyme de",
            "audit_trail.external_email": "E-mail externe",
            "audit_trail.story_text": "Texte de l'histoire",
            "audit_trail.out_of_office_message": "Message d'absence",
            "audit_trail.out_of_office": "Absent du bureau",
            "audit_trail.ip_address": "Adresse IP",
            "audit_trail.role": "Rôle",
            "audit_trail.description": "Description",
            "audit_trail.about_me": "À propos de moi",
            "audit_trail.nested_group": "Groupe imbriqué",
            "audit_trail.invite_only": "Inviter uniquement",
            "audit_trail.what_am_i_doing_this_quarter": "Que fais-je ce trimestre",
            "audit_trail.my_responsibilities": "Mes responsabilités",
            "audit_trail.office_location": "Emplacement du bureau",
            "audit_trail.is_private": "Est privé",
            "audit_trail.linkedin_url": "URL LinkedIn",
            "audit_trail.is_locked": "Est verrouillé",
            "audit_trail.projects_past_and_present": "Projets (passés et présents)",
            "audit_trail.is_read_only": "Est en lecture seule",
            "audit_trail.what_i_am_responsible_for": "Quelles sont mes responsabilités",
            "audit_trail.nick_name": "Surnom",
            "audit_trail.primary_location": "Emplacement principal",
            "audit_trail.area": "Zone",
            "audit_trail.zoom_url": "URL de Zoom",
            "audit_trail.created_on_mobile": "Créé depuis un téléphone portable",
            "audit_trail.who_can_post": "Qui peut poster des publications",
            "audit_trail.slack_name": "Nom Slack",
            "audit_trail.mattermost_username": "Pseudo Mattermost",
            "audit_trail.mattermost_team": "Domaine Mattermost",
            "audit_trail.slack_team": "Équipe Slack",
            "audit_trail.dotted_line_manager": "Responsable indirect",
            "audit_trail.owners": "Propriétaires",
            "audit_trail.products": "Produits",
            "audit_trail.additional_contact_methods": "Méthode de contact supplémentaire",
            "audit_trail.skype_name": "Nom Skype",
            "audit_trail.key_accounts": "Comptes principaux",
            "audit_trail.what_am_i_responsible_for": "Quelles sont mes responsabilités",
            "audit_trail.primary_location_floor_plan": "Emplacement principal - Emplacement du poste de travail",
            "audit_trail.is_owner": "Est le propriétaire",
            "audit_trail.secondary_location_address": "Adresse secondaire du lieu",
            "audit_trail.desk_location": "Emplacement du poste de travail",
            "audit_trail.display_address": "Afficher l'adresse",
            "audit_trail.official_office_distribution_list": "Liste de distribution au bureau officielle",
            "audit_trail.prior_start_date": "Date de début antérieure",
            "audit_trail.overview_text": "Vue d'ensemble du texte",
            "audit_trail.conference_room": "Salle de conférence",
            "audit_trail.expiration_date": "Date d'expiration",
            "audit_trail.type": "Type",
            "audit_trail.conference_room_floor_plan": "Plan de la salle de conférence",
            "audit_trail.official_distribution_list": "Liste de distribution officielle",
            "audit_trail.company_logo": "Logo de l'entreprise",
            "audit_trail.self_signup_enabled": "Auto-connexion activée",
            "audit_trail.conference_room_capacity": "Capacité de la salle de conférence",
            "audit_trail.alternate_address": "Autre adresse",
            "audit_trail.configuration_mode_enabled": "Mode de pré-lancement",
            "audit_trail.webex": "WebEx",
            "audit_trail.secondary_location": "Emplacement secondaire",
            "audit_trail.primary_address": "Adresse principale",
            "audit_trail.prior_company_name": "Nom de l'entreprise précédente",
            "audit_trail.membership": "Membres",
            "audit_trail.authorizer_email": "Adresse e-mail de l'autorisateur",
            "audit_trail.guest_email": "Adresse e-mail de l'invité",
            "audit_trail.guest_last_name": "Nom de l'invité",
            "audit_trail.guest_first_name": "Prénom de l'invité",
            "audit_trail.guest_company_name": "Nom de l'entreprise de l'invité",
            "audit_trail.tags_enabled": "Balises activées",
            "audit_trail.company_posts_enabled": "Publications de l'entreprise activées",
            "audit_trail.social_groups_enabled": "Publications sociales activées",
            "audit_trail.is_early_adopter": "Est un adopteur précoce",
            "audit_trail.wiki_profile_edits_enabled": "Modifications du profil Wiki activées",
            "audit_trail.conference_room_description": "Description de la salle de conférence",
            "audit_trail.technical_contact": "Contact technique",
            "audit_trail.navbar_logo": "Logo de la barre de navigation",
            "audit_trail.dist_lists_admin_only": "Administrateur des listes de distribution uniquement",
            "audit_trail.seating_chart": "Plan d'étage",
            "audit_trail.primary_color": "Couleur principale",
            "audit_trail.dotted_lines_enabled": "Liens indirects activés",
            "audit_trail.active_by_default": "Actif par défaut",
            "audit_trail.section_type": "Type de section",
            "audit_trail.intended_for": "Destiné à",
            "audit_trail.sort_order": "Ordre de tri",
            "audit_trail.update_interval": "Intervalle de mise à jour",
            "audit_trail.revoked": "Revoqué",
            "audit_trail.company": "Entreprise",
            "audit_trail.okta_metadata_url": "URL des métadonnées Okta",
            "audit_trail.profile_celebrations_enabled": "Célébration des profils activée",
            "audit_trail.highlight_color": "Couleur de surbrillance",
            "audit_trail.background_color": "Couleur d'arrière-plan",
            "audit_trail.secondary_location_floor_plan": "Emplacement secondaire - Emplacement du poste de travail",
            "audit_trail.primary_button_color": "Couleur du bouton principal",
            "audit_trail.company_logo_title": "Titre du logo de l'entreprise",
            "audit_trail.guest_passes_enabled": "Invitations activées",
            "audit_trail.timeline_moderation_datetime": "Date et heure de modération de la chronologie",
            "audit_trail.light_navbar": "Barre de navigation claire",
            "audit_trail.champion_contact": "Contact du champion",
            "audit_trail.dist_lists_enabled": "Listes de distribution activées",
            "audit_trail.saml_metadata_url": "URL des métadonnées SAML",
            "audit_trail.company_logo_url": "URL du logo de l'entreprise",
            "audit_trail.website": "Site Web",
            "audit_trail.dist_lists_admin_email": "Adresse e-mail de l'administrateur des listes de distribution",
            "audit_trail.dist_lists_domain": "Domaine des listes de distribution",
            "audit_trail.billing_contact": "Contrat de facturation",
            "audit_trail.welcome_email_enabled": "E-mail de bienvenue activé",
            "audit_trail.sponsor_contact": "Contact de sponsor",
            "audit_trail.birthday_today": "Anniversaire aujourd'hui",
            "audit_trail.secondary_contact": "Contact secondaire",
            "audit_trail.light_background": "Arrière-plan clair",
            "audit_trail.primary_contact": "Contact principal",
            "audit_trail.saml_metadata_file": "Fichier de métadonnées SAML",
            "audit_trail.contest_winner": "Gagnant du concours",
            "audit_trail.alternate_email": "Autre adresse e-mail",
            "audit_trail.employees_manager": "Responsable de <0>{{name, text}}</0>",
            "audit_trail.employees_dotted_line_manager": "Responsable indirect de <0>{{name, text}}</0>",
            "profile_edit_page.first_name": "Prénom",
            "profile_edit_page.nickname": "Surnom",
            "profile_edit_page.last_name": "Nom",
            "profile_edit_page.title": "Fonction",
            "profile_edit_page.contact_information": "Coordonnées",
            "profile_edit_page.email": "E-mail",
            "profile_edit_page.office_phone": "Téléphone fixe",
            "profile_edit_page.mobile_phone": "Téléphone portable",
            "profile_edit_page.messaging": "Messagerie",
            "profile_edit_page.slack_team": "Équipe Slack",
            "profile_edit_page.slack_username": "Nom d'utilisateur Slack",
            "profile_edit_page.mattermost_team": "Domaine Mattermost",
            "profile_edit_page.mattermost_username": "Pseudo Mattermost",
            "profile_edit_page.conferencing": "Conférence",
            "profile_edit_page.location": "Lieu",
            "profile_edit_page.primary_work_location": "Lieu de travail principal",
            "profile_edit_page.office": "Bureau",
            "profile_edit_page.edit_desk_location": "Modifier l'emplacement du poste de travail",
            "profile_edit_page.secondary_work_location": "Lieu de travail secondaire",
            "profile_edit_page.social": "Social",
            "profile_edit_page.profile_url": "URL de profil",
            "profile_edit_page.locate_mine": "Localiser le mien",
            "profile_edit_page.other_contact_info": "Autres coordonnées",
            "profile_edit_page.other_contact_info_description":
                "Mode de communication préféré ? Autres canaux de communication ? Emplacement du bureau/box ?",
            "profile_edit_page.admin_fields": "Champs d'administrateur",
            "profile_edit_page.active_employee": "Employé actif",
            "profile_edit_page.start_date": "Date de début",
            "profile_edit_page.prior_company_name": "Nom de l'entreprise précédente",
            "profile_edit_page.prior_start_date": "Date de début antérieure",
            "profile_edit_page.prior_start_date_hover":
                "Si l'employé vient d'une entreprise qui a fait l'objet d'une acquisition, il s'agit de la date à laquelle il a commencé à y travailler.",
            "profile_edit_page.admin": "Administrateur",
            "profile_edit_page.contractor": "Sous-traitant",
            "profile_edit_page.external_id": "ID externe",
            "profile_edit_page.announcements": "Annonces",
            "profile_edit_page.balloons": "Bulles",
            "profile_edit_page.last_modified_date": "Date de dernière modification",
            "profile_edit_page.last_modified_by": "Dernière modification par",
            "profile_edit_page.profile_photo": "Photo de profil",
            "profile_edit_page.customize_custom_sections": "Personnaliser les sections personnalisées",
            "profile_edit_page.meeting_url": "URL de réunion",
            "profile_edit_page.name": "Nom",
            "profile_edit_page.personal_room_url": "URL de salle personnelle",
            "profile_edit_page.address": "Adresse",
            "profile_edit_page.set_desk_location": "Définir le lieu du support technique",
            "profile_edit_page.early_adopter": "Adoption précoce",
            "profile_edit_page.deleting...": "Suppression en cours...",
            "profile_edit_page.delete_profile": "Supprimer le profil",
            "profile_edit_page.saving...": "Enregistrement...",
            "profile_edit_page.save_and_add_another": "Enregistrer et en ajouter un autre",
            "profile_edit_page.active?": "Actif ?",
            "profile_edit_page.intended_for": "Destiné à",
            "profile_edit_page.no_sections": "Aucune section n'a été créée pour l'instant",
            "profile_edit_page.select_custom_sections":
                "Sélectionnez les sections que tout le monde verra pour <1>{{fullName, text}}</1>",
            "profile_edit_page.mark_inactive_warning":
                "En marquant un employé comme inactif, vous réaffectez tous les subordonnés directs à leur responsable, puis annulez l'affectation de ce dernier.",
            "profile_edit_page.mark_active_warning":
                "En réactivant un employé, vous rétablissez son appartenance à la communauté et à la liste de distribution.",
            "profile_edit_page.mark_active_note": "Remarque : vous devez définir son responsable.",
            "profile_edit_page.early_adopter_warning":
                "Ce paramètre s'applique uniquement lorsqu'Orgwiki est en mode de pré-lancement. Les utilisateurs qui l'adoptent le plus tôt recevront des notifications par e-mail et dans l'application sur le produit OrgWiki",
            "profile_edit_page.nickname_prompt": "Voulez-vous définir votre surnom sur {{suggestedNickName, text}} ?",
            "profile_edit_page.delete_profile_warning":
                "Voulez-vous vraiment supprimer ce profil ? La suppression d'un profil supprime également les publications et les commentaires de l'utilisateur. Toutes les actions effectuées par l'utilisateur figureront encore dans la piste d'audit.",
            "profile_edit_page.processing_error":
                "Un problème est survenu lors du traitement de votre demande. Code : {{status, number}}, {{statusText, text}}",
            "profile_edit_page.inactive": "Inactif",
            "profile_edit_page.edit_profile_sections": "Modifier les sections de profil",
            "profile_edit_page.save_changes": "Enregistrer les modifications",
            "profile_edit_page.save_location_and_continue_prompt":
                "Vous devez enregistrer votre emplacement pour pouvoir définir l'emplacement de votre poste de travail. Voulez-vous enregistrer les modifications de votre profil et continuer ?",
            "profile_edit_page.employee_not_active_warning":
                "Avertissement : cet employé est actuellement inactif. Les utilisateurs autres que les administrateurs ne peuvent pas consulter cet enregistrement d'employé.",
            "profile_edit_page.select_one": "Sélectionner un élément",
            "profile_edit_page.remote": "Distant",
            "profile_edit_page.none": "Aucun",
            "profile_edit_page.enter_a_city": "Entrer une ville",
            "profile_edit_page.edit_employee_profile": "Modifier le profil de {{firstName, text}}, {{lastName, text}}",
            "profile_edit_page.add_manager": "Ajouter un responsable",
            "profile_edit_page.mattermost_team_tooltip":
                "Doit être un domaine complètement qualifié, par exemple companyname.mattermost.com",
            "profile_edit_page.mattermost_username_tooltip":
                "Le nom @mention de Mattermost fait la distinction entre les majuscules et les minuscules, par exemple : JoeSmith",
            "profile_edit_page.edit_profile_header":
                "Modifier le profil de <1>{{firstName, text}} {{lastName, text}}</1>",
            "profile_edit_page.upload_photo": "Charger une photo",
            "profile_edit_page.use_linkedin_photo": "Utiliser une photo LinkedIn",
            "profile_edit_page.use_office_365_photo": "Utiliser une photo Office 365",
            "profile_edit_page.use_gsuite_photo": "Utiliser une photo G Suite",
            "profile_edit_page.users_will_be_notified":
                "Les utilisateurs seront informés de toute modification apportée à leur profil.",
            "profile_edit_page.profile_sections_updated": "Sections de profil mises à jour",
            "profile_edit_page.problem_processing_request":
                "Un problème est survenu lors du traitement de votre demande. Code : {{status, number}} {{statusText, text}}, erreur : {{errorText, text}}",
            "profile_edit_page.employee_created": "Employé {{name, text}} créé",
            "profile_edit_page.error": "Erreur : {{errorText, text}}",
            "profile_edit_page.city": "Ville",
            "profile_edit_page.primary_location": "Emplacement principal",
            "profile_edit_page.search_offices": "Rechercher des bureaux...",
            "locations.all_offices": "Tous les bureaux",
            "locations.inactive_offices": "Bureaux inactifs",
            "locations.navigate_to": "Accéder à :",
            "locations.new_office": "Nouveau bureau",
            "locations.offices_caps": "BUREAUX",
            "locations.search_offices": "Rechercher des bureaux",
            "locations.no_results_found": "Aucun résultat trouvé.",
            "locations.no_offices": "Aucun bureau",
            "locations.office": "Bureau",
            "locations.offices": "Bureaux",
            "locations.location": "Lieu",
            "locations.people": "Personnes",
            "locations.actions": "Actions",
            "locations.people_count": "{{count, number}} <1>Personne</1>",
            "locations.people_count_other": "{{count, number}} <1>Personnes</1>",
            "locations.done": "Terminé",
            "locations.office_overview": "Vue d'ensemble du bureau",
            "locations.floor_plans": "Plans d'étage",
            "locations.conference_rooms": "Salles de conférence",
            "locations.history": "Historique",
            "locations.edit_office": "Modifier un bureau",
            "locations.office_people_count": "<0>{{count, number}} personne</0> dans ce bureau",
            "locations.office_people_count_other": "<0>{{count, number}} personnes</0> dans ce bureau",
            "locations.secondary_office_people_count":
                "{{count, number}} personne a indiqué que ce bureau est son lieu de travail secondaire",
            "locations.secondary_office_people_count_other":
                "{{count, number}} personnes ont indiqué que ce bureau est leur lieu de travail secondaire",
            "locations.office_is_inactive": "Bureau inactif",
            "locations.office_inactive_couple_options":
                "Ce bureau est inactif et visible uniquement par les administrateurs. Vous avez le choix entre plusieurs options :",
            "locations.make_office_visible":
                "Cette opération rend le bureau visible par tous et permet aux utilisateurs de le rejoindre.",
            "locations.duplicate_office_merge": "Si ce bureau est un doublon d'un bureau existant, regroupez-les.",
            "locations.merge_this_office": "Fusionner ce bureau",
            "locations.set_office_to_active": "Définir le bureau comme actif",
            "locations.edit_information": "Modifier les informations",
            "locations.office_information_not_provided": "Les informations de bureau n'ont pas été fournies.",
            "locations.consider_adding_information":
                "Pensez à ajouter ici des informations utiles, telles que les contacts du bureau, les mots de passe du réseau Wi-Fi, les instructions spéciales et tout autre renseignement pouvant s'avérer utile pour les personnes qui visitent ce bureau.",
            "locations.information": "Informations",
            "locations.new_community": "Nouveau {{communitySynonym, text}}",
            "locations.new_room": "Nouvelle salle",
            "locations.no_conference_rooms": "Aucune salle de conférence",
            "locations.search_rooms": "Rechercher des salles",
            "locations.name": "Nom",
            "locations.capacity": "Capacité",
            "locations.this_office_empty": "Ce bureau est vide.",
            "locations.distribution_list": "Liste de distribution",
            "locations.include_secondary_office_members": "Inclure des membres du bureau secondaire",
            "locations.title": "Fonction",
            "locations.secondary": "Secondaire",
            "locations.primary": "Principal",
            "locations.search_members": "Rechercher des membres",
            "locations.something_went_wrong_dlist":
                "Une erreur est survenue lors de la création de la liste de distribution officielle.",
            "locations.primary__required": "L'adresse principale est un champ obligatoir.e",
            "locations.unexpected_update_error":
                "Une erreur inattendue est survenue lors de la mise à jour de votre bureau.",
            "locations.unexpected_create_error":
                "Une erreur inattendue est survenue lors de la création de votre bureau.",
            "locations.unexpected_floor_plan_error":
                "Une erreur est survenue lors de l'enregistrement des plans d'étage. Veuillez réessayer.",
            "locations.cant_delete_location":
                "Vous ne pouvez pas supprimer {{name, text}} tant que des personnes y sont associées.",
            "locations.are_you_sure_delete_location": "Voulez-vous supprimer {{name, text}} ?",
            "locations.add_floor_plan": "Ajouter un plan d'étage",
            "locations.edit_floor_plan": "Modifier un plan d'étage",
            "locations.edit_address": "Modifier l'adresse",
            "locations.add_address": "Ajouter une adresse",
            "locations.move_up": "Déplacer vers le haut",
            "locations.office_name": "Nom de bureau",
            "locations.status": "Statut",
            "locations.active": "Actif",
            "locations.inactive_hidden": "Inactif (masqué)",
            "locations.office_photo": "Photo de bureau",
            "locations.official_distribution_list": "Liste de distribution officielle",
            "locations.official_distribution_list_explanation":
                "OrgWiki affiche la liste officielle bien en évidence de sorte que les utilisateurs sachent qu'ils doivent l'utiliser pour communiquer avec les personnes dans le bureau.",
            "locations.change_official_distribution_list": "Modifier la liste de distribution officielle",
            "locations.set_official_distribution_list": "Définir la liste de distribution officielle",
            "locations.remove": "Supprimer",
            "locations.no_official_distribution_list_set": "Aucune liste de distribution officielle définie",
            "locations.primary_address": "Adresse principale",
            "locations.primary_address_explanation": "L'adresse principale est utilisée pour rechercher des personnes.",
            "locations.merge_with_another_office": "Fusion avec un autre bureau en cours...",
            "locations.add_primary_address": "Ajouter l'adresse principale",
            "locations.no_primary_address_set": "Aucune adresse principale définie",
            "locations.display_address": "Afficher l'adresse",
            "locations.display_address_explanation":
                "Cette option indique comment les adresses de bureaux seront affichées dans OrgWiki.",
            "locations.address_variations": "Variations d'adresse",
            "locations.address_variations_explanation":
                "OrgWiki utilise ces adresses pour mettre des personnes en correspondance avec ce bureau (elles correspondront ainsi au bon bureau même si leur nom contient une faute de frappe, est incomplet, etc.).",
            "locations.add_address_variation": "Ajouter une variation d'adresse",
            "locations.no_address_variations": "Aucune variation d'adresse",
            "locations.no_floor_plans": "Aucun plan d'étage",
            "locations.delete_office": "Supprimer le bureau",
            "locations.floor_plan_name": "Nom de plan d'étage",
            "locations.name_is_required": "Un nom est requis",
            "locations.image_format": "Image (format .jpg ou .png)",
            "locations.select_image": "Sélectionner une image...",
            "locations.image_is_required": "Une image est requise",
            "locations.loc_merged_into_dest": "{{location, text}} fusionné dans {{destination, text}}",
            "locations.merge_warning":
                "La fusion réaffectera toutes les personnes du bureau source au bureau de destination, ajoutera l'adresse du bureau source comme adresse secondaire sur la destination et supprimera le bureau source. Les plans d'étage et les salles de conférence dans le bureau source ne seront pas conservés.",
            "locations.dlist_member_warning":
                "Les listes de distribution qui incluent le bureau source en tant que membre seront mises à jour de sorte à inclure le bureau de destination en tant que membre.",
            "locations.merge": "Fusionner",
            "locations.address_search": "Recherche d'adresse",
            "locations.street_address": "Rue",
            "locations.city": "Ville",
            "locations.state_province": "État/Province",
            "locations.country": "Pays",
            "locations.save_previous_address": "Enregistrer l'adresse précédente comme secondaire",
            "locations.found_matching_address":
                "Nous avons trouvé une adresse qui correspond à ce que vous avez saisi.",
            "locations.recommend_this_version": "Nous vous recommandons d'utiliser cette version :",
            "locations.recommended_address": "Adresse recommandée :",
            "locations.use_recommended_address": "Utiliser l'adresse recommandée",
            "locations.what_you_entered": "Informations saisies :",
            "locations.use_this_address": "Utiliser cette adresse",
            "locations.go_back": "Retour",
            "locations.address_entered_exists": "L'adresse saisie existe déjà !",
            "locations.existing_address": "Adresse existante :",
            "locations.similar_address_found":
                "<0>Nous avons trouvé une adresse identique à ce que vous avez saisi.</0> Voulez-vous continuer ?",
            "locations.address_you_entered": "Adresse saisie :",
            "locations.similar_existing_address": "Adresse existante identique :",
            "locations.add_address_anyways": "Ajouter cette adresse quand même",
            "locations.select_an_existing_list": "Sélectionnez une liste existante.",
            "locations.enter_an_email_address": "Saisissez une adresse e-mail.",
            "locations.specify_an_owner": "Spécifiez un propriétaire.",
            "locations.list_already_exists": "Une liste contenant cette adresse e-mail existe déjà.",
            "locations.remove_user_from_group_list":
                "Supprimer <1>{{count, number}} utilisateur</1> de <4>{{group, text}}</4>, car il est également membre de listes imbriquées",
            "locations.remove_user_from_group_list_other":
                "Supprimer <1>{{count, number}} utilisateurs</1> de <4>{{group, text}}</4>, car ils sont également membres de listes imbriquées",
            "locations.remove_user_from_group_office":
                "Supprimer <1>{{count, number}} utilisateur</1> de <4>{{group, text}}</4>, car il est également membre du bureau",
            "locations.remove_user_from_group_office_other":
                "Supprimer <1>{{count, number}} utilisateurs</1> de <4>{{group, text}}</4>, car il sont également membres du bureau",
            "locations.remove_list_from_group":
                "Supprimer <1>{{count, number}} liste</1> de <4>{{group, text}}</4>, car elle est également membre de listes imbriquées",
            "locations.remove_list_from_group_other":
                "Supprimer <1>{{count, number}} listes</1> de <4>{{group, text}}</4>, car elles sont également membres de listes imbriquées",
            "locations.remove_nested_list_from_group":
                "Supprimer <1>{{count, number}} liste imbriquée</1> de <4>{{group, text}}</4>, car elle est également membre de la liste imbriquée <6>{{name, text}}</6>",
            "locations.remove_nested_list_from_group_other":
                "Supprimer <1>{{count, number}} listes imbriquées</1> de <4>{{group, text}}</4>, car elles sont également membres de la liste imbriquée <6>{{name, text}}</6>",
            "locations.create_new_distribution_list": "Créer une liste de distribution",
            "locations.use_existing_distribution_list": "Utiliser une liste de distribution existante",
            "locations.distribution_list_name": "Nom de la liste de distribution",
            "locations.email_address": "Adresse e-mail",
            "locations.email": "E-mail",
            "locations.who_can_join": "Qui peut se rajouter (outre les membres du bureau) ?",
            "locations.new_members_must_be_approved": "Les nouveaux membres doivent être approuvés.",
            "locations.anyone_can_join": "Tout le monde peut se rajouter.",
            "locations.selected_owner": "Propriétaire sélectionné",
            "locations.select_a_different_owner": "Sélectionner un autre propriétaire",
            "locations.select_an_owner": "Sélectionner un propriétaire",
            "locations.search_people": "Rechercher des personnes",
            "locations.select_distribution_list": "Sélectionner une liste de distribution",
            "locations.search_existing_dlists": "Rechercher les listes de distribution existantes",
            "locations.select_a_different_list": "Sélectionner une autre liste",
            "locations.duplicates_found": "<0>Doublons trouvés<0>Suggestions de nettoyage de liste :",
            "locations.removing_duplicates_membership":
                "La suppression des doublons ne modifie pas l'appartenance de {{name, text}}.",
            "locations.eliminating_redundancies":
                "L'élimination des redondances permet de conserver les erreurs et les modifications de l'appartenance manuelle de <2>{{name, text}}</2>.",
            "locations.create_official_distribution_list": "Créer une liste de distribution officielle",
            "locations.ie_first_floor": "Par exemple « Premier étage »",
            "floor_plans.select_a_floor_plan": "Sélectionner un plan d'étage :",
            "floor_plans.no_floor_plans": "Aucun plan d'étage.",
            "floor_plans.edit_floor_plan": "Modifier un plan d'étage",
            "floor_plans.loading_floor_plan": "Chargement d'un plan d'étage",
            "floor_plans.create_new_room": "Créer une salle {{placeholder, text}}",
            "floor_plans.add_person": "Ajouter une personne",
            "floor_plans.add_person_colon": "Ajouter une personne :",
            "floor_plans.add_room": "Ajouter une salle",
            "floor_plans.add_conference_room": "Ajouter une salle de conférence",
            "floor_plans.employee_not_in_this_city":
                "L'employé ne se trouve pas dans cette ville. Voulez-vous l'ajouter à ce plan de salle ?",
            "floor_plans.search_people": "Rechercher des personnes...",
            "floor_plans.select_room": "Sélectionner une salle :",
            "floor_plans.search_rooms": "Rechercher des salles...",
            "floor_plans.optional": "Facultatif",
            "floor_plans.description": "Description",
            "floor_plans.capacity": "Capacité",
            "floor_plans.room_name": "Nom de salle",
            "dlists.distribution_lists_caps": "LISTES DE DISTRIBUTION",
            "dlists.my_distribution_lists": "Mes listes de distribution",
            "dlists.all_distribution_lists": "Toutes les listes de distribution",
            "dlists.distribution_lists": "Listes de distribution",
            "dlists.custom_distribution_lists": "Listes de distribution personnalisées",
            "feed.tab_all_updates": "Toutes les <1>mises à jour</1>",
            "feed.tab_posts": "Publications",
            "feed.tab_manager_changes": "<1>Changements</1> de responsable",
            "feed.tab_title_changes": "<1>Modifications</1> de fonction",
            "feed.tab_new_employees": "Nouveaux <1>employés</1>",
            "feed.show_more": "Afficher plus d'activité",
            "feed.no_posts": "Il n'existe aucune publication pour l'instant. Partagez une publication aujourd'hui.",
            "feed.no_manager_changes": "Aucun changement de responsable à afficher.",
            "feed.no_title_changes": "Aucune modification de fonction à afficher.",
            "feed.no_new_hires": "Aucune nouvelle recrue à afficher.",
            "feed.no_tles": "Aucune entrée de chronologie à afficher.",
            "tle.delete_tle_error": "Un problème est survenu lors de la suppression de cette entrée de chronologie.",
            "tle.like_post": "Aimer cette publication",
            "tle.unlike_post": "Ne plus aimer cette publication",
            "tle.share_post": "Partager cette publication",
            "tle.copy_paste_link": "Copiez et collez le lien ci-dessus pour partager cette publication.",
            "tle.copy_link": "Copier le lien",
            "tle.link_copied": "Lien copié.",
            "tle.copy": "Copier",
            "tle.comment_placeholder": "Écrire un commentaire...",
            "tle.delete_confirm": "Voulez-vous supprimer cette entrée ?  Les commentaires seront également supprimés.",
            "tle.delete_comment_confirm": "Voulez-vous supprimer ce commentaire ?",
            "tle.delete_comment_error": "Un problème est survenu lors de la suppression de ce commentaire.",
            "tle.back_to_feed": "Retour au flux",
            "tle.now_reports_to": "Maintenant subordonnés à <1></1>",
            "tle.now_dotted_line_reports_to": "Maintenant subordonnés indirects à <1></1>",
            "tle.title_is_now": "La fonction est maintenant la suivante : {{title, text}}",
            "tle.joins_as": "Rejoindre en tant que {{title, text}}",
            "tle.has_left": "A quitté l'entreprise - était {{title, text}}",
            "tle.one_liker": "<0></0> a aimé cela",
            "tle.two_likers": "<0></0>, <2></2> ont aimé cela",
            "tle.three_likers": "<0></0>, <2></2>, <4></4> ont aimé cela",
            "tle.many_likers": "<0></0>, <2></2>, <4></4> et <6>{{otherLikersStr, text}}</6> ont aimé cela",
            "tle.other_likers_count": "{{count, number}} autre",
            "tle.other_likers_count_other": "{{count, number}} autres",
            "tle.you": "Vous",
            "tle.view_count": "{{count, number}} vue",
            "tle.view_count_other": "{{count, number}} vues",
            "tle.like": "J'aime",
            "tle.like_other": "J'aime",
            "tle.like_count": "{{count}} J'aime",
            "tle.like_count_other": "{{count}} J'aime",
            "tle.comment": "Commentaire",
            "tle.comment_other": "Commentaires",
            "tle.comment_count": "{{count}} commentaire",
            "tle.comment_count_other": "{{count}} commentaires",
            "tle.commenter_title_inactive_annotation": "(Inactif) ",
            "edit_tle.title": "Modifier le texte de la publication",
            "edit_tle.optional_post_text_placeholder": "Écrivez une courte légende avant la publication.",
            "edit_tle.required_post_text_placeholder": "Écrire une légende (obligatoire)",
            "edit_tle.optional_story_text_placeholder": "Ajouter une histoire (facultatif)",
            "new_post.post_placeholder": "Partager une mise à jour avec l'entreprise",
            "new_post.story_placeholder": "Ajouter une histoire",
            "new_post.drag_photo": "Faites glisser ici une photo pour la charger.",
            "new_post.upload_photo": "Charger une photo",
            "new_post.posting...": "Publication en cours...",
            "new_post.post": "Publier",
            "advanced_search.refine_results": "Affiner les résultats",
            "advanced_search.clear_filters": "Effacer les filtres",
            "advanced_search.title": "Fonction",
            "advanced_search.remove_title_filter": "Supprimer le filtre de fonction",
            "advanced_search.has_title": "A une fonction",
            "advanced_search.remove_var_filter": "Supprimer le filtre {{tag, text}}",
            "advanced_search.office": "Bureau",
            "advanced_search.remove_location_filter": "Supprimer le filtre de lieu",
            "advanced_search.location": "Lieu",
            "advanced_search.located_in_office": "Situé dans le bureau",
            "advanced_search.organization": "Organisation",
            "advanced_search.team": "Équipe",
            "advanced_search.team_filter": "Filtre {{teamSynonym, text}}",
            "advanced_search.remove_manager_filter": "Supprimer le filtre de responsable",
            "advanced_search.select_a_manager": "Sélectionner un responsable",
            "advanced_search.browse_all_managers": "Parcourir tous les responsables",
            "advanced_search.group": "Groupe",
            "advanced_search.remove_group_filter": "Supprimer le filtre de groupe",
            "advanced_search.has_group": "A un groupe",
            "advanced_search.less_than_one_year": "< 1 an",
            "advanced_search.between_one_and_two_years": "1 à 2 ans",
            "advanced_search.between_two_and_three_years": "2 à 3 ans",
            "advanced_search.between_three_and_four_years": "3 à 4 ans",
            "advanced_search.between_four_and_five_years": "4 à 5 ans",
            "advanced_search.greater_than_five_years": "> 5 ans",
            "advanced_search.tenure": "Ancienneté",
            "advanced_search.show_matches_on": "Afficher les correspondances pour",
            "advanced_search.in_facet": "Dans {{tag, text}}",
            "advanced_search.has_facet": "A {{tag, text}}",
            "advanced_search.responsibilities": "Responsabilités",
            "advanced_search.about": "À propos de",
            "advanced_search.groups": "Groupes",
            "advanced_search.this_quarter": "Ce trimestre",
            "advanced_search.projects": "Projets",
            "advanced_search.key_account": "Compte principal",
            "advanced_search.email": "E-mail",
            "advanced_search.mobile_phone": "Téléphone portable",
            "advanced_search.office_phone": "Téléphone professionnel",
            "advanced_search.secondary_location": "Lieu secondaire",
            "advanced_search.email_all": "Envoyer à tous les contacts",
            "advanced_search.searching": "Recherche en cours...",
            "advanced_search.copy_email_addresses": "Copier les adresses e-mail des profils correspondants",
            "advanced_search.search_error":
                "Désolé, une erreur est survenue lors de la recherche pour <1>{{searchText, text}}</1>.",
            "advanced_search.search_results_count": "{{count, number}} profil correspondant",
            "advanced_search.search_results_count_other": "{{count, number}} profils correspondants",
            "advanced_search.copied_to_clipboard": "{{count, number}} e-mail copié dans le presse-papiers",
            "advanced_search.copied_to_clipboard_other": "{{count, number}} e-mails copiés dans le presse-papiers",
            "advanced_search.count_parens": "({{count, number}})",
            "advanced_search.generic_match": "{{tag, text}} correspondant à {{query, text}}",
            "stars_and_moons.tenure": "Employé depuis {{duration, text}}",
            "mentions.group_notify": "Notifier tout le monde dans {{name, text}}",
            "mentions.company_notify": "Notifier tout le monde chez {{name, text}}",
            "onboarding_welcome.highlights_title": "Bienvenue dans OrgWiki, {{firstName, text}} !",
            "onboarding_welcome.highlights_subtitle":
                "<0>OrgWiki vous met en contact avec tous les autres employés de {{companyName, text}}.</0> Voici certains des nombreux avantages que cela représente :",
            "onboarding_welcome.highlight_directory":
                "<0>Répertoire d'entreprise</0> avec des profils pour tous les employés de l'entreprise.",
            "onboarding_welcome.highlight_profiles":
                "<0>Profils détaillés</0> de vos collègues pour vous aider à mieux les connaître.",
            "onboarding_welcome.highlight_dlists":
                "<0>Listes de distribution</0> intégrées pour afficher et gérer toutes vos listes.",
            "onboarding_welcome.highlight_offices":
                "<0>Informations sur le bureau</0> avec des plans d'étage interactifs.",
            "onboarding_welcome.highlight_search":
                "<0>Recherche avancée</0> pour localiser en toute facilité des collègues en fonction de leur nom, leur poste, leur emplacement, leurs centres d'intérêt, etc.",
            "onboarding_welcome.highlight_feed":
                "<0>Flux d'entreprise</0> pour consulter les mises à jour récentes relatives aux postes de collègues, aux nouvelles recrues et aux publications partagées.",
            "onboarding_welcome.highlight_feed_non_social":
                "<0>Flux d'entreprise</0> pour consulter les mises à jour récentes relatives aux postes de collègues et aux nouvelles recrues.",
            "onboarding_welcome.bottom_para_1":
                "<0>Tous les employés de {{companyName, text}} ont leur propre profil</0> contenant leurs <2>coordonnées</2>, une <4>photo</4>, des informations sur leurs <6>responsabilités</6>, une description personnelle <8>À propos de moi</8> et plus encore.",
            "onboarding_welcome.bottom_para_2":
                "<0>Prenez quelques minutes pour compléter votre profil. </0>Vous avez presque terminé !",
            "onboarding_welcome.get_started": "Commencer",
            "onboarding_welcome.remind_me_tom": "Me le rappeler demain",
            "onboarding_photo.add_photo": "Ajouter une photo",
            "onboarding_photo.photo_desc":
                "Une photo de qualité permet à vos collègues de vous reconnaître et de se souvenir de votre nom.",
            "onboarding_photo.remind_me_later": "Me le rappeler ultérieurement",
            "onboarding_photo.upload_photo": "Charger une photo",
            "onboarding_photo.uploading_photo": "Chargement d'une photo",
            "onboarding_photo.import_photo": "Vous avez déjà une photo ailleurs ?",
            "onboarding_photo.import_linkedin": "Utiliser une photo LinkedIn",
            "onboarding_photo.import_gsuite": "Utiliser une photo G Suite",
            "onboarding_photo.import_office365": "Utiliser une photo Office 365",
            "onboarding_responsibilities.save_changes": "Enregistrer mes modifications",
            "onboarding_responsibilities.still_accurate": "Information toujours exacte",
            "onboarding_responsibilities.what_are_they": "Quelles sont vos responsabilités ?",
            "onboarding_responsibilities.update_them": "Mettre à jour vos responsabilités",
            "onboarding_responsibilities.still_your_role":
                "Cette description correspond-elle toujours à vos responsabilités et à votre rôle actuels ?",
            "onboarding_responsibilities.describe_wyd": "Décrivez votre rôle chez {{companyName, text}}.",
            "onboarding_responsibilities.what_do_you_even_do":
                "Quel est votre rôle ? Quelle est votre spécialité ? Quels comptes gérez-vous ?",
            "onboarding.save_and_continue": "Enregistrer et continuer",
            "onboarding.remind_me_later": "Me le rappeler ultérieurement",
            "onboarding_responsibilities.others_responsibility": "Responsabilités de {{name, text}}",
            "onboarding.need_inspiration": "Besoin d'inspiration ?",
            "onboarding_about.title": "Parlez-nous de vous",
            "onboarding_about.help_coworkers": "Aidez vos collègues à mieux vous connaître.",
            "onboarding_about.ideas":
                "D'où venez-vous ? Où travailliez-vous avant de rejoindre {{companyName, text}} ? Qu'aimez-vous faire pendant le week-end ?",
            "onboarding_about.others_about": "À propos de {{name, text}}",
            "onboarding_mobile.title": "Merci de tenir à jour votre profil !",
            "onboarding_mobile.in_your_pocket": "Tout sur {{companyName, text}} à portée de main !",
            "onboarding_mobile.while_ur_here":
                "Voulez-vous en profiter pour installer l'application mobile OrgWiki sur votre téléphone ?",
            "onboarding_mobile.get_mobile_app": "Obtenir l'application mobile",
            "onboarding_mobile.must_have":
                "<0>L'application mobile OrgWiki pour iOS et Android est incontournable, </0>avec des fonctionnalités de qualité comme indiqué ci-dessous :",
            "onboarding_mobile.contact_book":
                "<0>Carnet d'adresses</0> avec les profils de tous les employés de l'entreprise.",
            "onboarding_mobile.name_game":
                "<0>Jeux de noms</0> pour vous aider à apprendre les noms et à reconnaître les visages de vos collègues (iOS).",
            "onboarding_mobile.callerid":
                "<0>ID de l'appelant amélioré</0> pour vous indiquer lorsqu'un collègue vous appelle.",
            "onboarding_mobile.floor_plans":
                "<0>Plans d'étage</0> des bureaux pour localiser des collègues et des salles de conférence.",
            "onboarding_mobile.enter_your_number":
                "<0>Saisissez votre numéro mobile</0> et nous vous enverrons un lien de téléchargement :",
            "onboarding_mobile.send_link": "Envoyer le lien de téléchargement",
            "onboarding_mobile.continue": "Continuer",
            "onboarding_mobile.finish_profile_setup": "Terminer la configuration de mon profil",
            "onboarding_mobile.download_the_app": "Télécharger l'application :",
            "onboarding_profile_overlay.title": "Bienvenue dans votre profil !",
            "onboarding_profile_overlay.subtitle":
                "<0>Vous êtes bien parti !</0> À ce stade, nous vous encourageons à réviser votre profil et à vous assurer que tout est correct. Vous pouvez le modifier à tout moment.",
            "onboarding_profile_overlay.close_guide": "Fermer le guide de bienvenue",
            "onboarding_profile_overlay.things_you_can_do":
                "Voici quelques exemples de ce que vous pouvez faire avec OrgWiki :",
            "onboarding_profile_overlay.visit_feed_posts_enabled":
                "Consulter le flux pour voir les publications de vos collègues, les nouvelles recrues, les changements de responsable, etc.",
            "onboarding_profile_overlay.visit_feed_posts_disabled":
                "Consulter le flux pour voir les nouvelles recrues, les changements de responsable, etc.",
            "onboarding_profile_overlay.search_coworkers":
                "Rechercher des collègues en fonction de leur nom, leur poste ou tout mot-clé sur leur profil",
            "onboarding_profile_overlay.join_communities": "Rejoindre certaines communautés ayant des intérêts communs",
            "onboarding_profile_overlay.add_tag": "Ajouter {{tagLabel, true}} à votre profil",
            "onboarding_profile_overlay.view_offices":
                "Afficher les informations sur les bureaux de {{companyName, text}}",
            "onboarding_profile_overlay.join_dlists": "Parcourir et rejoindre des listes de distribution",
            "language_picker.header": "Préférence de langue",
            "employee_badge.out_of_office": "Absent du bureau",
            "banners.get_started": "Commencer",
            "banners.claim_30_day_trial": "Vous êtes convaincu ? Demandez votre essai gratuit de 30 jours !",
            "banners.welcome_to_orgwiki": "Bienvenue dans OrgWiki ! Pensez à <1>compléter votre profil.</1>",
            "banners.launching_orgwiki_will_email":
                "<0>Lorsque vous lancez OrgWiki</0>, les entrées du flux d'actualité sont effacées et tous les utilisateurs provisionnés reçoivent un e-mail de bienvenue.",
            "banners.not_yet": "Pas encore",
            "banners.orgwiki_prelaunch_mode": "OrgWiki est en mode de pré-lancement.",
            "banners.launch_orgwiki":
                "<0>Lancez OrgWiki</0> lorsque vous êtes prêt à le déployer pour tous les employés.",
            "banners.orgwiki_not_rolled_out": "OrgWiki n'a pas encore été déployé vers tous les employés.",
            "banners.feed_suspended_on":
                "Le flux de l'organigramme a été interrompu le {{date, MM/DD/YYYY hh:mm:ss A}}",
            "banners.feed_suspended_started":
                "Le flux de l'organigramme sera interrompu à partir du {{date, MM/DD/YYYY hh:mm:ss A}}",
            "footer.copyright": "Copyright ©2013—{{date, YYYY}} <2>Veeva</2>. Tous droits réservés.",
            "footer.about": "À propos d'OrgWiki",
            "footer.legal": "Service juridique",
            "footer.contact": "Nous contacter",
            "field_value_blank": "Ce champ ne peut pas être vide",
            "invalid_email_domain": "Le domaine de messagerie n'est pas associé à cette entreprise.",
            "invalid_img_format": "Format de l'image non valide. L'image doit être au format GIF, JPG ou PNG.",
            "adding_inactive_manager": "Impossible d'ajouter un responsable inactif.",
            "circular_mgr_change":
                "Responsable non valide. {{report, text}} ne peut pas être un subordonné de {{manager, text}} car {{manager, text}} est un subordonné de {{report, text}}.",
            "adding_mgr_to_inactive_employees": "Impossible d'ajouter un responsable aux employés inactifs.",
            "deleting_active_employees": "Seuls les employés inactifs peuvent être supprimés."
        }
    },
    "es": {
        "common": {
            "navbar.feed_sm": "Feed",
            "navbar.feed": "Feed de noticias",
            "navbar.people": "Personas",
            "navbar.groups": "Grupos",
            "navbar.offices": "Oficinas",
            "navbar.menu": "Menú",
            "navbar.admin": "Administrador",
            "navbar.my_profile": "Mi perfil",
            "navbar.company_config": "Configurar empresa",
            "navbar.manage_employees": "Gestionar empleados",
            "navbar.manage_guest_passes": "Gestionar pases de invitados",
            "navbar.reports": "Informes y análisis",
            "navbar.admin_enter": "Entrar en modo administrador",
            "navbar.admin_exit": "Salir de modo administrador",
            "navbar.logout": "Cerrar sesión",
            "navbar.restart_tour": "Volver a iniciar visita",
            "notifications.count+": "{{count, number}}+",
            "notifications.count": "{{count, number}}",
            "notifications.read_error": "Hubo un problema al marcar todas las notificaciones como leídas.",
            "notifications.empty": "Nada nuevo en este momento.",
            "notifications.action_required": "Acción requerida",
            "notifications.notifications": "Notificaciones",
            "notifications.moment_year": "{{date, MMMM D, YYYY}}",
            "notifications.moment": "{{date, MMMM D}}",
            "notifications.moment_from": "{{date, fromNow}}",
            "notifications.read_all": "Marcar todo como leído",
            "instant_search.office_count": "{{count, number}} persona en esta oficina",
            "instant_search.office_count_other": "{{count, number}} personas en esta oficina",
            "instant_search.room_capacity": "Capacidad de {{count, number}} persona",
            "instant_search.conference_room": "Sala de conferencias",
            "instant_search.members_everyone": "Todos",
            "instant_search.members_more": "{{count, number}} + miembros",
            "instant_search.members": "{{count, number}} miembro",
            "instant_search.members_other": "{{count, number}} miembros",
            "instant_search.skill_count": "{{count, number}} persona con esta {{label, text}}",
            "instant_search.skill_count_other": "{{count, number}} personas con esta {{label, text}}",
            "instant_search.skill_alias": "(También conocido como <1>{{match, text}}</1>)",
            "instant_search.all_groups": "Ver todos los grupos",
            "instant_search.all_skills": "Ver todo {{label, text}}",
            "instant_search.advanced_search": "Búsqueda avanzada de personas",
            "instant_search.placeholder": "Búsqueda OrgWiki",
            "loading...": "Cargando...",
            "loading": "Cargando",
            "cancel": "Cancelar",
            "save": "Guardar",
            "yes": "Sí",
            "no": "No",
            "ok": "Aceptar",
            "saving...": "Guardando...",
            "edit": "Editar",
            "update": "Actualizar",
            "create": "Crear",
            "delete": "Eliminar",
            "deleting...": "Eliminando...",
            "count": "{{count, number}}",
            "area.label": "Área",
            "area.label_other": "Áreas",
            "product.label": "Producto",
            "product.label_other": "Productos",
            "skill.label": "Destreza",
            "skill.label_other": "Destrezas",
            "skill.level1": "Principiante",
            "skill.level2": "Intermedio",
            "skill.level3": "Avanzado",
            "skill.level4": "Experto",
            "profile_page.manager": "Gerente",
            "profile_page.managers": "Gerentes",
            "profile_page.change_manager": "Cambiar gerente",
            "profile_page.one_minute_ago": "Hace 1 minuto",
            "profile_page.count_minutes_ago": "Hace {{minuteDisplay, number}} minutos",
            "profile_page.an_hour_ago": "Hace una hora",
            "profile_page.count_hours_ago": "Hace {{hourDisplay, number}} horas",
            "profile_page.a_day_ago": "hace un día",
            "profile_page.count_days_ago": "Hace {{dayDisplay, number}} días",
            "profile_page.count_weeks_ago": "Hace {{weekDisplay, number}} semanas",
            "profile_page.count_months_ago": "Hace {{monthDisplay, number}} meses",
            "profile_page.count_years_ago": "Hace {{yearDisplay, number}} años",
            "profile_page.updated_one_minute_ago": "Última actualización hace 1 minuto",
            "profile_page.updated_count_minutes_ago": "Última actualización hace {{minuteDisplay, number}} minutos",
            "profile_page.updated_an_hour_ago": "Última actualización hace 1 hora",
            "profile_page.updated_count_hours_ago": "Última actualización hace {{hourDisplay, number}} horas",
            "profile_page.updated_a_day_ago": "Última actualización hace 1 día",
            "profile_page.updated_count_days_ago": "Última actualización hace {{dayDisplay, number}} días",
            "profile_page.updated_count_weeks_ago": "Última actualización hace {{weekDisplay, number}} semanas",
            "profile_page.updated_count_months_ago": "Última actualización hace {{monthDisplay, number}} meses",
            "profile_page.updated_count_years_ago": "Última actualización hace {{yearDisplay, number}} años",
            "profile_page.team": "Equipo",
            "profile_page.teams": "Equipos",
            "profile_page.community": "Comunidad",
            "profile_page.communities": "Comunidades",
            "profile_page.posts": "Mensajes",
            "profile_page.count_members": "{{count, number}} Miembros",
            "profile_page.direct_and_total_reports_count":
                "{{directReportsCount, number}} informes directos · {{descendantsCount, number}} informes totales",
            "profile_page.direct_reports_count": "{{directReportsCount, number}} informes directos",
            "profile_page.reports": "Informes",
            "profile_page.profile_photo_title": "Foto de perfil de {{employeeFirstName, text}}",
            "profile_page.desk_location": "Ubicación del escritorio",
            "profile_page.dlists_count": "Listas de distribución ({{dlistsCount, number}})",
            "profile_page.service_meeting": "Reunión de {{serviceName, text}}",
            "profile_page.web_conference": "Conferencia web",
            "profile_page.add_manager": "Añadir Gerente",
            "profile_page.edit_manager": "Gerente de edición",
            "profile_page.add_reports": "Añadir informes",
            "profile_page.edit_reports": "Editar informes",
            "profile_page.no_manager_or_reports": "Sin gerente ni informes",
            "profile_page.organization": "Organización",
            "profile_page.dotted_line_reports": "Informes de línea discontinua",
            "profile_page.add_direct_report": "Añadir informe directo",
            "profile_page.add_dotted_line_report": "Añadir informe de línea discontinua",
            "profile_page.direct_reports": "Informes directos",
            "profile_page.copy_the_teams_email_addresses": "Copiar las direcciones de correo electrónico del equipo.",
            "profile_page.name_plus_reports": "{{name, text}} + Informes",
            "profile_page.name_plus_team": "{{name, text}} + Equipo",
            "profile_page.count_people": "{{count, number}} personas",
            "profile_page.download_team_powerpoint": "Descargar Powerpoint de equipo",
            "profile_page.employee_will_be_notified": "Se notificará a {{name, text}} cuando se guarden los cambios.",
            "profile_page.your_team_slides":
                "La descarga de las diapositivas de su equipo debe comenzar en un momento.",
            "profile_page.preparing_team_slides": "Preparando las diapositivas del equipo",
            "profile_page.copied_to_clipboard": "{{prefix, text}} para {{listText, text}} copiado al portapapeles",
            "profile_page.distribution_list": "Lista de distribución",
            "profile_page.count_email": "{{count, number}} correo electrónico",
            "profile_page.count_email_other": "{{count, number}} correos electrónicos",
            "profile_page.add_direct_manager": "Añadir gerente directo",
            "profile_page.direct_manager": "Gerente directo",
            "profile_page.dotted_line_managers": "Gerentes de línea discontinua",
            "profile_page.employee_profile_only_percent_complete":
                "El perfil de {{name, text}} solo se ha completado al {{percent, number}}%.",
            "profile_page.employee_profile_percent_complete":
                "El perfil de {{name, text}} está completado al {{percent, number}}%",
            "profile_page.your_profile_only_percent_complete": "Su perfil solo está completo al {{name, number}}%.",
            "profile_page.your_profile_percent_complete": "Su perfil está completo al {{percent, number}}%.",
            "profile_page.this_profile_only_percent_complete": "Su perfil solo está completo al {{percent, number}}%.",
            "profile_page.this_profile_percent_complete": "Este perfil está completo al {{percent, number}}%.",
            "profile_page.hide_details": "Ocultar detalles",
            "profile_page.show_details": "Mostrar detalles",
            "profile_page.add": "Añadir",
            "profile_page.phone_number": "Número de teléfono",
            "profile_page.profile_photo": "Fotografía de perfil",
            "profile_page.upload_your_photo": "Suba su foto",
            "profile_page.add_a_number": "Añadir un número de teléfono móvil o de oficina",
            "profile_page.include_the_city": "Incluir la ciudad donde trabaja",
            "profile_page.location": "Ubicación",
            "profile_page.fill_in_responsibilities": "Rellene sus responsabilidades",
            "profile_page.responsibilities": "Responsabilidades",
            "profile_page.share_about_yourself": "Comparta un poco de información sobre usted",
            "profile_page.about": "Acerca",
            "profile_page.you_have_more_things": "Tiene {{count, number}} cosa más por hacer para completar su perfil.",
            "profile_page.you_have_more_things_other":
                "Tiene {{count, number}} cosas más por hacer para completar su perfil.",
            "profile_page.email_team": "Equipo de correo electrónico",
            "profile_page.key": "Clave:",
            "profile_page.create_new_skill": "Crear nueva {{tag, text}}: {{name, text}}",
            "profile_page.already_have_skill": "(Usted ya tiene esta {{tag, text}})",
            "profile_page.search_skills": "Buscar {{tag, text}}",
            "profile_page.add_new_skill": "Añadir una nueva {{tag, text}}",
            "profile_page.select_a_level": "Seleccionar un nivel:",
            "profile_page.add_skill": "Añadir {{tag, text}}",
            "profile_page.add_a_skill": "Añadir una {{tag, text}}",
            "profile_page.problem_saving_skills":
                "Se ha producido un error al guardar sus cambios relacionados con {{tag, text}}",
            "profile_page.no_skills": "{{name, text}} no ha añadido ninguna {{tag, text}}",
            "profile_page.remove_skill": "Eliminar {{tag, text}}",
            "profile_page.skill_max_size": "{{tag, text}} no puede tener más de 100 caracteres.",
            "audit_trail.hide_history": "Ocultar historia",
            "audit_trail.view_history": "Ver historia",
            "audit_trail.profile_history": "Historia del perfil",
            "audit_trail.object": "Objeto",
            "audit_trail.name": "Nombre",
            "audit_trail.modified_date": "Fecha de modificación",
            "audit_trail.modified_by": "Modificado por",
            "audit_trail.action": "Acción",
            "audit_trail.field": "Campo",
            "audit_trail.field_name": "Nombre de campo",
            "audit_trail.old_value": "Valor anterior",
            "audit_trail.new_value": "Nuevo valor",
            "audit_trail.date": "{{date, MM/DD/YYYY hh:mm:ss A}}",
            "audit_trail.restricted_membership": "Pertenencia restringida",
            "audit_trail.is_active": "Está activa",
            "audit_trail.person": "Persona",
            "audit_trail.title": "Título",
            "audit_trail.manager": "Gerente",
            "audit_trail.email": "Correo electrónico",
            "audit_trail.city_description": "Descripción de la ciudad",
            "audit_trail.first_name": "Nombre",
            "audit_trail.last_name": "Apellido",
            "audit_trail.external_id": "ID externo",
            "audit_trail.start_date": "Fecha de inicio",
            "audit_trail.mobile_phone": "Teléfono móvil",
            "audit_trail.text": "Texto",
            "audit_trail.skill_level": "Nivel de destreza",
            "audit_trail.office_phone": "Teléfono de oficina",
            "audit_trail.city": "Ciudad",
            "audit_trail.country": "País",
            "audit_trail.office_type": "Tipo de oficina",
            "audit_trail.state": "Estado",
            "audit_trail.responsibilities": "Responsabilidades",
            "audit_trail.profile_photo": "Fotografía de perfil",
            "audit_trail.location_address": "Dirección de ubicación",
            "audit_trail.location_type": "Tipo de ubicación",
            "audit_trail.about": "Acerca",
            "audit_trail.post_text": "Texto de publicación",
            "audit_trail.is_contractor": "Es contratista",
            "audit_trail.is_admin": "Es administrador",
            "audit_trail.can_create_company_mentions": "Puede crear menciones de la empresa",
            "audit_trail.balloons": "Globos",
            "audit_trail.photo": "Fotografía",
            "audit_trail.synonym_of": "Sinónimo de ",
            "audit_trail.external_email": "Correo electrónico externo",
            "audit_trail.story_text": "Texto de la historia",
            "audit_trail.out_of_office_message": "Mensaje de ausencia",
            "audit_trail.out_of_office": "Fuera de la oficina",
            "audit_trail.ip_address": "Dirección IP",
            "audit_trail.role": "Cargo",
            "audit_trail.description": "Descripción",
            "audit_trail.about_me": "Sobre mí ",
            "audit_trail.nested_group": "Grupo anidado",
            "audit_trail.invite_only": "Solo invitación",
            "audit_trail.what_am_i_doing_this_quarter": "Qué estoy haciendo este trimestre",
            "audit_trail.my_responsibilities": "Mis responsabilidades",
            "audit_trail.office_location": "Ubicación de la oficina",
            "audit_trail.is_private": "Es privada",
            "audit_trail.linkedin_url": "URL de LinkedIn",
            "audit_trail.is_locked": "Está bloqueado",
            "audit_trail.projects_past_and_present": "Proyectos: pasado y presente",
            "audit_trail.is_read_only": "Es de solo lectura",
            "audit_trail.what_i_am_responsible_for": "De qué soy responsable",
            "audit_trail.nick_name": "Alias",
            "audit_trail.primary_location": "Ubicación principal",
            "audit_trail.area": "Área",
            "audit_trail.zoom_url": "Hacer zoom en URL",
            "audit_trail.created_on_mobile": "Creado en móvil",
            "audit_trail.who_can_post": "Quién puede publicar",
            "audit_trail.slack_name": "Nombre de Slack",
            "audit_trail.mattermost_username": "Identificador Mattermost",
            "audit_trail.mattermost_team": "Dominio Mattermost",
            "audit_trail.slack_team": "Equipo de Slack",
            "audit_trail.dotted_line_manager": "Gerente indirecto",
            "audit_trail.owners": "Propietarios",
            "audit_trail.products": "Productos",
            "audit_trail.additional_contact_methods": "Métodos de contacto adicionales",
            "audit_trail.skype_name": "Nombre de Skype",
            "audit_trail.key_accounts": "Cuentas principales",
            "audit_trail.what_am_i_responsible_for": "De qué soy responsable",
            "audit_trail.primary_location_floor_plan": "Ubicación principal: ubicación del escritorio",
            "audit_trail.is_owner": "Es propietario",
            "audit_trail.secondary_location_address": "Dirección de ubicación secundaria",
            "audit_trail.desk_location": "Ubicación del escritorio",
            "audit_trail.display_address": "Mostrar dirección",
            "audit_trail.official_office_distribution_list": "Lista oficial de distribución de oficina",
            "audit_trail.prior_start_date": "Fecha de inicio anterior",
            "audit_trail.overview_text": "Texto de descripción general",
            "audit_trail.conference_room": "Sala de conferencias",
            "audit_trail.expiration_date": "Fecha de caducidad",
            "audit_trail.type": "Tipo",
            "audit_trail.conference_room_floor_plan": "Planos de planta de sala de conferencias",
            "audit_trail.official_distribution_list": "Lista oficial de distribución",
            "audit_trail.company_logo": "Logotipo de la empresa",
            "audit_trail.self_signup_enabled": "Registro automático habilitado",
            "audit_trail.conference_room_capacity": "Capacidad de sala de conferencias",
            "audit_trail.alternate_address": "Dirección alternativa",
            "audit_trail.configuration_mode_enabled": "Nodo de prelanzamiento",
            "audit_trail.webex": "WebEx",
            "audit_trail.secondary_location": "Ubicación secundaria",
            "audit_trail.primary_address": "Dirección principal",
            "audit_trail.prior_company_name": "Nombre de la empresa anterior",
            "audit_trail.membership": "Pertenencia",
            "audit_trail.authorizer_email": "Correo electrónico de autorizador",
            "audit_trail.guest_email": "Correo electrónico de invitado/a",
            "audit_trail.guest_last_name": "Apellidos de invitado/a",
            "audit_trail.guest_first_name": "Nombre de invitado/a",
            "audit_trail.guest_company_name": "Empresa de invitado/a",
            "audit_trail.tags_enabled": "Etiquetas habilitadas",
            "audit_trail.company_posts_enabled": "Publicaciones de empresa habilitadas",
            "audit_trail.social_groups_enabled": "Publicaciones de redes sociales habilitadas",
            "audit_trail.is_early_adopter": "Es usuario pionero",
            "audit_trail.wiki_profile_edits_enabled": "Modificaciones de perfil Wiki habilitada",
            "audit_trail.conference_room_description": "Descripción de sala de conferencias",
            "audit_trail.technical_contact": "Contacto técnico",
            "audit_trail.navbar_logo": "Logotipo de la barra de navegación",
            "audit_trail.dist_lists_admin_only": "Listas de distribución solo pata administrador",
            "audit_trail.seating_chart": "Plano de planta",
            "audit_trail.primary_color": "Color principal",
            "audit_trail.dotted_lines_enabled": "Líneas discontinuas habilitadas",
            "audit_trail.active_by_default": "Activo por defecto",
            "audit_trail.section_type": "Tipo de sección",
            "audit_trail.intended_for": "Destinado a",
            "audit_trail.sort_order": "Clasificar pedido",
            "audit_trail.update_interval": "Actualizar intervalo",
            "audit_trail.revoked": "Revocado",
            "audit_trail.company": "Empresa",
            "audit_trail.okta_metadata_url": "URL de metadatos Okta",
            "audit_trail.profile_celebrations_enabled": "Habilitado las celebraciones de perfil",
            "audit_trail.highlight_color": "Color de resaltado",
            "audit_trail.background_color": "Color de fondo",
            "audit_trail.secondary_location_floor_plan": "Ubicación secundaria: ubicación del escritorio",
            "audit_trail.primary_button_color": "Color de botón principal",
            "audit_trail.company_logo_title": "Título del logotipo de la empresa",
            "audit_trail.guest_passes_enabled": "Pases de invitados habilitados",
            "audit_trail.timeline_moderation_datetime": "Fecha y hora de moderación de la línea de tiempo",
            "audit_trail.light_navbar": "Barra de navegación ligera",
            "audit_trail.champion_contact": "Contacto revolucionario",
            "audit_trail.dist_lists_enabled": "Listas de distribución habilitadas",
            "audit_trail.saml_metadata_url": "URL de metadatos SAML",
            "audit_trail.company_logo_url": "URL del logotipo de la empresa",
            "audit_trail.website": "Sitio web",
            "audit_trail.dist_lists_admin_email": "Correo electrónico de administrador de listas de distribución",
            "audit_trail.dist_lists_domain": "Dominio de listas de distribución",
            "audit_trail.billing_contact": "Contrato de facturación",
            "audit_trail.welcome_email_enabled": "Correo electrónico de bienvenida habilitado",
            "audit_trail.sponsor_contact": "Contacto del patrocinador",
            "audit_trail.birthday_today": "Cumpleaños hoy",
            "audit_trail.secondary_contact": "Contacto secundario",
            "audit_trail.light_background": "Fondo claro",
            "audit_trail.primary_contact": "Contacto principal",
            "audit_trail.saml_metadata_file": "Archivo de metadatos SAML",
            "audit_trail.contest_winner": "Ganador de concurso",
            "audit_trail.alternate_email": "Correo electrónico alternativo",
            "audit_trail.employees_manager": "Gerente <0>{{name, text}}'s</0> ",
            "audit_trail.employees_dotted_line_manager": "Gerente indirecto <0>{{name, text}}'s</0>",
            "profile_edit_page.first_name": "Nombre",
            "profile_edit_page.nickname": "Apodo",
            "profile_edit_page.last_name": "Apellido",
            "profile_edit_page.title": "Título",
            "profile_edit_page.contact_information": "Información de contacto",
            "profile_edit_page.email": "Correo electrónico",
            "profile_edit_page.office_phone": "Teléfono de oficina",
            "profile_edit_page.mobile_phone": "Teléfono móvil",
            "profile_edit_page.messaging": "Mensajería",
            "profile_edit_page.slack_team": "Equipo de Slack",
            "profile_edit_page.slack_username": "Nombre de usuario de Slack",
            "profile_edit_page.mattermost_team": "Dominio Mattermost",
            "profile_edit_page.mattermost_username": "Identificador Mattermost",
            "profile_edit_page.conferencing": "Conferencia",
            "profile_edit_page.location": "Ubicación",
            "profile_edit_page.primary_work_location": "Lugar de trabajo principal",
            "profile_edit_page.office": "Oficina",
            "profile_edit_page.edit_desk_location": "Editar ubicación de escritorio",
            "profile_edit_page.secondary_work_location": "Lugar de trabajo secundario",
            "profile_edit_page.social": "Social",
            "profile_edit_page.profile_url": "URL del perfil",
            "profile_edit_page.locate_mine": "Localizar el mío",
            "profile_edit_page.other_contact_info": "Otra información de contacto",
            "profile_edit_page.other_contact_info_description":
                "¿Método preferido de comunicación? ¿Otros canales de comunicación? ¿Ubicación de oficina/estación de trabajo?",
            "profile_edit_page.admin_fields": "Campos de administrador",
            "profile_edit_page.active_employee": "Empleado activo",
            "profile_edit_page.start_date": "Fecha de inicio",
            "profile_edit_page.prior_company_name": "Nombre de la empresa anterior",
            "profile_edit_page.prior_start_date": "Fecha de inicio anterior",
            "profile_edit_page.prior_start_date_hover":
                "Si el empleado es de una empresa adquirida, esta es la fecha en la que comenzaron en esa empresa.",
            "profile_edit_page.admin": "Administrador",
            "profile_edit_page.contractor": "Contratista",
            "profile_edit_page.external_id": "ID externo",
            "profile_edit_page.announcements": "Anuncios",
            "profile_edit_page.balloons": "Globos",
            "profile_edit_page.last_modified_date": "Fecha de última modificación",
            "profile_edit_page.last_modified_by": "Última modificación realizada por",
            "profile_edit_page.profile_photo": "Fotografía de perfil",
            "profile_edit_page.customize_custom_sections": "Personalizar secciones personalizadas",
            "profile_edit_page.meeting_url": "URL de reunión",
            "profile_edit_page.name": "Nombre",
            "profile_edit_page.personal_room_url": "URL de la sala personal",
            "profile_edit_page.address": "Dirección",
            "profile_edit_page.set_desk_location": "Establecer ubicación de escritorio",
            "profile_edit_page.early_adopter": "Usuario pionero",
            "profile_edit_page.deleting...": "Eliminando...",
            "profile_edit_page.delete_profile": "Borrar perfil",
            "profile_edit_page.saving...": "Guardando...",
            "profile_edit_page.save_and_add_another": "Guardar y añadir otro",
            "profile_edit_page.active?": "¿Activo?",
            "profile_edit_page.intended_for": "Destinado a",
            "profile_edit_page.no_sections": "Aún no se han creado secciones",
            "profile_edit_page.select_custom_sections":
                "Seleccione las secciones que todos verán para <1> {{fullName, text}} </1>",
            "profile_edit_page.mark_inactive_warning":
                "Marcar a un empleado como inactivo reasignará cualquier informe directo a su gerente y luego borrará su asignación de gerente.",
            "profile_edit_page.mark_active_warning":
                "La reactivación de un empleado restaurará la pertenencia anterior a la comunidad y la lista de distribución.",
            "profile_edit_page.mark_active_note": "Nota: tendrá que configurar su gerente.",
            "profile_edit_page.early_adopter_warning":
                "Esta configuración solo se aplica mientras Orgwiki está en modo de pre-lanzamiento. Los primeros usuarios recibirán correos electrónicos de productos de OrgWiki y notificaciones dentro de la aplicación",
            "profile_edit_page.nickname_prompt": "¿Desea establecer su apodo como {{SuggestNickName, text}}?",
            "profile_edit_page.delete_profile_warning":
                "¿Está seguro de que desea eliminar este perfil? Al eliminar un perfil, también se eliminan las publicaciones o comentarios realizados por el usuario. Cualquier acción que el usuario haya tomado se mostrará en el registro de auditoría.",
            "profile_edit_page.processing_error":
                "Hubo un problema al procesar su solicitud. Código: {{status, number}}, {{statusText, text}}",
            "profile_edit_page.inactive": "No activo",
            "profile_edit_page.edit_profile_sections": "Editar secciones de perfil",
            "profile_edit_page.save_changes": "Guardar cambios",
            "profile_edit_page.save_location_and_continue_prompt":
                "Debe guardar su ubicación antes de poder establecer la ubicación de su escritorio. ¿Desea guardar los cambios a su perfil y continuar?",
            "profile_edit_page.employee_not_active_warning":
                "Advertencia: este empleado no está activo actualmente. Los usuarios no administradores no podrán ver este registro de empleado.",
            "profile_edit_page.select_one": "Seleccionar uno",
            "profile_edit_page.remote": "Remoto",
            "profile_edit_page.none": "Ninguno",
            "profile_edit_page.enter_a_city": "Introducir una ciudad",
            "profile_edit_page.edit_employee_profile": "Editar el perfil de {{firstName, text}}, {{lastName, text}}",
            "profile_edit_page.add_manager": "Añadir Gerente",
            "profile_edit_page.mattermost_team_tooltip":
                "Debe ser el dominio completo, por ejemplo, nombredelaempresa.mattermost.com",
            "profile_edit_page.mattermost_username_tooltip":
                "El nombre @mention de Mattermost distingue entre mayúsculas y minúsculas, por ejemplo, JoeSmith",
            "profile_edit_page.edit_profile_header":
                "Editar el perfil de <1>{{firstName, text}} {{lastName, text}}</1>",
            "profile_edit_page.upload_photo": "Subir foto",
            "profile_edit_page.use_linkedin_photo": "Utilizar fotografía de LinkedIn",
            "profile_edit_page.use_office_365_photo": "Utilizar fotografía de Office 365",
            "profile_edit_page.use_gsuite_photo": "Utilizar fotografía de G Suite",
            "profile_edit_page.users_will_be_notified":
                "Los usuarios serán notificados de cualquier cambio realizado en su perfil.",
            "profile_edit_page.profile_sections_updated": "Secciones de perfil actualizadas",
            "profile_edit_page.problem_processing_request":
                "Hubo un problema al procesar su solicitud. Código: {{status, number}} {{statusText, text}}, error: {{errorText, text}}",
            "profile_edit_page.employee_created": "Empleado {{name, text}} creado",
            "profile_edit_page.error": "Error: {{errorText, text}}",
            "profile_edit_page.city": "Ciudad",
            "profile_edit_page.primary_location": "Ubicación principal",
            "profile_edit_page.search_offices": "Buscar oficinas...",
            "locations.all_offices": "Todas las oficinas",
            "locations.inactive_offices": "Oficinas inactivas",
            "locations.navigate_to": "Ir a:",
            "locations.new_office": "Nueva oficina",
            "locations.offices_caps": "OFICINAS",
            "locations.search_offices": "Buscar oficinas",
            "locations.no_results_found": "No se han encontrado resultados.",
            "locations.no_offices": "Sin oficinas",
            "locations.office": "Oficina",
            "locations.offices": "Oficinas",
            "locations.location": "Ubicación",
            "locations.people": "Personas",
            "locations.actions": "Acciones",
            "locations.people_count": "{{count, number}} <1>Persona</1>",
            "locations.people_count_other": "{{count, number}} <1>Personas</1>",
            "locations.done": "Hecho",
            "locations.office_overview": "Descripción general de la oficina",
            "locations.floor_plans": "Planos de planta",
            "locations.conference_rooms": "Salas de conferencias",
            "locations.history": "Historial",
            "locations.edit_office": "Editar oficina",
            "locations.office_people_count": "<0>{{count, number}} persona</0> en esta oficina",
            "locations.office_people_count_other": "<0>{{count, number}} personas</0> en esta oficina",
            "locations.secondary_office_people_count":
                "{{count, number}} persona tiene esta oficina como ubicación secundaria",
            "locations.secondary_office_people_count_other":
                "{{count, number}} personas tienen esta oficina como ubicación secundaria",
            "locations.office_is_inactive": "La oficina está inactiva",
            "locations.office_inactive_couple_options":
                "Esta oficina está inactiva y solo es visible para los administradores. Hay un par de opciones:",
            "locations.make_office_visible":
                "Esto hará que la oficina sea visible para todos y estará disponible para que los usuarios se unan.",
            "locations.duplicate_office_merge":
                "Si esta oficina es un duplicado de una oficina existente, ¡fusiónelas!",
            "locations.merge_this_office": "Fusionar esta oficina",
            "locations.set_office_to_active": "Establecer oficina en activo",
            "locations.edit_information": "Editar información",
            "locations.office_information_not_provided": "La información de la oficina no se ha proporcionado.",
            "locations.consider_adding_information":
                "Considere añadir información útil aquí, como contactos de oficina, contraseñas de WiFi, instrucciones especiales y cualquier otra cosa que pueda ser útil para alguien que visite esta oficina.",
            "locations.information": "Información",
            "locations.new_community": "Nueva {{communitySynonym, text}}",
            "locations.new_room": "Nueva sala",
            "locations.no_conference_rooms": "No hay salas de conferencias",
            "locations.search_rooms": "Buscar salas",
            "locations.name": "Nombre",
            "locations.capacity": "Capacidad",
            "locations.this_office_empty": "Esta oficina está vacía.",
            "locations.distribution_list": "Lista de distribución",
            "locations.include_secondary_office_members": "Incluir miembros de la oficina secundaria",
            "locations.title": "Título",
            "locations.secondary": "Secundario",
            "locations.primary": "Primario",
            "locations.search_members": "Buscar Miembros",
            "locations.something_went_wrong_dlist": "Algo salió mal al crear la lista de distribución oficial",
            "locations.primary__required": "La dirección principal es un campo obligatorio",
            "locations.unexpected_update_error": "Hemos encontrado un error inesperado al actualizar su oficina.",
            "locations.unexpected_create_error": "Hemos encontrado un error inesperado al crear su oficina.",
            "locations.unexpected_floor_plan_error":
                "Se produjo un error al guardar los planos de planta. Inténtelo de nuevo.",
            "locations.cant_delete_location":
                "No puede eliminar {{name, text}} mientras todavía haya personas asociadas a él.",
            "locations.are_you_sure_delete_location": "¿Seguro que desea eliminar {{name, text}}?",
            "locations.add_floor_plan": "Añadir planos de planta",
            "locations.edit_floor_plan": "Editar planos de planta",
            "locations.edit_address": "Editar dirección",
            "locations.add_address": "Añadir dirección",
            "locations.move_up": "Mover hacia arriba",
            "locations.office_name": "Nombre de la oficina",
            "locations.status": "Estado",
            "locations.active": "Activo",
            "locations.inactive_hidden": "Inactivo (Oculto)",
            "locations.office_photo": "Foto de la oficina",
            "locations.official_distribution_list": "Lista oficial de distribución",
            "locations.official_distribution_list_explanation":
                "Orgwiki muestra la lista oficial de manera prominente para que los usuarios sepan que deben utilizarla para comunicarse con las personas en la oficina.",
            "locations.change_official_distribution_list": "Cambio de lista oficial de distribución",
            "locations.set_official_distribution_list": "Establecer lista oficial de distribución",
            "locations.remove": "Eliminar",
            "locations.no_official_distribution_list_set": "No hay lista oficial de distribución establecida",
            "locations.primary_address": "Dirección principal",
            "locations.primary_address_explanation": "La dirección principal se utiliza para buscar personas.",
            "locations.merge_with_another_office": "Combinar con otra oficina ...",
            "locations.add_primary_address": "Añadir dirección principal",
            "locations.no_primary_address_set": "Sin dirección principal establecida",
            "locations.display_address": "Mostrar dirección",
            "locations.display_address_explanation":
                "Así es como se mostrará la dirección de las oficinas dentro de OrgWiki.",
            "locations.address_variations": "Variaciones de dirección",
            "locations.address_variations_explanation":
                "OrgWiki utiliza estas direcciones para hacer coincidir a las personas con esta oficina (para que coincidan con la oficina correcta, incluso si hay errores tipográficos o están incompletos, etc.).",
            "locations.add_address_variation": "Añadir variación de dirección",
            "locations.no_address_variations": "Sin variaciones de dirección",
            "locations.no_floor_plans": "Sin planos de planta",
            "locations.delete_office": "Eliminar oficina",
            "locations.floor_plan_name": "Nombre del plano de planta",
            "locations.name_is_required": "Se requiere el nombre",
            "locations.image_format": "Imagen (formato .jpg o .png)",
            "locations.select_image": "Seleccionar imagen...",
            "locations.image_is_required": "Se requiere imagen",
            "locations.loc_merged_into_dest": "{{location, text}} fusionada con {{destination, text}}",
            "locations.merge_warning":
                "La fusión reasignará a todas las personas en la oficina de origen a la oficina de destino, agregará la dirección de la oficina de origen como una dirección alternativa en el destino y eliminará la oficina de origen. Los planos de planta y salas de conferencias en la oficina de origen no se conservarán.",
            "locations.dlist_member_warning":
                "Las listas de distribución que incluyen la oficina de origen como miembro se actualizarán para incluir a la oficina de destino como miembro.",
            "locations.merge": "Fusión",
            "locations.address_search": "Búsqueda de direcciones",
            "locations.street_address": "Dirección",
            "locations.city": "Ciudad",
            "locations.state_province": "Estado/Provincia",
            "locations.country": "País",
            "locations.save_previous_address": "Guardar dirección anterior como una alternativa",
            "locations.found_matching_address": "Hemos encontrado una dirección coincidente con la que introdujo.",
            "locations.recommend_this_version": "Recomendamos utilizar esta versión:",
            "locations.recommended_address": "Dirección recomendada:",
            "locations.use_recommended_address": "Utilizar la dirección recomendada",
            "locations.what_you_entered": "Lo que introdujo:",
            "locations.use_this_address": "Utilizar esta dirección",
            "locations.go_back": "Volver",
            "locations.address_entered_exists": "¡La dirección introducida ya existe!",
            "locations.existing_address": "Dirección existente:",
            "locations.similar_address_found":
                "<0>Hemos encontrado una dirección que coincide con la que introdujo.</0> ¿Desea continuar?",
            "locations.address_you_entered": "Dirección que introdujo:",
            "locations.similar_existing_address": "Dirección existente similar:",
            "locations.add_address_anyways": "Añadir esta dirección de todos modos",
            "locations.select_an_existing_list": "Seleccione una lista existente.",
            "locations.enter_an_email_address": "Introduzca una dirección de correo electrónico.",
            "locations.specify_an_owner": "Especifique un propietario.",
            "locations.list_already_exists": "Ya existe una lista con este correo electrónico.",
            "locations.remove_user_from_group_list":
                "Elimine <1> {{count, number}} usuario </1> de <4> {{group, text}} </4> ya que también es miembro de listas anidadas",
            "locations.remove_user_from_group_list_other":
                "Elimine <1> {{count, number}} usuarios </1> de <4> {{group, text}} </4> ya que también son miembros de listas anidadas",
            "locations.remove_user_from_group_office":
                "Elimine <1> {{count, number}} usuario </1> de <4> {{group, text}} </4> ya que también es miembro de la oficina",
            "locations.remove_user_from_group_office_other":
                "Elimine <1> {{count, number}} usuarios </1> de <4> {{group, text}} </4> ya que también son miembros de la oficina",
            "locations.remove_list_from_group":
                "Elimine <1> {{count, number}} lista </1> de <4> {{group, text}} </4> ya que también es miembro de listas anidadas",
            "locations.remove_list_from_group_other":
                "Elimine <1> {{count, number}} listas </1> de <4> {{group, text}} </4> ya que también son miembros de listas anidadas",
            "locations.remove_nested_list_from_group":
                "Elimine <1> {{count, number}} lista anidada </1> de <4> {{group, text}} </4> ya que también es miembro de listas anidadas <6>{{name, text}}</6>",
            "locations.remove_nested_list_from_group_other":
                "Elimine <1> {{count, number}} listas anidadas </1> de <4> {{group, text}} </4> ya que también son miembros de listas anidadas <6>{{name, text}}</6>",
            "locations.create_new_distribution_list": "Crear nueva lista de distribución",
            "locations.use_existing_distribution_list": "Utilice la lista de distribución existente",
            "locations.distribution_list_name": "Nombre de lista de distribución",
            "locations.email_address": "Dirección de correo electrónico",
            "locations.email": "Correo electrónico",
            "locations.who_can_join": "¿Quién puede unirse (además de los miembros de Office)?",
            "locations.new_members_must_be_approved": "Los nuevos miembros deben ser aprobados",
            "locations.anyone_can_join": "Cualquiera puede unirse",
            "locations.selected_owner": "Propietario seleccionado",
            "locations.select_a_different_owner": "Seleccione un propietario diferente",
            "locations.select_an_owner": "Seleccione un propietario",
            "locations.search_people": "Buscar personas",
            "locations.select_distribution_list": "Seleccione lista de distribución",
            "locations.search_existing_dlists": "Busque listas de distribución existentes",
            "locations.select_a_different_list": "Seleccione una lista diferente",
            "locations.duplicates_found": "<0>¡Se han encontrado duplicados!</0> Enumerar sugerencias de limpieza:",
            "locations.removing_duplicates_membership":
                "La eliminación de duplicados no cambia la pertenencia de {{name, text}}.",
            "locations.eliminating_redundancies":
                "La eliminación de las redundancias ayudará a mantener los cambios y errores manuales de la pertenencia de <2>{{name, text}}</2>.",
            "locations.create_official_distribution_list": "Crear lista oficial de distribución",
            "locations.ie_first_floor": "p. ej., \"Primera planta\"",
            "floor_plans.select_a_floor_plan": "Seleccione un plano de planta:",
            "floor_plans.no_floor_plans": "Sin planos de planta.",
            "floor_plans.edit_floor_plan": "Editar planos de planta",
            "floor_plans.loading_floor_plan": "Cargando planos de planta",
            "floor_plans.create_new_room": "Crear nueva sala {{placeholder, text}}",
            "floor_plans.add_person": "Añadir persona",
            "floor_plans.add_person_colon": "Añadir persona:",
            "floor_plans.add_room": "Añadir sala",
            "floor_plans.add_conference_room": "Añadir sala de conferencia",
            "floor_plans.employee_not_in_this_city":
                "El empleado no está en esta ciudad, ¿está seguro de que desea añadirlo a este cuadro de asientos?",
            "floor_plans.search_people": "Buscar personas...",
            "floor_plans.select_room": "Seleccionar sala:",
            "floor_plans.search_rooms": "Busque salas...",
            "floor_plans.optional": "Optativo",
            "floor_plans.description": "Descripción",
            "floor_plans.capacity": "Capacidad",
            "floor_plans.room_name": "Nombre de la sala",
            "dlists.distribution_lists_caps": "LISTAS DE DISTRIBUCIÓN",
            "dlists.my_distribution_lists": "Mis listas de distribución",
            "dlists.all_distribution_lists": "Todas las listas de distribución",
            "dlists.distribution_lists": "Listas de distribución",
            "dlists.custom_distribution_lists": "Listas de distribución personalizadas",
            "feed.tab_all_updates": "Todo <1>Actualizaciones</1>",
            "feed.tab_posts": "Mensajes",
            "feed.tab_manager_changes": "Gerente <1>Changes</1>",
            "feed.tab_title_changes": "Título <1>Changes</1>",
            "feed.tab_new_employees": "Nuevo <1>Employees</1>",
            "feed.show_more": "Mostrar más actividad",
            "feed.no_posts": "Aún no hay publicaciones. Comparta algo hoy.",
            "feed.no_manager_changes": "No hay cambios de gerente para mostrar.",
            "feed.no_title_changes": "No hay cambios de título para mostrar.",
            "feed.no_new_hires": "No hay nuevas contrataciones para mostrar.",
            "feed.no_tles": "No hay entradas de línea de tiempo para mostrar.",
            "tle.delete_tle_error": "Se ha producido un error al eliminar esta entrada de la línea de tiempo.",
            "tle.like_post": "Como esta publicación",
            "tle.unlike_post": "A diferencia de esta publicación",
            "tle.share_post": "Compartir esta publicación",
            "tle.copy_paste_link": "Copiar y pegar el enlace de arriba para compartir esta publicación.",
            "tle.copy_link": "Copiar enlace",
            "tle.link_copied": "Enlace copiado.",
            "tle.copy": "Copiar",
            "tle.comment_placeholder": "Escribir un comentario...",
            "tle.delete_confirm":
                "¿Seguro que desea eliminar esta entrada?  Cualquier comentario también será eliminado.",
            "tle.delete_comment_confirm": "¿Seguro que desea eliminar este comentario?",
            "tle.delete_comment_error": "Hubo un problema al borrar este comentario.",
            "tle.back_to_feed": "Volver al feed",
            "tle.now_reports_to": "Ahora informa a <1></1>",
            "tle.now_dotted_line_reports_to": "Ahora los informes de línea discontinua para <1></1>",
            "tle.title_is_now": "El título es ahora {{title, text}}",
            "tle.joins_as": "Se une como {{title, text}}",
            "tle.has_left": "Ha dejado la empresa: era {{title, text}}",
            "tle.one_liker": "A <0></0> le gustó esto",
            "tle.two_likers": "A <0></0>, <2></2> le gustó esto",
            "tle.three_likers": "A <0></0>, <2></2>, <4></4> les gustó esto",
            "tle.many_likers": "A <0> </0>, <2> </2>, <4> </4> y <6> {{otherLikersStr, text}} </6> les gustó esto",
            "tle.other_likers_count": "{{count, number}} otro",
            "tle.other_likers_count_other": "{{count, number}} otros",
            "tle.you": "Usted",
            "tle.view_count": "{{count, number}} vista",
            "tle.view_count_other": "{{count, number}} vistas",
            "tle.like": "Me gusta",
            "tle.like_other": "Me gusta",
            "tle.like_count": "{{count}} me gusta",
            "tle.like_count_other": "{{count}} me gusta",
            "tle.comment": "Comentario",
            "tle.comment_other": "Comentarios",
            "tle.comment_count": "{{count}} comentario",
            "tle.comment_count_other": "{{count}} comentarios",
            "tle.commenter_title_inactive_annotation": "(No activo) ",
            "edit_tle.title": "Editar texto de publicación",
            "edit_tle.optional_post_text_placeholder": "Por favor, escriba una leyenda breve antes de publicar.",
            "edit_tle.required_post_text_placeholder": "Escriba una leyenda (requerido)",
            "edit_tle.optional_story_text_placeholder": "Añadir una historia (opcional)",
            "new_post.post_placeholder": "Compartir una actualización con la empresa.",
            "new_post.story_placeholder": "Añadir una historia",
            "new_post.drag_photo": "Arrastre la foto aquí para subirla.",
            "new_post.upload_photo": "Subir foto",
            "new_post.posting...": "Publicando...",
            "new_post.post": "Publicar",
            "advanced_search.refine_results": "Refinar Resultados",
            "advanced_search.clear_filters": "Limpiar filtros",
            "advanced_search.title": "Título",
            "advanced_search.remove_title_filter": "Eliminar filtro de título",
            "advanced_search.has_title": "Tiene titulo",
            "advanced_search.remove_var_filter": "Eliminar el filtro {{tag, text}}",
            "advanced_search.office": "Oficina",
            "advanced_search.remove_location_filter": "Eliminar filtro de ubicación",
            "advanced_search.location": "Ubicación",
            "advanced_search.located_in_office": "Ubicado en oficina",
            "advanced_search.organization": "Organización",
            "advanced_search.team": "Equipo",
            "advanced_search.team_filter": "Filtro de {{teamSynonym, text}}",
            "advanced_search.remove_manager_filter": "Eliminar filtro de gerente",
            "advanced_search.select_a_manager": "Seleccione un gerente",
            "advanced_search.browse_all_managers": "Examinar todos los gerentes",
            "advanced_search.group": "Grupo",
            "advanced_search.remove_group_filter": "Eliminar filtro de grupo",
            "advanced_search.has_group": "Tiene grupo",
            "advanced_search.less_than_one_year": "<1 año",
            "advanced_search.between_one_and_two_years": "1-2 años",
            "advanced_search.between_two_and_three_years": "2-3 años",
            "advanced_search.between_three_and_four_years": "3-4 años",
            "advanced_search.between_four_and_five_years": "4-5 años",
            "advanced_search.greater_than_five_years": ">5 años",
            "advanced_search.tenure": "Tenencia",
            "advanced_search.show_matches_on": "Mostrar coincidencias en",
            "advanced_search.in_facet": "En {{tag, text}}",
            "advanced_search.has_facet": "Tiene {{tag, text}}",
            "advanced_search.responsibilities": "Responsabilidades",
            "advanced_search.about": "Acerca",
            "advanced_search.groups": "Grupos",
            "advanced_search.this_quarter": "Este cuarto",
            "advanced_search.projects": "Proyectos",
            "advanced_search.key_account": "Cuenta principal",
            "advanced_search.email": "Correo electrónico",
            "advanced_search.mobile_phone": "Teléfono móvil",
            "advanced_search.office_phone": "Teléfono de oficina",
            "advanced_search.secondary_location": "Ubicación secundaria",
            "advanced_search.email_all": "Enviar correo electrónico a todos",
            "advanced_search.searching": "Buscando...",
            "advanced_search.copy_email_addresses": "Copiar direcciones de correo electrónico de perfiles coincidentes",
            "advanced_search.search_error": "Lo sentimos, se produjo un error al buscar <1> {{searchText, text}} </1>",
            "advanced_search.search_results_count": "{{count, number}} perfil coincidente",
            "advanced_search.search_results_count_other": "{{count, number}} perfiles coincidentes",
            "advanced_search.copied_to_clipboard": "{{count, number}} correo electrónico copiado al portapapeles",
            "advanced_search.copied_to_clipboard_other":
                "{{count, number}} correos electrónicos copiados al portapapeles",
            "advanced_search.count_parens": "({{count, number}})",
            "advanced_search.generic_match": "{{tag, text}} que coincide: {{query, text}}",
            "stars_and_moons.tenure": "Empleado por {{duration, text}}",
            "mentions.group_notify": "Notificar a todos en {{name, text}}",
            "mentions.company_notify": "Notificar a todos en {{name, text}}",
            "onboarding_welcome.highlights_title": "¡Bienvenido/a a OrgWiki, {{firstName, text}}!",
            "onboarding_welcome.highlights_subtitle":
                "<0> OrgWiki le conecta con todos los demás en {{companyName, text}}.</0> Hay mucho por ver, pero aquí hay algunos aspectos a tener en cuenta:",
            "onboarding_welcome.highlight_directory":
                "<0> Directorio de la empresa </0> con perfiles para todos en la empresa",
            "onboarding_welcome.highlight_profiles":
                "<0>Los perfiles detallados </0> de los colaboradores le ayudan a conocerlos mejor.",
            "onboarding_welcome.highlight_dlists":
                "<0> Listas de distribución </0> integradas, para que pueda ver y administrar todas sus listas.",
            "onboarding_welcome.highlight_offices":
                "<0> Información de la oficina </0> con planos de planta interactivos.",
            "onboarding_welcome.highlight_search":
                "<0> Búsqueda avanzada </0> facilita la búsqueda de compañeros de trabajo según el nombre, el título, la ubicación, los intereses y más.",
            "onboarding_welcome.highlight_feed":
                "<0> Feed de la empresa </0> le permite ver actualizaciones recientes de títulos de compañeros de trabajo, nuevas contrataciones y publicaciones compartidas.",
            "onboarding_welcome.highlight_feed_non_social":
                "<0> Feed de la empresa </0> le permite ver actualizaciones recientes de títulos de compañeros de trabajo y nuevas contrataciones.",
            "onboarding_welcome.bottom_para_1":
                "<0>Todo el mundo en {{companyName, text}} tiene su propio perfil </0> con su <2> Información de contacto </2>, una <4> Fotografía </4>, detalles sobre sus <6> Responsabilidades </6>, una descripción personal <8> Acerca de mí</8>, y más.",
            "onboarding_welcome.bottom_para_2":
                "<0> Tomemos solo unos minutos para completar su perfil. </0> Ya hemos hecho la mayor parte del trabajo, ¡así que esto será fácil!",
            "onboarding_welcome.get_started": "¡Empezar!",
            "onboarding_welcome.remind_me_tom": "Recuérdemelo mañana",
            "onboarding_photo.add_photo": "Añadir una fotografía",
            "onboarding_photo.photo_desc":
                "Una buena foto ayuda a sus compañeros de trabajo a reconocerlo y aprender su nombre.",
            "onboarding_photo.remind_me_later": "Recuérdemelo más tarde",
            "onboarding_photo.upload_photo": "Subir foto",
            "onboarding_photo.uploading_photo": "Subiendo fotografía",
            "onboarding_photo.import_photo": "¿Ya tiene una fotografía en otro lado?",
            "onboarding_photo.import_linkedin": "Utilizar fotografía de LinkedIn",
            "onboarding_photo.import_gsuite": "Utilizar fotografía de G Suite",
            "onboarding_photo.import_office365": "Utilizar fotografía de Office 365",
            "onboarding_responsibilities.save_changes": "Guardar mis cambios",
            "onboarding_responsibilities.still_accurate": "Esto sigue siendo exacto",
            "onboarding_responsibilities.what_are_they": "¿Cuáles son sus responsabilidades?",
            "onboarding_responsibilities.update_them": "Actualice sus responsabilidades",
            "onboarding_responsibilities.still_your_role":
                "¿Esto todavía describe su función y responsabilidades actuales?",
            "onboarding_responsibilities.describe_wyd": "Describa lo que hace en {{companyName, text}}",
            "onboarding_responsibilities.what_do_you_even_do":
                "¿Cuál es su función? ¿Cuál es su especialidad? ¿Qué cuentas gestiona?",
            "onboarding.save_and_continue": "Guardar y continuar",
            "onboarding.remind_me_later": "Recuérdemelo más tarde",
            "onboarding_responsibilities.others_responsibility": "Las responsabilidades de {{name, text}}",
            "onboarding.need_inspiration": "¿Necesita algo de inspiración?",
            "onboarding_about.title": "Cuéntenos acerca de usted",
            "onboarding_about.help_coworkers": "Ayude a sus compañeros de trabajo a conocerle mejor.",
            "onboarding_about.ideas":
                "¿De dónde es? ¿Dónde trabajó antes de unirse a {{companyName, text}}? ¿Qué hace los fines de semana?",
            "onboarding_about.others_about": "Acerca de {{name, text}}",
            "onboarding_mobile.title": "¡Gracias por mantener su perfil actualizado!",
            "onboarding_mobile.in_your_pocket": "¡Toda {{companyName, text}} en su bolsillo!",
            "onboarding_mobile.while_ur_here":
                "Mientras esté aquí, ¿le gustaría instalar la aplicación móvil OrgWiki en su teléfono?",
            "onboarding_mobile.get_mobile_app": "Obtenga la aplicación móvil",
            "onboarding_mobile.must_have":
                "<0> La aplicación móvil OrgWiki para iOS y Android es una herramienta imprescindible, </0> con excelentes características como:",
            "onboarding_mobile.contact_book": "<0> Libro de contactos </0> con perfiles para todos en la empresa",
            "onboarding_mobile.name_game":
                "<0> Juegos de nombres </0> para ayudar a aprender los nombres y las caras de los compañeros de trabajo (iOS).",
            "onboarding_mobile.callerid":
                "<0>Identificador de llamadas mejorado</0> le permite saber cuándo están llamando los compañeros de trabajo.",
            "onboarding_mobile.floor_plans":
                "<0> Planos de planta </0> de oficinas para que pueda encontrar compañeros de trabajo y salas de conferencias.",
            "onboarding_mobile.enter_your_number":
                "<0>Introduzca su número de móvil</0> y le enviaremos un mensaje de texto con el enlace de descarga:",
            "onboarding_mobile.send_link": "Enviar enlace de descarga",
            "onboarding_mobile.continue": "Continuar",
            "onboarding_mobile.finish_profile_setup": "Terminar la configuración de mi perfil",
            "onboarding_mobile.download_the_app": "Descargar la aplicación:",
            "onboarding_profile_overlay.title": "¡Bienvenido a su perfil!",
            "onboarding_profile_overlay.subtitle":
                "<0> ¡Has comenzado bien!</0> Desde aquí le alentamos a que revise su perfil y se asegure de que todo sea correcto. Puede editar esto en cualquier momento.",
            "onboarding_profile_overlay.close_guide": "Cerrar guía de bienvenida",
            "onboarding_profile_overlay.things_you_can_do":
                "Estas son algunas de las cosas que puede hacer con OrgWiki:",
            "onboarding_profile_overlay.visit_feed_posts_enabled":
                "¡Visite el feed para ver las publicaciones de sus compañeros de trabajo, las nuevas contrataciones, los cambios de gerente y mucho más!",
            "onboarding_profile_overlay.visit_feed_posts_disabled":
                "¡Visite el feed para ver las nuevas contrataciones, los cambios de gerente y mucho más!",
            "onboarding_profile_overlay.search_coworkers":
                "Busque compañeros de trabajo por nombre, título o cualquier palabra clave en su perfil",
            "onboarding_profile_overlay.join_communities": "Únase a algunas comunidades de intereses comunes",
            "onboarding_profile_overlay.add_tag": "Añada {{tagLabel, true}} a su perfil",
            "onboarding_profile_overlay.view_offices": "Ver información sobre las oficinas de {{companyName, text}}",
            "onboarding_profile_overlay.join_dlists": "Navegar y unirse a listas de distribución",
            "language_picker.header": "Preferencia de idioma",
            "employee_badge.out_of_office": "Fuera de la oficina",
            "banners.get_started": "Empezar",
            "banners.claim_30_day_trial": "¿Cómo lo ve? ¡Reclame su prueba de 30 días!",
            "banners.welcome_to_orgwiki": "¡Bienvenido a OrgWiki! Por favor, recuerde <1> completar su perfil.</1>",
            "banners.launching_orgwiki_will_email":
                "<0> El lanzamiento de OrgWiki </0> borrará las entradas de noticias y enviará un correo electrónico de bienvenida a todos los usuarios aprovisionados.",
            "banners.not_yet": "Aún no",
            "banners.orgwiki_prelaunch_mode": "OrgWiki está en modo de pre-lanzamiento.",
            "banners.launch_orgwiki":
                "<0> Inicie OrgWiki </0> cuando esté listo para implementarlo a todos los empleados.",
            "banners.orgwiki_not_rolled_out": "OrgWiki aún no se ha extendido a todos los empleados.",
            "banners.feed_suspended_on": "El feed del organigrama se suspendió el {{date, MM/DD/YYYY hh:mm:ss A}}",
            "banners.feed_suspended_started":
                "El feed del organigrama se suspenderá a partir del {{date, MM/DD/YYYY hh:mm:ss A}}",
            "footer.copyright": "Copyright ©2013—{{date, YYYY}} <2>Veeva</2>. Todos los derechos reservados.",
            "footer.about": "Acerca de OrgWiki",
            "footer.legal": "Legal",
            "footer.contact": "Contacto",
            "field_value_blank": "Este campo no puede estar vacío",
            "invalid_email_domain": "El dominio de correo electrónico no está asociado con esta empresa.",
            "invalid_img_format": "Formato de imagen no válido. Debe ser GIF, JPG o PNG.",
            "adding_inactive_manager": "No es posible añadir gerente no activo.",
            "circular_mgr_change":
                "Gerente no válido. {{report, text}} no puede presentar informes a {{manager, text}} porque {{manager, text}} es responsable de informar ante {{report, text}}.",
            "adding_mgr_to_inactive_employees": "No es posible añadir gerente a empleados no activos.",
            "deleting_active_employees": "Solo se pueden eliminar empleados no activos. "
        }
    }
}
