import { useEffect } from 'react'

export const useTitle = (title: string, skip = false) => {
    useEffect(() => {
        if (skip) {
            return
        }
        if (title) document.title = title
    }, [title, skip])
}
