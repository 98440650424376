import React from 'react'
import Bowser from 'bowser'
import { useTitle } from '~/react/common-components/hooks/useTitle'

const RedirectPage = () => {
    const browser = Bowser.getParser(globalThis.navigator.userAgent)
    const isAndroid = browser.getOSName() === 'Android'
    const isIOS = browser.getOSName() === 'iOS'

    useTitle('Download OrgWiki Mobile')

    if (isIOS) {
        globalThis.location.assign('https://itunes.apple.com/us/app/orgwiki/id1164733100')
    } else if (isAndroid) {
        globalThis.location.assign('https://play.google.com/store/apps/details?id=com.orgwiki.android')
    }

    return (
        <div className="two-column userOnboarding">
            <div className="leftColContainer onboardStep-5">
                <div className="leftColContent">
                    <h3 className="onboardTitle">Get the OrgWiki Mobile App</h3>
                    <div className="appstoreOptions center-align-content">
                        <a href="https://goo.gl/sVmXy6">
                            <img
                                height="40px"
                                src="/welcome/images/app-badge-ios.png"
                                alt="Download on the App Store"
                            />
                        </a>
                        <span>
                            <a href="https://goo.gl/jSdVHa">
                                <img
                                    height="40px"
                                    alt="Get it on Google Play"
                                    src="/welcome/images/app-badge-android.png"
                                />
                            </a>
                        </span>
                    </div>
                    <hr />
                    <p>
                        <strong>The OrgWiki mobile app for iOS and Android is a must-have, </strong>
                        with great features such as:
                    </p>
                    <ul className="onboardFeatures">
                        <li>
                            <strong>Contact book</strong> with profiles for everyone in the company
                        </li>
                        <li>
                            <strong>Name Games</strong> to help learn names and faces of coworkers (iOS).
                        </li>
                        <li>
                            <strong>Enhanced CallerID</strong> lets you know when coworkers are calling.
                        </li>
                        <li>
                            <strong>Floor plans</strong> of offices so you can find coworkers and conference rooms.
                        </li>
                    </ul>
                </div>
            </div>

            <div className="rightColContainer onboardStep-5">
                <div className="rightColContent">
                    <h3>Your whole company in your pocket!</h3>
                    <div className="mobilePreview">
                        <div className="phoneScreen">
                            <div className="customerLogo">
                                <img className="branding-home-img" src="../../static/orgwiki/img/V-logo.png" />
                            </div>
                            <div className="theSearch"></div>
                            <div className="theContacts">
                                <div className="avatarBase ico-user-photo peerPhoto pp2"></div>
                                <div className="avatarBase ico-user-photo peerPhoto pp3"></div>
                                <div className="avatarBase ico-user-photo peerPhoto pp4"></div>
                                <div className="avatarBase ico-user-photo peerPhoto pp5"></div>
                                <div className="avatarBase ico-user-photo peerPhoto pp6"></div>
                                <div className="avatarBase ico-user-photo peerPhoto pp7"></div>
                                <div className="avatarBase ico-user-photo peerPhoto pp8"></div>
                                <div className="avatarBase ico-user-photo peerPhoto pp9"></div>
                                <div className="avatarBase ico-user-photo peerPhoto pp10"></div>
                            </div>

                            <div className="theBar">
                                <div className="theStatusBar"></div>
                                <div className="theNotch"></div>
                            </div>
                            <div className="theTabs"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RedirectPage
